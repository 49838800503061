import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { NgChartsModule } from 'ng2-charts';
import { AuthModule } from '../../modules/auth/auth.module';
import { SharedModule } from '../../modules/shared/shared.module';
import { BikeRoutingModule } from './bike-routing.module';
import { ListBikeComponent } from './pages/bikes/list-bike/list-bike.component';
import { ListModelComponent } from './pages/models/list-model/list-model.component';
import { ListVersionComponent } from './pages/versions/softwares/list-version/list-version.component';
import { ViewVersionComponent } from './pages/versions/softwares/view-version/view-version.component';
import { CreateVersionComponent } from './pages/versions/softwares/create-version/create-version.component';
import { TestNextVersionComponent } from './pages/versions/softwares/test-next-version/test-next-version.component';
import { ListFirmwareComponent } from './pages/versions/firmwares/list-firmware/list-firmware.component';
import { ViewModelComponent } from './pages/models/view-model/view-model.component';
import { ImportBikeComponent } from './pages/bikes/import-bike/import-bike.component';
import { ListSegmentComponent } from './pages/segments/list-segment/list-segment.component';
import { ViewFirmwareComponent } from './pages/versions/firmwares/view-firmware/view-firmware.component';
import { CreateFirmwareComponent } from './pages/versions/firmwares/create-firmware/create-firmware.component';
import { UpdateFirmwareComponent } from './pages/versions/firmwares/update-firmware/update-firmware.component';
import { DetailsComponent } from './pages/bikes/view-bike/details/details.component';
import { ViewBike2Component } from './pages/bikes/view-bike/view-bike.component';
import { ProvisioningComponent } from './pages/bikes/view-bike/provisioning/provisioning.component';
import { AutodiagComponent } from './pages/bikes/view-bike/autodiag/autodiag.component';
import { RidesComponent } from './pages/bikes/view-bike/rides/rides.component';
import { ServicesComponent } from './pages/bikes/view-bike/services/services.component';
import { BatteryComponent } from './pages/bikes/view-bike/battery/battery.component';
import { AutonomyComponent } from './pages/bikes/view-bike/autonomy/autonomy.component';
import { TimelineComponent } from './pages/bikes/view-bike/timeline/timeline.component';
import { ConnectivityComponent } from './pages/bikes/view-bike/connectivity/connectivity.component';
import { ListBatteryComponent } from './pages/batteries/list-batteries/list-battery.component';
import { ViewBatteryComponent } from './pages/batteries/view-battery/view-battery.component';
import { ProvisioningLogsComponent } from './pages/bikes/view-bike/provisioning/directives/provisioning-logs/provisioning-logs.component';
import { BikeModelLinkComponent } from './components/bike_model_link/bike-model-link.component';
import { SegmentComponent } from './components/segment_link/segment.component';
import { VersionsComponent } from './pages/versions/versions.component';
import { SimCardDetailsComponent } from './components/sim-card-details/sim-card-details.component';
import { ListCockpitComponent } from './pages/cockpits/list-cockpits/list-cockpit.component';
import { ViewCockpitComponent } from './pages/cockpits/view-cockpit/view-cockpit.component';
import { ListSimComponent } from './pages/sims/list-sims/list-sim.component';
import { ViewSimComponent } from './pages/sims/view-sim/view-sim.component';
import { GSMComponent } from './pages/bikes/view-bike/gsm/gsm.component';
import { SavComponent } from './pages/bikes/view-bike/sav/sav.component';
import { BikeSummaryComponent } from './components/bike_summary/bike-summary.component';
import { SimcardStatusComponent } from './components/simcard-status/simcard-status.component';
import { SimcardProviderComponent } from './components/simcard-provider/simcard-provider.component';
import { AceModule } from 'ngx-ace-wrapper';
import { BikesSupervisionComponent } from './pages/bikes/bikes-supervision/bikes-supervision.component';
import { TrendsComponent } from './components/trends/trends.component';

@NgModule({
  declarations: [
    ListBikeComponent,
    ListModelComponent,
    ListVersionComponent,
    ViewVersionComponent,
    CreateVersionComponent,
    TestNextVersionComponent,
    ViewModelComponent,
    ImportBikeComponent,
    ListSegmentComponent,
    ListFirmwareComponent,
    ViewFirmwareComponent,
    CreateFirmwareComponent,
    UpdateFirmwareComponent,
    ViewBike2Component,
    DetailsComponent,
    ProvisioningComponent,
    AutodiagComponent,
    RidesComponent,
    ServicesComponent,
    BatteryComponent,
    AutonomyComponent,
    TimelineComponent,
    ConnectivityComponent,
    ListBatteryComponent,
    ViewBatteryComponent,
    ProvisioningLogsComponent,
    BikeModelLinkComponent,
    SegmentComponent,
    VersionsComponent,
    SimCardDetailsComponent,
    ListCockpitComponent,
    ViewCockpitComponent,
    ListSimComponent,
    ViewSimComponent,
    GSMComponent,
    SavComponent,
    BikeSummaryComponent,
    SimcardStatusComponent,
    SimcardProviderComponent,
    BikesSupervisionComponent,
    TrendsComponent,
  ],
  imports: [
    CommonModule,
    AuthModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ClarityModule,
    BikeRoutingModule,
    NgChartsModule,
    AceModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BikeModule {}
