<div class="content-area">
  <div class="clr-row clr-justify-content-center">
    <div class="clr-col-md-4 clr-col-12">
      <form clrForm [formGroup]="loginForm" class="clr-form-horizontal" (ngSubmit)="onSubmit()">
        <div class="card">
          <div class="card-block">
            <h3 class="card-title" i18n>Login</h3>
            <clr-alert [clrAlertType]="'danger'" [clrAlertClosed]="!errors?.general">
              <div clr-alert-item class="alert-item">
                <span class="alert-text">
                  {{ errors?.general }}
                </span>
              </div>
            </clr-alert>

            <p class="card-text">
              <clr-input-container>
                <label i18n>Email</label>
                <input clrInput type="email" formControlName="email" name="email" autocomplete="email" required />
              </clr-input-container>
              <clr-input-container>
                <label i18n>Password</label>
                <input
                  clrInput
                  type="password"
                  formControlName="password"
                  name="password"
                  autocomplete="current-password"
                  required
                />
              </clr-input-container>
            </p>
          </div>
          <div class="card-footer">
            <input type="submit" class="btn btn-primary" [disabled]="!loginForm.valid" value="Login" i18n />
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
