import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from 'src/app/modules/api/services/api.service';
import { ResponseEntities } from 'src/app/modules/api/models/response-entities.model';
import { catchError, map } from 'rxjs/operators';
import { definitiveCache } from 'src/app/modules/api/definitive-cache/definitive-cache';
import { ErrorsData, KmData, SupervisionByBike } from '../models/bike-supervision.model';

@Injectable({
  providedIn: 'root',
})
export class SupervisionService {
  constructor(private api: ApiService) {}

  private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message} with error : ${error}`);

      return of(result as T);
    };
  }

  public loadBikesSupervision(params: any): Observable<ResponseEntities<SupervisionByBike>> {
    return this.api.get<ResponseEntities<SupervisionByBike>>(`/supervision/bikes`, { params }).pipe(
      map((a) => new ResponseEntities<SupervisionByBike>(SupervisionByBike, a)),
      catchError(this.handleError<ResponseEntities<SupervisionByBike>>('all')),
      definitiveCache(),
    );
  }

  public loadWeeklyBikesSupervision(params: any): Observable<ResponseEntities<SupervisionByBike>> {
    return this.api.get<ResponseEntities<SupervisionByBike>>(`/supervision/weekly/bikes`, { params }).pipe(
      map((a) => new ResponseEntities<SupervisionByBike>(SupervisionByBike, a)),
      catchError(this.handleError<ResponseEntities<SupervisionByBike>>('all')),
      definitiveCache(),
    );
  }

  public loadSegmentWeeklySupervisionKmData(params: any): Observable<KmData> {
    return this.api.get<KmData>(`/supervision/weekly/km`, { params });
  }

  public loadSegmentWeeklySupervisionErrorsData(params: any): Observable<ErrorsData> {
    return this.api.get<ErrorsData>(`/supervision/weekly/errors`, { params });
  }

  public loadSegmentSupervisionKmData(params: any): Observable<KmData> {
    return this.api.get<KmData>(`/supervision/km`, { params });
  }

  public loadSegmentSupervisionErrorsData(params: any): Observable<ErrorsData> {
    return this.api.get<ErrorsData>(`/supervision/errors`, { params });
  }
}
