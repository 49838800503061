<h1 i18n>Bike Segments</h1>

<clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
  <clr-dg-column i18n>ID</clr-dg-column>
  <clr-dg-column i18n>Name</clr-dg-column>

  <clr-dg-row *ngFor="let segment of segments" [clrDgItem]="segment">
    <clr-dg-cell>{{ segment.id }}</clr-dg-cell>
    <clr-dg-cell>{{ segment.name }}</clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer i18n>
    {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} versions
    <clr-dg-pagination #pagination [clrDgTotalItems]="total"></clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
