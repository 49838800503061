import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from './modules/auth/services/authentication.service';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public isAuthenticated: boolean;

  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  public ngOnInit(): void {
    this.isAuthenticated = this.authenticationService.isAuthenticated();

    this.authenticationService.authenticationSuccess$.pipe(untilDestroyed(this)).subscribe((user) => {
      // this.logger.debug('Authentication Success');

      this.isAuthenticated = this.authenticationService.isAuthenticated();
    });

    this.authenticationService.logout$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.isAuthenticated = this.authenticationService.isAuthenticated();

      this.router.navigate(['/account', 'login']);
    });
  }

  public ngOnDestroy(): void {}

  public onLogout(event: Event): void {
    event.preventDefault();

    this.authenticationService.logout();
  }
}
