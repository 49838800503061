import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'simcard-provider',
  templateUrl: './simcard-provider.component.html',
  styleUrls: ['./simcard-provider.component.scss'],
})
export class SimcardProviderComponent implements OnInit {
  @Input()
  provider: string;

  constructor() {}

  ngOnInit(): void {}
}
