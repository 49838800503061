import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/modules/api/services/api.service';
import { Observable, of } from 'rxjs';
import { ResponseEntities } from 'src/app/modules/api/models';
import { User } from '../models/user.model';
import { UserProfile } from '../models/user-profile.model';
import { Bike, CustomerBike } from '../../bike/models/bike.model';
import { catchError, map } from 'rxjs/operators';
import { definitiveCache } from 'src/app/modules/api/definitive-cache/definitive-cache';
import { UserSettings } from '../models/user-settings.model';
import { UserKnownPhone } from '../models/user-known-phone.model';
import { CguValidation } from '../models/cgu-validation.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private api: ApiService) {}

  private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message} with error : ${error}`);

      return of(result as T);
    };
  }

  public getAllFiltered(params: { [prop: string]: any }): Observable<ResponseEntities<User>> {
    return this.api
      .get<ResponseEntities<User>>('/customers/search/findAllWithSearchFilters', {
        params: {
          projection: 'customerListAdmin',
          ...params,
        },
      })
      .pipe(
        map((a) => new ResponseEntities<User>(User, a)),
        catchError(this.handleError<ResponseEntities<User>>('all_filtered')),
        definitiveCache(),
      );
  }

  public getAll(params: { [prop: string]: any }): Observable<ResponseEntities<User>> {
    return this.api
      .get<ResponseEntities<User>>(`/customers`, {
        params: {
          projection: 'customerListAdmin',
          ...params,
        },
      })
      .pipe(
        map((a) => new ResponseEntities<User>(User, a)),
        catchError(this.handleError<ResponseEntities<User>>('all')),
        definitiveCache(),
      );
  }

  public getById(userId: string): Observable<User> {
    return this.api.get<User>(`/customers/${userId}`, {
      params: {
        projection: 'customerFullAdmin',
      },
    });
  }

  public getUserBikes(userId: string): Observable<ResponseEntities<Bike>> {
    return this.api.get<ResponseEntities<Bike>>(`/customers/${userId}/bikes`).pipe(
      map((a) => new ResponseEntities<Bike>(Bike, a)),
      catchError(this.handleError<ResponseEntities<Bike>>('getCustomerBikes')),
      definitiveCache(),
    );
  }

  public updateProfileById(userId: string, user: UserProfile): Observable<User> {
    return this.api.patch<User>(`/customers/${userId}/profile`, user);
  }

  public remove(userId: string): Observable<object> {
    return this.api.delete<any>(`/customers/${userId}`);
  }

  public restore(userId: string): Observable<object> {
    return this.api.patch(`/customers/${userId}/restore`, {});
  }

  public getSettings(userId: string): Observable<UserSettings> {
    return this.api.get<UserSettings>(`customers/${userId}/settings`);
  }

  public getKnownPhones(userId: string): Observable<UserKnownPhone[]> {
    return this.api.get<UserKnownPhone[]>(`customers/${userId}/known-phones`);
  }

  public revokeDevice(userId: string, refreshToken: string): Observable<any> {
    return this.api.delete<any>(`/customers/${userId}/refresh-token`, { params: { refreshTokenValue: refreshToken } });
  }

  public revokeAccessToken(userId: string, accessToken: string): Observable<any> {
    return this.api.delete<any>(`/customers/${userId}/access-token`, { params: { accessTokenValue: accessToken } });
  }

  public getAllCustomerBikes(customerId: string): Observable<ResponseEntities<CustomerBike>> {
    return this.api.get<CustomerBike[]>(`/customerBikes?customerId=${customerId}`).pipe(
      map((a) => new ResponseEntities<CustomerBike>(CustomerBike, a)),
      catchError(this.handleError<ResponseEntities<CustomerBike>>('all')),
      definitiveCache(),
    );
  }

  addUser(userProfile: UserProfile) {
    return this.api.post<UserProfile>('/customers', userProfile).pipe(
      map((a) => new User(a)),
      catchError(this.handleError<User>('create')),
      definitiveCache(),
    );
  }

  getCguHistory(id: string): Observable<CguValidation[]> {
    return this.api.get<CguValidation[]>(`/customers/${id}/cgus`).pipe(
      map((a: CguValidation[]) => a.map((cgu: CguValidation) => new CguValidation(cgu))),
      catchError(this.handleError<CguValidation[]>('all')),
      definitiveCache(),
    );
  }
}
