import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/modules/api/services/api.service';
import { Observable, of } from 'rxjs';
import { NextVersion } from 'src/app/pages/bike/models/next-version.model';
import { ResponseEntities, Status } from 'src/app/modules/api/models';
import { Descriptor, SoftwarePackages } from '../models/software-packages.model';
import { map, catchError } from 'rxjs/operators';
import { definitiveCache } from 'src/app/modules/api/definitive-cache/definitive-cache';
import { VersionStat } from '../models/version-stat.model';
import { HttpHeaders } from '@angular/common/http';
import { Segment } from '../models/segment.model';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  constructor(private api: ApiService) {}

  private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message} with error : ${error}`);

      return of(result as T);
    };
  }

  public addSegment(id: string, name: string): Observable<Segment> {
    return this.api.post<Segment>(`/software-package/${id}/segments`, {
      name,
    });
  }

  public removeSegment(softwarePackageId: string, segmentId: string): Observable<boolean> {
    return this.api.delete<boolean>(`/software-package/${softwarePackageId}/segments/${segmentId}`);
  }

  public getAll(params: { [param: string]: string }): Observable<ResponseEntities<SoftwarePackages>> {
    params.projection = 'softwarePackageListExcerpt';

    return this.api
      .get<ResponseEntities<SoftwarePackages>>('/softwarepackages', {
        params,
      })
      .pipe(
        map((a) => new ResponseEntities<SoftwarePackages>(SoftwarePackages, a)),
        catchError(this.handleError<ResponseEntities<SoftwarePackages>>('getAll')),
        definitiveCache()
      );
  }

  public checkNextVersion(
    mac: string,
    appVersion: string,
    bluetoothVersion: string,
    imageVersion: string
  ): Observable<NextVersion> {
    return this.api.post<NextVersion>(`/upgrade/nextVersion/check/${mac}`, {
      bluetoothVersion,
      imageVersion,
      appVersion,
      doNotPersistCurrentVersion: true,
    });
  }

  public get(id: string): Observable<SoftwarePackages> {
    return this.api
      .get<SoftwarePackages>(`/softwarepackages/${id}`, {
        params: {
          projection: 'softwarePackageDetails',
        },
      })
      .pipe(
        map((a) => new SoftwarePackages(a)),
        catchError(this.handleError<SoftwarePackages>('get')),
        definitiveCache()
      );
  }

  public postTestNextVersion(
    appVersion: string,
    bluetoothVersion: string,
    imageVersion: string,
    segments: Segment[]
  ): Observable<NextVersion> {
    return this.api
      .post<NextVersion>('/upgrade/nextVersion/test', {
        versions: {
          bluetoothVersion,
          imageVersion,
          appVersion,
        },
        segments,
      })
      .pipe(
        map((a) => new NextVersion(a)),
        catchError(this.handleError<NextVersion>('postTestNextVersion')),
        definitiveCache()
      );
  }

  public postCreateVersion(formData: FormData): Observable<any> {
    return this.api
      .post<NextVersion>('/upgrade/nextVersion', formData, {
        headers: new HttpHeaders({
          'Content-Type': '',
        }),
      })
      .pipe(definitiveCache());
  }

  public delete(id: string): Observable<any> {
    return this.api.delete<ResponseEntities<SoftwarePackages>>(`/softwarepackages/${id}`);
  }

  public enable(id: string, newState: boolean): Observable<Status> {
    return this.api.put<Status>(`/softwarepackages/${id}/${newState}`, {});
  }

  public updateAutodiagDescriptor(id: string, content: string): Observable<any> {
    return this.api.patch<Descriptor>(`/softwaredescriptors/${id}`, { content });
  }

  public stats(type: string): Observable<VersionStat[]> {
    return this.api.get<VersionStat[]>('/version/statistics', {
      params: {
        filter: type,
      },
    });
  }
}
