import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { flatMap } from 'rxjs/operators';
import { ShopService } from '../../../../services/shop.service';
import { Shop } from '../../../../models/shop.model';
import { RetailerService } from '../../../../services/retailer.service';

@UntilDestroy()
@Component({
  selector: 'app-view-shop',
  templateUrl: './view-shop.component.html',
  styleUrls: ['./view-shop.component.scss'],
})
export class ViewShopComponent implements OnInit, OnDestroy {
  public shop: Shop;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private shopService: ShopService,
    private retailerService: RetailerService
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        untilDestroyed(this),
        flatMap((params) => this.shopService.get(params.id))
      )
      .subscribe((shop) => {
        this.shop = shop;
      });
  }

  ngOnDestroy(): void {}

  onDelete(id: string) {
    this.shopService
      .remove(id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => this.router.navigate(['pro/shops']),
        error: (err) => window.alert(`Unable to delete shop : ${err}`),
      });
  }
}
