import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, flatMap, switchMap } from 'rxjs/operators';
import { VersionService } from '../../../../services/version.service';
import { Descriptor, SoftwarePackages } from '../../../../models/software-packages.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Segment } from '../../../../models/segment.model';
import { SegmentService } from '../../../../services/segment.service';
import { segmentPattern } from '../../../../../../modules/shared/validators/segments.validator';

const DESCRIPTOR_TYPE_AUTODIAG = 'autodiag';
const SOFTWARE_TYPE_APP = 'APP';

@UntilDestroy()
@Component({
  selector: 'app-view-version',
  templateUrl: './view-version.component.html',
  styleUrls: ['./view-version.component.scss'],
})
export class ViewVersionComponent implements OnInit, OnDestroy {
  addSegmentForm: UntypedFormGroup;

  public version: SoftwarePackages;

  public segments: Segment[] = [];

  public autodiagDescriptor: Descriptor;

  public readonly SOFTWARE_TYPE_APP: string = SOFTWARE_TYPE_APP;

  openAutodiagModal: boolean = false;
  editAutodiag: boolean = false;
  updateAutodiagForm: UntypedFormGroup;
  updateAutodiagFormError: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private versionService: VersionService,
    private segmentService: SegmentService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        untilDestroyed(this),
        flatMap((params) => this.versionService.get(params.id))
      )
      .subscribe((version) => {
        console.log('version:', version);
        this.version = version;
        this.autodiagDescriptor = this.version?.softwares
          ?.find((s) => s.softwareType === SOFTWARE_TYPE_APP)
          .descriptors?.find((d) => d.type === DESCRIPTOR_TYPE_AUTODIAG);
        this.updateAutodiagForm.get('content').setValue(this.autodiagDescriptor?.content);
      });

    this.updateAutodiagForm = this.formBuilder.group({ content: [{ value: '', disabled: true }, Validators.required] });

    this.addSegmentForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(segmentPattern)]],
    });

    this.addSegmentForm.controls.name.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(150),
        switchMap((term) => this.segmentService.search(term))
      )
      .subscribe((segments) => {
        console.log(segments);
        this.segments = segments;
      });
  }

  ngOnDestroy(): void {}

  public onDelete(version: SoftwarePackages): void {
    this.versionService
      .delete(version.id)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.router.navigate(['/bikes', 'versions']);
      });
  }

  public onDisable(version: SoftwarePackages): void {
    this.changeEnableState(version, false);
  }

  public onEnable(version: SoftwarePackages): void {
    this.changeEnableState(version, true);
  }

  public onSubmitSegment(): void {
    // stop here if form is invalid
    if (this.addSegmentForm.invalid) {
      return;
    }

    this.versionService
      .addSegment(this.version.id, this.addSegmentForm.get('name').value)
      .pipe(untilDestroyed(this))
      .subscribe((segment) => {
        this.version.segments.push(segment);

        this.addSegmentForm.reset();
      });
  }

  public onDeleteSegment(segment: Segment): boolean {
    this.versionService
      .removeSegment(this.version.id, segment.id)
      .pipe(untilDestroyed(this))
      .subscribe((ok) => {
        this.version.segments = this.version.segments.filter((s) => s.id !== segment.id);
      });

    return false;
  }

  public enterAutodiagEditMode() {
    this.editAutodiag = true;
    this.updateAutodiagForm.get('content').enable();
  }

  public cancelAutodiagEditMode() {
    this.updateAutodiagForm.get('content').setValue(this.autodiagDescriptor.content);
    this.exitAutodiagEditMode();
  }

  private exitAutodiagEditMode() {
    this.updateAutodiagFormError = '';
    this.editAutodiag = false;
    this.updateAutodiagForm.get('content').disable();
  }

  public updateAutodiagDescriptor() {
    this.updateAutodiagFormError = '';

    // stop here if form is invalid
    if (this.updateAutodiagForm.invalid) {
      return;
    }

    const content = this.updateAutodiagForm.get('content').value;
    this.versionService
      .updateAutodiagDescriptor(this.autodiagDescriptor.id, content)
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        if (response.success) {
          this.autodiagDescriptor.content = content;
          this.exitAutodiagEditMode();
        } else {
          this.updateAutodiagFormError = 'Oops, something went wrong... Please try again.';
        }
      });
  }

  private changeEnableState(version: SoftwarePackages, newState: boolean): void {
    this.versionService
      .enable(version.id, newState)
      .pipe(untilDestroyed(this))
      .subscribe((status) => {
        if (status.success) {
          this.version.enable = newState;
        }
      });
  }
}
