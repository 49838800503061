<h1 i18n>Update Firmware</h1>

<ng-container *ngIf="firmware">

  <form clrForm [formGroup]="updateFirmwareForm" (ngSubmit)="onSubmit()" *ngIf="!firmwareLoading">
    <clr-alert [clrAlertType]="'danger'" [clrAlertClosed]="!error">
      <div clr-alert-item class="alert-item">
      <span class="alert-text">
        {{ error }}
      </span>
      </div>
    </clr-alert>

    <div class="clr-row">
      <div class="clr-col-xl-4 clr-col-sm-12">
        <h3 i18n>Firmware</h3>

        <clr-select-container>
          <label i18n>Type</label>
          <select clrSelect formControlName="type">
            <option *ngFor="let type of firmwareTypes" [value]="type">{{type}}</option>
          </select>
        </clr-select-container>

        <clr-input-container>
          <label i18n>Version</label>
          <input clrInput type="text" formControlName="version" placeholder="1.2.3"/>
        </clr-input-container>
      </div>

      <div class="clr-col-xl-6 clr-col-sm-12">

        <h3 i18n>Targets</h3>

        <clr-checkbox-container clrInline>
          <label>Distribution</label>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="distributionMediaBLE" value="BLUETOOTH" formControlName="distributionMediaBLE"/>
            <label>BLE</label>
          </clr-checkbox-wrapper>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="distributionMediaLTE" value="LTE" formControlName="distributionMediaLTE"/>
            <label>LTE</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>
        <clr-combobox-container>
          <label>Models</label>
          <clr-combobox
            formControlName="models"
            name="multiSelect"
            clrMulti="true"
            placeholder="Starts typing..."
            [clrLoading]="modelsLoading"
          >
            <ng-container *clrOptionSelected="let selected">
              <cds-icon shape="bicycle" role="img"></cds-icon>
              {{ selected?.name }}
            </ng-container>
            <clr-options>
              <clr-option *clrOptionItems="let state of models; field: 'name'" [clrValue]="state">
                <cds-icon shape="bicycle" role="img"></cds-icon>
                {{ state.name }}
              </clr-option>
            </clr-options>
          </clr-combobox>
        </clr-combobox-container>

        <clr-combobox-container>
          <label>Segments</label>
          <clr-combobox
            formControlName="segments"
            name="multiSelect"
            clrMulti="true"
            placeholder="Starts typing..."
            [clrLoading]="segmentsLoading"
            (clrInputChange)="fetchSegments($event)"
            (clrOpenChange)="$event ? fetchSegments('') : null"
          >
            <ng-container *clrOptionSelected="let selected">
              <cds-icon shape="tags" role="img"></cds-icon>
              {{ selected?.name }}
            </ng-container>
            <clr-options>
              <clr-option *clrOptionItems="let state of segments; field: 'name'" [clrValue]="state">
                <cds-icon shape="tags" role="img"></cds-icon>
                {{ state.name }}
              </clr-option>
            </clr-options>
          </clr-combobox>
        </clr-combobox-container>


      </div>

      <div class="clr-col-xl-12 clr-col-sm-12">
        <h3 i18n>Requirements</h3>

        <div class="clr-row">
          <div class="clr-col-xl-4 clr-col-lg-12">
            <table class="table table-vertical">
              <tbody>
              <tr>
                <th>Firmwares</th>
                <td>

                  <div formArrayName="firmwareConstraints">
                    <div class="firmwareConstraints">
                      <span class="label label-info"
                            *ngFor="let control of firmwareConstraints().controls; index as i">
                        {{ control.value.moduleType }}&nbsp;&gt; {{ control.value.version }}
                        <a href (click)="!!removeConstraint('FIRMWARE', i)">
                          <cds-icon shape="times"></cds-icon>
                        </a>
                      </span>
                      <span *ngIf="firmwareConstraints().length == 0">-</span>
                    </div>
                  </div>
              </tr>
              <tr>
                <th>Hardwares</th>
                <td>

                  <div formArrayName="hardwareConstraints">
                    <div class="constraints">
                      <span class="label label-info"
                            *ngFor="let control of hardwareConstraints().controls; index as i">
                        {{ control.value.moduleType }}&nbsp;&gt; {{ control.value.version }}
                        <a href (click)="!!removeConstraint('HARDWARE', i)">
                          <cds-icon shape="times"></cds-icon>
                        </a>
                      </span>
                      <span *ngIf="hardwareConstraints().length == 0">-</span>
                    </div>
                  </div>
              </tr>
              <tr>
                <th>Options</th>
                <td>

                  <div formArrayName="optionConstraints">
                    <div class="constraints">
                      <span class="label label-info"
                            *ngFor="let control of optionConstraints().controls; index as i">
                        {{ control.value.moduleType }}&nbsp;&gt; {{ control.value.version }}
                        <a href (click)="!!removeConstraint('OPTION', i)">
                          <cds-icon shape="times"></cds-icon>
                        </a>
                      </span>
                      <span *ngIf="optionConstraints().length == 0">-</span>
                    </div>
                  </div>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="clr-col-xl-8 clr-col-lg-12">
            <form class="clr-form add-constraint-form" [formGroup]="addConstraintForm">
              <div class="requirement-title">Add a requirement :</div>
              <div class="clr-row clr-align-content-center">
                <div class="clr-col-xl-3 clr-col-md-6 clr-col-sm-12">
                  <label for="requirement-type" class="clr-control-label">Type</label>
                  <div class="clr-control-container">
                    <div class="clr-select-wrapper">
                      <select formControlName="constraintType" id="requirement-type">
                        <option *ngFor="let type of constraintTypes" [value]="type">{{type}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="clr-col-xl-3 clr-col-md-6 clr-col-sm-12">
                  <label for="module-type" class="clr-control-label">Module</label>
                  <div class="clr-control-container">
                    <div class="clr-select-wrapper">
                      <select formControlName="moduleType" id="module-type">
                        <option *ngFor="let type of moduleTypes[addConstraintForm.get('constraintType').value]"
                                [value]="type">{{type}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="clr-col-xl-3 clr-col-md-6 clr-col-sm-12">
                  <label for="requirement-version" class="clr-control-label">Version</label>
                  <div class="clr-control-container">
                    <div class="clr-input-wrapper">
                      <input type="text" id="requirement-version" placeholder="1.0.0" class="clr-input"
                             formControlName="version"/>
                    </div>
                  </div>
                </div>
                <div class="clr-col-xl-2 clr-col-md-6 clr-col-sm-12">
                  <button type="button" class="btn btn-sm btn-info-outline btn-icon" (click)="addConstraint()">
                    <cds-icon shape="plus"></cds-icon>
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="clr-flex-row" style="margin-top: 20px;">
      <button type="button" class="btn btn-primary-outline" (click)="onCancel()">
        Cancel
      </button>
      <button class="btn btn-success" (submit)="onSubmit()">
        Validate
      </button>
    </div>
  </form>
  <!-- .clr-row -->
</ng-container>
