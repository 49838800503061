import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/modules/api/services/api.service';
import { Observable, of } from 'rxjs';
import { ResponseEntities } from 'src/app/modules/api/models';
import { SmsLog } from '../models/sms-log.model';
import { MailLog } from '../models/mail-log.model';
import { PushLog } from '../models/push-log.model';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  constructor(private api: ApiService) {}

  private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message} with error : ${error}`);

      return of(result as T);
    };
  }

  public getAllHistory(
    type: string,
    params: { [prop: string]: any }
  ): Observable<ResponseEntities<SmsLog | MailLog | PushLog>> {
    let url = `/${type}History`;

    const options: any = {
      params,
    };

    if (options.params.customerId) {
      url = `/${type}History/search/findAllByCustomer_Id?sort=updateTrace.createdOn,desc`;
    }

    return this.api.get<ResponseEntities<SmsLog | MailLog | PushLog>>(url, options).pipe(
      map((a) => {
        switch (type) {
          case 'sms':
            return new ResponseEntities<SmsLog>(SmsLog, a);
          case 'mail':
            return new ResponseEntities<MailLog>(MailLog, a);
          default:
            return new ResponseEntities<PushLog>(PushLog, a);
        }
      }),
      catchError(this.handleError<ResponseEntities<SmsLog | MailLog | PushLog>>(`get${type}History`))
    );
  }
}
