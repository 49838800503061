import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appConfirm]',
})
export class ConfirmDirective {
  private message: string = 'Are you sure you want to delete it?';
  @Input()
  public set appConfirmSpecificMessage(value: string) {
    this.message = value;
  }

  @Output()
  appConfirm = new EventEmitter();

  @HostListener('click', ['$event'])
  public onClick($event: Event): void {
    const v = window.confirm(this.message);
    if (v) {
      this.appConfirm.emit();
    }
  }
}
