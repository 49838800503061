<div *ngIf="bike">
  <div class="clr-row">
    <div class="clr-col-12">
      <h2 i18n>Last 8 weeks Stats</h2>

      <div class="clr-row search-form-row">
        <div class="clr-col-lg-3 clr-col-md-6 clr-col-sm-6">
          <cds-date layout="horizontal" fixedControlWidth="true">
            <label i18n>From</label>
            <input type="date" [value]="statsFrom" (change)="onUpdateStatsFromDate($event)" />
          </cds-date>
        </div>
        <div class="clr-col-lg-3 clr-col-md-6 clr-col-sm-6">
          <span i18n>Last maintenance operation:</span>
          <span *ngIf="lastMaintenanceOperation != null">
            {{ lastMaintenanceOperation.endedOn | date: 'dd/MM/yy HH:mm' }} by
            {{ lastMaintenanceOperation.startedBy }}
          </span>
          <span *ngIf="lastMaintenanceOperation == null">&nbsp;-</span>
        </div>
      </div>
      <div class="spinner" *ngIf="statsLoading">
        <clr-spinner clrMedium i18n> Loading...</clr-spinner>
      </div>
      <div *ngIf="!statsLoading">
        <div *ngIf="stats.length == 0" class="no-data">No data.</div>
        <div *ngIf="stats.length > 0">
          <table class="table table-compact">
            <thead>
              <tr>
                <th i18n>Name</th>
                <th i18n>Detail</th>
                <th i18n>Description</th>
                <th i18n>TOTAL</th>
                <th *ngFor="let weekData of statsWeeksRange">W{{ weekData.week }} ({{ weekData.year }})</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let stat of stats; let i = index">
                <tr>
                  <td>
                    {{ stat.name }}
                  </td>
                  <td>
                    {{ stat.detail == null ? '-' : stat.detail }}
                  </td>
                  <td class="stat-description" [title]="stat.description">
                    {{ stat.description }}
                  </td>
                  <td class="td-stat-week">
                    <div class="stat-week">
                      <div class="stat-week-data stat-week-data-ride">{{ stat.totalInRide }}</div>
                      <div class="stat-week-data stat-week-data-all">{{ stat.total }}</div>
                    </div>
                  </td>
                  <td *ngFor="let weekData of stat.weeks" class="td-stat-week">
                    <div class="stat-week">
                      <div class="stat-week-data" [ngClass]="weekData.countRide == 0 ? '' : 'stat-week-data-ride'">
                        {{ weekData.countRide == 0 ? '-' : weekData.countRide }}
                      </div>
                      <div class="stat-week-data" [ngClass]="weekData.countAll == 0 ? '' : 'stat-week-data-all'">
                        {{ weekData.countAll == 0 ? '-' : weekData.countAll }}
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <div class="stats-legend">
            <div class="td-stat-week">
              <div class="stat-week">
                <div class="stat-week-data" class="stat-week-data stat-week-data-ride" i18n>during the ride</div>
                <div class="stat-week-data" class="stat-week-data stat-week-data-all" i18n>total</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="clr-col-12">
      <h2 i18n>Auto diagnostics</h2>

      <clr-accordion class="filters-accordion">
        <clr-accordion-panel [(clrAccordionPanelOpen)]="filtersOpened" class="filters-accordion-panel">
          <clr-accordion-title>Filters</clr-accordion-title>
          <clr-accordion-content *clrIfExpanded>
            <form
              clrForm
              [formGroup]="searchForm"
              (ngSubmit)="onSubmit(pagination)"
              [clrLayout]="'horizontal'"
              class="search-form clr-form-compact"
            >
              <div class="clr-row search-form-row">
                <div class="clr-col-xl-6 clr-col-sm-12">
                  <clr-checkbox-container clrInline>
                    <label>Was during a ride</label>
                    <clr-checkbox-wrapper>
                      <input
                        type="checkbox"
                        clrCheckbox
                        name="duringRideYes"
                        value="true"
                        formControlName="duringRideYes"
                      />
                      <label>Yes</label>
                    </clr-checkbox-wrapper>
                    <clr-checkbox-wrapper>
                      <input
                        type="checkbox"
                        clrCheckbox
                        name="duringRideNo"
                        value="false"
                        formControlName="duringRideNo"
                      />
                      <label>No</label>
                    </clr-checkbox-wrapper>
                  </clr-checkbox-container>
                </div>

                <div class="clr-col-xl-6 clr-col-sm-12">
                  <clr-input-container>
                    <label i18n>Ride id</label>
                    <input
                      size="40"
                      class="free-search"
                      clrInput
                      type="text"
                      placeholder="ex : 8e6cab49-2085-4bbe-97c9-7ae8282a650c"
                      formControlName="rideId"
                    />
                  </clr-input-container>
                </div>
                <div class="clr-col-xl-6 clr-col-sm-12">
                  <clr-input-container>
                    <label i18n>Category</label>
                    <input
                      size="40"
                      class="free-search"
                      clrInput
                      type="text"
                      placeholder="ex : battery_no_can"
                      formControlName="category"
                    />
                  </clr-input-container>
                </div>
                <div class="clr-col-xl-6 clr-col-sm-12">
                  <clr-input-container>
                    <label i18n>Detail</label>
                    <input
                      size="40"
                      class="free-search"
                      clrInput
                      type="text"
                      placeholder="ex : something written in error detail"
                      formControlName="detail"
                    />
                  </clr-input-container>
                </div>
                <div class="clr-col-xl-6 clr-col-sm-12">
                  <clr-input-container>
                    <label i18n>App Version</label>
                    <input
                      size="40"
                      class="free-search"
                      clrInput
                      type="text"
                      placeholder="ex : 0.0.1"
                      formControlName="version"
                    />
                  </clr-input-container>
                </div>
              </div>
              <div class="clr-row">
                <div class="clr-col-12">
                  <button class="btn btn-primary search-btn" type="submit" i18n>
                    <cds-icon shape="search"></cds-icon>
                  </button>
                </div>
              </div>
            </form>
          </clr-accordion-content>
        </clr-accordion-panel>
      </clr-accordion>

      <clr-datagrid (clrDgRefresh)="refreshBikeAutodiagHistory($event)" [clrDgLoading]="bikeAutodiagLoading">
        <clr-dg-column i18n>Created At</clr-dg-column>
        <clr-dg-column i18n>Version</clr-dg-column>
        <clr-dg-column i18n>Battery levels</clr-dg-column>
        <clr-dg-column i18n>Ride id</clr-dg-column>
        <clr-dg-column i18n>Errors</clr-dg-column>

        <clr-dg-row *ngFor="let bikeAutodiag of bikeAutodiags" [clrDgItem]="bikeAutodiag">
          <clr-dg-cell>{{ bikeAutodiag.createdOn | date: 'dd/MM/yy HH:mm' }}</clr-dg-cell>
          <clr-dg-cell>{{ bikeAutodiag.appVersion }}</clr-dg-cell>
          <clr-dg-cell>{{ bikeAutodiag.batteryLevels }}</clr-dg-cell>
          <clr-dg-cell>{{ bikeAutodiag.rideId ? bikeAutodiag.rideId : '-' }}</clr-dg-cell>
          <clr-dg-cell>
            <table class="table table-compact autodiag-table">
              <thead>
                <tr>
                  <th class="left" i18n>Error name</th>
                  <th class="left" i18n>Description</th>
                  <th class="left" i18n>Detail</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let err of bikeAutodiag.errors">
                  <td class="left">{{ err.name }}</td>
                  <td class="left">{{ err.description }}</td>
                  <td class="left">{{ err.detail }}</td>
                </tr>
              </tbody>
            </table>
          </clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer i18n>
          {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ bikeAutodiagTotal }} errors
          <clr-dg-pagination #pagination [clrDgTotalItems]="bikeAutodiagTotal"></clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>
    </div>

    <div *ngIf="showBikeErrors" class="clr-col-12">
      <h2 i18n>Errors</h2>

      <clr-datagrid (clrDgRefresh)="refreshBikeErrorHistory($event)" [clrDgLoading]="bikeErrorLoading">
        <clr-dg-column i18n>Error Code</clr-dg-column>
        <clr-dg-column i18n>Error Reason</clr-dg-column>
        <clr-dg-column i18n>Payload</clr-dg-column>
        <clr-dg-column i18n>Created At</clr-dg-column>

        <clr-dg-row *ngFor="let bikeError of bikeErrors" [clrDgItem]="bikeError">
          <clr-dg-cell>{{ bikeError.errorCode }}</clr-dg-cell>
          <clr-dg-cell>{{ bikeError.errorReason }}</clr-dg-cell>
          <clr-dg-cell>{{ bikeError.payload | json }}</clr-dg-cell>
          <clr-dg-cell>{{ bikeError.createdOn | date: 'dd/MM/yy HH:mm' }}</clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer i18n>
          {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ bikeErrorTotal }}
          errors
          <clr-dg-pagination #pagination [clrDgTotalItems]="bikeErrorTotal"></clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>
    </div>
  </div>
  <!-- .clr-row -->
</div>
