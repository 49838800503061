import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClrDatagridStateInterface } from '@clr/angular';
import { BatteryService } from '../../../services/battery.service';
import { Battery, BatteryConnection } from '../../../models/battery.model';

@UntilDestroy()
@Component({
  selector: 'app-view-model',
  templateUrl: './view-battery.component.html',
  styleUrls: ['./view-battery.component.scss'],
})
export class ViewBatteryComponent implements OnInit, OnDestroy {
  public batteryHistory: Battery[] = [];
  public currentBattery: Battery;
  public sn: string;
  public total: number;
  public loading = true;

  constructor(
    private route: ActivatedRoute,
    private batteryService: BatteryService,
  ) {}

  ngOnInit(): void {
    this.route.params.pipe(untilDestroyed(this)).subscribe((params) => {
      this.sn = params.sn;
    });
  }

  ngOnDestroy(): void {}

  public refresh(state: ClrDatagridStateInterface): void {
    this.loading = true;

    this.batteryService
      .getHistoryBySN(this.sn, null)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result) => {
          this.batteryHistory = result;
          this.currentBattery = result.find((b) => b.supersededOn == null);
          this.total = this.batteryHistory?.length;

          this.fetchConnections(this.currentBattery.id);
        },
        complete: () => {
          this.loading = false;
        },
      });
  }
  public batteriesConnections: BatteryConnection[];

  public fetchConnections(batteryId: string): void {
    this.batteryService
      .loadConnectionsByBatteryId(batteryId, null)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result) => {
          this.batteriesConnections = result;
        },
        complete: () => {
          // this.loading = false;
        },
      });
  }

  public testReportShowMore: boolean = false;
  public selectedTestReport: string;
}
