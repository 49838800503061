import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { ClrDatagridStateInterface } from '@clr/angular';
import { Ride } from '../../../../pages/user/models/ride.model';
import { RideStats } from '../../../../pages/user/models/ride-stats.model';
import { RideService } from '../../../../pages/user/services/ride.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@UntilDestroy()
@Component({
  selector: 'rides',
  templateUrl: './rides.component.html',
  styleUrls: ['./rides.component.scss'],
})
export class RidesComponent implements OnInit, OnDestroy {
  @Input()
  bikeId?: string;
  @Input()
  customerId: string;

  rides: Ride[];
  rideStats: RideStats[];
  totalRidesStats: RideStats;
  rideLoading: boolean = true;
  totalRide: number = 0;

  public filtersOpened: boolean = false;
  public searchForm: UntypedFormGroup;

  constructor(private rideService: RideService, private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.fetchRideStats();
    this.searchForm = this.formBuilder.group({
      rideId: '',
      onlyValid: false,
      source: '',
      bikeAppVersion: '',
      from: '',
      to: '',
    });
  }

  ngOnDestroy(): void {}

  public refreshRide(state: ClrDatagridStateInterface): void {
    this.rideLoading = true;

    const params: { [key: string]: string } = {
      page: Number(state.page.current - 1).toString(10),
      size: Number(state.page.size).toString(10),
    };
    if (this.bikeId) {
      params.bikeId = this.bikeId;
    }
    if (this.customerId) {
      params.customerId = this.customerId;
    }
    if (this.searchForm.get('rideId').value) {
      params.rideId = this.searchForm.get('rideId').value;
    }
    if (this.searchForm.get('onlyValid').value) {
      params.onlyValid = this.searchForm.get('onlyValid').value;
    }
    if (this.searchForm.get('bikeAppVersion').value) {
      params.bikeAppVersion = this.searchForm.get('bikeAppVersion').value;
    }
    if (this.searchForm.get('source').value) {
      params.source = this.searchForm.get('source').value;
    }
    if (this.searchForm.get('from').value) {
      const fromDateParts = this.searchForm.get('from').value.split('/');
      params.from = new Date(+fromDateParts[2], +fromDateParts[1] - 1, +fromDateParts[0]).getTime().toString(10);
    }
    if (this.searchForm.get('to').value) {
      const toDateParts = this.searchForm.get('to').value.split('/');
      params.to = new Date(+toDateParts[2], +toDateParts[1] - 1, +toDateParts[0]).getTime().toString(10);
    }

    this.rideService
      .getAll(params)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.rideLoading = false))
      )
      .subscribe((resp) => {
        const rides = resp.values();

        this.rides = rides;
        this.totalRide = resp.page.totalElements || this.rides?.length || 0;
        this.rideLoading = false;
      });
  }

  private fetchRideStats(): void {
    let params: any = {};
    if (this.bikeId) {
      params.bikeId = this.bikeId;
    }
    if (this.customerId) {
      params.customerId = this.customerId;
    }
    this.rideService
      .getStats(params)
      .pipe(untilDestroyed(this))
      .subscribe((resp) => {
        const stats = resp.values();
        this.rideStats = stats;
        if (!stats) {
          return;
        }
        this.totalRidesStats = stats.reduce((previousValue, currentValue, currentIndex, array) => {
          return new RideStats({
            distance: currentValue.distance + (previousValue ? previousValue.distance : 0),
            duration: currentValue.duration + (previousValue ? previousValue.duration : 0),
            count: currentValue.count + (previousValue ? previousValue.count : 0),
          });
        });
      });
  }

  public downloadRide(id: string): void {
    this.rideService.download(id);
  }
}
