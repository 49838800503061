import { LinkResource, UpdateTrace } from 'src/app/modules/api/models';

export class Segment extends LinkResource {
  id: string;
  name: string;
  updateTrace: UpdateTrace;

  constructor(data?: any) {
    super(data?._links);

    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.updateTrace = new UpdateTrace(data.updateTrace);
    }
  }
}
