import { Component, OnDestroy, OnInit } from '@angular/core';
import { BikeService } from '../../../../services/bike.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Bike } from '../../../../models/bike.model';
import { BikeConnectivityHistory } from '../../../../models/bike-connectivity-history.model';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../../../environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-view-bike',
  templateUrl: './connectivity.component.html',
  styleUrls: [],
})
export class ConnectivityComponent implements OnInit, OnDestroy {
  now?: Date;
  bike?: Bike;

  constructor(
    private bikeService: BikeService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.parent.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.bike = data.bike;
      this.now = data.now;
    });
  }

  ngOnDestroy(): void {}

  public getElasticLinkForConsumers(): string {
    const index: string = environment.production ? 'filebeat-*' : 'd87d05d0-1a9a-11eb-bbbc-4764e22eadd1';
    return `https://b29ecf86ca9e4437aeef2e5e7258efbb.eu-west-3.aws.elastic-cloud.com:9243/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-1M,to:now))&_a=(columns:!(message,event.original,client.mac),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'${index}',key:event.provider,negate:!f,params:(query:mqtt),type:phrase),query:(match_phrase:(event.provider:mqtt)))),index:'${index}',interval:auto,query:(language:kuery,query:'service.type%20:%20%22consumer%22%20and%20(event.mac%20:%20%22${this.bike.bikeProvision.mac}%22%20or%20client.mac%20:%20%22${this.bike.bikeProvision.mac}%22)'),sort:!(!('@timestamp',desc)))`;
  }

  connectivityHistoryLoaded: boolean = false;
  loadMoreConnectivityHistoryToken: string;
  connectivityHistoryItems: BikeConnectivityHistory[] = [];

  public loadConnectivityHistory() {
    this.bikeService
      .getConnectivityHistory(this.bike.id, this.now, '')
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.connectivityHistoryItems = response.results;
        this.loadMoreConnectivityHistoryToken = response.paging.nextPageCursor;
        this.connectivityHistoryLoaded = true;
      });
  }
}
