import { Injectable } from '@angular/core';
import { Bike, CustomerBike } from 'src/app/pages/bike/models/bike.model';
import { BikeEvent } from '../models/bike-event.model';
import { BikeHistory } from '../models/bike-history.model';
import { BikeTimelineMapper } from './bike-timeline.mapper';
import { User } from '../../../modules/auth/models/user';

@Injectable({
  providedIn: 'root',
})
export class BikeTimelineService {
  constructor(private mapper: BikeTimelineMapper) {}

  public extractEventsFromBike(bike: Bike, currentUser: User): BikeEvent[] {
    let events: BikeEvent[] = [];
    events.push(this.mapper.mapProvisionToBikeEvent(bike));
    events.push(this.mapper.mapLastUpdate(bike, currentUser));

    return events; //this.updateAndSortEvents(events, this.extractEventsFromCustomerBikes(bike.customerBikes));
  }

  public extractEventsFromBikeHistory(bikeHistories: BikeHistory[]): BikeEvent[] {
    return bikeHistories
      ?.filter((event) => !['install', 'uninstall', 'customer.invited', 'customer.deleted'].includes(event.eventName))
      .map(this.mapper.mapFromHistoryEvent);
  }

  public extractEventsFromCustomerBikes(customerBikes: CustomerBike[]): BikeEvent[] {
    const customerBikesEvents: BikeEvent[] = [];
    customerBikes.forEach((customerBike) => {
      customerBikesEvents.push(this.mapper.mapUserAdded(customerBike));
      if (customerBike.deleteTrace.deletedOn) {
        customerBikesEvents.push(this.mapper.mapUserDeleted(customerBike));
      }
    });
    return customerBikesEvents;
  }
}
