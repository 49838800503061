import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from 'src/app/modules/api/services/api.service';
import { ResponseEntities } from 'src/app/modules/api/models/response-entities.model';
import { catchError, map } from 'rxjs/operators';
import { definitiveCache } from 'src/app/modules/api/definitive-cache/definitive-cache';
import { SimCard } from '../models/simcard.model';
import { MatoomaStats } from '../models/matooma-stats.model';

@Injectable({
  providedIn: 'root',
})
export class SimCardService {
  constructor(private api: ApiService) {}

  private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message} with error : ${error}`);

      return of(result as T);
    };
  }

  public getAll(params: any): Observable<ResponseEntities<SimCard>> {
    return this.api
      .get<ResponseEntities<SimCard>>('/simcards', {
        params,
      })
      .pipe(
        map((a) => new ResponseEntities<SimCard>(SimCard, a)),
        catchError(this.handleError<ResponseEntities<SimCard>>('all')),
        definitiveCache()
      );
  }

  getByIccid(iccid: string, params: any): Observable<SimCard> {
    return this.api
      .get<SimCard>(`/simcards/${iccid}`, {
        params: {
          ...params,
        },
      })
      .pipe(map((a) => new SimCard(a)));
  }

  public getConsumption(iccid: string): Observable<MatoomaStats> {
    return this.api.get<MatoomaStats>(`/consumption/sms/${iccid}`).pipe(
      map((a) => new MatoomaStats(a)),
      catchError(this.handleError<MatoomaStats>('getSmsConsumption')),
      definitiveCache()
    );
  }
}
