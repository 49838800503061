import { Component, OnInit, OnDestroy } from '@angular/core';
import { Admin } from '../../models/admin.model';
import { AdminService } from '../../services/admin.service';
import { ClrDatagridStateInterface } from '@clr/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-list-admin',
  templateUrl: './list-admin.component.html',
  styleUrls: ['./list-admin.component.scss'],
})
export class ListAdminComponent implements OnInit, OnDestroy {
  public admins: Admin[];
  public total: number;
  public loading = true;
  public importForm: UntypedFormGroup;
  public submitted = false;
  public imported = false;
  public error = '';

  public importModal = false;

  public roles: string[] = ['ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_SUPPORT'];

  constructor(private adminService: AdminService, private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.importForm = this.formBuilder.group({
      emails: ['', Validators.required],
      role: ['ROLE_SUPPORT', Validators.required],
    });
  }

  ngOnDestroy(): void {}

  // convenience getter for easy access to form fields
  get f(): { [key: string]: AbstractControl } {
    return this.importForm.controls;
  }

  public refresh(state: ClrDatagridStateInterface): void {
    console.log('table state:', state);

    this.loading = true;

    // We convert the filters from an array to a map,
    // because that's what our backend-calling service is expecting
    const filters: { [prop: string]: any } = {};

    if (state.filters) {
      for (const filter of state.filters) {
        const { property, value } = filter as { property: string; value: string };
        filters[property] = value;
      }
    }

    console.log('table filters:', filters);

    const params = filters;

    params.page = state.page.current - 1;
    params.size = state.page.size;

    this.adminService
      .getAll(params)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        console.log('Result', result);

        this.admins = result.values();
        this.total = result.page.totalElements || this.admins.length;
        this.loading = false;
      });
  }

  public onDelete(admin: Admin): void {
    this.adminService
      .delete(admin.id)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.admins = this.admins.filter((a) => a.id !== admin.id);
      });
  }

  public onResetPassword(admin: Admin): void {
    this.adminService.resetPassword(admin.id).pipe(untilDestroyed(this)).subscribe();
  }

  public onSubmit(): void {
    this.submitted = true;

    // stop here if form is invalid
    if (this.importForm.invalid) {
      this.importForm.markAsTouched();

      return;
    }

    this.error = null;

    const emails = this.f.emails.value
      .split('\n')
      .map((email) => this.adminService.create(email, this.f.role.value).pipe(untilDestroyed(this)));

    forkJoin(emails)
      .pipe(untilDestroyed(this))
      .subscribe((admins: Admin[]) => {
        this.imported = true;

        this.admins.push(...admins);

        this.importForm.reset();

        this.importModal = false;
      });
  }
}
