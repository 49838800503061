import { Component, Input, OnInit } from '@angular/core';
import { Segment } from '../../models/segment.model';

@Component({
  selector: 'segment',
  templateUrl: './segment.component.html',
})
export class SegmentComponent implements OnInit {
  @Input()
  segment: Segment;

  constructor() {}

  ngOnInit(): void {}
}
