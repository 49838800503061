<div *ngIf="bike" class="clr-row">
  <div class="clr-col-xl-7 clr-col-lg-12 clr-col-md-12 bike-diagnosis">
    <h4 class="bike-diagnosis-title">
      <span i18n>Bike diagnosis</span>
      <div class="clr-select-wrapper">
        <select id="select-basic" class="clr-select" [(ngModel)]="since" (ngModelChange)="onSinceChange()">
          <option value="week" i18n>Last week</option>
          <option value="month" i18n>Last month</option>
          <option value="inspection" i18n>Since last maintenance</option>
          <option value="all" i18n>All time</option>
        </select>
      </div>
    </h4>
    <div class="clr-row bike-diagnosis-content">
      <div
        *ngFor="let key of Object.keys(types)"
        class="clr-col-xl-4 clr-col-lg-6 clr-col-md-6 clr-col-xs-12 clr-justify-content-around"
      >
        <div
          class="diagnosis-card {{
            warningsGroupedByTypes && warningsGroupedByTypes[key]
              ? 'criticity-' + warningsGroupedByTypes[key].criticity
              : 'criticity-ok'
          }} {{ types[key].selected ? 'selected' : '' }}"
        >
          <div class="left-part">
            <div class="icon-wrapper">
              <cds-icon shape="{{ types[key].icon }}" size="20"></cds-icon>
            </div>
            <div class="diagnosis-card-label">
              <div class="text" i18n>{{ key }}</div>
              <div class="description">
                <span *ngIf="warningsGroupedByTypes && warningsGroupedByTypes[key]">
                  <cds-icon shape="warning" size="15"></cds-icon>
                  <span i18n>{{ warningsGroupedByTypes[key].warnings.length }} warning(s)</span>
                </span>
                <span *ngIf="!warningsGroupedByTypes || !warningsGroupedByTypes[key]">
                  <cds-icon shape="check" size="15"></cds-icon>
                </span>
              </div>
            </div>
          </div>
          <div
            class="diagnosis-card-actions"
            *ngIf="warningsGroupedByTypes && warningsGroupedByTypes[key]"
            (click)="onWarningToggled(types[key])"
          >
            <cds-icon *ngIf="types[key].selected" shape="eye" size="15"></cds-icon>
            <cds-icon *ngIf="!types[key].selected" shape="eye-hide" size="15"></cds-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="clr-col-12">
      <div *ngIf="filteredWarnings.length == 0">-</div>
      <table class="table table-noborder table-compact bike-diagnosis-table" *ngIf="filteredWarnings.length > 0">
        <thead>
          <tr>
            <th i18n>Date</th>
            <th i18n>Criticity</th>
            <th i18n>Type</th>
            <th i18n class="left">Cause</th>
            <th i18n class="left">Impacts</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let warning of filteredWarnings; let index = index">
            <td>{{ warning.date | date: 'dd/MM/YY HH:mm:ss' }}</td>
            <td>
              <span class="type-status">
                <cds-icon shape="circle" solid="true" size="15" [ngClass]="'criticity-' + warning.criticity"></cds-icon>
              </span>
            </td>
            <td>
              <div class="details-type">
                <div class="label">
                  <cds-icon shape="{{ types[warning.type].icon }}" size="15"></cds-icon>
                  {{ warning.type }}
                </div>
              </div>
            </td>
            <td class="left">
              {{ warning.description }}
            </td>
            <td class="left">
              <ul>
                <li *ngFor="let impact of warning.impacts">{{ impact }}</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="clr-col-xl-1"></div>
  <div class="clr-col-xl-4 clr-col-lg-12">
    <bike-summary [bike]="bike"></bike-summary>

    <h5>
      <span i18n>Maintenance operations&nbsp;</span>
      <button
        (click)="showMaintenanceOpeModal = true"
        type="button"
        class="btn btn-sm btn-secondary btn-icon btn-maintenance-ope"
        i18n
      >
        <cds-icon shape="plus"></cds-icon>
      </button>
      <clr-modal [(clrModalOpen)]="showMaintenanceOpeModal" [clrModalSize]="'md'">
        <h3 class="modal-title" i18n>Declare new maintenance operation</h3>
        <div class="modal-body">
          <div class="clr-row">
            <div class="clr-col-6">
              <clr-date-container>
                <label>Started on</label>
                <input type="date" [(clrDate)]="dateFrom" />
              </clr-date-container>
            </div>
            <div class="clr-col-6">
              <clr-date-container>
                <label>Ended on</label>
                <input type="date" [(clrDate)]="dateTo" />
              </clr-date-container>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="showMaintenanceOpeModal = false">Cancel</button>
          <button type="button" class="btn btn-primary" (click)="submitMaintenceOperation()">Ok</button>
        </div>
      </clr-modal>
    </h5>
    <table class="table table-compact">
      <thead>
        <tr>
          <th i18n>Type</th>
          <th i18n>Started</th>
          <th i18n>Ended</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let operation of maintenanceOperations; let index = index">
          <td>{{ operation.type }}</td>
          <td>
            <div>{{ operation.startedOn | date: 'dd/MM/yy HH:mm' }}</div>
            <div>by {{ operation.startedBy }}</div>
          </td>
          <td>
            <span *ngIf="operation.endedOn">
              <div>{{ operation.endedOn | date: 'dd/MM/yy HH:mm' }}</div>
              <div>by {{ operation.endedBy }}</div>
            </span>
            <span *ngIf="!operation.endedOn">-</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
