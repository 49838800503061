import { Component, Input, OnInit } from '@angular/core';
import { BikeModel } from '../../models/bike-model.model';
import { SimCard } from '../../models/simcard.model';

@Component({
  selector: 'simcard-status',
  templateUrl: './simcard-status.component.html',
  styleUrls: ['./simcard-status.component.scss'],
})
export class SimcardStatusComponent implements OnInit {
  @Input()
  simCard: SimCard;

  constructor() {}

  ngOnInit(): void {}
}
