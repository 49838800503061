import { Component, OnInit, OnDestroy } from '@angular/core';
import { HistoryService } from '../../../services/history.service';
import { ClrDatagridStateInterface } from '@clr/angular';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SmsLog } from '../../../models/sms-log.model';
import { MailLog } from '../../../models/mail-log.model';
import { PushLog } from '../../../models/push-log.model';
import { DomSanitizer } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { User } from '../../../models/user.model';
import { NotificationService } from '../../../services/notification.service';
import { NotificationChannel, PushToken } from '../../../models/notification-channel.model';
import { Bike } from '../../../../bike/models/bike.model';
import { CustomerService } from '../../../services/customer.service';

@UntilDestroy()
@Component({
  selector: 'app-list-notification-history',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  public notifications: (SmsLog | MailLog | PushLog)[];
  public total: number;
  public loading = true;
  public type: string = null;
  public queries: { [key: string]: string };
  public user: User;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private historyService: HistoryService,
    private sanitizer: DomSanitizer,
    private notificationService: NotificationService,
    private customerService: CustomerService,
  ) {}

  public ngOnInit(): void {
    console.log('ngOnInit');

    this.route.parent.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.user = data.user;
      this.fetchNotificationChannels();
      this.fetchPushTokens();
      this.fetchBikesOfUser(this.user);
    });
    this.route.params.pipe(untilDestroyed(this)).subscribe((params) => (this.type = params.type));

    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((queries) => {
      console.log('query params:', queries);
      this.queries = queries;
    });

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event) => event instanceof NavigationStart),
      )
      .subscribe((event) => {
        console.log('event', event);
        this.loading = true;
        this.notifications = null;
        this.total = null;
      });
  }

  public ngOnDestroy(): void {}

  public refresh(state: ClrDatagridStateInterface): void {
    console.log('table state:', state);

    this.loading = true;

    // We convert the filters from an array to a map,
    // because that's what our backend-calling service is expecting
    const filters: { [prop: string]: any } = { customerId: this.user.id };

    if (state.filters) {
      for (const stateFilter of state.filters) {
        const { property, value } = stateFilter as { property: string; value: string };
        filters[property] = value;
      }
    }

    console.log('table filters:', filters);

    const params = { ...filters, ...this.queries };

    params.page = state.page.current - 1;
    params.size = state.page.size;

    this.historyService
      .getAllHistory(this.type, params)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (resp) => {
          const notifications = resp.values();

          notifications.map((item) => {
            const mail = item as MailLog;

            if (mail.raw) {
              mail.safeContent = this.sanitizer.bypassSecurityTrustResourceUrl(
                `data:text/html;base64,${mail.getContentToBase64()}`,
              );
            }

            return mail;
          });

          console.log('notifications', notifications);

          this.notifications = notifications;
          this.total = resp.page.totalElements || this.notifications.length;
        },
        complete: () => (this.loading = false),
      });
  }

  public notificationsChannels: NotificationChannel[];
  public groupedByNotificationsChannels: any;

  public fetchNotificationChannels(): void {
    this.notificationService
      .getNotificationsChannels(this.user.id)
      .pipe(untilDestroyed(this))
      .subscribe((event) => {
        this.notificationsChannels = event;
        const groupBy = (input, key) => {
          return input.reduce((acc, currentValue) => {
            let groupKey = currentValue[key];
            if (!acc[groupKey]) {
              acc[groupKey] = [];
            }
            acc[groupKey].push(currentValue);
            return acc;
          }, {});
        };
        this.groupedByNotificationsChannels = groupBy(this.notificationsChannels, 'channel');
      });
  }

  public pushTokens: PushToken[];
  public nbOfValidPushTokens: number = 0;

  public fetchPushTokens(): void {
    this.notificationService
      .getPushTokens(this.user.id, { page: 0, size: 5, evenDeleted: false })
      .pipe(untilDestroyed(this))
      .subscribe((event) => {
        this.pushTokens = event.values();
        this.nbOfValidPushTokens = this.pushTokens?.length || 0;
      });
  }

  public bikes: Bike[];

  private fetchBikesOfUser(user: User): void {
    this.customerService
      .getUserBikes(user.id)
      .pipe(untilDestroyed(this))
      .subscribe((resp) => {
        const bikes = resp.values();
        this.bikes = bikes;
      });
  }

  protected readonly Object = Object;

  pushTokensDetailsOpened: boolean = false;

  public showPushTokensDetails(): boolean {
    this.pushTokensDetailsOpened = true;
    return false;
  }

  public detailsPushTokens: PushToken[];
  public showAlsoDeletedOnes: boolean = false;
  public tokensTotal: number = 0;

  public refreshTokens(state: ClrDatagridStateInterface): void {
    console.log('table state:', state);

    // We convert the filters from an array to a map,
    // because that's what our backend-calling service is expecting
    const filters: { [prop: string]: any } = { customerId: this.user.id };

    if (state.filters) {
      for (const stateFilter of state.filters) {
        const { property, value } = stateFilter as { property: string; value: string };
        filters[property] = value;
      }
    }

    console.log('table filters:', filters);

    const params = { ...filters, ...this.queries };

    params.page = state.page.current - 1;
    params.size = state.page.size;
    params.evenDeleted = this.showAlsoDeletedOnes;

    this.notificationService
      .getPushTokens(this.user.id, params)
      .pipe(untilDestroyed(this))
      .subscribe((event) => {
        this.detailsPushTokens = event.values();
        this.tokensTotal = event.page.totalElements || this.detailsPushTokens?.length || 0;
      });
  }
}
