import { User } from './user.model';
import { Shop } from './shop.model';
import { DeleteTrace, UpdateTrace } from '../../../modules/api/models';

export class Retailer {
  id: string;
  customer: User;
  shop: Shop;
  updateTrace: UpdateTrace;
  deleteTrace: DeleteTrace;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.customer = new User(data.customer);
      this.shop = new Shop(data.shop);
      this.updateTrace = new UpdateTrace(data.updateTrace);
      this.deleteTrace = new DeleteTrace(data.deleteTrace);
    }
  }
}
