import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { User } from '../../models/user.model';

@UntilDestroy()
@Component({
  selector: 'user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  @Input()
  public user: User;
  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
