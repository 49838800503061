<h1 i18n>{{ mode === 'UPDATE' ? 'Update ' : 'Create ' }}Shop</h1>

<form clrForm [formGroup]="shopForm" (ngSubmit)="onSubmit()">
  <clr-alert [clrAlertType]="'danger'" [clrAlertClosed]="!error">
    <div clr-alert-item class="alert-item">
      <span class="alert-text">
        {{ error }}
      </span>
    </div>
  </clr-alert>

  <clr-spinner *ngIf="loading" [clrInline]="true"></clr-spinner>

  <div class="clr-row" *ngIf="!loading">
    <div class="clr-col-lg-5 clr-col-md-6 clr-col-12">
      <div class="clr-row">
        <clr-input-container class="clr-col-12">
          <label i18n>Name</label>
          <input clrInput type="text" formControlName="name" required placeholder="" />
        </clr-input-container>
      </div>

      <div class="clr-row">
        <clr-input-container class="clr-col-12">
          <label i18n>Email</label>
          <input clrInput type="email" email formControlName="email" required placeholder="" />
        </clr-input-container>
      </div>
      <div class="clr-row">
        <clr-input-container class="clr-col-12">
          <label i18n>Phone</label>
          <input clrInput type="text" formControlName="phoneNumber" required placeholder="" />
        </clr-input-container>
      </div>

      <div class="clr-row">
        <clr-select-container class="clr-col-12">
          <label>Classification</label>
          <select clrSelect formControlName="classification">
            <option [value]="'GOLD'">GOLD</option>
            <option [value]="'SILVER'">SILVER</option>
            <option [value]="'BRONZE'">BRONZE</option>
          </select>
        </clr-select-container>
      </div>

      <div class="clr-row">
        <clr-input-container class="clr-col-12">
          <label i18n>Manager full name</label>
          <input clrInput type="text" formControlName="managerFullName" placeholder="" />
        </clr-input-container>
      </div>
    </div>
    <div class="clr-col-lg-7 clr-col-md-6 clr-col-12">
      <h4 i18n>Address</h4>
      <div class="clr-row">
        <div class="clr-col-12">
          <address-form [addressFormGroup]="getAddressFormGroup()"></address-form>
        </div>
      </div>
    </div>
  </div>

  <div class="clr-row form-actions" *aclCheck="'actions_pro'">
    <div class="clr-col-md-12">
      <button class="btn btn-outline" type="button" i18n (click)="back()">Cancel</button>
      <button class="btn btn-primary" type="submit" i18n>Save</button>
    </div>
  </div>
</form>
