export class BikeConnectivityHistory {
  id: string;
  eventType: string;
  eventDate: Date;
  clientInitiatedDisconnect: boolean;
	disconnectReason: string;
	createdDate: Date;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.eventType = data.eventType;
      this.eventDate = data.eventDate;
      this.clientInitiatedDisconnect = data.clientInitiatedDisconnect;
      this.disconnectReason = data.disconnectReason;
      this.createdDate = data.createdDate;
    }
  }
}
