import { Segment } from './segment.model';
import { BikeModel } from './bike-model.model';

export class Firmware {
  id: string;
  type: string;
  version: string;
  enabled: boolean;
  segments: Segment[];
  models: BikeModel[];
  constraints: FirmwareConstraint[];
  files: FirmwareFile[];
  distributionMediaLTE: boolean;
  distributionMediaBLE: boolean;
  tenants: string[];

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.type = data.type;
      this.version = data.version;
      this.enabled = data.enabled;
      this.distributionMediaLTE = data.distributionMediaLTE;
      this.distributionMediaBLE = data.distributionMediaBLE;

      if (data.segments) {
        this.segments = data.segments.map((segment) => new Segment(segment));
      }

      if (data.models) {
        this.models = data.models.map((model) => new BikeModel(model));
        function onlyUnique(value, index, array) {
          return array.indexOf(value) === index;
        }
        this.tenants = this.models.map((v) => v.tenant).filter(onlyUnique);
      }
      if (data.constraints) {
        this.constraints = data.constraints.map((constraint) => new FirmwareConstraint(constraint));
      }

      if (data.files) {
        this.files = data.files.map((file) => new FirmwareFile(file));
      }
    }
  }

  getDistribution() {
    return (
      (this.distributionMediaBLE ? 'BLE' : '') +
      (this.distributionMediaBLE && this.distributionMediaLTE ? ', ' : '') +
      (this.distributionMediaLTE ? 'LTE' : '')
    );
  }
  getConstraintsByType(type: String) {
    return this.constraints.filter((c) => c.constraintType == type);
  }
}

export class FirmwareFile {
  id: string;
  type: string;
  name: string;
  size: number;
  compression: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.type = data.type;
      this.name = data.name;
      this.size = data.size;
      this.compression = data.compression;
    }
  }
}

class FirmwareConstraint {
  id: string;
  constraintType: string;
  moduleType: string;
  version: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.constraintType = data.constraintType;
      this.moduleType = data.moduleType;
      this.version = data.version;
    }
  }
}
