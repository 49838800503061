import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/modules/api/services/api.service';
import { Observable, of } from 'rxjs';
import { ResponseEntities } from 'src/app/modules/api/models';
import { Ride } from '../models/ride.model';
import { RideGlobalStats, RideStats } from '../models/ride-stats.model';
import { catchError, map } from 'rxjs/operators';
import { definitiveCache } from 'src/app/modules/api/definitive-cache/definitive-cache';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class RideService {
  constructor(private api: ApiService) {}

  private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message} with error : ${error}`);

      return of(result as T);
    };
  }

  public getAll(params: { [key: string]: string }): Observable<ResponseEntities<Ride>> {
    return this.api
      .get<ResponseEntities<Ride>>(`/rideSessions`, {
        params,
      })
      .pipe(
        map((a) => new ResponseEntities<Ride>(Ride, a)),
        catchError(this.handleError<ResponseEntities<Ride>>('getAllByUserId')),
        definitiveCache()
      );
  }

  public getLast10ByUserId(userId: string): Observable<ResponseEntities<Ride>> {
    return this.api
      .get<ResponseEntities<Ride>>(`/rideSessions`, {
        params: {
          page: '0',
          size: '10',
          customerId: userId,
        },
      })
      .pipe(
        map((a) => new ResponseEntities<Ride>(Ride, a)),
        catchError(this.handleError<ResponseEntities<Ride>>('getLast10ByUserId')),
        definitiveCache()
      );
  }

  public getStats(params): Observable<ResponseEntities<RideStats>> {
    return this.api.get<ResponseEntities<RideStats>>(`/rideSessions/stats`, { params }).pipe(
      map((a) => new ResponseEntities<RideStats>(RideStats, a)),
      catchError(this.handleError<ResponseEntities<RideStats>>('getStatsByUserId')),
      definitiveCache()
    );
  }

  public stats(type: string): Observable<RideGlobalStats[]> {
    return this.api.get('/rideSessions/statistics', {
      params: {
        type,
      },
    });
  }

  public download(id: string): void {
    this.api
      .get(`/rideSessions/${id}/download`, {
        headers: new HttpHeaders({
          Accept: 'application/octet-stream',
        }),
        responseType: 'arraybuffer' as 'json',
      })
      .subscribe((response) => this.doDownloadFile(response, 'application/octet-stream', `${id}.json`));
  }

  private downLoadFile(data: any, type: string, name: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url, name);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  }

  //@see: https://stackoverflow.com/a/19328891/9722369
  private doDownloadFile(data: any, type: string, name: string) {
    var a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');

    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);

    a.remove();
  }
}
