export class SupervisionByBike {
  bike: BikeSupervision;
  data: SupervisionData;

  constructor(data: any) {
    this.bike = new BikeSupervision(data.bike);
    this.data = new SupervisionData();
    this.data.weeklyKm = new KmData(data.km);
    this.data.weeklyErrors = new ErrorsData(data.errors);
  }
}

export class BikeSupervision {
  id: string;
  serialNumber: string;
  modelName: string;
  bikeName: string;
  owner: string;

  constructor(data: any) {
    this.id = data.id;
    this.serialNumber = data.serialNumber;
    this.modelName = data.modelName;
    this.bikeName = data.bikeName;
    this.owner = data.owner;
  }
}

export class SupervisionData {
  km: KmData;
  errors: ErrorsData;
  weeklyKm: KmData;
  weeklyErrors: ErrorsData;

  constructor() {}

  getLastWeekEvolutionInPercent() {
    const weeks: Week[] = this.getWeeks();
    if (!weeks || weeks.length < 2) {
      return undefined;
    }
    const lastWeekRatio: number = this.getRatioByWeek(weeks[0]);
    if (lastWeekRatio == undefined) {
      return undefined;
    }

    const previousWeekRatio: number = this.getRatioByWeek(weeks[1]);
    if (previousWeekRatio == undefined) {
      return undefined;
    }

    return Math.floor(((lastWeekRatio - previousWeekRatio) / (previousWeekRatio + lastWeekRatio)) * 100);
  }
  getWeeks(): Week[] {
    const concatenatedArray: Week[] = [
      ...(this.weeklyKm ? this.weeklyKm.byWeek.map((bw) => bw.week) : []),
      ...(this.weeklyErrors ? this.weeklyErrors.byWeek.map((bw) => bw.week) : []),
    ];
    const mergedArray: Week[] = Array.from(new Set(concatenatedArray.map((obj) => JSON.stringify(obj)))).map((str) =>
      JSON.parse(str),
    );
    return mergedArray.sort((b, a) => {
      // Tri par année (year)
      if (a.year !== b.year) {
        return a.year - b.year;
      }
      // Si les années sont égales, tri par numéro de semaine (week_number)
      return a.num - b.num;
    });
  }

  getKmByWeek(week: Week): number {
    if (!this.weeklyKm) {
      return undefined;
    }
    const byWeek = this.weeklyKm.byWeek.find((bw) => bw.week.num == week.num && bw.week.year == week.year);
    if (byWeek) {
      return byWeek.count;
    }
    return undefined;
  }
  getKmLabelByWeek(week: Week): string {
    if (!this.weeklyKm) {
      return '-';
    }
    const byWeek = this.weeklyKm.byWeek.find((bw) => bw.week.num == week.num && bw.week.year == week.year);
    if (byWeek) {
      return `${byWeek.count} km`;
    }
    return '-';
  }

  getErrorsByWeek(week: Week): number {
    if (!this.weeklyErrors) {
      return undefined;
    }
    const byWeek = this.weeklyErrors.byWeek.find((bw) => bw.week.num == week.num && bw.week.year == week.year);
    if (!byWeek) {
      return undefined;
    }
    return byWeek.count;
  }

  getErrorsLabelByWeek(week: Week): string {
    if (!this.weeklyErrors) {
      return '-';
    }
    const byWeek = this.weeklyErrors.byWeek.find((bw) => bw.week.num == week.num && bw.week.year == week.year);
    if (!byWeek) {
      return '-';
    }
    return `${byWeek.count} err`;
  }

  getErrorDetailsByWeek(week: Week): string {
    if (!this.weeklyErrors) {
      return '-';
    }
    const byWeek = this.weeklyErrors.byWeek.find((bw) => bw.week.num == week.num && bw.week.year == week.year);
    if (!byWeek) {
      return '-';
    }
    return byWeek.details;
  }

  getRatioByWeek(week: Week): number {
    const km = this.getKmByWeek(week);
    if (!km) {
      return;
    }
    const errors = this.getErrorsByWeek(week);
    if (!errors) {
      return;
    }
    return Math.floor(errors / km);
  }
}

export class KmData {
  total: number;
  byWeek: WeeklyKmData[];

  constructor(data: any) {
    this.total = data.total;
    this.byWeek = data.byWeek ? data.byWeek.map((byWeek) => new WeeklyKmData(byWeek)) : [];
  }
}
export class WeeklyKmData {
  week: Week;
  count: number;

  constructor(data: any) {
    this.week = new Week(data.week);
    this.count = data.count;
  }
}

export class ErrorsData {
  total: number;
  byWeek: WeeklyErrorsData[];

  constructor(data: any) {
    this.total = data.total;
    this.byWeek = data.byWeek ? data.byWeek.map((byWeek: any) => new WeeklyErrorsData(byWeek)) : [];
  }
}

export class WeeklyErrorsData {
  week: Week;
  count: number;
  details: string;
  constructor(data: any) {
    this.week = new Week(data.week);
    this.details = data.details;
    this.count = data.count;
  }
}

export class Week {
  year: number;
  num: number;

  constructor(data: any) {
    this.year = data.year;
    this.num = data.num;
  }
}
