import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClrTabLink } from '@clr/angular';
import { User } from '../../models/user.model';

@UntilDestroy()
@Component({
  selector: 'app-view-customer',
  templateUrl: './view-customer.component.html',
  styleUrls: [],
})
export class ViewCustomerComponent implements AfterViewInit, OnInit {
  tabsData: any[] = [
    { label: 'Details', path: 'details' },
    { label: 'Timeline', path: 'timeline' },
    { label: 'Rides', path: 'rides' },
    { label: 'Notifications', path: 'notifications/mail' },
  ];

  public user: User;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
  ) {}

  @ViewChildren(ClrTabLink)
  tabs: QueryList<ClrTabLink>;

  ngOnInit() {
    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.user = data.user;
      if (this.user?.role === 'ROLE_RETAILER') {
        this.tabsData.push({ label: 'Shop', path: 'shop' });
      }
    });
  }

  ngAfterViewInit() {
    const url = this.router.url.substring(this.router.url.lastIndexOf('/') + 1);
    const currentTabIndex = this.tabsData.findIndex((comp) => comp.path === url);
    if (currentTabIndex > -1) {
      this.tabs.filter((element, index) => index === currentTabIndex)[0].activate();
      this.cdr.detectChanges();
    }
  }
}
