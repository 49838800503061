import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateTraceComponent } from './components/update-trace/update-trace.component';
import { RouterLinkMatchDirective } from './directives/router-link-match.directive';
import { FileSizePipe } from './pipes/filesize.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { MeterPipe } from './pipes/meter.pipe';
import { ConfirmDirective } from './directives/confirm.directive';
import { AclCheckDirective } from './directives/acl-check.directive';
import { RidesComponent } from './components/rides/rides.component';
import { AuthModule } from '../auth/auth.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { BikeRoutingModule } from '../../pages/bike/bike-routing.module';
import { NgChartsModule } from 'ng2-charts';
import { LocationLinkComponent } from './components/location-link/location-link.component';
import { TenantComponent } from './components/tenant/tenant.component';

@NgModule({
  declarations: [
    UpdateTraceComponent,
    RouterLinkMatchDirective,
    FileSizePipe,
    DurationPipe,
    MeterPipe,
    ConfirmDirective,
    AclCheckDirective,
    RidesComponent,
    LocationLinkComponent,
    TenantComponent,
  ],
  exports: [
    UpdateTraceComponent,
    RouterLinkMatchDirective,
    ConfirmDirective,
    FileSizePipe,
    DurationPipe,
    MeterPipe,
    AclCheckDirective,
    RidesComponent,
    LocationLinkComponent,
    TenantComponent,
  ],
  imports: [CommonModule, ClarityModule, ReactiveFormsModule],
})
export class SharedModule {}
