<h1 i18n>{{ mode === 'UPDATE' ? 'Update ' : 'Create ' }}Retailer</h1>

<form clrForm [formGroup]="addProUserForm" (ngSubmit)="onSubmit()" *ngIf="addProUserForm">
  <clr-alert [clrAlertType]="'danger'" [clrAlertClosed]="!error">
    <div clr-alert-item class="alert-item">
      <span class="alert-text">
        {{ error }}
      </span>
    </div>
  </clr-alert>

  <div class="clr-row">
    <div class="clr-col-4">
      <h3>User</h3>
      <clr-input-container>
        <label i18n>Email</label>
        <input clrInput type="email" email formControlName="email" required placeholder="retail@sav.com" />
      </clr-input-container>

      <clr-input-container>
        <label i18n>Locale</label>
        <input clrInput type="text" formControlName="locale" required placeholder="fr_FR" />
      </clr-input-container>

      <clr-input-container>
        <label i18n>First name</label>
        <input clrInput type="text" formControlName="firstName" placeholder="Jean" />
      </clr-input-container>

      <clr-input-container>
        <label i18n>Last name</label>
        <input clrInput type="text" formControlName="lastName" placeholder="Dupont" />
      </clr-input-container>

      <clr-input-container>
        <label i18n>Phone number</label>
        <input clrInput type="text" formControlName="phoneNumber" placeholder="0601020304" />
      </clr-input-container>
    </div>
    <div class="clr-col-4">
      <h3>Shop</h3>
      <shop-combobox [(shop)]="shop"></shop-combobox>
    </div>
    <div class="clr-col-8">
      <button class="btn btn-outline" i18n (click)="back()">Cancel</button>
      <button class="btn btn-primary" type="submit" i18n>Validate</button>
    </div>
  </div>
</form>
