import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClarityModule } from '@clr/angular';
import {
  ClarityIcons,
  loadCoreIconSet,
  loadChartIconSet,
  loadCommerceIconSet,
  loadEssentialIconSet,
  loadMediaIconSet,
  loadMiniIconSet,
  loadSocialIconSet,
  loadTechnologyIconSet,
  loadTextEditIconSet,
  loadTravelIconSet,
} from '@cds/core/icon';
import '@cds/core/time/register.js';
import '@cds/core/date/register.js';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiModule } from './modules/api/api.module';
import { AuthModule } from './modules/auth/auth.module';
import { AccountModule } from './pages/account/account.module';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { BikeModule } from './pages/bike/bike.module';
import { UserModule } from './pages/user/user.module';
import { SharedModule } from './modules/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { NgChartsModule } from 'ng2-charts';
registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ClarityModule,
    BrowserAnimationsModule,
    ApiModule,
    AuthModule,
    SharedModule,
    AccountModule,
    DashboardModule,
    BikeModule,
    UserModule,
    NgChartsModule,
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'fr-FR' }],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    loadChartIconSet();
    loadCommerceIconSet();
    loadEssentialIconSet();
    loadMediaIconSet();
    loadMiniIconSet();
    loadSocialIconSet();
    loadTechnologyIconSet();
    loadTextEditIconSet();
    loadTravelIconSet();
    loadCoreIconSet();
  }
}
