import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClarityModule, ClrFormsModule, ClrAlertModule, ClrInputModule, ClrCommonFormsModule } from '@clr/angular';

import { AuthModule } from '../../modules/auth/auth.module';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';

@NgModule({
  declarations: [DashboardComponent],
  imports: [CommonModule, ClarityModule, AuthModule, SharedModule, DashboardRoutingModule],
})
export class DashboardModule {}
