import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { Bike } from '../../../../../bike/models/bike.model';
import { CustomerService } from '../../../../services/customer.service';
import { RetailerService } from '../../../../services/retailer.service';
import { Retailer } from '../../../../models/retailer.model';

@UntilDestroy()
@Component({
  selector: 'app-view-retailer',
  templateUrl: './view-retailer.component.html',
  styleUrls: ['./view-retailer.component.scss'],
})
export class ViewRetailerComponent implements OnInit, OnDestroy {
  public retailer: Retailer;
  public bikes: Bike[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private retailerService: RetailerService
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');

    this.fetchRetailer(id);
    this.fetchBikesOfUser(id);
  }

  ngOnDestroy(): void {}

  private fetchRetailer(id: string): void {
    this.retailerService
      .get(id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (retailer) => {
          this.retailer = retailer;
        },
      });
  }

  private fetchBikesOfUser(userId: string): void {
    this.customerService
      .getUserBikes(userId)
      .pipe(untilDestroyed(this))
      .subscribe((resp) => {
        const bikes = resp.values();
        this.bikes = bikes;
      });
  }

  public getRoleOfBike(bike: Bike): string {
    return bike.customerBikes
      .filter((customerBike) => {
        if (!customerBike.customer) {
          return false;
        }

        return customerBike.customer.id === this.retailer.id;
      })
      .map((customerBike) => customerBike.role)
      .pop();
  }

  public getNameOfBike(bike: Bike): string {
    return bike.customerBikes
      .filter((customerBike) => {
        if (!customerBike.customer) {
          return false;
        }

        return customerBike.customer.id === this.retailer.id;
      })
      .map((customerBike) => customerBike.bikeName)
      .pop();
  }

  private remove(retailer: Retailer): void {
    this.retailerService
      .remove(retailer.id)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.fetchRetailer(retailer.id);
      });
  }

  private restore(retailer: Retailer): void {
    this.retailerService
      .restore(retailer.id)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.fetchRetailer(retailer.id);
      });
  }

  public onRestore(retailer: Retailer): void {
    console.log('restore', retailer);

    this.restore(retailer);
  }

  public onDelete(retailer: Retailer): void {
    if (window.confirm('Are you sure you want to delete this retailer?')) {
      console.log('delete', retailer);
      this.remove(retailer);
    } else {
      console.log('not delete', retailer);
    }
  }
}
