<h1 i18n>Supervision</h1>

<h3 i18n>Overview</h3>
<div class="clr-row supervision-overview">
  <div class="clr-col-md-6 clr-col-lg-3">
    <div class="card">
      <div class="card-block">
        <h4 class="card-title">
          <span i18n>Target</span>

          <button
            (click)="onOpenUpdateTargetModal()"
            type="button"
            class="btn btn-outline-primary btn-sm btn-icon target-edit-btn"
            i18n
          >
            <cds-icon shape="pencil"></cds-icon>
          </button>
        </h4>
        <div class="card-text">
          <div>
            <span i18n>Segment :</span>
            <span class="target-value label">
              <cds-icon style="margin-right: 5px" shape="tag" role="img"></cds-icon>
              <span>{{ segmentName }}&nbsp;</span>
            </span>
          </div>
          <div class="target-period">
            <div>
              <span i18n>From :</span>
              <span class="target-value">{{ from | date: 'dd/MM/yy' }}</span>
            </div>
            <div>
              <span i18n>To : </span>
              <span class="target-value">{{ to | date: 'dd/MM/yy' }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="separator"></div>
  <div class="clr-col-md-6 clr-col-lg-4 clr-col-xl-3" *ngIf="segmentSupervision">
    <div class="card">
      <div class="card-block">
        <h4 class="card-title">Distance</h4>
        <div class="card-text supervision-data">
          <span *ngIf="segmentSupervisionKmLoading" class="spinner spinner-lg"> Loading... </span>
          <span *ngIf="!segmentSupervisionKmLoading">
            {{
              (segmentSupervision.weeklyKm ? segmentSupervision.weeklyKm.total : '-') ||
                (segmentSupervision.km ? segmentSupervision.km.total : '-')
            }}</span
          >
          <span class="unit">&nbsp;km</span>
        </div>
      </div>
    </div>
  </div>
  <div class="clr-col-md-6 clr-col-lg-4 clr-col-xl-3" *ngIf="segmentSupervision">
    <div class="card">
      <div class="card-block">
        <h4 class="card-title">
          Autodiag&nbsp;
          <span class="trends" *ngIf="segmentSupervision.getLastWeekEvolutionInPercent()" style="float: right">
            <trends
              [title]="segmentSupervision.getLastWeekEvolutionInPercent() + '%'"
              [bound]="10"
              [percentage]="segmentSupervision.getLastWeekEvolutionInPercent()"
            ></trends>
          </span>
        </h4>
        <div class="card-text supervision-data">
          <span *ngIf="segmentSupervisionErrorLoading" class="spinner spinner-lg"> Loading... </span>
          <span *ngIf="!segmentSupervisionErrorLoading">
            {{
              (segmentSupervision.weeklyErrors ? segmentSupervision.weeklyErrors.total : '-') ||
                (segmentSupervision.errors ? segmentSupervision.errors.total : '-')
            }}
          </span>
          <span class="unit">&nbsp;err.</span>
        </div>
      </div>
    </div>
  </div>
</div>
<h3 i18n>Weekly Overview</h3>
<div class="clr-row supervision-segment">
  <div class="clr-col-12">
    <div *ngIf="!segmentSupervision" i18n>No data</div>
    <clr-datagrid
      *ngIf="segmentSupervision"
      class="supervision-table datagrid-compact"
      [clrDgLoading]="segmentSupervisionKmLoading && segmentSupervisionErrorLoading"
    >
      <clr-dg-column class="bike" i18n>Segment</clr-dg-column>
      <clr-dg-column class="total" i18n>TOTAL</clr-dg-column>
      <clr-dg-column *ngFor="let week of weeks" i18n>
        W{{ week.num }} /{{ week.year.toString().slice(-2) }}
      </clr-dg-column>
      <clr-dg-row>
        <clr-dg-cell class="bike">
          <span class="bike label">
            <cds-icon style="margin-right: 5px" shape="tag" role="img"></cds-icon>
            <span>{{ segmentName }}&nbsp;</span>
          </span>
        </clr-dg-cell>
        <clr-dg-cell class="total">
          <div class="km">
            <span *ngIf="segmentSupervisionKmLoading" class="spinner spinner-inline"> Loading... </span>
            <span *ngIf="!segmentSupervisionKmLoading">
              {{
                (segmentSupervision.weeklyKm ? segmentSupervision.weeklyKm.total : '-') ||
                  (segmentSupervision.km ? segmentSupervision.km.total : '-')
              }}
              km
            </span>
          </div>
          <div class="errors">
            <span *ngIf="segmentSupervisionErrorLoading" class="spinner spinner-inline"> Loading... </span>
            <span *ngIf="!segmentSupervisionErrorLoading">
              {{
                (segmentSupervision.weeklyErrors ? segmentSupervision.weeklyErrors.total : '-') ||
                  (segmentSupervision.errors ? segmentSupervision.errors.total : '-')
              }}
              err
            </span>
          </div>
        </clr-dg-cell>
        <clr-dg-cell *ngFor="let week of segmentSupervision.getWeeks()">
          <div class="km">
            <span *ngIf="segmentSupervisionKmLoading" class="spinner spinner-inline"> Loading... </span>
            <span *ngIf="!segmentSupervisionKmLoading">
              {{ segmentSupervision.getKmLabelByWeek(week) }}
            </span>
          </div>
          <div class="errors" title="{{ segmentSupervision.getErrorDetailsByWeek(week) }}">
            <span *ngIf="!segmentSupervisionErrorLoading">
              {{ segmentSupervision.getErrorsLabelByWeek(week) }}
            </span>
          </div>
          <!--<div>{{ segmentSupervision.getRatioByWeek(week) }} err/km</div>-->
        </clr-dg-cell>
      </clr-dg-row>
    </clr-datagrid>
  </div>
</div>

<h3 i18n>Weekly details</h3>
<div class="clr-row supervision-bikes">
  <div class="clr-col-12">
    <button
      type="button"
      *ngIf="!showBikesSupervision"
      class="btn btn-sm btn-secondary"
      (click)="showBikesSupervision = true"
      i18n
    >
      <cds-icon shape="sync"></cds-icon>
      Load details
    </button>

    <clr-datagrid
      *ngIf="showBikesSupervision"
      (clrDgRefresh)="refreshBikesSupervision($event)"
      [clrDgLoading]="bikesWeeklySupervisionLoading"
      class="supervision-table datagrid-compact"
    >
      <clr-dg-column class="bike" i18n>Bike</clr-dg-column>
      <clr-dg-column class="total" i18n>TOTAL</clr-dg-column>
      <clr-dg-column *ngFor="let week of weeks" i18n>
        W{{ week.num }} /{{ week.year.toString().slice(-2) }}
      </clr-dg-column>

      <clr-dg-row *ngFor="let bikeSupervision of bikesSupervision">
        <clr-dg-cell class="bike">
          <div>
            <a [routerLink]="['/bikes', 'view', bikeSupervision.bike.id]" href>{{ bikeSupervision.bike.id }}</a>
            &nbsp;
          </div>
          <div>{{ bikeSupervision.bike.serialNumber }}&nbsp;</div>
          <div [title]="bikeSupervision.bike.owner">{{ bikeSupervision.bike.modelName }}</div>
        </clr-dg-cell>
        <clr-dg-cell class="total">
          <div class="km">{{ bikeSupervision.data.weeklyKm.total }} km</div>
          <div class="errors">{{ bikeSupervision.data.weeklyErrors.total }} err.</div>
        </clr-dg-cell>
        <clr-dg-cell *ngFor="let week of segmentSupervision.getWeeks()">
          <div class="km">{{ bikeSupervision.data.getKmLabelByWeek(week) }}</div>
          <div class="errors" title="{{ segmentSupervision.getErrorDetailsByWeek(week) }}">
            {{ bikeSupervision.data.getErrorsLabelByWeek(week) }}
          </div>
        </clr-dg-cell>
      </clr-dg-row>

      <clr-dg-footer i18n>
        {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} bikes
        <clr-dg-pagination #pagination [clrDgTotalItems]="total" [clrDgPageSize]="7"></clr-dg-pagination>
      </clr-dg-footer>
    </clr-datagrid>
  </div>
</div>

<clr-modal [(clrModalOpen)]="updateTargetModalDisplayed" [clrModalSize]="'sm'">
  <h3 class="modal-title" i18n>Supervision target</h3>
  <div class="modal-body supervision-target-form">
    <clr-combobox-container class="form-input">
      <label i18n>Segment : </label>
      <clr-combobox
        name="segment"
        placeholder="Starts typing..."
        [(ngModel)]="segment"
        [clrLoading]="segmentsLoading"
        (clrInputChange)="fetchSegments($event)"
        (clrOpenChange)="$event ? fetchSegments('') : null"
      >
        <ng-container *clrOptionSelected="let selected">
          <cds-icon shape="tags" role="img"></cds-icon>
          {{ selected?.name }}
        </ng-container>
        <clr-options>
          <clr-option *clrOptionItems="let state of segments; field: 'name'" [clrValue]="state">
            <cds-icon shape="tags" role="img"></cds-icon>
            {{ state.name }}
          </clr-option>
        </clr-options>
      </clr-combobox>
    </clr-combobox-container>
    <cds-date fixedControlWidth="true" class="form-input">
      <label i18n>From : </label>
      <input type="date" [value]="startDate" (change)="setStartDate($event)" />
    </cds-date>
    <cds-date fixedControlWidth="true" class="form-input">
      <label i18n>To : &nbsp;</label>
      <input type="date" [value]="endDate" (change)="setEndDate($event)" />
    </cds-date>
    <clr-alert [clrAlertType]="'danger'" [clrAlertClosed]="!formError">
      <div clr-alert-item class="alert-item">
        <span class="alert-text">
          {{ formError }}
        </span>
      </div>
    </clr-alert>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="updateTargetModalDisplayed = false" i18n>Cancel</button>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="!startDate || !endDate || !segment"
      (click)="onTargetUpdated()"
      i18n
    >
      Update
    </button>
  </div>
</clr-modal>
