import { Component, Input, OnInit } from '@angular/core';
import { BikeModel } from '../../models/bike-model.model';

@Component({
  selector: 'bike-model-link',
  templateUrl: './bike-model-link.component.html',
})
export class BikeModelLinkComponent implements OnInit {
  @Input()
  model: BikeModel;

  constructor() {}

  ngOnInit(): void {}
}
