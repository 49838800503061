<h1 i18n>Add a firmware</h1>

<form clrForm [formGroup]="addFirmwareForm" (ngSubmit)="onSubmit()">
  <clr-alert [clrAlertType]="'danger'" [clrAlertClosed]="!error">
    <div clr-alert-item class="alert-item">
      <span class="alert-text">
        {{ error }}
      </span>
    </div>
  </clr-alert>

  <clr-input-container>
    <label i18n>Zip</label>
    <input clrInput type="file" placeholder="Upload file" accept="*" (change)="onFileSelect($event)" />
  </clr-input-container>

  <button class="btn btn-primary" type="submit" i18n>
    <clr-spinner *ngIf="formSubmitted" [clrInline]="true"  [clrInverse]="true">Loading ...</clr-spinner>
    <span *ngIf="!formSubmitted" >Upload firmware</span>
  </button>

  <!-- label class="btn btn-link">
    <input type="file"
           (change)="onChange($event)"
           placeholder="Upload file"
           accept="*" style="display: none"/>
    Attach Files
  </label -->
</form>
