import { Component, OnInit, OnDestroy } from '@angular/core';
import { VersionStat } from '../../../bike/models/version-stat.model';
import { VersionService } from 'src/app/pages/bike/services/version.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BikeService } from 'src/app/pages/bike/services/bike.service';
import { BikeStats } from 'src/app/pages/bike/models/bike.model';
import { RideService } from 'src/app/pages/user/services/ride.service';
import { RideGlobalStats } from 'src/app/pages/user/models/ride-stats.model';
@UntilDestroy()
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public versionStats: VersionStat[];
  public bikeStats: BikeStats;
  public rideStats: RideGlobalStats[];

  constructor(
    private versionService: VersionService,
    private bikeService: BikeService,
    private rideService: RideService
  ) {}

  ngOnInit(): void {
    this.fetchAppVersionStats(false);

    this.bikeService
      .stats()
      .pipe(untilDestroyed(this))
      .subscribe((stats) => (this.bikeStats = stats));
  }

  private fetchAppVersionStats(all: boolean): void {
    this.versionService
      .stats('APP')
      .pipe(untilDestroyed(this))
      .subscribe((stats) => {
        if (all) {
          this.versionStats = stats;
        } else {
          this.versionStats = stats.slice(0, 5);
        }
      });
  }

  ngOnDestroy(): void {}

  public refresh(): void {
    this.rideService
      .stats('week')
      .pipe(untilDestroyed(this))
      .subscribe((stats) => {
        stats = stats.reverse();

        if (stats.length > 5) {
          return (this.rideStats = stats.slice(0, 5));
        }

        return (this.rideStats = stats);
      });
  }

  public showAllVersions(): void {
    this.fetchAppVersionStats(true);
  }
}
