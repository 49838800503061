import { Location, Version } from '../../bike/models/bike.model';

export class AngellBack {
  id: string;
  startAt: Date;
  endAt: Date;
  isCovered: boolean;
  bikeId: number;
  assets: AngellBackAsset[];

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.startAt = data.startAt;
      this.endAt = data.endAt;
      this.isCovered = data.isCovered;
      this.bikeId = data.bikeId;
      if (data.assets) {
        this.assets = data.assets.map((a) => new AngellBackAsset(a));
      }
    }
  }
}

class AngellBackAsset {
  id: string;
  date: Date;
  filename: string;
  url: string;
  location: Location;
  internalBattery: number;
  externalBattery: number;
  mobileAppVersion: string;
  bikeVersion: string;
  isLocked: string;
  isAlarmOn: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.date = data.updateDate;
      this.filename = data.filename;
      this.url = data.url;
      if (data.metadata) {
        this.location = new Location(data.metadata.location);
        this.internalBattery = data.metadata.internalBatteryLevel;
        this.externalBattery = data.metadata.externalBatteryLevel;
        this.isLocked = data.metadata.isLocked;
        this.isAlarmOn = data.metadata.isAlarmOn;
        this.mobileAppVersion = data.metadata.mobileAppVersion;
        const appVersion: Version = new Version(data.metadata.appVersion);
        const bleVersion: Version = new Version(data.metadata.bluetoothVersion);
        const imgVersion: Version = new Version(data.metadata.imageVersion);
        const bootloaderVersion: Version = new Version(data.metadata.bootloaderVersion);
        this.bikeVersion = `${appVersion ? appVersion.toString() : '-'}/${bleVersion ? bleVersion.toString() : '-'}/${
          imgVersion ? imgVersion.toString() : '-'
        }/${bootloaderVersion ? bootloaderVersion.toString() : '-'}`;
      }
    }
  }
}
