import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClrDatagridStateInterface } from '@clr/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BatteryService } from '../../../services/battery.service';
import { Battery } from '../../../models/battery.model';

@UntilDestroy()
@Component({
  selector: 'app-list-bike',
  templateUrl: './list-battery.component.html',
  styleUrls: ['./list-battery.component.scss'],
})
export class ListBatteryComponent implements OnInit, OnDestroy {
  public batteries: Battery[];
  public total: number;
  public loading = true;

  constructor(private batteryService: BatteryService, private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      searchTerm: '',
    });
  }

  ngOnDestroy(): void {}

  public refresh(state: ClrDatagridStateInterface): void {
    this.loading = true;

    const params = {
      page: state.page.current - 1,
      size: state.page.size,
    };

    this.batteryService
      .getAll(params)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result) => {
          this.batteries = result.values();
          this.total = result.page.totalElements || this.batteries?.length;
        },
        complete: () => {
          this.loading = false;
        },
      });
  }

  public searchForm: UntypedFormGroup;
  public filtersOpened: boolean = true;

  private constructParamsFromState(state) {
    const params = {
      searchTerm: this.searchForm.get('searchTerm').value,
      page: state.page.current - 1,
      size: state.page.size,
    };
    return params;
  }
  public onSubmit(state: ClrDatagridStateInterface): void {
    if (this.searchForm.invalid) {
      return;
    }

    this.loading = true;

    const params = this.constructParamsFromState(state);

    this.batteryService
      .getAll(params)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.batteries = result.values();
        this.total = result.page.totalElements || this.batteries?.length;
        this.loading = false;
      });
  }
}
