import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClrDatagridStateInterface } from '@clr/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SimCardService } from '../../../services/simcard.service';
import { SimCard } from '../../../models/simcard.model';

@UntilDestroy()
@Component({
  selector: 'app-list-bike',
  templateUrl: './list-sim.component.html',
  styleUrls: ['./list-sim.component.scss'],
})
export class ListSimComponent implements OnInit, OnDestroy {
  public simCards: SimCard[];
  public total: number;
  public loading = true;

  constructor(
    private simCardService: SimCardService,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      searchTerm: '',
      provider: '',
      state: '',
    });
  }

  ngOnDestroy(): void {}

  public searchForm: UntypedFormGroup;
  public filtersOpened: boolean = true;

  private constructParamsFromState(state) {
    const params = {
      searchTerm: this.searchForm.get('searchTerm').value,
      state: this.searchForm.get('state').value,
      provider: this.searchForm.get('provider').value,
      page: state.page.current - 1,
      size: state.page.size,
    };
    return params;
  }
  public onSubmit(state: ClrDatagridStateInterface): void {
    if (this.searchForm.invalid) {
      return;
    }

    this.loading = true;

    const params = this.constructParamsFromState(state);

    this.simCardService
      .getAll(params)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.simCards = result.values();
        this.total = result.page.totalElements || this.simCards?.length;
        this.loading = false;
      });
  }
}
