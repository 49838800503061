const ROLE_SUPPORT_RIGHTS = {
  /** MENU */
  menu_bikesList: true,
  menu_bikesModel: true,
  menu_bikesVersions: true,
  menu_bikesSegments: true,
  menu_cockpitsList: true,
  menu_usersList: true,
  menu_proList: true,
  menu_services: true,
  menu_notificationsList: true,
  menu_adminsList: false,
  menu_afterSalesList: false,
  menu_import: false,
  /** SHOW */
  show_location: false,
  show_api_logs: false,
  show_push_agent: false,
  /** ACTIONS */
  actions_bikes: false,
  actions_versions: false,
  actions_users: false,
  actions_pro: true,
  actions_services: false,
  actions_firmwares: false,
  actions_sync_bike_provisioning: false,
  actions_rmcustomerbike: true,
  actions_marketzone: false,
  actions_provisioning: false,
};

const ROLE_ADMIN_RIGHTS = {
  ...ROLE_SUPPORT_RIGHTS,
  /** MENU */
  menu_adminsList: true,
  menu_import: true,
  menu_newsCenter: true,
  menu_bikesFirmwares: true,
  menu_afterSalesList: true,
  /** SHOW */
  show_location: true,
  show_api_logs: true,
  show_push_agent: true,
  /** ACTIONS */
  actions_bikes: true,
  actions_versions: true,
  actions_users: true,
  actions_services: true,
  actions_news: true,
  actions_firmwares: true,
  actions_marketzone: true,
  actions_provisioning: true,
};

const ROLE_SUPERADMIN_RIGHTS = {
  ...ROLE_ADMIN_RIGHTS,
  actions_news_run_campaign: true,
  actions_sync_bike_provisioning: true,
};

export abstract class ACLS {
  static readonly ROLE_SUPERADMIN = ROLE_SUPERADMIN_RIGHTS;
  static readonly ROLE_ADMIN = ROLE_ADMIN_RIGHTS;
  static readonly ROLE_SUPPORT = ROLE_SUPPORT_RIGHTS;
}
