<h1 i18n>Test next version</h1>

<div class="clr-row">
  <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
    <form clrForm [formGroup]="testVersionForm" (ngSubmit)="onSubmit()">
      <clr-input-container>
        <label i18n>App version</label>
        <input clrInput type="text" formControlName="appVersion" placeholder="1.2.3" required />
      </clr-input-container>

      <clr-input-container>
        <label i18n>Bluetooth version</label>
        <input clrInput type="text" formControlName="bleVersion" placeholder="1.2.3" required />
      </clr-input-container>

      <clr-input-container>
        <label i18n>Image version</label>
        <input clrInput type="text" formControlName="imageVersion" placeholder="1.2.3" required />
      </clr-input-container>

      <clr-combobox-container>
        <label>Segments</label>
        <clr-combobox
          formControlName="segments"
          name="multiSelect"
          clrMulti="true"
          placeholder="Starts typing..."
          [clrLoading]="segmentsLoading"
          (clrInputChange)="fetchSegments($event)"
          (clrOpenChange)="$event ? fetchSegments('') : null"
        >
          <ng-container *clrOptionSelected="let selected">
            <cds-icon shape="tags" role="img"></cds-icon> {{ selected?.name }}
          </ng-container>
          <clr-options>
            <clr-option *clrOptionItems="let state of segments; field: 'name'" [clrValue]="state">
              <cds-icon shape="tags" role="img"></cds-icon> {{ state.name }}
            </clr-option>
          </clr-options>
        </clr-combobox>
      </clr-combobox-container>

      <button class="btn btn-primary" type="submit" i18n>Test version</button>
    </form>
  </div>

  <div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
    <div class="card" *ngIf="testNextVersionResult">
      <div class="card-header" i18n>Result</div>
      <div class="card-block">
        <div *ngIf="testNextVersionResult.isUpToDate" i18n class="card-title">Up to date</div>
        <div *ngIf="!testNextVersionResult.isUpToDate" i18n class="card-title">Not up to date</div>
        <div class="card-text">
          <a [routerLink]="['/bikes', 'versions', testNextVersionResult.nextVersion]" href>
            {{ testNextVersionResult.nextVersion }}
          </a>
          <br />
          <span *ngIf="!testNextVersionResult.isUpToDate">{{ testNextVersionResult.filename }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
