import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../../models/user.model';
import { CustomerService } from '../../../services/customer.service';
import { RideService } from '../../../services/ride.service';
import { CustomerTimelineService } from '../../../services/customer-timeline.service';
import { FiltersUserEvent, TimelineEvent } from '../../../models/timeline-event.model';
import { Shop } from '../../../models/shop.model';

@UntilDestroy()
@Component({
  selector: 'app-view-user',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss'],
})
export class ShopComponent implements OnInit, OnDestroy {
  public user: User;

  public shop: Shop;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.parent.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.user = data.user;
    });
  }

  ngOnDestroy(): void {}
}
