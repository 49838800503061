import { UpdateTrace } from '../../../modules/api/models';

export class Battery {
  id: string;
  corepack: Corepack;
  provisionedOn: Date;
  supersededOn: Date;
  color: string;
  serialNumber: string;
  lockCode: string;
  testReport: any;
  updateTrace: UpdateTrace;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.provisionedOn = new Date(data.provisionedOn);
      this.supersededOn = new Date(data.supersededOn);
      this.color = data.color;
      this.serialNumber = data.serialNumber;
      this.lockCode = data.lockCode;
      this.testReport = data.testReport;
      this.updateTrace = new UpdateTrace(data.updateTrace);
      if (data.corepack) {
        this.corepack = new Corepack(data.corepack);
      }
    }
  }
}

export class Corepack {
  id: string;
  manufacturer: string;
  serialNumber: string;
  softwareVersion: string;
  hardwareVersion: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.manufacturer = data.manufacturer;
      this.serialNumber = data.serialNumber;
      this.softwareVersion = data.softwareVersion;
      this.hardwareVersion = data.hardwareVersion;
    }
  }
}

export class BatteryConnection {
  id: string;
  bikeId: string;
  corepack: Corepack;
  battery: Battery;
  count: number;
  updateTrace: UpdateTrace;
  firstConnection: Date;
  lastConnection: Date;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.bikeId = data.bikeId;
      this.corepack = data.corepack;
      this.battery = data.battery;
      this.count = data.count;
      this.updateTrace = new UpdateTrace(data.updateTrace);
      if (data.updateTrace) {
        this.firstConnection = data.updateTrace.createdOn;
        this.lastConnection = data.updateTrace.updatedOn ? data.updateTrace.updatedOn : data.updateTrace.createdOn;
      }
    }
  }
}
