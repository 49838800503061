import { LinkResource, UpdateTrace } from 'src/app/modules/api/models';
import { Segment } from './segment.model';

export class SoftwarePackages extends LinkResource {
  id: string;
  softwarePackageType: string;
  enable: boolean;
  filename: string;
  softwares: Software[];
  segments: Segment[];
  softwareVersionRequirements: VersionRequirement;

  updateTrace: UpdateTrace;

  constructor(data?: any) {
    super(data?._links);

    if (data) {
      this.id = data.id;
      this.softwares = data.softwares;
      this.softwarePackageType = data.softwarePackageType;
      this.enable = data.enable;
      this.filename = data.filename;
      this.softwares = data.softwares ? data.softwares.map((software) => new Software(software)) : [];
      this.softwareVersionRequirements = new VersionRequirement(data.softwareVersionRequirements);
      this.segments = data.segments ? data.segments.map((segment) => new Segment(segment)) : [];
      this.updateTrace = new UpdateTrace(data.updateTrace);
    }
  }
}

class Software {
  bikeCountLimit: number;
  version: Version;
  softwareType: string;
  descriptors: Descriptor[];

  constructor(data?: any) {
    if (data) {
      this.bikeCountLimit = data.bikeCountLimit;
      this.descriptors = data?.softwareDescriptors?.map((descriptor) => new Descriptor(descriptor));
      this.version = new Version(data.version);
      this.softwareType = data.softwareType;
    }
  }
}

export class Descriptor {
  id: string;
  type: string;
  content: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.type = data.type;
      this.content = data.content;
    }
  }
}

class Version {
  majorVersion: number;
  minorVersion: number;
  incrementalVersion: number;

  public toString(): string {
    return `${this.majorVersion}.${this.minorVersion}.${this.incrementalVersion}`;
  }

  constructor(data?: any) {
    if (data) {
      this.majorVersion = data.majorVersion;
      this.minorVersion = data.minorVersion;
      this.incrementalVersion = data.incrementalVersion;
    }
  }
}

class VersionRequirement {
  compactMinAppVersion: number;
  compactMinImageVersion: number;
  compactMinBluetoothVersion: number;

  constructor(data?: any) {
    if (data) {
      this.compactMinAppVersion = data.compactMinAppVersion;
      this.compactMinImageVersion = data.compactMinImageVersion;
      this.compactMinBluetoothVersion = data.compactMinBluetoothVersion;
    }
  }
}
