<div *ngIf="bike">
  <div class="clr-row">
    <div class="clr-col-lg-6 clr-col-md-8 clr-col-12" *ngFor="let customerBike of getUndeletedCustomerBikes()">
      <h4 i18n>{{ customerBike.bikeName }} ({{ customerBike.email }})</h4>
      <clr-datagrid (clrDgRefresh)="refreshAutonomy($event, customerBike.id)">
        <clr-dg-column i18n>Date</clr-dg-column>
        <clr-dg-column i18n>Eco</clr-dg-column>
        <clr-dg-column i18n>Dry</clr-dg-column>
        <clr-dg-column i18n>Fast</clr-dg-column>

        <clr-dg-row *ngFor="let item of autonomies[customerBike.id].values" [clrDgItem]="item">
          <clr-dg-cell>{{ item.updateTrace.createdOn | date: 'dd/MM/yy HH:mm' }}</clr-dg-cell>
          <clr-dg-cell>{{ item.autonomyEco }}km</clr-dg-cell>
          <clr-dg-cell>{{ item.autonomyDry }}km</clr-dg-cell>
          <clr-dg-cell>{{ item.autonomyFast }}km</clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer i18n>
          {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of
          {{ autonomies[customerBike.id].total }} autonomies
          <clr-dg-pagination #pagination [clrDgTotalItems]="autonomies[customerBike.id].total"></clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>
    </div>
  </div>
  <!-- .clr-row -->
</div>
