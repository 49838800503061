import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from 'src/app/modules/api/services/api.service';
import { ResponseEntities } from 'src/app/modules/api/models/response-entities.model';
import { catchError, map } from 'rxjs/operators';
import { definitiveCache } from 'src/app/modules/api/definitive-cache/definitive-cache';
import { Battery, BatteryConnection } from '../models/battery.model';
import { encodeURL } from 'js-base64';

@Injectable({
  providedIn: 'root',
})
export class BatteryService {
  constructor(private api: ApiService) {}

  private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message} with error : ${error}`);

      return of(result as T);
    };
  }

  public getAll(params: any): Observable<ResponseEntities<Battery>> {
    return this.api
      .get<ResponseEntities<Battery>>('/batteries', {
        params,
      })
      .pipe(
        map((a) => new ResponseEntities<Battery>(Battery, a)),
        catchError(this.handleError<ResponseEntities<Battery>>('all')),
        definitiveCache(),
      );
  }

  getHistoryBySN(sn: string, params: any): Observable<Battery[]> {
    return this.api.get<Battery[]>(`/batteries/history`, {
      params: {
        ...params,
        sn: sn,
      },
    });
  }

  loadConnectionsByBike(bikeId: string, params: any): Observable<BatteryConnection[]> {
    return this.api
      .get<BatteryConnection[]>(`/bikes/${bikeId}/batteries/pairing`, {
        params,
      })
      .pipe(
        map((bcs) => {
          const ibcs: BatteryConnection[] = [];
          for (const bc of bcs) {
            ibcs.push(new BatteryConnection(bc));
          }
          return ibcs;
        }),
        catchError(this.handleError<BatteryConnection[]>('all')),
        definitiveCache(),
      );
  }

  loadConnectionsByBatteryId(batteryId: string, params: any): Observable<BatteryConnection[]> {
    return this.api
      .get<BatteryConnection[]>(`/batteries/${batteryId}/bikes/pairing`, {
        params,
      })
      .pipe(
        map((bcs) => {
          const ibcs: BatteryConnection[] = [];
          for (const bc of bcs) {
            ibcs.push(new BatteryConnection(bc));
          }
          return ibcs;
        }),
        catchError(this.handleError<BatteryConnection[]>('all')),
        definitiveCache(),
      );
  }
}
