import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from 'src/app/modules/api/services/api.service';
import { ResponseCursorEntities, ResponseEntities } from 'src/app/modules/api/models/response-entities.model';
import {
  Bike,
  BikeStats,
  CustomerBike,
  CustomerBikeAutonomy,
  MaintenanceOperation,
} from 'src/app/pages/bike/models/bike.model';
import { catchError, map } from 'rxjs/operators';
import { definitiveCache } from 'src/app/modules/api/definitive-cache/definitive-cache';
import { Segment } from '../models/segment.model';
import { ImportResult } from '../models/import-result.model';
import { BikeErrorHistory } from '../models/bike-error-history.model';
import { BikeAutodiagHistory } from '../models/bike-autodiag-history.model';
import { BikeProvisionLogfile } from '../models/bike-provision.model';
import { BikeHistory } from '../models/bike-history.model';
import { BikeProvisionHistory } from '../models/bike-provision-history.model';
import { NextVersionOTAV2 } from '../models/bike-next-version.model';
import { BikeConnectivityHistory } from '../models/bike-connectivity-history.model';
import { BikeStatusHistory } from '../models/bike-status-history.model';
import { BikeAutodiagStatDto } from '../models/bike-autodiag-stat.model';
import { BikeHardwareConfig } from '../models/bike-hardware.config';
import { BikeGSMHistory } from '../models/bike-gsm-history.model';
import { BikeWarning } from '../models/bike-warning.model';

@Injectable({
  providedIn: 'root',
})
export class BikeService {
  constructor(private api: ApiService) {}

  private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message} with error : ${error}`);

      return of(result as T);
    };
  }

  public addSegment(id: string, name: string): Observable<Segment> {
    return this.api.post<Segment>(`/bikes/${id}/segments`, {
      name,
    });
  }

  public removeSegment(bikeId: string, segmentId: string): Observable<boolean> {
    return this.api.delete<boolean>(`/bikes/${bikeId}/segments/${segmentId}`);
  }

  public getAll(params: any): Observable<ResponseEntities<Bike>> {
    params.projection = 'bikeFullAdmin';

    return this.api
      .get<ResponseEntities<Bike>>('/bikes', {
        params,
      })
      .pipe(
        map((a) => new ResponseEntities<Bike>(Bike, a)),
        catchError(this.handleError<ResponseEntities<Bike>>('all')),
        definitiveCache(),
      );
  }

  public search(params: any): Observable<ResponseEntities<Bike>> {
    params.projection = 'bikeFullAdmin';

    return this.api
      .get<ResponseEntities<Bike>>('/bikes', {
        params,
      })
      .pipe(
        map((a) => new ResponseEntities<Bike>(Bike, a)),
        catchError(this.handleError<ResponseEntities<Bike>>('all')),
        definitiveCache(),
      );
  }

  public get(id: number): Observable<Bike> {
    return this.api
      .get<Bike>(`/bikes/${id}`, {
        params: {
          projection: 'bikeFullAdmin',
        },
      })
      .pipe(map((a) => new Bike(a)));
  }
  public deleteBikeUser(bikeId: string, userEmail: string): Observable<any> {
    return this.api
      .delete<any>(`/bikes/${bikeId}/users/${userEmail}`)
      .pipe(catchError(this.handleError<any>('deleteUserOnBike')));
  }

  public importBikes(model: string, csv: string, dryRun: boolean): Observable<ImportResult[]> {
    return this.api
      .post<ImportResult[]>(
        '/bikes/imports',
        { model, csv },
        {
          params: {
            dryRun: dryRun ? 'true' : 'false',
          },
        },
      )
      .pipe(
        map((response) => {
          response.map((item) => new ImportResult(item));

          return response;
        }),
      );
  }

  public stats(): Observable<BikeStats> {
    return this.api.get('/bikes/statistics');
  }

  public getErrorsHistory(
    id: string,
    params: { [param: string]: string },
  ): Observable<ResponseEntities<BikeErrorHistory>> {
    return this.api
      .get<ResponseEntities<BikeErrorHistory>>(`/bikes/${id}/error-logs`, {
        params,
      })
      .pipe(
        map((a) => new ResponseEntities<BikeErrorHistory>(BikeErrorHistory, a)),
        catchError(this.handleError<ResponseEntities<BikeErrorHistory>>('all')),
        definitiveCache(),
      );
  }
  public getWarnings(id: string, since: number, params): Observable<ResponseEntities<BikeWarning>> {
    params.since = since;
    return this.api
      .get<ResponseEntities<BikeWarning>>(`/bikes/${id}/warnings`, {
        params,
      })
      .pipe(
        map((a) => new ResponseEntities<BikeWarning>(BikeWarning, a)),
        catchError(this.handleError<ResponseEntities<BikeWarning>>('all')),
        definitiveCache(),
      );
  }
  public getAutodiagHistory(id: string, params): Observable<ResponseEntities<BikeAutodiagHistory>> {
    return this.api
      .get<ResponseEntities<BikeAutodiagHistory>>(`/bikes/${id}/autodiags`, {
        params,
      })
      .pipe(
        map((a) => new ResponseEntities<BikeAutodiagHistory>(BikeAutodiagHistory, a)),
        catchError(this.handleError<ResponseEntities<BikeAutodiagHistory>>('all')),
        definitiveCache(),
      );
  }
  public getAutodiagStats(id: string, date: number): Observable<BikeAutodiagStatDto[]> {
    return this.api
      .get<BikeAutodiagStatDto[]>(`/bikes/${id}/autodiags/stats`, {
        params: {
          from: date.toString(),
        },
      })
      .pipe(map((a) => a.map((i) => new BikeAutodiagStatDto(i))));
  }

  public getLogfile(mac: string): Observable<BikeProvisionLogfile[]> {
    return this.api
      .get<BikeProvisionLogfile[]>(`/bikes/provision/${mac}/logs`)
      .pipe(map((a) => a.map((i) => new BikeProvisionLogfile(i))));
  }

  public getAllCustomerBikes(bikeId: string): Observable<ResponseEntities<CustomerBike>> {
    return this.api.get<CustomerBike[]>(`/customerBikes?bikeId=${bikeId}`).pipe(
      map((a) => new ResponseEntities<CustomerBike>(CustomerBike, a)),
      catchError(this.handleError<ResponseEntities<CustomerBike>>('all')),
      definitiveCache(),
    );
  }

  public getCustomerBikeAutonomy(
    customerBikeId: string,
    params: any,
  ): Observable<ResponseEntities<CustomerBikeAutonomy>> {
    return this.api
      .get<CustomerBikeAutonomy[]>(`/customerBikes/${customerBikeId}/autonomy`, {
        params,
      })
      .pipe(
        map((a) => new ResponseEntities<CustomerBikeAutonomy>(CustomerBikeAutonomy, a)),
        catchError(this.handleError<ResponseEntities<CustomerBikeAutonomy>>('all')),
        definitiveCache(),
      );
  }

  public getEvents(
    id: string,
    dateFrom: Date,
    page: number,
    limit?: number,
    filters?: { [key: string]: any },
  ): Observable<ResponseEntities<BikeHistory>> {
    return this.api
      .get<ResponseEntities<BikeHistory>>(`/bikes/${id}/v2/events`, {
        params: {
          from: dateFrom.getTime().toString(),
          // from: '1669209324000',
          size: limit ? limit.toString() : '100',
          page: page.toString(),
          ...filters,
        },
      })
      .pipe(
        map((a) => new ResponseEntities<BikeHistory>(BikeHistory, a)),
        catchError(this.handleError<ResponseEntities<BikeHistory>>('all')),
        definitiveCache(),
      );
  }

  public getProvisionHistory(
    mac: string,
    dateFrom: Date,
    nextPageCursor: string,
  ): Observable<ResponseCursorEntities<BikeProvisionHistory>> {
    return this.api
      .get<ResponseCursorEntities<BikeProvisionHistory>>(`/bikes/provision/${mac}/history`, {
        params: {
          from: dateFrom.getTime().toString(),
          // from: '1656626400000',
          size: '5',
          cursor: nextPageCursor,
        },
      })
      .pipe(
        map((a) => new ResponseCursorEntities<BikeProvisionHistory>(BikeProvisionHistory, a)),
        catchError(this.handleError<ResponseCursorEntities<BikeProvisionHistory>>('all')),
        definitiveCache(),
      );
  }

  public updateProvisioning(mac: string, dto: any): Observable<BikeProvisionHistory> {
    return this.api.patch<BikeProvisionHistory>(`/bikes/provision/${mac}`, dto);
  }

  public getHardwareConfigHistory(bikeId: string): Observable<ResponseEntities<BikeHardwareConfig>> {
    return this.api.get<ResponseEntities<BikeHardwareConfig>>(`/bikes/${bikeId}/hw-configs`).pipe(
      map((a) => new ResponseEntities<BikeHardwareConfig>(BikeHardwareConfig, a)),
      catchError(this.handleError<ResponseEntities<BikeHardwareConfig>>('all')),
      definitiveCache(),
    );
  }

  public getSyncProvisioningFlag(bikeId: string): Observable<boolean> {
    return this.api.get<any>(`/bikes/${bikeId}/sync-provisioning`).pipe(
      map((a) => a.enabled),
      definitiveCache(),
    );
  }

  public updateSyncProvisioningFlag(bikeId: string, enabled: boolean): Observable<boolean> {
    return this.api.put<any>(`/bikes/${bikeId}/sync-provisioning`, { enabled: enabled }).pipe(
      map((a) => a.enabled),
      definitiveCache(),
    );
  }

  public getConnectivityHistory(
    bikeId: string,
    dateFrom: Date,
    nextPageCursor: string,
  ): Observable<ResponseCursorEntities<BikeConnectivityHistory>> {
    return this.api
      .get<ResponseCursorEntities<BikeConnectivityHistory>>(`/bikes/${bikeId}/connectivity`, {
        params: {
          from: dateFrom.getTime().toString(),
          size: '20',
          cursor: nextPageCursor,
        },
      })
      .pipe(
        map((a) => new ResponseCursorEntities<BikeConnectivityHistory>(BikeConnectivityHistory, a)),
        catchError(this.handleError<ResponseCursorEntities<BikeConnectivityHistory>>('all')),
        definitiveCache(),
      );
  }

  public getGSMHistory(
    bikeId: string,
    dateFrom: Date,
    nextPageCursor: string,
  ): Observable<ResponseCursorEntities<BikeGSMHistory>> {
    return this.api
      .get<ResponseCursorEntities<BikeGSMHistory>>(`/bikes/${bikeId}/gsm`, {
        params: {
          from: dateFrom.getTime().toString(),
          size: '20',
          cursor: nextPageCursor,
        },
      })
      .pipe(
        map((a) => new ResponseCursorEntities<BikeGSMHistory>(BikeGSMHistory, a)),
        catchError(this.handleError<ResponseCursorEntities<BikeGSMHistory>>('all')),
        definitiveCache(),
      );
  }

  public getBikeStatusHistory(bikeId: string, dateFrom: Date, dateTo: Date): Observable<BikeStatusHistory[]> {
    return this.api
      .get<BikeStatusHistory[]>(`/bikes/${bikeId}/history/status`, {
        params: {
          from: dateFrom.getTime().toString(),
          to: dateTo.getTime().toString(),
        },
      })
      .pipe(
        map((a) => a.map((item) => new BikeStatusHistory(item))),
        catchError(this.handleError<BikeStatusHistory[]>('all')),
        definitiveCache(),
      );
  }

  public getNextVersion(id: string): Observable<NextVersionOTAV2> {
    return this.api.get<Bike>(`/bikes/${id}/firmwares/nextversion`).pipe(map((a) => new NextVersionOTAV2(a)));
  }

  public getMaintenanceOperations(bikeId: string, params: any): Observable<ResponseEntities<MaintenanceOperation>> {
    return this.api
      .get<CustomerBikeAutonomy[]>(`/bikes/${bikeId}/maintenance/operations`, {
        params,
      })
      .pipe(
        map((a) => new ResponseEntities<MaintenanceOperation>(MaintenanceOperation, a)),
        catchError(this.handleError<ResponseEntities<MaintenanceOperation>>('all')),
        definitiveCache(),
      );
  }

  public saveMaintenanceOperation(
    bikeId: string,
    maintenanceOperation: MaintenanceOperation,
  ): Observable<MaintenanceOperation> {
    return this.api.post<MaintenanceOperation>(`/bikes/${bikeId}/maintenance/operations`, maintenanceOperation).pipe(
      map((a) => new MaintenanceOperation(a)),
      catchError(this.handleError<MaintenanceOperation>('postMaintenanceOperation')),
      definitiveCache(),
    );
  }

  public updateTheftDate(id: string, date: Date): Observable<any> {
    //
    return this.api.put(`/bikes/${id}/theft-date`, { date });
  }
}
