import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/modules/api/services/api.service';
import { Observable, of } from 'rxjs';
import { ResponseEntities } from '../../../modules/api/models';
import { Ride } from '../models/ride.model';
import { catchError, map } from 'rxjs/operators';
import { definitiveCache } from 'src/app/modules/api/definitive-cache/definitive-cache';
import { NotificationChannel, PushToken } from '../models/notification-channel.model';
import { Retailer } from '../models/retailer.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private api: ApiService) {}

  private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message} with error : ${error}`);

      return of(result as T);
    };
  }

  public getNotificationsTypes(type: string, tenant: string): Observable<string[]> {
    return this.api.get<string[]>(`/_debug/notification/${type}/types?tenant=${tenant}`);
  }

  public getNotificationsChannels(customerId: string): Observable<NotificationChannel[]> {
    return this.api.get<NotificationChannel[]>(`/customers/${customerId}/notificationChannels`).pipe(
      map((a) => a.map((nc) => new NotificationChannel(nc))),
      catchError(this.handleError<NotificationChannel[]>('getAllByUserId')),
      definitiveCache(),
    );
  }

  public getPushTokens(customerId: string, params: any): Observable<ResponseEntities<PushToken>> {
    return this.api.get<PushToken[]>(`/customers/${customerId}/devicePushId`, { params }).pipe(
      map((a) => new ResponseEntities<PushToken>(PushToken, a)),
      catchError(this.handleError<ResponseEntities<PushToken>>('all_filtered')),
      definitiveCache(),
    );
  }
}
