export class BikeProvisionLogfile {
  type: string;
  name: string;
  createdAt: Date;
  url: string;
  status: string;

  constructor(data?: any) {
    if (data) {
      this.type = data.type;
      this.name = data.name;
      this.createdAt = data.createdAt;
      this.url = data.url;
      this.status = data.status;
    }
  }
}
