import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from 'src/app/modules/api/services/api.service';
import { ResponseEntities } from 'src/app/modules/api/models/response-entities.model';
import { catchError, map } from 'rxjs/operators';
import { definitiveCache } from 'src/app/modules/api/definitive-cache/definitive-cache';
import { Cockpit } from '../models/cockpit.model';

@Injectable({
  providedIn: 'root',
})
export class CockpitService {
  constructor(private api: ApiService) {}

  private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message} with error : ${error}`);

      return of(result as T);
    };
  }

  public getAll(params: any): Observable<ResponseEntities<Cockpit>> {
    return this.api
      .get<ResponseEntities<Cockpit>>('/cockpits', {
        params,
      })
      .pipe(
        map((a) => new ResponseEntities<Cockpit>(Cockpit, a)),
        catchError(this.handleError<ResponseEntities<Cockpit>>('all')),
        definitiveCache()
      );
  }

  getHistoryByMac(mac: string, params: any): Observable<Cockpit[]> {
    return this.api.get<Cockpit[]>(`/cockpits/${mac}/history`, {
      params,
    });
  }
}
