import { Component, OnDestroy, OnInit } from '@angular/core';
import { BikeService } from '../../../../services/bike.service';
import { VersionService } from '../../../../services/version.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, switchMap } from 'rxjs/operators';
import { Bike, CustomerBike } from '../../../../models/bike.model';
import { NextVersion } from '../../../../models/next-version.model';
import { MatoomaStats } from '../../../../models/matooma-stats.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Segment } from '../../../../models/segment.model';
import { SegmentService } from '../../../../services/segment.service';
import { ShipmentAddress } from '../../../../models/magento-shipment-address.model';
import { NextVersionOTAV2 } from '../../../../models/bike-next-version.model';
import { segmentPattern } from '../../../../../../modules/shared/validators/segments.validator';
import { ActivatedRoute } from '@angular/router';
import { MarketZonesService } from '../../../../services/market-zones.service';
import { MarketZone } from '../../../../models/market-zone.model';
import { DateService } from '../../../../../../modules/shared/services/date.service';

@UntilDestroy()
@Component({
  selector: 'app-view-bike-details',
  templateUrl: './details.component.html',
  styleUrls: [],
})
export class DetailsComponent implements OnInit, OnDestroy {
  addSegmentForm: UntypedFormGroup;

  bike: Bike;

  nextVersion?: NextVersion;
  matoomaStats?: MatoomaStats;
  segments: Segment[] = [];
  customersBikes: CustomerBike[] = [];

  public shipmentAddress: ShipmentAddress;

  public logsFilters: any = {
    bike: true,
    customers: true,
    os: 'all',
  };

  constructor(
    private bikeService: BikeService,
    private versionService: VersionService,
    private segmentService: SegmentService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private marketZonesService: MarketZonesService,
    private dateService: DateService,
  ) {}

  ngOnInit(): void {
    this.route.parent.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.bike = data.bike;
      this.customersBikes = data.bike.customersBikes;

      this.fetchNextVersion(this.bike);
      this.fetchNextVersionOTAV2(this.bike.id);
    });

    this.addSegmentForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(segmentPattern)]],
    });

    this.addSegmentForm.controls.name.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(150),
        switchMap((term) => this.segmentService.search(term)),
      )
      .subscribe((segments) => {
        console.log(segments);
        this.segments = segments;
      });

    this.loadMarketZones();
  }

  ngOnDestroy(): void {}

  public getElasticLink(): string {
    let customers = '',
      os = '';
    let path = '*';
    if (this.logsFilters.customers && this.bike.customerBikes) {
      customers = this.bike.customerBikes.map((cb) => `%20OR%20%22${cb.customer?.id}%22`).join('');
    }
    if (this.logsFilters.os == 'android') {
      os = '%20and%20labels.os_name%20:%20Android';
    }
    if (this.logsFilters.os == 'ios') {
      os = '%20and%20labels.os_name%20:%20iOS';
    }
    if (this.logsFilters.os == 'bike') {
      path = '%2FbikeChannel%2F*';
    }
    return `https://b29ecf86ca9e4437aeef2e5e7258efbb.eu-west-3.aws.elastic-cloud.com:9243/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-30d%2Fd,to:now))&_a=(columns:!(http.response.status_code,url.path,message,http.request.method,user.email),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'filebeat-*',key:url.path,negate:!f,type:exists,value:exists),query:(exists:(field:url.path))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'filebeat-*',key:http.response.status_code,negate:!f,type:exists,value:exists),query:(exists:(field:http.response.status_code))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'filebeat-*',key:http.request.method,negate:!t,params:(query:OPTIONS),type:phrase),query:(match_phrase:(http.request.method:OPTIONS)))),index:'filebeat-*',interval:auto,query:(language:kuery,query:'url.path:%20${path}%20AND%20message:(%22${this.bike.bikeProvision.mac}%22%20OR%20%22${this.bike.id}%22%20${customers})${os}'),sort:!(!('@timestamp',desc)))`;
    // https://b29ecf86ca9e4437aeef2e5e7258efbb.eu-west-3.aws.elastic-cloud.com:9243/app/discover#/view/92092520-4b81-11ec-934a-2b3e997640ed?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-30d%2Fd,to:now))&_a=(columns:!(http.response.status_code,url.path,message,http.request.method,user.email),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:apm_static_index_pattern_id,key:url.path,negate:!f,type:exists,value:exists),query:(exists:(field:url.path))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:d87d05d0-1a9a-11eb-bbbc-4764e22eadd1,key:http.response.status_code,negate:!f,type:exists,value:exists),query:(exists:(field:http.response.status_code))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:d87d05d0-1a9a-11eb-bbbc-4764e22eadd1,key:http.request.method,negate:!t,params:(query:OPTIONS),type:phrase),query:(match_phrase:(http.request.method:OPTIONS)))),index:d87d05d0-1a9a-11eb-bbbc-4764e22eadd1,interval:auto,query:(language:kuery,query:'url.path:%20*%20AND%20message:(%22C0:FD:B7:82:27:CE%22%20OR%20%22281474976710657%22%20)'),sort:!(!('@timestamp',desc)))
  }

  public nextVersionOTAV2: NextVersionOTAV2;

  public fetchNextVersionOTAV2(bikeId: string): void {
    this.bikeService
      .getNextVersion(bikeId)
      .pipe(untilDestroyed(this))
      .subscribe(
        (result) => {
          this.nextVersionOTAV2 = result;
        },
        (error) => console.log(error),
      );
  }

  private fetchNextVersion(bike: Bike): void {
    this.versionService
      .checkNextVersion(
        bike.bikeProvision.mac,
        bike.appVersion.toString(),
        bike.bluetoothVersion.toString(),
        bike.imageVersion.toString(),
      )
      .pipe(untilDestroyed(this))
      .subscribe((nextVersion) => {
        console.log('nextVersion:', nextVersion);

        this.nextVersion = nextVersion;
      });
  }

  public onSubmitSegment(): void {
    // stop here if form is invalid
    if (this.addSegmentForm.invalid) {
      return;
    }

    this.bikeService
      .addSegment(this.bike.id, this.addSegmentForm.get('name').value)
      .pipe(untilDestroyed(this))
      .subscribe((segment) => {
        this.bike.segments.push(segment);

        this.addSegmentForm.reset();
      });
  }

  public onDeleteSegment(segment: Segment): boolean {
    this.bikeService
      .removeSegment(this.bike.id, segment.id)
      .pipe(untilDestroyed(this))
      .subscribe((ok) => {
        this.bike.segments = this.bike.segments.filter((s) => s.id !== segment.id);
      });

    return false;
  }

  private onDeleteCustomerBike(customerBike: CustomerBike): void {
    this.bikeService
      .deleteBikeUser(this.bike.id, customerBike.email)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        console.log('Delete user from bike', result);

        this.bike.customerBikes = this.bike.customerBikes.filter((cb) => cb.email !== customerBike.email);
      });
  }

  openMarketZoneEdition: boolean = false;
  marketZones: MarketZone[] = [];
  marketZonesLoading: boolean = false;
  selectedZone: MarketZone;
  private loadMarketZones(): void {
    this.marketZonesService
      .getAll()
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.marketZones = result;
        this.selectedZone = this.marketZones.find((mz) => mz.name == this.bike.marketZoneName);
      });
  }

  public updateMarketZone(): void {
    this.marketZonesService
      .updateForBike(this.bike.id, this.selectedZone)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.bike.marketZoneName = this.selectedZone.name;
          this.openMarketZoneEdition = false;
        },
      });
  }

  openTheftEdition: boolean = false;
  theftDate: string;

  public onOpenTheftEdition(): void {
    this.openTheftEdition = true;

    this.theftDate = null;
    if (this.bike.theftDate != null) {
      this.theftDate = this.dateService.formatDateToCdsDatePickerFormat(this.bike.theftDate);
    }
  }

  onUpdateTheftDate(evt) {
    this.theftDate = evt.target.value;
  }

  public updateTheftDate(): void {
    let theftDate: Date = null;
    if (this.theftDate != '') {
      theftDate = this.dateService.getDateFromCdsDatePickerFormat(this.theftDate, 0, 0, 0);
    }

    this.bikeService
      .updateTheftDate(this.bike.id, theftDate)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          if (theftDate != null) {
            this.bike.isStolen = true;
          } else {
            this.bike.isStolen = false;
          }
          this.bike.theftDate = theftDate;
          this.openTheftEdition = false;
        },
      });
  }
}
