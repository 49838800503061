<div>
  <div class="clr-row">
    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
      <h2 i18n>Stats total</h2>

      <table *ngIf="rideStats" class="table">
        <thead>
          <tr>
            <th i18n>Distance</th>
            <th i18n>Duration</th>
            <th i18n>Number of ride</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ totalRidesStats.distance | meter }}</td>
            <td>{{ totalRidesStats.duration | duration }}</td>
            <td>{{ totalRidesStats.count }}</td>
          </tr>
        </tbody>
      </table>

      <h2 i18n>Rides stats by month</h2>

      <table *ngIf="rideStats" class="table">
        <thead>
          <tr>
            <th i18n>Date</th>
            <th i18n>Distance</th>
            <th i18n>Duration</th>
            <th i18n>Number of ride</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let stat of rideStats">
            <td>{{ stat.month }}/{{ stat.year }}</td>
            <td>{{ stat.distance | meter }}</td>
            <td>{{ stat.duration | duration }}</td>
            <td>{{ stat.count }}</td>
          </tr>
        </tbody>
      </table>

      <clr-alert *ngIf="!rideStats" [clrAlertClosable]="false">
        <clr-alert-item>
          <span class="alert-text" i18n>No stats for now</span>
        </clr-alert-item>
      </clr-alert>
    </div>
    <div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
      <h2 i18n>Last rides sessions</h2>

      <clr-accordion class="filters-accordion">
        <clr-accordion-panel [(clrAccordionPanelOpen)]="filtersOpened" class="filters-accordion-panel">
          <clr-accordion-title>Filters</clr-accordion-title>
          <clr-accordion-content *clrIfExpanded>
            <form
              clrForm
              [formGroup]="searchForm"
              (ngSubmit)="refreshRide(pagination)"
              [clrLayout]="'horizontal'"
              class="search-form clr-form-compact"
            >
              <div class="clr-row search-form-row">
                <div class="clr-col-md-6 clr-col-sm-12">
                  <clr-date-container>
                    <label>From</label>
                    <input clrDate placeholder="Pick a date" name="today" formControlName="from" />
                  </clr-date-container>
                </div>
                <div class="clr-col-md-6 clr-col-sm-12">
                  <clr-date-container>
                    <label>To</label>
                    <input clrDate placeholder="Pick a date" name="today" formControlName="to" />
                  </clr-date-container>
                </div>

                <div class="clr-col-md-6 clr-col-sm-12">
                  <clr-select-container>
                    <label class="clr-col-xl-3 clr-col-md-2">Validity</label>
                    <select class="clr-col-xl-9 clr-col-md-10" clrSelect formControlName="onlyValid">
                      <option [value]="true">Only valid</option>
                      <option [value]="false">All</option>
                    </select>
                  </clr-select-container>
                </div>
                <div class="clr-col-md-6 clr-col-sm-12">
                  <clr-input-container>
                    <label i18n>Ride_id</label>
                    <input clrInput type="text" placeholder="Ride id" formControlName="rideId" />
                  </clr-input-container>
                </div>

                <div class="clr-col-md-6 clr-col-sm-12">
                  <clr-select-container>
                    <label class="clr-col-xl-3 clr-col-md-2">Source</label>
                    <select class="clr-col-xl-9 clr-col-md-10" clrSelect formControlName="source">
                      <option value="">Any</option>
                      <option value="BIKE">BIKE</option>
                      <option value="PHONE">PHONE</option>
                    </select>
                  </clr-select-container>
                </div>
                <div class="clr-col-md-6 clr-col-sm-12">
                  <clr-input-container>
                    <label i18n>Version</label>
                    <input clrInput type="text" placeholder="  ex: 1.2.25" formControlName="bikeAppVersion" />
                  </clr-input-container>
                </div>
              </div>
              <div class="clr-row">
                <div class="clr-col-12">
                  <button class="btn btn-primary search-btn" type="submit" i18n>
                    <cds-icon shape="search"></cds-icon>
                  </button>
                </div>
              </div>
            </form>
          </clr-accordion-content>
        </clr-accordion-panel>
      </clr-accordion>

      <clr-datagrid (clrDgRefresh)="refreshRide($event)" [clrDgLoading]="rideLoading">
        <clr-dg-column i18n>ID</clr-dg-column>
        <clr-dg-column i18n>Duration</clr-dg-column>
        <clr-dg-column i18n>Distance</clr-dg-column>
        <clr-dg-column i18n>Profile</clr-dg-column>
        <clr-dg-column i18n>Start</clr-dg-column>
        <clr-dg-column i18n>End</clr-dg-column>
        <clr-dg-column i18n>Source</clr-dg-column>
        <clr-dg-column i18n>Location permission</clr-dg-column>
        <clr-dg-column i18n></clr-dg-column>

        <clr-dg-row *ngFor="let ride of rides">
          <clr-dg-cell>{{ ride.id }}</clr-dg-cell>
          <clr-dg-cell>{{ ride.duration | duration }}</clr-dg-cell>
          <clr-dg-cell>{{ ride.distance | meter }}</clr-dg-cell>
          <clr-dg-cell>{{ ride.profile }}</clr-dg-cell>
          <clr-dg-cell>{{ ride.startTime | date : 'dd/MM/yy HH:mm' }}</clr-dg-cell>
          <clr-dg-cell>{{ ride.endTime | date : 'dd/MM/yy HH:mm' }}</clr-dg-cell>
          <clr-dg-cell>{{ ride.source }}</clr-dg-cell>
          <clr-dg-cell>
            <ng-container [ngSwitch]="ride.locationPermissionStatus">
              <span *ngSwitchCase="'FULL_AUTHORIZED'"
                ><cds-icon class="is-success" shape="check-circle" style="width: 16px; height: 16px"></cds-icon> Full
                Authorized</span
              >
              <span *ngSwitchCase="'HALF_AUTHORIZED'"
                ><cds-icon class="is-warning" shape="exclamation-circle" style="width: 16px; height: 16px"></cds-icon>
                Half Authorized</span
              >
              <span *ngSwitchCase="'DENIED'"
                ><cds-icon class="is-danger" shape="exclamation-circle" style="width: 16px; height: 16px"></cds-icon>
                Denied</span
              >
              <span *ngSwitchCase="'UNDEFINED'"
                ><cds-icon class="is-info" shape="info-circle" style="width: 16px; height: 16px"></cds-icon>
                Undefined</span
              >
              <span *ngSwitchDefault></span>
            </ng-container>
          </clr-dg-cell>
          <clr-dg-cell>
            <button (click)="downloadRide(ride.id)" class="btn btn-sm">
              <cds-icon shape="download"></cds-icon>
            </button>
          </clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer i18n>
          {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ totalRide }} rides
          <clr-dg-pagination #pagination [clrDgPageSize]="10" [clrDgTotalItems]="totalRide"></clr-dg-pagination>
        </clr-dg-footer>

        <clr-dg-placeholder>No ride for now</clr-dg-placeholder>
      </clr-datagrid>

      <!--
                                    <table class="table" *ngIf="rides">
                                      <thead>
                                      <tr>
                                        <th i18n>ID</th>
                                        <th i18n>Duration</th>
                                        <th i18n>Distance</th>
                                        <th i18n>Profile</th>
                                        <th i18n>Start</th>
                                        <th i18n>End</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr *ngFor="let ride of rides">
                                        <td>{{ ride.id }}</td>
                                        <td>{{ ride.duration | duration }}</td>
                                        <td>{{ ride.distance | meter }}</td>
                                        <td>{{ ride.profile }}</td>
                                        <td>{{ ride.startTime | date:'dd/MM/yy HH:mm' }}</td>
                                        <td>{{ ride.endTime | date:'dd/MM/yy HH:mm' }}</td>
                                      </tr>
                                      </tbody>
                                    </table>

                                    <clr-alert *ngIf="!rides" [clrAlertClosable]="false">
                                      <clr-alert-item>
                                        <span class="alert-text" i18n>No ride for now</span>
                                      </clr-alert-item>
                                    </clr-alert>
                                    -->
    </div>
  </div>
</div>
