<div class="clr-row">
  <div class="clr-col-xl-3 clr-col-12">
    <h2 i18n>Customer services status</h2>
    <table class="table table-vertical">
      <tbody>
        <tr>
          <th i18n>Mails</th>
          <td>
            <span role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg">
              <span
                [ngClass]="{
                  label: true,
                  'label-success': user.emailStatus === 'DELIVERY',
                  'label-danger': user.emailStatus !== 'DELIVERY'
                }"
                >{{ user.emailStatus }}</span
              >
              <span class="tooltip-content" *ngIf="user.emailStatus === 'DELIVERY'"
                >DELIVERY means user is ok to receive email from angell.</span
              >
              <span class="tooltip-content" *ngIf="user.emailStatus === 'BOUNCE'"
                >BOUNCE occurs when an email that you send can't be delivered because of a permanent issue => we do not
                send emails to this customer anymore.</span
              >
              <span class="tooltip-content" *ngIf="user.emailStatus === 'COMPLAINT'"
                >COMPLAINT occurs when a customer reports an email as unwanted => we do not send emails to this customer
                anymore.</span
              >
            </span>
          </td>
        </tr>
        <tr>
          <th i18n>Push notifications&nbsp;</th>
          <td>
            <a
              href="#"
              (click)="showPushTokensDetails()"
              role="tooltip"
              aria-haspopup="true"
              class="tooltip tooltip-lg"
            >
              <span *ngIf="nbOfValidPushTokens > 0" class="label label-success" i18n>
                {{ nbOfValidPushTokens }} valid token(s)
              </span>
              <span *ngIf="nbOfValidPushTokens <= 0" class="label label-danger" i18n>no valid token</span>
              <span class="tooltip-content" *ngIf="nbOfValidPushTokens > 0" i18n>
                If customer has at least one valid push token, he should be able to receive push notifications.
              </span>
              <span class="tooltip-content" *ngIf="nbOfValidPushTokens <= 0" i18n>
                If customer doesnt have ant valid push token, he wont be able to receive push notifications.
              </span>
            </a>
            <clr-modal [(clrModalOpen)]="pushTokensDetailsOpened" [clrModalSize]="'xl'">
              <h3 class="modal-title" i18n>Push tokens</h3>
              <div class="modal-body">
                <div class="clr-row">
                  <div class="clr-col-12">
                    <clr-toggle-container>
                      <clr-toggle-wrapper>
                        <input
                          type="checkbox"
                          clrToggle
                          value="option1"
                          name="options"
                          [(ngModel)]="showAlsoDeletedOnes"
                          (ngModelChange)="refreshTokens({ page: { current: 1, from: 0, size: 10, to: 9 } })"
                        />
                        <label i18n>Show also deleted ones</label>
                      </clr-toggle-wrapper>
                    </clr-toggle-container>
                    <clr-datagrid (clrDgRefresh)="refreshTokens($event)" [clrDgLoading]="loading">
                      <clr-dg-column i18n>ID</clr-dg-column>
                      <clr-dg-column i18n>Device type</clr-dg-column>
                      <clr-dg-column i18n>Agent</clr-dg-column>
                      <clr-dg-column i18n>Created</clr-dg-column>
                      <clr-dg-column i18n>Updated</clr-dg-column>
                      <clr-dg-column i18n>Deleted</clr-dg-column>

                      <clr-dg-row *ngFor="let token of detailsPushTokens" [clrDgItem]="token">
                        <clr-dg-cell class="token-id">
                          <span [title]="token.id">{{ token.id }}</span>
                        </clr-dg-cell>
                        <clr-dg-cell>
                          {{ token.deviceType }}
                        </clr-dg-cell>
                        <clr-dg-cell>
                          <ul *ngIf="token.agent">
                            <li>{{ token.agent.osName }} {{ token.agent.osVersion }}</li>
                            <li>{{ token.agent.manufacturer }} {{ token.agent.model }}</li>
                            <li>{{ token.agent.appName }} {{ token.agent.appVersion }} ({{ token.agent.appBuild }})</li>
                          </ul>
                        </clr-dg-cell>
                        <clr-dg-cell>
                          <div>{{ token.updateTrace.createdOn | date: 'dd/MM/yy HH:mm' }}</div>
                          <div>by {{ token.updateTrace.createdBy }}</div>
                        </clr-dg-cell>
                        <clr-dg-cell>
                          <div>{{ token.updateTrace.updatedOn | date: 'dd/MM/yy HH:mm' }}</div>
                          <div *ngIf="token.updateTrace.updatedBy">by {{ token.updateTrace.updatedBy }}</div>
                        </clr-dg-cell>
                        <clr-dg-cell>
                          <div>{{ token.deleteTrace.deletedOn | date: 'dd/MM/yy HH:mm' }}</div>
                          <div *ngIf="token.deleteTrace.deletedBy">by {{ token.deleteTrace.deletedBy }}</div>
                        </clr-dg-cell>
                      </clr-dg-row>

                      <clr-dg-footer i18n>
                        {{ pagination2.firstItem + 1 }} - {{ pagination2.lastItem + 1 }} of {{ tokensTotal }} token(s)
                        <clr-dg-pagination
                          #pagination2
                          [clrDgTotalItems]="tokensTotal"
                          [clrDgPageSize]="15"
                        ></clr-dg-pagination>
                      </clr-dg-footer>
                    </clr-datagrid>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="pushTokensDetailsOpened = false" i18n>
                  Close
                </button>
              </div>
            </clr-modal>
          </td>
        </tr>
        <tr>
          <th i18n>SMS</th>
          <td>
            <span role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg">
              <span
                [ngClass]="{
                  label: true,
                  'label-success': user.phoneNumber,
                  'label-danger': !user.phoneNumber
                }"
                >{{ user.phoneNumber || 'No phone number' }}</span
              >
              <span class="tooltip-content" *ngIf="user.phoneNumber" i18n>
                User has filled in his phone number, he will be able to receive sms.
              </span>
              <span class="tooltip-content" *ngIf="!user.phoneNumber" i18n>
                User has NOT filled in his phone number, he wont be able to receive sms.
              </span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <h2 i18n>Bikes status</h2>
    <div *ngIf="bikes?.length <= 0" i18n>Customer has no bike.</div>
    <table class="table" *ngIf="bikes?.length > 0">
      <thead>
        <th i18n>Bike id</th>
        <th i18n>Alarm</th>
        <th i18n>Maintenance operation by PRO</th>
      </thead>
      <tbody>
        <tr *ngFor="let bike of bikes">
          <td>
            <a [routerLink]="['/bikes', 'view', bike.id]" href>{{ bike.id }}</a>
          </td>
          <td>
            <a href="..." role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg">
              <span
                [ngClass]="{
                  label: true,
                  'label-success': !bike.carMode,
                  'label-danger': bike.carMode
                }"
                i18n
              >
                {{ bike.carMode == true ? 'disabled' : 'enabled' }}
              </span>
              <span class="tooltip-content" i18n>
                If alarm is disabled, bike wont notify theft, and no theft notifications will be sent.
              </span>
            </a>
          </td>
          <td>
            <a href="..." role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg">
              <span
                [ngClass]="{
                  label: true,
                  'label-success': !bike.isAfterSalesMode,
                  'label-danger': bike.isAfterSalesMode
                }"
                >{{ bike.isAfterSalesMode ? 'IN PROGRESS' : 'NONE' }}</span
              >
              <span class="tooltip-content" i18n>
                If a maintenance operation is in progress via Angell Pro, customer won't receive any notification.
              </span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <h2 i18n>Notifications channels</h2>
    <table class="table table-vertical" *ngIf="groupedByNotificationsChannels">
      <tbody>
        <tr *ngFor="let channel of Object.keys(groupedByNotificationsChannels)">
          <th i18n>{{ channel }}</th>
          <td>
            <span
              *ngFor="let notifChannel of groupedByNotificationsChannels[channel]"
              [ngClass]="{
                label: true,
                'label-success': notifChannel.isEnabled,
                'label-danger': !notifChannel.isEnabled
              }"
              >{{ notifChannel.option | lowercase }}</span
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="clr-col-xl-9 clr-col-12">
    <h2 i18n>History</h2>

    <ul class="nav" role="tablist">
      <li role="presentation" class="nav-item">
        <button
          class="btn btn-link nav-link"
          [routerLink]="['/users', 'view', user.id, 'notifications', 'mail']"
          [queryParams]="queries"
          routerLinkActive="active"
          type="button"
          i18n
        >
          Mail
        </button>
      </li>
      <li role="presentation" class="nav-item">
        <button
          class="btn btn-link nav-link"
          [routerLink]="['/users', 'view', user.id, 'notifications', 'push']"
          [queryParams]="queries"
          routerLinkActive="active"
          type="button"
          i18n
        >
          Push
        </button>
      </li>
      <li role="presentation" class="nav-item">
        <button
          class="btn btn-link nav-link"
          [routerLink]="['/users', 'view', user.id, 'notifications', 'sms']"
          [queryParams]="queries"
          routerLinkActive="active"
          type="button"
          i18n
        >
          SMS
        </button>
      </li>
    </ul>

    <section role="tabpanel" *ngIf="type == 'mail'">
      <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
        <clr-dg-column i18n>Tenant</clr-dg-column>
        <clr-dg-column i18n>User ID</clr-dg-column>
        <clr-dg-column i18n>To</clr-dg-column>
        <clr-dg-column i18n>Subject</clr-dg-column>
        <clr-dg-column i18n>Format</clr-dg-column>
        <clr-dg-column i18n>Status</clr-dg-column>
        <clr-dg-column i18n>Date</clr-dg-column>

        <clr-dg-row *ngFor="let notification of notifications" [clrDgItem]="notification">
          <clr-dg-cell>
            <tenant [name]="notification.tenant"></tenant>
          </clr-dg-cell>
          <clr-dg-cell>{{ notification.userId }}</clr-dg-cell>
          <clr-dg-cell>{{ notification.to }}</clr-dg-cell>
          <clr-dg-cell>{{ notification.subject }}</clr-dg-cell>
          <clr-dg-cell>
            <span *ngIf="notification.isHtml == true">HTML</span>
            <span *ngIf="notification.isHtml == false">Text</span>
          </clr-dg-cell>
          <clr-dg-cell>{{ notification.notificationStatus }}</clr-dg-cell>
          <clr-dg-cell>{{ notification.updateTrace.createdOn | date: 'dd/MM/yy HH:mm' }}</clr-dg-cell>
        </clr-dg-row>

        <clr-dg-detail *clrIfDetail="let info">
          <clr-dg-detail-header>{{ info.subject }}</clr-dg-detail-header>
          <clr-dg-detail-body>
            <iframe class="view-mail-content" [src]="info.safeContent"></iframe>
          </clr-dg-detail-body>
        </clr-dg-detail>

        <clr-dg-footer i18n>
          {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} notifications
          <clr-dg-pagination #pagination [clrDgTotalItems]="total" [clrDgPageSize]="15"></clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>
    </section>

    <section role="tabpanel" *ngIf="type == 'push'">
      <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
        <clr-dg-column i18n>Tenant</clr-dg-column>
        <clr-dg-column i18n>To</clr-dg-column>
        <clr-dg-column i18n>Title</clr-dg-column>
        <clr-dg-column i18n>Device</clr-dg-column>
        <clr-dg-column i18n>Status</clr-dg-column>
        <clr-dg-column i18n>Date</clr-dg-column>

        <clr-dg-row *ngFor="let notification of notifications" [clrDgItem]="notification">
          <clr-dg-cell>
            <tenant [name]="notification.tenant"></tenant>
          </clr-dg-cell>
          <clr-dg-cell
            >{{
              notification.devicePushId?.id.length > 25
                ? (notification.devicePushId?.id | slice: 0 : 25) + '...'
                : notification.devicePushId?.id
            }}
          </clr-dg-cell>
          <clr-dg-cell>{{ notification.title }}</clr-dg-cell>
          <clr-dg-cell>{{ notification.devicePushId?.deviceType }}</clr-dg-cell>
          <clr-dg-cell>{{ notification.notificationStatus }}</clr-dg-cell>
          <clr-dg-cell>{{ notification.updateTrace.createdOn | date: 'dd/MM/yy HH:mm' }}</clr-dg-cell>
        </clr-dg-row>

        <clr-dg-detail *clrIfDetail="let info">
          <clr-dg-detail-header>{{ info.title }}</clr-dg-detail-header>
          <clr-dg-detail-body>
            <table class="table table-vertical">
              <tbody>
                <tr>
                  <th i18n>To</th>
                  <td>{{ info.devicePushId?.id }}</td>
                </tr>
                <tr>
                  <th i18n>Device</th>
                  <td>{{ info.devicePushId?.deviceType }}</td>
                </tr>
                <tr *aclCheck="'show_push_agent'">
                  <th i18n>Device agent</th>
                  <td>{{ info.devicePushId?.agent }}</td>
                </tr>
                <tr>
                  <th i18n>Title</th>
                  <td>{{ info.title }}</td>
                </tr>
                <tr>
                  <th i18n>Content</th>
                  <td>{{ info.text }}</td>
                </tr>
                <tr>
                  <th i18n>Status</th>
                  <td>{{ info.notificationStatus }}</td>
                </tr>
                <tr>
                  <th i18n>Response id</th>
                  <td>{{ info.responseId }}</td>
                </tr>
              </tbody>
            </table>
          </clr-dg-detail-body>
        </clr-dg-detail>

        <clr-dg-footer i18n>
          {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} notifications
          <clr-dg-pagination #pagination [clrDgTotalItems]="total" [clrDgPageSize]="15"></clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>
    </section>

    <section role="tabpanel" *ngIf="type == 'sms'">
      <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
        <clr-dg-column i18n>Tenant</clr-dg-column>
        <clr-dg-column i18n>To</clr-dg-column>
        <clr-dg-column i18n>Message</clr-dg-column>
        <clr-dg-column i18n>Status</clr-dg-column>
        <clr-dg-column i18n>Date</clr-dg-column>

        <clr-dg-row *ngFor="let notification of notifications" [clrDgItem]="notification">
          <clr-dg-cell>
            <tenant [name]="notification.tenant"></tenant>
          </clr-dg-cell>
          <clr-dg-cell>{{ notification.phoneNumber }}</clr-dg-cell>
          <clr-dg-cell>{{ notification.text }}</clr-dg-cell>
          <clr-dg-cell>{{ notification.notificationStatus }}</clr-dg-cell>
          <clr-dg-cell>{{ notification.updateTrace.createdOn | date: 'dd/MM/yy HH:mm' }}</clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer i18n>
          {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} notifications
          <clr-dg-pagination #pagination [clrDgTotalItems]="total" [clrDgPageSize]="15"></clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>
    </section>
  </div>
</div>
