<table class="table table-vertical">
  <tbody>
    <tr *ngIf="updateTrace?.createdOn">
      <th i18n>Created at</th>
      <td>
        <span>{{ updateTrace.createdOn | date : 'dd/MM/yy HH:mm' }} by </span>
        <span *ngIf="updateTrace.createdBy == 'system'">System</span>
        <span *ngIf="updateTrace.createdBy != 'system'">
          <a [routerLink]="['users', updateTrace.createdBy]" href>{{ updateTrace.createdBy }}</a>
        </span>
      </td>
    </tr>

    <tr *ngIf="updateTrace?.updatedOn">
      <th i18n>Updated at</th>
      <td>
        <span>{{ updateTrace.updatedOn | date : 'dd/MM/yy HH:mm' }} by </span>
        <span *ngIf="updateTrace.updatedBy == 'system'">System</span>
        <span *ngIf="updateTrace.updatedBy != 'system'">
          <a [routerLink]="['users', updateTrace.updatedBy]" href>{{ updateTrace.updatedBy }}</a>
        </span>
      </td>
    </tr>

    <tr *ngIf="deleteTrace?.deletedOn">
      <th i18n>Deleted at</th>
      <td>
        <span>{{ deleteTrace.deletedOn | date : 'dd/MM/yy HH:mm' }} by </span>
        <span *ngIf="deleteTrace.deletedBy == 'system'">System</span>
        <span *ngIf="deleteTrace.deletedBy != 'system'">
          <a [routerLink]="['users', deleteTrace.deletedBy]" href>{{ deleteTrace.deletedBy }}</a>
        </span>
      </td>
    </tr>
  </tbody>
</table>
