<div *ngIf="bike">
  <div class="clr-row">
    <div class="clr-col-lg-8 clr-col-md-12 clr-col-12">
      <h2 i18n>Batteries pairing</h2>
      <table class="table" *ngIf="batteriesConnections && batteriesConnections.length > 0">
        <thead>
          <tr>
            <th i18n>Corepack SN</th>
            <th i18n>Manufacturer</th>
            <th i18n>Battery SN</th>
            <th i18n>Nb of conn.</th>
            <th i18n>1st connection</th>
            <th i18n>Last connection</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let batteryConnection of batteriesConnections">
            <td>{{ batteryConnection.corepack.serialNumber }}</td>
            <td>
              <span class="label">
                {{ batteryConnection.corepack.manufacturer }}
              </span>
            </td>
            <td>
              <a
                *ngIf="batteryConnection.battery"
                [routerLink]="['/batteries', batteryConnection.battery?.serialNumber]"
              >
                {{ batteryConnection.battery?.serialNumber }}
              </a>
              <span *ngIf="!batteryConnection.battery">-</span>
            </td>
            <td>{{ batteryConnection.count }}</td>
            <td>{{ batteryConnection.firstConnection | date: 'dd/MM/yy HH:mm' }}</td>
            <td>{{ batteryConnection.lastConnection | date: 'dd/MM/yy HH:mm' }}</td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="!batteriesConnections || batteriesConnections.length <= 0">-</div>
    </div>
  </div>
  <h2 i18n>Battery level chart</h2>
  <form [clrLayout]="'horizontal'" class="search-form clr-form-compact" clrForm>
    <div class="clr-row search-form-row">
      <div class="clr-col-lg-2 clr-col-sm-12">
        <cds-date layout="horizontal" fixedControlWidth="true">
          <label>From</label>
          <input type="date" [value]="startDate" (change)="setStartDate($event)" />
        </cds-date>
      </div>
      <div class="clr-col-lg-2 clr-col-sm-12">
        <cds-time layout="horizontal" fixedControlWidth="true">
          <label>&nbsp;</label>
          <input type="time" [value]="startTime" (change)="setStartTime($event)" />
        </cds-time>
      </div>
      <div class="clr-col-lg-2 clr-col-sm-12">
        <cds-date layout="horizontal" fixedControlWidth="true">
          <label>To</label>
          <input type="date" [value]="endDate" (change)="setEndDate($event)" />
        </cds-date>
      </div>
      <div class="clr-col-lg-2 clr-col-sm-12">
        <cds-time layout="horizontal" fixedControlWidth="true">
          <label>&nbsp;</label>
          <input type="time" [value]="endTime" (change)="setEndTime($event)" />
        </cds-time>
      </div>
    </div>
    <!--<div class="clr-row search-form-row">
      <div class="clr-col-lg-3 clr-col-sm-12">
        <clr-date-container>
          <label>To</label>
          <input
            (clrDateChange)="onChangeBatteryTo($event)"
            clrDate
            formControlName="to"
            name="to"
            placeholder="Pick a date"
          />
        </clr-date-container>
      </div>
    </div>-->
  </form>
  <div class="clr-row">
    <div class="clr-col-lg-10 clr-col-md-12 clr-col-12">
      <canvas
        [data]="lineChartData"
        [legend]="lineChartLegend"
        [options]="lineChartOptions"
        [type]="'line'"
        baseChart
        height="200"
        width="400"
      >
      </canvas>
    </div>
  </div>
  <!-- .clr-row -->
</div>
