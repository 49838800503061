<table class="table table-vertical">
  <tbody>
    <tr>
      <th i18n>Iccid</th>
      <td>
        <a [routerLink]="['/sims', 'view', iccid]" href>
          {{ iccid }}
        </a>
      </td>
    </tr>

    <tr>
      <th i18n>Provider</th>
      <td>
        <clr-spinner *ngIf="loading" [clrInline]="true"></clr-spinner>
        <simcard-provider *ngIf="!loading" [provider]="simCard.provider"></simcard-provider>
      </td>
    </tr>
    <tr>
      <th i18n>Activated</th>
      <td>
        <span *ngIf="!loading && !simCard.isActivated()" class="label label-danger" i18n>No</span>
        <span *ngIf="!loading && simCard.isActivated()" class="label label-success" i18n>Yes</span>
      </td>
    </tr>
  </tbody>
</table>
