<ng-container *ngIf="mode == 'upload'">
  <h1 i18n>Import bikes</h1>

  <form clrForm [formGroup]="importForm" (ngSubmit)="onSubmit()">
    <clr-alert [clrAlertType]="'danger'" [clrAlertClosed]="!error">
      <div clr-alert-item class="alert-item">
        <span class="alert-text">
          {{ error }}
        </span>
      </div>
    </clr-alert>

    <clr-select-container>
      <label i18n>Bike Model</label>
      <select clrSelect formControlName="model">
        <option *ngFor="let model of models" value="{{ model.technicalName }}">{{ model.name }}</option>
      </select>
    </clr-select-container>

    <clr-textarea-container>
      <label>CSV</label>
      <textarea
        clrTextarea
        formControlName="csv"
        cols="150"
        rows="10"
        placeholder="Date;MAC;Key BASE64;CCID SIM;Ref TOP;Date TOP;ID TOP;Ref BOT;Date BOT;ID BOT;Version APP;Version IMG;Version BLE;Color;
12/10/20 14:01:05;E9:AE:73:92:B3:86;R6nqmlacNuddRP7wzTAiSA==;8931089719050277356;22621;20/03;00062;22620;20/04;00145;1.2.30;1.2.5;1.14.183;Noir;
12/10/20 14:10:42;E3:64:BD:02:9F:EE;eedpf/YaXQV7WPIwD83Fvw==;8931089719050277349;22621;20/03;00024;22620;20/04;00070;1.2.30;1.2.5;1.14.183;Noir;"
        required
      ></textarea>
      <clr-control-helper i18n>CSV content with header</clr-control-helper>
      <clr-control-error *clrIfError="'required'" i18n>This is a required field</clr-control-error>
      <clr-control-error *clrIfError="'incorrect'" i18n>Bad format</clr-control-error>
    </clr-textarea-container>

    <button class="btn btn-primary" type="submit" i18n>Preview</button>
  </form>
</ng-container>

<ng-container *ngIf="mode == 'preview'">
  <h1 i18n>
    <span *ngIf="!imported">Import bikes preview</span>
    <span *ngIf="imported">Import bikes confirmation</span>
  </h1>

  <table class="table">
    <thead>
      <tr>
        <th i18n>No Change</th>
        <th i18n>Create</th>
        <th i18n>Created</th>
        <th i18n>Update</th>
        <th i18n>Updated</th>
        <th i18n>Failed</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ resultsStats.noChange }}</td>
        <td>{{ resultsStats.create }}</td>
        <td>{{ resultsStats.created }}</td>
        <td>{{ resultsStats.update }}</td>
        <td>{{ resultsStats.updated }}</td>
        <td>{{ resultsStats.failed }}</td>
      </tr>
    </tbody>
  </table>

  <clr-datagrid [clrDgLoading]="loading">
    <clr-dg-action-bar>
      <button [disabled]="imported" type="button" class="btn btn-sm btn-secondary" (click)="onApply()" i18n>
        <cds-icon shape="check"></cds-icon> Apply
      </button>
      <button [disabled]="imported" class="btn btn-sm btn-secondary" (click)="onCancel()" i18n>
        <cds-icon shape="times"></cds-icon> Cancel
      </button>
    </clr-dg-action-bar>

    <clr-dg-column i18n>Bike ID</clr-dg-column>
    <clr-dg-column i18n>Mac</clr-dg-column>
    <clr-dg-column i18n>Color</clr-dg-column>
    <clr-dg-column i18n>State</clr-dg-column>

    <clr-dg-row *ngFor="let item of results">
      <clr-dg-cell>{{ item.source.id }}</clr-dg-cell>
      <clr-dg-cell>{{ item.source.mac }}</clr-dg-cell>
      <clr-dg-cell>{{ item.source.color }}</clr-dg-cell>
      <clr-dg-cell [ngSwitch]="item.state">
        <span class="label label-info" *ngSwitchCase="'NO_CHANGE'">No Change</span>
        <span class="label label-success" *ngSwitchCase="'CREATE'">Create</span>
        <span class="label label-success" *ngSwitchCase="'CREATED'">Created</span>
        <span class="label label-warning" *ngSwitchCase="'UPDATE'">Update</span>
        <span class="label label-warning" *ngSwitchCase="'UPDATED'">Updated</span>
        <span class="label label-danger" *ngSwitchCase="'FAILED'">Failed</span>
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>{{ results.length }} bikes</clr-dg-footer>
  </clr-datagrid>
</ng-container>
