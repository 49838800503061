import { LinkResource, UpdateTrace } from 'src/app/modules/api/models';

export class PushLog extends LinkResource {
  id: string;
  title: string;
  text: string;
  devicePushId: DevicePushId;
  notificationStatus: string;
  responseId: string;
  userId: string;
  tenant: string;

  updateTrace: UpdateTrace;

  constructor(data?: any) {
    if (data) {
      super(data._links);
      this.id = data.id;
      this.title = data.title;
      this.text = data.text;
      this.devicePushId = new DevicePushId(data.devicePushId);
      this.userId = data.userId;
      this.tenant = data.tenant;
      this.notificationStatus = data.notificationStatus;
      this.responseId = data.responseId;

      this.updateTrace = new UpdateTrace(data.updateTrace);
    }
  }
}

export class DevicePushId {
  id: string;
  deviceType: string;
  agent: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.deviceType = data.deviceType;
      this.agent = data.agent;
    }
  }
}
