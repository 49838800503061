<div *ngIf="bike">
  <div class="clr-row">
    <div class="clr-col-lg-5 clr-col-md-8 clr-col-12">
      <h2 i18n>Angell Back</h2>

      <div *ngIf="!angellBacks || angellBacks?.length == 0">
        <div>No data</div>
        <span *aclCheck="'actions_bikes'">
          <a
            [queryParams]="{ bikeId: bike.id }"
            [routerLink]="['/services', 'angell-back', 'form']"
            class="btn btn-primary"
            href
            i18n
            >Create a Angell Back</a
          >
        </span>
      </div>

      <table *ngIf="angellBacks && angellBacks.length > 0" class="table">
        <tbody>
          <tr>
            <th>Start</th>
            <th>End</th>
            <th>Covered</th>
            <th></th>
          </tr>
          <tr *ngFor="let angellBack of angellBacks">
            <td>{{ angellBack.startAt | date }}</td>
            <td>{{ angellBack.endAt | date }}</td>
            <td>
              <span *ngIf="angellBack.covered == false" class="label label-danger" i18n>No</span>
              <span *ngIf="angellBack.covered == true" class="label label-success" i18n>Yes</span>
            </td>
            <td>
              <button
                (click)="showAngellBack(angellBack.id)"
                aria-label="show angell back"
                class="btn btn-icon btn-sm"
                i18n
                style="margin-top: 0; margin-bottom: 0"
                title="Show contract"
                type="button"
              >
                <cds-icon shape="eye"></cds-icon>&nbsp;View
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="clr-row">
    <div class="clr-col-lg-5 clr-col-md-8 clr-col-12">
      <h2 i18n>Angell Care</h2>

      <div *ngIf="!bike.serviceAngellCare">
        <div>No data</div>
        <span *aclCheck="'actions_bikes'">
          <a
            [queryParams]="{ bikeId: bike.id }"
            [routerLink]="['/services', 'angell-care', 'form']"
            class="btn btn-primary"
            href
            i18n
            >Create a Angell Care</a
          >
        </span>
      </div>

      <table *ngIf="bike.serviceAngellCare" class="table">
        <tbody>
          <tr>
            <th i18n>Number</th>
            <th i18n>Start At</th>
            <th i18n>End At</th>
            <th i18n>Valid</th>
            <th></th>
          </tr>
          <tr>
            <td>{{ bike.serviceAngellCare.contractNumber }}</td>
            <td>{{ bike.serviceAngellCare.startAt | date }}</td>
            <td>{{ bike.serviceAngellCare.endAt | date }}</td>
            <td>
              <span *ngIf="bike.serviceAngellCare.valid == false" class="label label-danger" i18n>Expired</span>
              <span *ngIf="bike.serviceAngellCare.valid == true" class="label label-success" i18n>Valid</span>
            </td>
            <td>
              <button
                (click)="showAngellCare(bike.serviceAngellCare.id)"
                aria-label="show angell back"
                class="btn btn-icon btn-sm"
                i18n
                style="margin-top: 0; margin-bottom: 0"
                title="Show contract"
                type="button"
              >
                <cds-icon shape="eye"></cds-icon>&nbsp;View
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
