<h1 i18n>Bike Model Information <tenant [name]="model.tenant"></tenant></h1>

<ng-container *ngIf="model">
  <div class="clr-row">
    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
      <h2 i18n>Model</h2>
      <table class="table table-vertical">
        <tbody>
          <tr>
            <th i18n>ID</th>
            <td>{{ model.id }}</td>
          </tr>
          <tr>
            <th i18n>Name</th>
            <td>{{ model.name }}</td>
          </tr>
          <tr>
            <th i18n>Technical name</th>
            <td>{{ model.technicalName }}</td>
          </tr>
          <tr>
            <th i18n>Description</th>
            <td>{{ model.description }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- .clr-row -->

  <div class="clr-row" *ngIf="model.updateTrace">
    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
      <h2 i18n>Change history</h2>

      <app-update-trace [updateTrace]="model.updateTrace"></app-update-trace>
    </div>
  </div>
  <!-- .clr-row -->
</ng-container>
