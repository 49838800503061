import { LinkResource } from 'src/app/modules/api/models';

export class BikeErrorHistory extends LinkResource {
  id: string;
  errorCode: number;
  errorReason: string;
  payload: { [key: string]: string };
  createdOn: Date;
  createdBy: string;

  constructor(data?: any) {
    super(data?._links);

    if (data) {
      this.id = data.id;
      this.errorCode = data.errorCode;
      this.errorReason = data.errorReason;
      this.payload = data.payload;
      this.createdOn = data.createdOn;
      this.createdBy = data.createdBy;
    }
  }
}
