import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ShopService } from '../../../../services/shop.service';
import { Shop } from '../../../../models/shop.model';

@UntilDestroy()
@Component({
  selector: 'app-form-shop',
  templateUrl: './form-shop.component.html',
  styleUrls: ['./form-shop.component.scss'],
})
export class FormShopComponent implements OnInit, OnDestroy {
  shopForm: UntypedFormGroup;
  submitted = false;
  error = '';

  mode = 'CREATE';
  bikes = [];
  initialShop: Shop;

  loading: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private shopService: ShopService,
    private location: Location
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.shopForm = this.formBuilder.group({
      name: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
      email: ['', Validators.required],
      managerFullName: '',
      classification: ['', Validators.required],
      address: this.formBuilder.group({
        addressLine1: ['', Validators.required],
        addressLine2: [''],
        city: ['', Validators.required],
        zipCode: ['', Validators.required],
        country: ['', Validators.required],
      }),
    });
    if (id) {
      this.mode = 'UPDATE';
      this.loading = true;
      this.shopService
        .get(id)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (value) => {
            this.initialShop = value;

            this.shopForm = this.formBuilder.group({
              name: [this.initialShop.name, Validators.required],
              phoneNumber: [this.initialShop.phoneNumber, [Validators.required, Validators.pattern('[- +()0-9]+')]],
              email: [this.initialShop.email, Validators.required],
              managerFullName: this.initialShop.managerFullName,
              classification: [this.initialShop.classification, Validators.required],
              address: this.formBuilder.group({
                addressLine1: [this.initialShop.address.line1, Validators.required],
                addressLine2: [this.initialShop.address.line2],
                city: [this.initialShop.address.city, Validators.required],
                zipCode: [this.initialShop.address.zipCode, Validators.required],
                country: [this.initialShop.address.country, Validators.required],
              }),
            });
          },
          complete: () => {
            this.loading = false;
          },
        });
    }
  }

  ngOnDestroy(): void {}

  back() {
    this.location.back();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.shopForm.controls;
  }

  getDataFromForm(): Shop {
    const data = {
      id: this.initialShop?.id,
      name: this.shopForm.get('name').value,
      phoneNumber: this.shopForm.get('phoneNumber').value,
      email: this.shopForm.get('email').value,
      managerFullName: this.shopForm.get('managerFullName').value,
      classification: this.shopForm.get('classification').value,
      address: {
        line1: this.shopForm.get('address').get('addressLine1').value,
        line2: this.shopForm.get('address').get('addressLine2').value,
        zipCode: this.shopForm.get('address').get('zipCode').value,
        city: this.shopForm.get('address').get('city').value,
        country: this.shopForm.get('address').get('country').value,
      },
    };
    return new Shop(data);
  }

  public onSubmit(): void {
    this.submitted = true;

    // stop here if form is invalid
    if (this.shopForm.invalid) {
      return;
    }

    const createData = this.getDataFromForm();

    this.error = null;

    if (this.mode === 'UPDATE') {
      this.shopService
        .update(this.initialShop.id, createData)
        .pipe(untilDestroyed(this))
        .subscribe(
          (res) => this.router.navigate(['pro/shops/view', res.id]),
          (error) => {
            console.log(error);
            this.error = error;
          }
        );
    } else {
      this.shopService
        .create(createData)
        .pipe(untilDestroyed(this))
        .subscribe(
          (res) => {
            console.log('res : ', res);
            this.router.navigate(['pro/shops/view', res.id]);
          },
          (error) => {
            console.log(error);
            this.error = error;
          }
        );
    }
  }

  protected readonly FormGroup = FormGroup;

  getAddressFormGroup() {
    return this.shopForm.get('address') as FormGroup;
  }
}
