import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/modules/api/services/api.service';
import { Observable, of } from 'rxjs';
import { ResponseEntities } from 'src/app/modules/api/models';
import { User } from '../models/user.model';
import { UserProfile } from '../models/user-profile.model';
import { Bike, CustomerBike } from '../../bike/models/bike.model';
import { catchError, map } from 'rxjs/operators';
import { definitiveCache } from 'src/app/modules/api/definitive-cache/definitive-cache';
import { UserSettings } from '../models/user-settings.model';
import { UserKnownPhone } from '../models/user-known-phone.model';
import { Shop } from '../models/shop.model';

@Injectable({
  providedIn: 'root',
})
export class ShopService {
  constructor(private api: ApiService) {}

  private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message} with error : ${error}`);

      return of(result as T);
    };
  }

  public getAll(params: { [prop: string]: any }): Observable<ResponseEntities<Shop>> {
    return this.api
      .get<ResponseEntities<Shop>>('/shops', {
        params: {
          ...params,
        },
      })
      .pipe(
        map((a) => new ResponseEntities<Shop>(Shop, a)),
        catchError(this.handleError<ResponseEntities<Shop>>('all_filtered')),
        definitiveCache()
      );
  }

  public get(id: string): Observable<Shop> {
    return this.api.get<Shop>(`/shops/${id}`, {
      params: {},
    });
  }

  public create(shop: Shop): Observable<Shop> {
    return this.api.post<Shop>(`/shops/`, shop);
  }

  public update(id: string, shop: Shop): Observable<Shop> {
    return this.api.put<Shop>(`/shops/${id}`, shop);
  }

  public remove(id: string): Observable<object> {
    return this.api.delete<any>(`/shops/${id}`);
  }
}
