import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './modules/auth/guards/authentication.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'bikes',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./pages/bike/bike.module').then((m) => m.BikeModule),
  },
  {
    path: 'news',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./pages/news/news.module').then((m) => m.NewsModule),
  },
  {
    path: 'services',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./pages/services/services.module').then((m) => m.ServicesModule),
  },
  {
    path: 'users',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./pages/user/user.module').then((m) => m.UserModule),
  },
  {
    path: '',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
