<span
  class="label"
  [ngClass]="
    (simCard.getState() == 'Active' && 'label-success') ||
    (simCard.getState() == 'Suspended' && 'label-warning') ||
    (simCard.getState() == 'Terminated' && 'label-error')
  "
>
  <span>{{ simCard.getState() }}&nbsp;</span>
</span>
