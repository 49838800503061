import { Component, OnInit, OnDestroy } from '@angular/core';
import { SoftwarePackages } from '../../../../models/software-packages.model';
import { VersionService } from '../../../../services/version.service';
import { ClrDatagridStateInterface } from '@clr/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Segment } from '../../../../models/segment.model';

@UntilDestroy()
@Component({
  selector: 'list-softwares',
  templateUrl: './list-version.component.html',
  styleUrls: ['./list-version.component.scss'],
})
export class ListVersionComponent implements OnInit, OnDestroy {
  public versions: SoftwarePackages[];
  public total: number;
  public loading = true;

  constructor(private versionService: VersionService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  getSoftwareByType(softwarePackage: SoftwarePackages, softwareType: string): any {
    let result;

    softwarePackage.softwares.forEach((software) => {
      if (software.softwareType === softwareType) {
        result = software.version;
      }
    });

    return result;
  }

  public refresh(state: ClrDatagridStateInterface): void {
    console.log('table state:', state);

    this.loading = true;

    // We convert the filters from an array to a map,
    // because that's what our backend-calling service is expecting
    const filters: { [prop: string]: any } = {};

    if (state.filters) {
      for (const filter of state.filters) {
        const { property, value } = filter as { property: string; value: string };
        filters[property] = value;
      }
    }

    console.log('table filters:', filters);

    const params = filters;

    params.page = state.page.current - 1;
    params.size = state.page.size;

    this.versionService
      .getAll(params)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.versions = result.values();
        this.total = result.page.totalElements || this.versions.length;
        this.loading = false;
      });
  }

  public onDelete(version: SoftwarePackages): void {
    this.versionService
      .delete(version.id)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.versions = this.versions.filter((v) => v.id !== version.id);
      });
  }

  public onDisable(version: SoftwarePackages): void {
    this.changeEnableState(version, false);
  }

  public onEnable(version: SoftwarePackages): void {
    this.changeEnableState(version, true);
  }

  public segmentsToList(segments: Segment[]): string {
    return segments.map((segment) => segment.name).join(', ');
  }

  private changeEnableState(version: SoftwarePackages, newState: boolean): void {
    this.versionService
      .enable(version.id, newState)
      .pipe(untilDestroyed(this))
      .subscribe((status) => {
        if (status.success) {
          const r = this.versions.find((v) => v.id === version.id);
          if (r) {
            r.enable = newState;
          }
        }
      });
  }
}
