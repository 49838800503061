export class AngellBackImportResult {
  mail: string;
  startDate: Date;
  serialNumber: string;
  result: boolean;
  message: string;

  constructor(data?: any) {
    if (data) {
      this.mail = data.mail;
      this.startDate = data.startDate;
      this.serialNumber = data.serialNumber;
      this.result = data.result;
      this.message = data.message;
    }
  }
}
