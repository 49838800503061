import { Component, OnDestroy, OnInit } from '@angular/core';
import { BikeService } from '../../../../services/bike.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Bike, MaintenanceOperation } from '../../../../models/bike.model';
import { BikeErrorHistory } from '../../../../models/bike-error-history.model';
import { ClrDatagridStateInterface } from '@clr/angular';
import { BikeAutodiagHistory } from '../../../../models/bike-autodiag-history.model';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  BikeAutodiagStat,
  BikeAutodiagStatDto,
  BikeAutodiagStatWeek,
} from '../../../../models/bike-autodiag-stat.model';
import { DateService } from '../../../../../../modules/shared/services/date.service';

@UntilDestroy()
@Component({
  selector: 'app-view-bike-autodiag',
  templateUrl: './autodiag.component.html',
  styleUrls: ['./autodiag.component.scss'],
})
export class AutodiagComponent implements OnInit, OnDestroy {
  bike?: Bike;

  public showBikeErrors: boolean = true;
  public bikeErrors: BikeErrorHistory[];
  public bikeErrorTotal: number;
  public bikeErrorLoading: boolean = true;

  public bikeAutodiags: BikeAutodiagHistory[];
  public bikeAutodiagTotal: number;
  public bikeAutodiagLoading: boolean = true;

  public searchForm: UntypedFormGroup;

  public filtersOpened: boolean = true;

  public lastMaintenanceOperation: MaintenanceOperation;

  constructor(
    private bikeService: BikeService,
    private dateService: DateService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
  ) {}

  versionPattern = '^([\\d\\*]+)(.[\\d\\*]+)*$';

  stats: BikeAutodiagStat[] = [];
  statsLoading: boolean = false;
  statsFrom: string;
  statsWeeksRange: any[];

  ngOnInit(): void {
    const now: Date = new Date();
    this.statsFrom = this.dateService.formatDateToCdsDatePickerFormat(now);
    this.route.parent.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.bike = data.bike;
      this.fetchStats(now);
      this.loadLastMaintenanceOperation(this.bike.id);
    });

    this.searchForm = this.formBuilder.group({
      version: ['', Validators.pattern(this.versionPattern)],
      duringRideYes: true,
      duringRideNo: false,
      rideId: null,
      category: null,
      detail: null,
    });
  }

  ngOnDestroy(): void {}

  public loadLastMaintenanceOperation(bikeId) {
    this.bikeService
      .getMaintenanceOperations(bikeId, null)
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.lastMaintenanceOperation = response.values().find((operation) => operation.endedOn != null);
      });
  }

  public refreshBikeErrorHistory(state: ClrDatagridStateInterface): void {
    this.bikeErrorLoading = true;

    // We convert the filters from an array to a map,
    // because that's what our backend-calling service is expecting
    const filters: { [prop: string]: any } = {};

    if (state.filters) {
      for (const filter of state.filters) {
        const { property, value } = filter as { property: string; value: string };
        filters[property] = value;
      }
    }

    const params = filters;

    params.page = state.page.current - 1;
    params.size = state.page.size;

    this.bikeService
      .getErrorsHistory(this.bike.id, params)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.bikeErrors = result.values();
        this.bikeErrorTotal = result.page.totalElements || this.bikeErrors?.length;

        this.bikeErrorLoading = false;
      });
  }

  public refreshBikeAutodiagHistory(state: ClrDatagridStateInterface): void {
    this.bikeAutodiagLoading = true;

    // We convert the filters from an array to a map,
    // because that's what our backend-calling service is expecting
    const filters: { [prop: string]: any } = {};

    if (state.filters) {
      for (const filter of state.filters) {
        const { property, value } = filter as { property: string; value: string };
        filters[property] = value;
      }
    }

    const params = this.constructParamsFromState(state);

    this.bikeService
      .getAutodiagHistory(this.bike.id, params)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.bikeAutodiags = result.values();
        this.bikeAutodiagTotal = result.page.totalElements || this.bikeAutodiags?.length || 0;
        this.bikeAutodiagLoading = false;

        if (this.bikeAutodiagTotal) {
          this.showBikeErrors = false;
        }
      });
  }

  private constructParamsFromState(state) {
    const duringRideYes = this.searchForm.get('duringRideYes').value;
    const duringRideNo = this.searchForm.get('duringRideNo').value;
    let duringRide;
    if (duringRideYes != duringRideNo) {
      duringRide = duringRideYes ? 'true' : 'false';
    }

    const params = {
      duringRide: duringRide,
      rideId: this.searchForm.get('rideId').value,
      category: this.searchForm.get('category').value,
      details: this.searchForm.get('detail').value,
      version: this.searchForm.get('version').value,
      page: state.page.current - 1,
      size: state.page.size,
    };
    if (!params.version) {
      delete params.version;
    }
    if (!params.details) {
      delete params.details;
    }
    if (!params.category) {
      delete params.category;
    }
    if (!params.rideId) {
      delete params.rideId;
    }
    if (!params.duringRide) {
      delete params.duringRide;
    }
    return params;
  }

  public onSubmit(state: ClrDatagridStateInterface): void {
    console.log(this.searchForm);
    if (this.searchForm.invalid) {
      return;
    }

    this.bikeAutodiagLoading = true;

    const params = this.constructParamsFromState(state);

    this.bikeService
      .getAutodiagHistory(this.bike.id, params)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.bikeAutodiags = result.values();
        this.bikeAutodiagTotal = result.page.totalElements || this.bikeAutodiags?.length || 0;
        this.bikeAutodiagLoading = false;

        if (this.bikeAutodiagTotal) {
          this.showBikeErrors = false;
        }
      });
  }

  // Stats stuff
  onUpdateStatsFromDate(evt) {
    this.statsFrom = evt.target.value;
    const date = this.dateService.getDateFromCdsDatePickerFormat(this.statsFrom, 23, 59, 59);
    this.fetchStats(date);
  }

  getWeeksRange(from: Date) {
    return [...Array(8).keys()].map((index) => {
      const date = this.dateService.addDays(from, -index * 7);
      return {
        year: this.dateService.getWeekYear(date), //date.getFullYear(),
        week: this.dateService.getWeekNb(date),
      };
    });
  }

  private fetchStats(date: Date) {
    this.statsLoading = true;
    this.statsWeeksRange = this.getWeeksRange(date);
    this.bikeService
      .getAutodiagStats(this.bike.id, date.getTime())
      .pipe(untilDestroyed(this))
      .subscribe({
        next: this.formatStats.bind(this),
        complete: this.onStatsFetchingComplete.bind(this),
      });
  }

  private onStatsFetchingComplete() {
    this.statsLoading = false;
  }

  public formatStats(result: BikeAutodiagStatDto[]) {
    const stats: BikeAutodiagStat[] = [];
    // Get distinct name & detail
    const statNames: string[] = [...new Set(result.map((stat) => stat.nameDetail))];

    for (const statName of statNames) {
      const stat = new BikeAutodiagStat({
        nameDetail: statName,
      });
      const weeks = [];
      // For each week, if we found data, use them, else use data with values to 0.
      for (const weekData of this.statsWeeksRange) {
        const data = result.find(
          (stat) => stat.nameDetail === statName && stat.year === weekData.year && stat.week === weekData.week,
        );

        if (data != null) {
          stat.name = data.name;
          stat.detail = data.detail;
          stat.description = data.description;
        }

        weeks.push(
          data != null
            ? new BikeAutodiagStatWeek(data)
            : new BikeAutodiagStatWeek({ year: weekData.year, week: weekData.week, countRide: 0, countNoRide: 0 }),
        );
      }

      stat.weeks = weeks;
      stat.totalInRide = weeks.reduce((accumulator, week: BikeAutodiagStatWeek) => accumulator + week.countRide, 0);
      stat.total = weeks.reduce((accumulator, week: BikeAutodiagStatWeek) => accumulator + week.countAll, 0);

      stats.push(stat);
    }

    this.stats = stats;
  }
}
