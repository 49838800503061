import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Bike } from '../../../models/bike.model';
import { BikeService } from '../../../services/bike.service';

@Injectable({ providedIn: 'root' })
export class ViewBikeResolver  {
  constructor(private bikeService: BikeService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Bike> {
    return this.bikeService.get(route.params.id);
  }
}
