<h1 i18n>Notifications</h1>

<ul class="nav" role="tablist">
  <li role="presentation" class="nav-item">
    <button
      class="btn btn-link nav-link"
      [routerLink]="['/users', 'notifications', 'history', 'mail']"
      [queryParams]="queries"
      routerLinkActive="active"
      type="button"
    >
      Mail
    </button>
  </li>
  <li role="presentation" class="nav-item">
    <button
      class="btn btn-link nav-link"
      [routerLink]="['/users', 'notifications', 'history', 'push']"
      [queryParams]="queries"
      routerLinkActive="active"
      type="button"
    >
      Push
    </button>
  </li>
  <li role="presentation" class="nav-item">
    <button
      class="btn btn-link nav-link"
      [routerLink]="['/users', 'notifications', 'history', 'sms']"
      [queryParams]="queries"
      routerLinkActive="active"
      type="button"
    >
      SMS
    </button>
  </li>
</ul>
<section role="tabpanel" *ngIf="type == 'mail'">
  <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
    <clr-dg-column i18n>Tenant</clr-dg-column>
    <clr-dg-column i18n>User ID</clr-dg-column>
    <clr-dg-column i18n>To</clr-dg-column>
    <clr-dg-column i18n>Subject</clr-dg-column>
    <clr-dg-column i18n>Format</clr-dg-column>
    <clr-dg-column i18n>Status</clr-dg-column>
    <clr-dg-column i18n>Date</clr-dg-column>

    <clr-dg-row *ngFor="let notification of notifications" [clrDgItem]="notification">
      <clr-dg-cell><tenant [name]="notification.tenant"></tenant> </clr-dg-cell>
      <clr-dg-cell>{{ notification.userId }}</clr-dg-cell>
      <clr-dg-cell>{{ notification.to }}</clr-dg-cell>
      <clr-dg-cell>{{ notification.subject }}</clr-dg-cell>
      <clr-dg-cell>
        <span *ngIf="notification.isHtml == true">HTML</span>
        <span *ngIf="notification.isHtml == false">Text</span>
      </clr-dg-cell>
      <clr-dg-cell>{{ notification.notificationStatus }}</clr-dg-cell>
      <clr-dg-cell>{{ notification.updateTrace.createdOn | date: 'dd/MM/yy HH:mm' }}</clr-dg-cell>
    </clr-dg-row>

    <clr-dg-detail *clrIfDetail="let info">
      <clr-dg-detail-header>{{ info.subject }}</clr-dg-detail-header>
      <clr-dg-detail-body>
        <iframe class="view-mail-content" [src]="info.safeContent"></iframe>
      </clr-dg-detail-body>
    </clr-dg-detail>

    <clr-dg-footer i18n>
      {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} notifications
      <clr-dg-pagination #pagination [clrDgTotalItems]="total" [clrDgPageSize]="15"></clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</section>

<section role="tabpanel" *ngIf="type == 'push'">
  <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
    <clr-dg-column i18n>Tenant</clr-dg-column>
    <clr-dg-column i18n>To</clr-dg-column>
    <clr-dg-column i18n>Title</clr-dg-column>
    <clr-dg-column i18n>Device</clr-dg-column>
    <clr-dg-column i18n>Status</clr-dg-column>
    <clr-dg-column i18n>Date</clr-dg-column>

    <clr-dg-row *ngFor="let notification of notifications" [clrDgItem]="notification">
      <clr-dg-cell><tenant [name]="notification.tenant"></tenant> </clr-dg-cell>
      <clr-dg-cell>{{
        notification.devicePushId?.id.length > 25
          ? (notification.devicePushId?.id | slice: 0 : 25) + '...'
          : notification.devicePushId?.id
      }}</clr-dg-cell>
      <clr-dg-cell>{{ notification.title }}</clr-dg-cell>
      <clr-dg-cell>{{ notification.devicePushId?.deviceType }}</clr-dg-cell>
      <clr-dg-cell>{{ notification.notificationStatus }}</clr-dg-cell>
      <clr-dg-cell>{{ notification.updateTrace.createdOn | date: 'dd/MM/yy HH:mm' }}</clr-dg-cell>
    </clr-dg-row>

    <clr-dg-detail *clrIfDetail="let info">
      <clr-dg-detail-header>{{ info.title }}</clr-dg-detail-header>
      <clr-dg-detail-body>
        <table class="table table-vertical">
          <tbody>
            <tr>
              <th i18n>To</th>
              <td>{{ info.devicePushId?.id }}</td>
            </tr>
            <tr>
              <th i18n>Device</th>
              <td>{{ info.devicePushId?.deviceType }}</td>
            </tr>
            <tr *aclCheck="'show_push_agent'">
              <th i18n>Device agent</th>
              <td>{{ info.devicePushId?.agent }}</td>
            </tr>
            <tr>
              <th i18n>Title</th>
              <td>{{ info.title }}</td>
            </tr>
            <tr>
              <th i18n>Content</th>
              <td>{{ info.text }}</td>
            </tr>
            <tr>
              <th i18n>Status</th>
              <td>{{ info.notificationStatus }}</td>
            </tr>
            <tr>
              <th i18n>Response id</th>
              <td>{{ info.responseId }}</td>
            </tr>
          </tbody>
        </table>
      </clr-dg-detail-body>
    </clr-dg-detail>

    <clr-dg-footer i18n>
      {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} notifications
      <clr-dg-pagination #pagination [clrDgTotalItems]="total" [clrDgPageSize]="15"></clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</section>

<section role="tabpanel" *ngIf="type == 'sms'">
  <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
    <clr-dg-column i18n>Tenant</clr-dg-column>
    <clr-dg-column i18n>To</clr-dg-column>
    <clr-dg-column i18n>Message</clr-dg-column>
    <clr-dg-column i18n>Status</clr-dg-column>
    <clr-dg-column i18n>Date</clr-dg-column>

    <clr-dg-row *ngFor="let notification of notifications" [clrDgItem]="notification">
      <clr-dg-cell><tenant [name]="notification.tenant"></tenant> </clr-dg-cell>
      <clr-dg-cell>{{ notification.phoneNumber }}</clr-dg-cell>
      <clr-dg-cell>{{ notification.text }}</clr-dg-cell>
      <clr-dg-cell>{{ notification.notificationStatus }}</clr-dg-cell>
      <clr-dg-cell>{{ notification.updateTrace.createdOn | date: 'dd/MM/yy HH:mm' }}</clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer i18n>
      {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} notifications
      <clr-dg-pagination #pagination [clrDgTotalItems]="total" [clrDgPageSize]="15"></clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</section>
