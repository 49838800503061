import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { flatMap } from 'rxjs/operators';
import { Cockpit } from '../../../models/cockpit.model';
import { CockpitService } from '../../../services/cockpit.service';
import { ClrDatagridStateInterface } from '@clr/angular';
import { BikeService } from '../../../services/bike.service';

@UntilDestroy()
@Component({
  selector: 'app-view-model',
  templateUrl: './view-cockpit.component.html',
  styleUrls: ['./view-cockpit.component.scss'],
})
export class ViewCockpitComponent implements OnInit, OnDestroy {
  public cockpitHistory: Cockpit[] = [];
  public currentCockpit: Cockpit;
  public mac: string;
  public total: number;
  public loading = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cockpitService: CockpitService,
  ) {}

  ngOnInit(): void {
    this.route.params.pipe(untilDestroyed(this)).subscribe((params) => {
      this.mac = params.mac;
    });
  }

  ngOnDestroy(): void {}

  public refresh(state: ClrDatagridStateInterface): void {
    this.loading = true;

    this.cockpitService
      .getHistoryByMac(this.mac, null)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result) => {
          this.cockpitHistory = result;
          this.currentCockpit = result[0];
          this.total = this.cockpitHistory?.length;
        },
        complete: () => {
          this.loading = false;
        },
      });
  }
}
