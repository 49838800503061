<div *ngIf="bike">
  <div class="clr-row">
    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
      <h2 i18n>Bike</h2>
      <table class="table table-vertical">
        <tbody>
          <tr>
            <th i18n>ID</th>
            <td>{{ bike.id }}</td>
          </tr>
          <tr>
            <th i18n>Cockpit</th>
            <td>
              <a [routerLink]="['/cockpits', 'view', bike.bikeProvision.mac]" href>{{ bike.bikeProvision.mac }}</a>
            </td>
          </tr>
          <tr>
            <th i18n>S/N (bike frame)</th>
            <td>{{ bike.bikeProvision.bikeFrameSerialNumber }}</td>
          </tr>
          <tr>
            <th i18n>Fully provisioned</th>
            <td>
              <span *ngIf="bike.bikeProvision.provisioned == false" class="label label-danger" i18n>No</span>
              <span *ngIf="bike.bikeProvision.provisioned == true" class="label label-success" i18n>Yes</span>
            </td>
          </tr>
          <tr>
            <th i18n>Installed</th>
            <td>
              <span *ngIf="bike.installed == false" class="label label-danger" i18n>No</span>
              <span *ngIf="bike.installed == true" class="label label-success" i18n>Yes</span>
            </td>
          </tr>
          <tr>
            <th i18n>Color</th>
            <td>{{ bike.bikeProvision.color }}</td>
          </tr>
          <tr>
            <th i18n>Model</th>
            <td>
              <bike-model-link [model]="bike.bikeProvision.model"></bike-model-link>
            </td>
          </tr>
          <tr>
            <th i18n>Stolen</th>
            <td>
              <span *ngIf="bike.isStolen == false" i18n>No</span>
              <span *ngIf="bike.isStolen == true" class="label label-danger" i18n>{{ bike.theftDate | date }}</span>
              <span
                ><button
                  type="button"
                  class="btn btn-sm btn-icon btn-secondary btn-link"
                  (click)="onOpenTheftEdition()"
                  i18n
                >
                  <cds-icon shape="pencil"></cds-icon></button
              ></span>

              <clr-modal [(clrModalOpen)]="openTheftEdition" [clrModalSize]="'sm'">
                <h3 class="modal-title">
                  <span>Update theft date</span>
                </h3>
                <div class="modal-body">
                  <div class="clr-row">
                    <div class="clr-col-xl-6 clr-col-sm-12">
                      <cds-date layout="horizontal" fixedControlWidth="true">
                        <label i18n>Theft date</label>
                        <input type="date" [value]="theftDate" (change)="onUpdateTheftDate($event)" />
                      </cds-date>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" (click)="openTheftEdition = false">Close</button>
                  <button type="submit" class="btn btn-primary" (click)="updateTheftDate()">Update</button>
                </div>
              </clr-modal>
            </td>
          </tr>
          <tr>
            <th i18n>Market Zone</th>
            <td>
              <span>{{ bike.marketZoneName }}</span>
              <span *aclCheck="'actions_marketzone'"
                ><button
                  type="button"
                  class="btn btn-sm btn-icon btn-secondary btn-link"
                  (click)="openMarketZoneEdition = true"
                  i18n
                >
                  <cds-icon shape="pencil"></cds-icon></button
              ></span>
              <clr-modal [(clrModalOpen)]="openMarketZoneEdition" [clrModalSize]="'sm'">
                <h3 class="modal-title">
                  <span>Update market zone</span>
                </h3>
                <div class="modal-body">
                  <div class="clr-row">
                    <div class="clr-col-xl-6 clr-col-sm-12">
                      <clr-combobox-container>
                        <label>Market zone</label>
                        <clr-combobox
                          [(ngModel)]="selectedZone"
                          name="selectedMarketZone"
                          placeholder=""
                          [clrLoading]="marketZonesLoading"
                        >
                          <clr-options>
                            <clr-option *clrOptionItems="let state of marketZones; field: 'name'" [clrValue]="state">
                              {{ state.name }}
                            </clr-option>
                          </clr-options>
                        </clr-combobox>
                      </clr-combobox-container>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" (click)="openMarketZoneEdition = false">Close</button>
                  <button type="submit" class="btn btn-primary" (click)="updateMarketZone()">Update</button>
                </div>
              </clr-modal>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
      <h2 i18n>Version</h2>
      <table class="table table-vertical">
        <tbody>
          <tr>
            <th i18n>App version</th>
            <td>{{ bike.appVersion.toString() }}</td>
          </tr>
          <tr>
            <th i18n>Ble version</th>
            <td>{{ bike.bluetoothVersion.toString() }}</td>
          </tr>
          <tr>
            <th i18n>Image version</th>
            <td>{{ bike.imageVersion.toString() }}</td>
          </tr>
          <tr>
            <th i18n>Bootloader version</th>
            <td>{{ bike.bootloaderVersion?.toString() }}</td>
          </tr>
          <tr>
            <th i18n>Bms version</th>
            <td>{{ bike.bmsVersion?.toString() }}</td>
          </tr>
          <tr>
            <th i18n>BLE SD version</th>
            <td>{{ bike.bleSdVersion?.toString() }}</td>
          </tr>
          <tr>
            <th i18n>Controller version</th>
            <td>{{ bike.controllerVersion?.toString() }}</td>
          </tr>
          <tr>
            <th i18n>Rearlight version</th>
            <td>{{ bike.rearlightVersion?.toString() }}</td>
          </tr>
          <tr>
            <th i18n>Is up to date</th>
            <td>
              <span
                *ngIf="nextVersion?.isUpToDate == false || nextVersionOTAV2?.remainingUpgrades > 0"
                class="label label-danger"
                i18n
                >No</span
              >
              <span
                *ngIf="nextVersion?.isUpToDate == true && nextVersionOTAV2?.remainingUpgrades == 0"
                class="label label-success"
                i18n
                >Yes</span
              >
            </td>
          </tr>
          <tr *ngIf="nextVersion && !nextVersion.isUpToDate">
            <th i18n>Next version</th>
            <td>
              <a [routerLink]="['/bikes', 'versions', 'view', nextVersion?.nextVersion]" href>{{
                nextVersion?.nextVersion
              }}</a>
            </td>
          </tr>
          <tr *ngIf="nextVersion && nextVersion.isUpToDate && nextVersionOTAV2?.remainingUpgrades > 0">
            <th i18n>Remaining upgrades (OTA V2)</th>
            <td>
              {{ nextVersionOTAV2?.remainingUpgrades }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
      <h2 i18n>Targets</h2>

      <table class="table table-vertical">
        <tbody>
          <tr>
            <th i18n>Segments</th>
            <td>
              <div class="segments"></div>
              <span *ngFor="let segment of bike.segments" class="label">
                {{ segment.name }}&nbsp;
                <a (click)="!!onDeleteSegment(segment)" *aclCheck="'actions_bikes'" href>
                  <cds-icon shape="times"></cds-icon>
                </a>
              </span>
              <span *ngIf="bike.segments.length == 0" i18n>No segment</span>
              <div *aclCheck="'actions_bikes'">
                <form (ngSubmit)="onSubmitSegment()" [formGroup]="addSegmentForm" clrForm>
                  <clr-datalist-container>
                    <input
                      autocomplete="off"
                      clrDatalistInput
                      formControlName="name"
                      name="name"
                      placeholder="Segment name"
                    />
                    <datalist>
                      <option>{{ addSegmentForm.get('name').value }}</option>
                      <option *ngFor="let segment of segments" [value]="segment.name"></option>
                    </datalist>
                  </clr-datalist-container>

                  <!-- input class="clr-input" type="text" formControlName="name" /-->
                  <button class="btn btn-sm" type="submit">Add</button>
                </form>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
      <h2 i18n>Users</h2>

      <clr-datagrid>
        <clr-dg-column i18n>Email</clr-dg-column>
        <clr-dg-column i18n>Role</clr-dg-column>
        <clr-dg-column i18n>Status</clr-dg-column>

        <clr-dg-row *ngFor="let user of bike.customerBikes" [clrDgItem]="user">
          <clr-dg-action-overflow *aclCheck="'actions_rmcustomerbike'">
            <button (appConfirm)="onDeleteCustomerBike(user)" appConfirm class="action-item" i18n>Delete</button>
          </clr-dg-action-overflow>

          <clr-dg-cell>
            <a *ngIf="user.customer" [routerLink]="['/users', 'view', user.customer.id]" href>{{ user.email }}</a>
            <span *ngIf="!user.customer">{{ user.email }}</span>
          </clr-dg-cell>

          <clr-dg-cell>{{ user.role }}</clr-dg-cell>
          <clr-dg-cell>
            <span *ngIf="user.isDisabled == false" class="label label-success" i18n>Enabled</span>
            <span *ngIf="user.isDisabled == true" class="label label-danger" i18n>Disabled</span>
          </clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer i18n>{{ bike.customerBikes.length }} users</clr-dg-footer>
      </clr-datagrid>
    </div>

    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
      <h2 i18n>Status</h2>
      <table class="table table-vertical">
        <tbody>
          <tr *ngIf="bike.isV2()">
            <th i18n>Connected</th>
            <td>
              <span *ngIf="bike.isConnected == false" class="label label-danger" i18n>No</span>
              <span *ngIf="bike.isConnected == true" class="label label-success" i18n>Yes</span>
            </td>
          </tr>
          <tr>
            <th i18n>Last Connection Date</th>
            <td *ngIf="bike.lastConnectedDate != null">{{ bike.lastConnectedDate | date: 'dd/MM/yy HH:mm' }}</td>
            <td *ngIf="bike.lastConnectedDate == null" i18n>No connection yet</td>
          </tr>
          <tr>
            <th i18n>Lock</th>
            <td>
              <span *ngIf="bike.locked == false" class="label label-danger" i18n>No</span>
              <span *ngIf="bike.locked == true" class="label label-success" i18n>Yes</span>
            </td>
          </tr>
          <tr>
            <th i18n>Siren</th>
            <td>
              <span *ngIf="bike.siren == false" class="label label-success" i18n>No</span>
              <span *ngIf="bike.siren == true" class="label label-danger" i18n>Yes</span>
            </td>
          </tr>
          <tr>
            <th i18n>Has Battery</th>
            <td>
              <span *ngIf="bike.hasBattery == false" class="label label-danger" i18n>No</span>
              <span *ngIf="bike.hasBattery == true" class="label label-success" i18n>Yes</span>
            </td>
          </tr>
          <tr>
            <th i18n>Internal Battery Level</th>
            <td>{{ bike.internalBatteryLevel }}%</td>
          </tr>
          <tr>
            <th i18n>External Battery Level</th>
            <td>{{ bike.externalBatteryLevel }}%</td>
          </tr>
          <tr *aclCheck="'show_location'">
            <th i18n>Location (lat,lon)</th>
            <td>
              <span *ngIf="bike.location.lon"
                ><a
                  href="https://www.google.com/maps/search/?api=1&query={{ bike.location.lat }},{{ bike.location.lon }}"
                  rel="noopener noreferrer"
                  target="_blank"
                  >{{ bike.location.lat }}, {{ bike.location.lon }}
                </a></span
              >
              <span *ngIf="!bike.location.lon" i18n>No position</span>
            </td>
          </tr>

          <tr *ngIf="bike.carMode != null">
            <th i18n>Car Mode</th>
            <td>
              <span *ngIf="bike.carMode == false" class="label label-danger" i18n>No</span>
              <span *ngIf="bike.carMode == true" class="label label-success" i18n>Yes</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
      <h2 i18n>Alerts</h2>
      <table class="table table-vertical">
        <tbody>
          <tr>
            <th i18n>Is fall on going</th>
            <td>
              <span *ngIf="bike.isFallOnGoing == false" class="label label-danger" i18n>No</span>
              <span *ngIf="bike.isFallOnGoing == true" class="label label-success" i18n>Yes</span>
            </td>
          </tr>
          <tr>
            <th i18n>Last fall on going</th>
            <td>
              <span *ngIf="bike.lastFallOnGoingDate !== null">{{
                bike.lastFallOnGoingDate | date: 'dd/MM/yy HH:mm'
              }}</span>
              <span *ngIf="bike.lastFallOnGoingDate === null" i18n>No fall</span>
            </td>
          </tr>
          <tr>
            <th i18n>Is theft on going</th>
            <td>
              <span *ngIf="bike.isTheftOnGoing == false" class="label label-danger" i18n>No</span>
              <span *ngIf="bike.isTheftOnGoing == true" class="label label-success" i18n>Yes</span>
            </td>
          </tr>
          <tr>
            <th i18n>Last theft on going</th>
            <td>
              <span *ngIf="bike.lastTheftOnGoingDate !== null">{{
                bike.lastTheftOnGoingDate | date: 'dd/MM/yy HH:mm'
              }}</span>
              <span *ngIf="bike.lastTheftOnGoingDate === null" i18n>No theft</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- .clr-row -->

  <div class="clr-row">
    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
      <h2 i18n>Order</h2>
      <table *ngIf="bike.bikeOrder" class="table table-vertical">
        <tbody>
          <tr>
            <th i18n>Order Ref</th>
            <td>
              <a
                *ngIf="bike.bikeOrder.orderRef"
                href="https://cart.angell.bike/gestion_ab/sales/order/view/order_id/{{ bike.bikeOrder.orderRef }}"
                target="_blank"
                title="Open in Magento"
                >{{ bike.bikeOrder.orderRef }}</a
              >
              <span *ngIf="!bike.bikeOrder.orderRef" i18n>No order</span>
            </td>
          </tr>

          <tr>
            <th i18n>Order At</th>
            <td>{{ bike.bikeOrder.orderAt | date: 'dd/MM/yy HH:mm' }}</td>
          </tr>

          <tr>
            <th i18n>Shipment At</th>
            <td>
              {{ bike.bikeOrder.shipmentAt | date: 'dd/MM/yy' }}
            </td>
          </tr>
          <tr>
            <th i18n>Delivery At</th>
            <td>{{ bike.bikeOrder.deliveryAt | date: 'dd/MM/yy' }}</td>
          </tr>
        </tbody>
      </table>

      <clr-alert *ngIf="!bike.bikeOrder" [clrAlertClosable]="false">
        <clr-alert-item>
          <span class="alert-text" i18n>No order info</span>
        </clr-alert-item>
      </clr-alert>
    </div>

    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
      <h2 i18n>Sim Card</h2>

      <sim-card [iccid]="bike.bikeProvision.iccid" [phoneNumber]="bike.bikePhoneNumber"></sim-card>
    </div>
    <!-- .clr-row -->

    <div class="clr-row">
      <div *ngIf="bike.updateTrace" class="clr-col-lg-6 clr-col-md-8 clr-col-12">
        <h2 i18n>Change history</h2>

        <app-update-trace [updateTrace]="bike.updateTrace"></app-update-trace>
      </div>

      <div *aclCheck="'show_api_logs'" class="clr-col-lg-6 clr-col-md-8 clr-col-12">
        <h2 i18n>API Logs</h2>
        <table class="table table-vertical">
          <tbody>
            <tr>
              <th i18n>Filters :</th>
              <td>
                <div>
                  <clr-checkbox-container class="no-margin-top" clrInline>
                    <label>Url path contains : </label>
                    <clr-checkbox-wrapper>
                      <input
                        [(ngModel)]="logsFilters.bike"
                        clrCheckbox
                        name="logsFilterBike"
                        type="checkbox"
                        value="option1"
                      />
                      <label>Bike Id or Mac</label>
                    </clr-checkbox-wrapper>
                    <clr-checkbox-wrapper>
                      <input
                        [(ngModel)]="logsFilters.customers"
                        clrCheckbox
                        name="logsFilterCustomer"
                        type="checkbox"
                        value="option1"
                      />
                      <label>One of Customer Id</label>
                    </clr-checkbox-wrapper>
                  </clr-checkbox-container>
                  <clr-radio-container clrInline>
                    <label>Source</label>
                    <clr-radio-wrapper>
                      <input [(ngModel)]="logsFilters.os" clrRadio name="options" type="radio" value="all" />
                      <label>All</label>
                    </clr-radio-wrapper>
                    <clr-radio-wrapper>
                      <input [(ngModel)]="logsFilters.os" clrRadio name="options" type="radio" value="bike" />
                      <label>Only bike</label>
                    </clr-radio-wrapper>
                    <clr-radio-wrapper>
                      <input [(ngModel)]="logsFilters.os" clrRadio name="options" type="radio" value="android" />
                      <label>Only android</label>
                    </clr-radio-wrapper>
                    <clr-radio-wrapper>
                      <input [(ngModel)]="logsFilters.os" clrRadio name="options" type="radio" value="ios" />
                      <label>Only ios</label>
                    </clr-radio-wrapper>
                  </clr-radio-container>
                </div>
              </td>
            </tr>
            <tr>
              <th i18n>Generated link :</th>
              <td>
                <a href="{{ getElasticLink() }}" target="_blank">Show logs in Elastic</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- .clr-row -->
  </div>
</div>
