import { Component, OnDestroy, OnInit } from '@angular/core';
import { BikeService } from '../../../../services/bike.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Bike } from '../../../../models/bike.model';
import { BikeGSMHistory } from '../../../../models/bike-gsm-history.model';
import { ActivatedRoute } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-view-bike',
  templateUrl: './gsm.component.html',
  styleUrls: [],
})
export class GSMComponent implements OnInit, OnDestroy {
  now?: Date;
  bike?: Bike;

  constructor(private bikeService: BikeService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.parent.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.bike = data.bike;
      this.now = data.now;
    });
  }

  ngOnDestroy(): void {}

  GSMHistoryLoaded: boolean = false;
  loadMoreGSMHistoryToken: string;
  GSMHistoryItems: BikeGSMHistory[] = [];

  public loadGSMHistory() {
    this.bikeService
      .getGSMHistory(this.bike.id, this.now, '')
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.GSMHistoryItems = response.results;
        this.loadMoreGSMHistoryToken = response.paging.nextPageCursor;
        this.GSMHistoryLoaded = true;
      });
  }
}
