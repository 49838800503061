import { LinkResource } from 'src/app/modules/api/models';
import { Version } from 'src/app/pages/bike/models/bike.model';

export class BikeAutodiagStatDto {
  name: string;
  detail: string;
  description: string;
  week: number;
  year: number;
  countRide: number;
  countNoRide: number;

  nameDetail: string;

  constructor(data?: any) {
    if (data) {
      this.name = data.name;
      this.detail = data.detail;
      this.description = data.description;
      this.nameDetail = `${this.name},${this.detail}`;

      this.week = data.week;
      this.year = data.year;
      this.countRide = data.countRide;
      this.countNoRide = data.countNoRide;
    }
  }
}
export class BikeAutodiagStat {
  name: string;
  detail: string;
  description: string;
  weeks: BikeAutodiagStatWeek[];
  totalInRide: number;
  total: number;

  constructor(data?: any) {
    if (data) {
      this.name = data.name;
      this.detail = data.detail;
      this.description = data.description;
      this.weeks = data.weeks;
      this.totalInRide = data.totalInRide;
      this.total = data.total;
    }
  }
}

export class BikeAutodiagStatWeek {
  date: string;
  week: number;
  year: number;
  countRide: number;
  countAll: number;

  constructor(data?: any) {
    if (data) {
      this.week = data.week;
      this.year = data.year;
      this.countRide = data.countRide;
      this.countAll = data.countRide + data.countNoRide;
    }
  }
}
