<h1 i18n>Admins</h1>

<clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
  <clr-dg-action-bar>
    <button type="button" class="btn btn-sm btn-secondary" (click)="importModal = true" i18n>
      <cds-icon shape="plus"></cds-icon> Add new users
    </button>
  </clr-dg-action-bar>

  <clr-dg-column i18n>User ID</clr-dg-column>
  <clr-dg-column i18n>Email</clr-dg-column>
  <clr-dg-column i18n>Role</clr-dg-column>

  <clr-dg-row *ngFor="let admin of admins">
    <clr-dg-action-overflow>
      <button
        class="action-item"
        appConfirm
        appConfirmSpecificMessage="Are you sure you want to reset password for this user ?"
        (appConfirm)="onResetPassword(admin)"
        i18n
      >
        Reset password
      </button>
      <button class="action-item" appConfirm (appConfirm)="onDelete(admin)" i18n>Delete</button>
    </clr-dg-action-overflow>

    <clr-dg-cell>{{ admin.id }}</clr-dg-cell>
    <clr-dg-cell>{{ admin.email }}</clr-dg-cell>
    <clr-dg-cell>{{ admin.role }}</clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer i18n>
    {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} admins
    <clr-dg-pagination #pagination [clrDgTotalItems]="total" [clrDgPageSize]="15"></clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>

<clr-modal [(clrModalOpen)]="importModal">
  <h3 class="modal-title" i18n>Create new users</h3>
  <div class="modal-body">
    <form clrForm [formGroup]="importForm" (ngSubmit)="onSubmit()" id="importForm">
      <clr-alert [clrAlertType]="'danger'" [clrAlertClosed]="!error">
        <div clr-alert-item class="alert-item">
          <span class="alert-text">
            {{ error }}
          </span>
        </div>
      </clr-alert>

      <clr-textarea-container>
        <label i18n>Emails</label>
        <textarea
          clrTextarea
          formControlName="emails"
          cols="150"
          rows="10"
          placeholder="user@angell.bike"
          required
        ></textarea>
        <clr-control-helper i18n>One email per line</clr-control-helper>
        <clr-control-error *clrIfError="'required'" i18n>This is a required field</clr-control-error>
        <clr-control-error *clrIfError="'incorrect'" i18n>Bad format</clr-control-error>
      </clr-textarea-container>

      <clr-select-container>
        <label>Roles</label>
        <select clrSelect formControlName="role">
          <option *ngFor="let role of roles" [value]="role">{{ role }}</option>
        </select>
      </clr-select-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-outline" form="importForm" i18n>Submit</button>
  </div>
</clr-modal>
