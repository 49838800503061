import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FirmwareService } from '../../../../services/firmware.service';

@UntilDestroy()
@Component({
  selector: 'app-create-firmware',
  templateUrl: './create-firmware.component.html',
  styleUrls: ['./create-firmware.component.scss'],
})
export class CreateFirmwareComponent implements OnInit, OnDestroy {
  addFirmwareForm: UntypedFormGroup;
  submitted = false;
  error = '';
  formSubmitted = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private firmwareService: FirmwareService
  ) {}

  ngOnInit(): void {
    this.addFirmwareForm = this.formBuilder.group({
      zip: ['', Validators.required],
    });
  }

  ngOnDestroy(): void {}

  public onFileSelect(event): void {
    console.log(event);
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.addFirmwareForm.get('zip').setValue(file);
    }
  }
  // convenience getter for easy access to form fields
  get f(): { [key: string]: AbstractControl } {
    return this.addFirmwareForm.controls;
  }
  public onSubmit(): void {
    this.submitted = true;

    // stop here if form is invalid
    if (this.addFirmwareForm.invalid) {
      return;
    }

    console.log('file:', this.f.zip.value);

    const httpFormData = new FormData();

    httpFormData.append('file', this.f.zip.value);

    this.error = null;

    this.formSubmitted = true;
    this.firmwareService
      .createFirmware(httpFormData)
      .pipe(untilDestroyed(this))
      .subscribe(
        (res) => this.router.navigate(['/bikes', 'firmwares', 'update', res.id]),
        (error) => {
          console.log(error);
          this.error = error;
        },
        () => (this.formSubmitted = false)
      );
  }
}
