<h1 i18n>Bikes</h1>

<div>
  <clr-accordion class="filters-accordion">
    <clr-accordion-panel [(clrAccordionPanelOpen)]="filtersOpened" class="filters-accordion-panel">
      <clr-accordion-title>Filters</clr-accordion-title>
      <clr-accordion-content *clrIfExpanded>
        <form
          clrForm
          [formGroup]="searchForm"
          (ngSubmit)="onSubmit(pagination)"
          [clrLayout]="'horizontal'"
          class="search-form clr-form-compact"
        >
          <div class="clr-row search-form-row">
            <div class="clr-col-xl-6 clr-col-sm-12">
              <clr-input-container>
                <label i18n>Search</label>
                <input
                  size="40"
                  class="free-search"
                  clrInput
                  type="text"
                  placeholder="S/N, mac, owner name/email, model name, ..."
                  formControlName="searchTerm"
                />
              </clr-input-container>
            </div>

            <div class="clr-col-xl-3 clr-col-sm-12">
              <clr-select-container>
                <label class="clr-col-xl-3 clr-col-md-2">Brand</label>
                <select class="clr-col-xl-9 clr-col-md-10" clrSelect formControlName="tenant">
                  <option [value]="'any'"></option>
                  <option [value]="'ANGELL'">Angell</option>
                  <option [value]="'MINI'">Mini</option>
                </select>
              </clr-select-container>
            </div>
            <div class="clr-col-xl-3 clr-col-sm-12">
              <clr-select-container>
                <label class="clr-col-xl-3 clr-col-md-2">Installed ?</label>
                <select class="clr-col-xl-9 clr-col-md-10" clrSelect formControlName="installed">
                  <option [value]="'any'"></option>
                  <option [value]="true">Yes</option>
                  <option [value]="false">No</option>
                </select>
              </clr-select-container>
            </div>
          </div>
          <div class="clr-row">
            <div class="clr-col-xl-6 clr-col-sm-12">
              <clr-combobox-container>
                <label>Segments</label>
                <clr-combobox
                  formControlName="segments"
                  name="multiSelect"
                  clrMulti="true"
                  placeholder="Starts typing..."
                  [clrLoading]="segmentsLoading"
                  (clrInputChange)="fetchSegments($event)"
                  (clrOpenChange)="$event ? fetchSegments('') : null"
                >
                  <ng-container *clrOptionSelected="let selected">
                    <cds-icon shape="tags" role="img"></cds-icon>
                    {{ selected?.name }}
                  </ng-container>
                  <clr-options>
                    <clr-option *clrOptionItems="let state of segments; field: 'name'" [clrValue]="state">
                      <cds-icon shape="tags" role="img"></cds-icon>
                      {{ state.name }}
                    </clr-option>
                  </clr-options>
                </clr-combobox>
              </clr-combobox-container>
            </div>
            <div class="clr-col-xl-3 clr-col-sm-12">
              <clr-select-container>
                <label class="clr-col-xl-3 clr-col-md-2">Tests status ?</label>
                <select class="clr-col-xl-9 clr-col-md-10" clrSelect formControlName="testResults">
                  <option [value]="'any'"></option>
                  <option value="SUCCESS">SUCCESS</option>
                  <option value="FAILED">FAILED</option>
                </select>
              </clr-select-container>
            </div>

            <div class="clr-col-xl-3 clr-col-sm-12">
              <clr-input-container>
                <label i18n>Version</label>
                <input clrInput type="text" placeholder="  ex: 1.2.25" formControlName="appVersion" />
              </clr-input-container>
            </div>
            <div class="clr-col-xl-3 clr-col-sm-12">
              <clr-select-container>
                <label class="clr-col-xl-3 clr-col-md-2">Market Zone</label>
                <select class="clr-col-xl-9 clr-col-md-10" clrSelect formControlName="marketZoneId">
                  <option [value]="''">All</option>
                  <option *ngFor="let marketZone of marketZones" [value]="marketZone.id">{{ marketZone.name }}</option>
                </select>
              </clr-select-container>
            </div>
          </div>
          <div class="clr-row">
            <div class="clr-col-12">
              <button class="btn btn-primary search-btn" type="submit" i18n>
                <cds-icon shape="search"></cds-icon>
              </button>
            </div>
          </div>
        </form>
      </clr-accordion-content>
    </clr-accordion-panel>
  </clr-accordion>
</div>
<clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
  <clr-dg-column i18n>Id</clr-dg-column>
  <clr-dg-column i18n title="Bike Frame Serial Number">BikeFrame S/N</clr-dg-column>
  <clr-dg-column i18n>Cockpit</clr-dg-column>
  <clr-dg-column i18n>Owner</clr-dg-column>
  <clr-dg-column i18n>Brand</clr-dg-column>
  <clr-dg-column i18n>Model</clr-dg-column>
  <clr-dg-column i18n>Version</clr-dg-column>
  <clr-dg-column i18n>Segments</clr-dg-column>
  <clr-dg-column i18n>Fully provisioned</clr-dg-column>
  <clr-dg-column i18n>Installed</clr-dg-column>
  <clr-dg-column i18n>Tests results</clr-dg-column>

  <clr-dg-row *ngFor="let bike of bikes">
    <clr-dg-cell>
      <a [routerLink]="['/bikes', 'view', bike.id]" href>{{ bike.id }}</a>
    </clr-dg-cell>
    <clr-dg-cell class="overflow-ellipsis" [title]="bike.bikeProvision.bikeFrameSerialNumber">
      {{ bike.bikeProvision.bikeFrameSerialNumber }}
      <a
        *ngIf="bike.serviceAngellCare"
        [routerLink]="['/services', 'angell-care', 'view', bike.serviceAngellCare.id]"
        href
        title="Angell Care"
      >
        <cds-icon clrVerticalNavIcon shape="certificate"></cds-icon>
      </a>
    </clr-dg-cell>
    <clr-dg-cell>
      <a [routerLink]="['/cockpits', 'view', bike.bikeProvision.mac]" href>{{ bike.bikeProvision.mac }}</a
      >&nbsp;
      <span *ngIf="bike.isV2() && bike.isConnected" class="connectivity online"></span>
    </clr-dg-cell>

    <clr-dg-cell [title]="bike.owner?.email"
      ><a [routerLink]="['/users', 'view', bike.owner?.customer.id]" href
        >{{ bike.owner?.customer.firstName }} {{ bike.owner?.customer.lastName }}</a
      >
    </clr-dg-cell>
    <clr-dg-cell> <tenant [name]="bike.bikeProvision?.model?.tenant"></tenant> </clr-dg-cell><
    <clr-dg-cell>
      <bike-model-link [model]="bike.bikeProvision.model"></bike-model-link>
    </clr-dg-cell>
    <clr-dg-cell>{{ bike.appVersion }}</clr-dg-cell>
    <clr-dg-cell>
      <segment [segment]="segment" *ngFor="let segment of bike.segments"></segment>
      <span *ngIf="bike.segments.length == 0" i18n>-</span>
    </clr-dg-cell>
    <clr-dg-cell>
      <span class="label label-danger" *ngIf="bike.bikeProvision.provisioned == false" i18n>No</span>
      <span class="label label-success" *ngIf="bike.bikeProvision.provisioned == true" i18n>Yes</span>
    </clr-dg-cell>
    <clr-dg-cell>
      <span class="label label-danger" *ngIf="bike.installed == false" i18n>No</span>
      <span class="label label-success" *ngIf="bike.installed == true" i18n>Yes</span>
    </clr-dg-cell>
    <clr-dg-cell>
      <span class="label label-danger" *ngIf="!['SUCCESS', 'PASS'].includes(bike.bikeProvision.testResults)" i18n>{{
        bike.bikeProvision.testResults
      }}</span>
      <span class="label label-success" *ngIf="['SUCCESS', 'PASS'].includes(bike.bikeProvision.testResults)" i18n>{{
        bike.bikeProvision.testResults
      }}</span>
    </clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer i18n>
    {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} bikes
    <clr-dg-pagination #pagination [clrDgTotalItems]="total" [clrDgPageSize]="15"></clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
