import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Bike } from '../../../../models/bike.model';
import { ActivatedRoute } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-view-bike',
  templateUrl: './rides.component.html',
  styleUrls: [],
})
export class RidesComponent implements OnInit, OnDestroy {
  bike?: Bike;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.parent.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.bike = data.bike;
    });
  }

  ngOnDestroy(): void {}
}
