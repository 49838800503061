export class UserProfile {
  email: string;
  locale: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  role: string;

  constructor(data?: any) {
    if (data) {
      this.email = data.email;
      this.locale = data.locale;
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.phoneNumber = data.phoneNumber;
      this.role = data.role;
    }
  }
}
