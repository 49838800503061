import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from 'src/app/modules/auth/guards/authentication.guard';
import { ListCustomerComponent } from 'src/app/pages/user/pages/list-customer/list-customer.component';
import { ViewCustomerComponent } from './pages/view-customer/view-customer.component';
import { EditCustomerComponent } from './pages/edit-customer/edit-customer.component';
import { ListAdminComponent } from './pages/list-admin/list-admin.component';
import { ListNotificationHistoryComponent } from './pages/list-notification-history/list-notification-history.component';
import { ListNotificationComponent } from './pages/list-notification/list-notification.component';
import { DetailsComponent } from './pages/view-customer/details/details.component';
import { ViewCustomerResolver } from './pages/view-customer/view-customer.resolver';
import { RidesComponent } from './pages/view-customer/rides/rides.component';
import { ShopComponent } from './pages/view-customer/timeline/shop.component';
import { FormRetailerComponent } from './pages/pro/retailers/form-retailer/form-retailer.component';
import { ListRetailersComponent } from './pages/pro/retailers/list-retailers/list-retailers.component';
import { ListShopsComponent } from './pages/pro/shops/list-shops/list-shops.component';
import { FormShopComponent } from './pages/pro/shops/form-shop/form-shop.component';
import { ViewShopComponent } from './pages/pro/shops/view-shop/view-shop.component';
import { TimelineComponent } from './pages/view-customer/shop/timeline.component';
import { ViewRetailerComponent } from './pages/pro/retailers/view-retailer/view-retailer.component';
import { NotificationsComponent } from './pages/view-customer/notifications/notifications.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'lists',
  },
  {
    path: 'lists',
    pathMatch: 'full',
    canActivate: [AuthenticationGuard],
    component: ListCustomerComponent,
  },
  {
    path: 'view/:id',
    canActivate: [AuthenticationGuard],
    component: ViewCustomerComponent,
    resolve: {
      user: ViewCustomerResolver,
    },
    data: {},
    children: [
      { path: '', redirectTo: 'details', pathMatch: 'full' },
      { path: 'details', component: DetailsComponent },
      { path: 'rides', component: RidesComponent },
      { path: 'timeline', component: TimelineComponent },
      { path: 'shop', component: ShopComponent },
      { path: 'notifications/:type', component: NotificationsComponent },
    ],
  },
  {
    path: 'edit/:id',
    canActivate: [AuthenticationGuard],
    component: EditCustomerComponent,
  },
  {
    path: 'admins/lists',
    pathMatch: 'full',
    canActivate: [AuthenticationGuard],
    component: ListAdminComponent,
  },
  {
    path: 'pro/shops',
    canActivate: [AuthenticationGuard],
    component: ListShopsComponent,
  },
  {
    path: 'pro/retailers',
    canActivate: [AuthenticationGuard],
    component: ListRetailersComponent,
  },
  {
    path: 'pro/shops/view/:id',
    canActivate: [AuthenticationGuard],
    component: ViewShopComponent,
  },
  {
    path: 'pro/shops/form',
    canActivate: [AuthenticationGuard],
    component: FormShopComponent,
  },
  {
    path: 'pro/shops/form/:id',
    canActivate: [AuthenticationGuard],
    component: FormShopComponent,
  },
  {
    path: 'pro/retailers/form',
    pathMatch: 'full',
    canActivate: [AuthenticationGuard],
    component: FormRetailerComponent,
  },
  {
    path: 'pro/retailers/form/:id',
    pathMatch: 'full',
    canActivate: [AuthenticationGuard],
    component: FormRetailerComponent,
  },
  {
    path: 'pro/retailers/view/:id',
    pathMatch: 'full',
    canActivate: [AuthenticationGuard],
    component: ViewRetailerComponent,
  },
  {
    path: 'notifications/history',
    pathMatch: 'full',
    redirectTo: 'notifications/history/mail',
  },
  {
    path: 'notifications/history/:type',
    pathMatch: 'full',
    canActivate: [AuthenticationGuard],
    component: ListNotificationHistoryComponent,
  },
  {
    path: 'notifications/lists',
    pathMatch: 'full',
    redirectTo: 'notifications/lists/mail',
  },
  {
    path: 'notifications/lists/:type',
    pathMatch: 'full',
    canActivate: [AuthenticationGuard],
    component: ListNotificationComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}
