import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { VersionService } from '../../../../services/version.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SegmentService } from '../../../../services/segment.service';
import { Segment } from '../../../../models/segment.model';

@UntilDestroy()
@Component({
  selector: 'app-test-next-version',
  templateUrl: './test-next-version.component.html',
  styleUrls: ['./test-next-version.component.scss'],
})
export class TestNextVersionComponent implements OnInit, OnDestroy {
  testVersionForm: UntypedFormGroup;
  submitted = false;
  versionPattern = '^(\\d+).(\\d+).(\\d+)$';
  testNextVersionResult = null;
  segmentsLoading = false;
  segments: Segment[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private versionService: VersionService,
    private segmentService: SegmentService
  ) {}

  ngOnInit(): void {
    this.testVersionForm = this.formBuilder.group({
      imageVersion: ['', Validators.pattern(this.versionPattern)],
      appVersion: ['', Validators.pattern(this.versionPattern)],
      bleVersion: ['', Validators.pattern(this.versionPattern)],
      segments: [[], Validators.required],
    });
  }

  ngOnDestroy(): void {}

  // convenience getter for easy access to form fields
  get f(): { [key: string]: AbstractControl } {
    return this.testVersionForm.controls;
  }

  public fetchSegments(term) {
    this.segmentsLoading = true;
    this.segmentService.search(term, true).subscribe((segments) => {
      this.segmentsLoading = false;
      this.segments = segments;
    });
  }

  public onSubmit(): void {
    this.submitted = true;

    // stop here if form is invalid
    if (this.testVersionForm.invalid) {
      return;
    }

    this.versionService
      .postTestNextVersion(
        this.f.appVersion.value,
        this.f.bleVersion.value,
        this.f.imageVersion.value,
        this.f.segments.value
      )
      .pipe(untilDestroyed(this))
      .subscribe((nextVersion) => {
        this.testNextVersionResult = nextVersion;
      });
  }
}
