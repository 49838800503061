import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserStorageService } from '../services/user-storage.service';

@Injectable()
export class TokenInjectorInterceptor implements HttpInterceptor {
  constructor(private userStorage: UserStorageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let accept = request.headers.get('Accept');
    if (!accept) {
      accept = 'application/json';
    }

    const headers = {
      Accept: accept,
      'Accept-Language': 'en',
    };

    const accessToken = this.userStorage.getToken();
    if (accessToken) {
      headers['Authorization'] = `Bearer ${accessToken}`;
    }

    request = request.clone({
      setHeaders: headers,
    });

    return next.handle(request);
  }
}
