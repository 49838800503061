import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClrDatagridStateInterface } from '@clr/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CockpitService } from '../../../services/cockpit.service';
import { Cockpit } from '../../../models/cockpit.model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@UntilDestroy()
@Component({
  selector: 'app-list-bike',
  templateUrl: './list-cockpit.component.html',
  styleUrls: ['./list-cockpit.component.scss'],
})
export class ListCockpitComponent implements OnInit, OnDestroy {
  public cockpits: Cockpit[];
  public total: number;
  public loading = true;

  constructor(private cockpitService: CockpitService, private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      searchTerm: '',
      isAssembledOnBike: null,
      testResults: '',
      appVersion: '',
    });
  }

  ngOnDestroy(): void {}

  public refresh(state: ClrDatagridStateInterface): void {
    this.loading = true;

    const params = {
      page: state.page.current - 1,
      size: state.page.size,
    };

    this.cockpitService
      .getAll(params)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result) => {
          this.cockpits = result.values();
          this.total = result.page.totalElements || this.cockpits.length;
        },
        complete: () => {
          this.loading = false;
        },
      });
  }

  public searchForm: UntypedFormGroup;
  public filtersOpened: boolean = true;

  private constructParamsFromState(state) {
    const params = {
      searchTerm: this.searchForm.get('searchTerm').value,
      isAssembledOnBike: this.searchForm.get('isAssembledOnBike').value,
      testResults: this.searchForm.get('testResults').value,
      appVersion: this.searchForm.get('appVersion').value,
      page: state.page.current - 1,
      size: state.page.size,
    };
    if (params.testResults === 'any') {
      delete params.testResults;
    }
    if (params.isAssembledOnBike == null) {
      delete params.isAssembledOnBike;
    }
    return params;
  }
  public onSubmit(state: ClrDatagridStateInterface): void {
    if (this.searchForm.invalid) {
      return;
    }

    this.loading = true;

    const params = this.constructParamsFromState(state);

    this.cockpitService
      .getAll(params)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.cockpits = result.values();
        this.total = result.page.totalElements || this.cockpits?.length;
        this.loading = false;
      });
  }
}
