import { Component, Input, OnInit } from '@angular/core';
import { Address } from '../../models/shop.model';

@Component({
  selector: 'address-view',
  templateUrl: './address-view.component.html',
})
export class AddressViewComponent implements OnInit {
  @Input()
  address: Address;

  constructor() {}

  ngOnInit(): void {}
}
