import { Injectable } from '@angular/core';
import { from } from 'rxjs';

import { User } from '../models/user';

const TOKEN_KEY = 'user.access_token';
const USER_KEY = 'user.info';

@Injectable({
  providedIn: 'root',
})
export class UserStorageService {
  constructor() {}

  public getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  public setToken(token: string): void {
    localStorage.setItem(TOKEN_KEY, token);
  }

  public getUser(): User | null {
    const user = localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return null;
  }

  public setUser(user: User): void {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public clear(): void {
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(TOKEN_KEY);
  }
}
