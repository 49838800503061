import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { VersionService } from '../../../../services/version.service';
import { Segment } from '../../../../models/segment.model';
import { SegmentService } from '../../../../services/segment.service';
import { segmentsValidator } from '../../../../../../modules/shared/validators/segments.validator';

@UntilDestroy()
@Component({
  selector: 'app-create-version',
  templateUrl: './create-version.component.html',
  styleUrls: ['./create-version.component.scss'],
})
export class CreateVersionComponent implements OnInit, OnDestroy {
  createVersionForm: UntypedFormGroup;
  submitted = false;
  error = '';
  versionPattern = '^(\\d+).(\\d+).(\\d+)$';

  public segments: Segment[] = [];
  public segmentsLoading: boolean = false;

  public fetchSegments(term) {
    this.segmentsLoading = true;
    this.segmentService
      .search(term, true)
      .pipe(untilDestroyed(this))
      .subscribe((segments) => {
        this.segmentsLoading = false;
        this.segments = [];
        if (segments) {
          this.segments.push(...segments);
        }
        if (!this.segments.find((c) => c.name == term)) {
          this.segments.unshift(new Segment({ name: term }));
        }
      });
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private versionService: VersionService,
    private segmentService: SegmentService
  ) {}

  ngOnInit(): void {
    this.createVersionForm = this.formBuilder.group({
      softwarePackageType: ['', Validators.required],
      minAppVersion: ['', Validators.pattern(this.versionPattern)],
      minImageVersion: ['', Validators.pattern(this.versionPattern)],
      minBluetoothVersion: ['', Validators.pattern(this.versionPattern)],
      imageVersion: ['', Validators.pattern(this.versionPattern)],
      appVersion: ['', Validators.pattern(this.versionPattern)],
      bleVersion: ['', Validators.pattern(this.versionPattern)],
      zip: ['', Validators.required],
      segments: new UntypedFormControl([], segmentsValidator()),
    });
  }

  ngOnDestroy(): void {}

  public onFileSelect(event): void {
    console.log(event);
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.createVersionForm.get('zip').setValue(file);
    }
  }

  getDataFromForm(): { [key: string]: any } {
    const createData = {
      softwarePackageType: this.f.softwarePackageType.value,
      softwares: [],
      softwareVersionRequirements: {
        minAppVersion: this.valueOrNull(this.f.minAppVersion.value),
        minImageVersion: this.valueOrNull(this.f.minImageVersion.value),
        minBluetoothVersion: this.valueOrNull(this.f.minBluetoothVersion.value),
      },
      segments: this.f.segments?.value?.map((s) => s.name),
    };

    const bikeCountLimit = 0;

    if (this.f.softwarePackageType.value === 'BIKE_IMAGE') {
      createData.softwares.push({
        softwareType: 'IMAGE',
        version: this.f.imageVersion.value,
        bikeCountLimit,
      });
    } else if (this.f.softwarePackageType.value === 'BIKE_APP_AND_BLE') {
      createData.softwares.push({
        softwareType: 'APP',
        version: this.f.appVersion.value,
        bikeCountLimit,
      });
      createData.softwares.push({
        softwareType: 'BLE',
        version: this.f.bleVersion.value,
        bikeCountLimit,
      });
    }

    return createData;
  }

  private valueOrNull(value: string): string | null {
    return value !== '' ? value : null;
  }

  public isSelected(selectValue): boolean {
    return this.createVersionForm.get('softwarePackageType').value === selectValue;
  }

  // convenience getter for easy access to form fields
  get f(): { [key: string]: AbstractControl } {
    return this.createVersionForm.controls;
  }

  public onSubmit(): void {
    this.submitted = true;

    // stop here if form is invalid
    if (this.createVersionForm.invalid) {
      return;
    }

    const createData = this.getDataFromForm();

    console.log('form:', createData);
    console.log('file:', this.f.zip.value);

    const httpFormData = new FormData();

    if (createData.softwares.length === 0) {
      return;
    }

    httpFormData.append('file', this.f.zip.value);
    httpFormData.append('data', JSON.stringify(createData));

    this.error = null;

    this.versionService
      .postCreateVersion(httpFormData)
      .pipe(untilDestroyed(this))
      .subscribe(
        (res) => this.router.navigate(['/bikes', 'versions']),
        (error) => {
          console.log(error);
          this.error = error;
        }
      );
  }
}
