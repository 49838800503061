import { Component, OnInit, Input } from '@angular/core';
import { DeleteTrace, UpdateTrace } from 'src/app/modules/api/models';

@Component({
  selector: 'location',
  templateUrl: './location-link.component.html',
})
export class LocationLinkComponent implements OnInit {
  @Input()
  mapsQuery: string;

  constructor() {}

  ngOnInit(): void {}
}
