import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';

import { AuthModule } from '../../modules/auth/auth.module';
import { UserRoutingModule } from './user-routing.module';
import { ViewCustomerComponent } from './pages/view-customer/view-customer.component';
import { ViewAdminComponent } from './pages/view-admin/view-admin.component';
import { ListCustomerComponent } from './pages/list-customer/list-customer.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { EditCustomerComponent } from './pages/edit-customer/edit-customer.component';
import { ListAdminComponent } from './pages/list-admin/list-admin.component';
import { ListNotificationHistoryComponent } from './pages/list-notification-history/list-notification-history.component';
import { ListNotificationComponent } from './pages/list-notification/list-notification.component';
import { DetailsComponent } from './pages/view-customer/details/details.component';
import { RidesComponent } from './pages/view-customer/rides/rides.component';
import { ShopComponent } from './pages/view-customer/timeline/shop.component';
import { FormRetailerComponent } from './pages/pro/retailers/form-retailer/form-retailer.component';
import { ListRetailersComponent } from './pages/pro/retailers/list-retailers/list-retailers.component';
import { ListShopsComponent } from './pages/pro/shops/list-shops/list-shops.component';
import { FormShopComponent } from './pages/pro/shops/form-shop/form-shop.component';
import { ViewShopComponent } from './pages/pro/shops/view-shop/view-shop.component';
import { ShopComboboxComponent } from './components/shop-combobox/shop-combobox.component';
import { ShopDetailsComponent } from './components/shop-details/shop-details.component';
import { TimelineComponent } from './pages/view-customer/shop/timeline.component';
import { ViewRetailerComponent } from './pages/pro/retailers/view-retailer/view-retailer.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { UserBikesDetailsComponent } from './components/user-bikes-details/user-bikes-details.component';
import { ListProUsersComponent } from './components/list-pro-users/list-pro-users.component';
import { ShopLinkComponent } from './components/shop-link/shop-link.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { AddressViewComponent } from './components/address-view/address-view.component';
import { NotificationsComponent } from './pages/view-customer/notifications/notifications.component';

@NgModule({
  declarations: [
    ViewCustomerComponent,
    DetailsComponent,
    RidesComponent,
    ShopComponent,
    ViewAdminComponent,
    ListCustomerComponent,
    FormRetailerComponent,
    EditCustomerComponent,
    ListAdminComponent,
    ListNotificationHistoryComponent,
    ListNotificationComponent,
    ListRetailersComponent,
    ListShopsComponent,
    FormShopComponent,
    ViewShopComponent,
    ShopComboboxComponent,
    TimelineComponent,
    ShopDetailsComponent,
    ViewRetailerComponent,
    UserDetailsComponent,
    UserBikesDetailsComponent,
    ListProUsersComponent,
    ShopLinkComponent,
    AddressFormComponent,
    AddressViewComponent,
    NotificationsComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, AuthModule, SharedModule, ClarityModule, UserRoutingModule, FormsModule],
})
export class UserModule {}
