<div [formGroup]="addressFormGroup">
  <div class="clr-row">
    <clr-input-container class="clr-col-12">
      <label i18n>Line 1</label>
      <input clrInput type="text" formControlName="addressLine1" required placeholder="" />
    </clr-input-container>
  </div>

  <div class="clr-row">
    <clr-input-container class="clr-col-12">
      <label i18n>Line 2</label>
      <input clrInput type="text" formControlName="addressLine2" placeholder="" />
    </clr-input-container>
  </div>
  <div class="clr-row">
    <clr-input-container class="clr-col-12">
      <label i18n>Zip Code</label>
      <input clrInput type="number" formControlName="zipCode" required placeholder="" />
    </clr-input-container>
  </div>
  <div class="clr-row">
    <clr-input-container class="clr-col-12">
      <label i18n>City</label>
      <input clrInput type="text" formControlName="city" required placeholder="" />
    </clr-input-container>
  </div>
  <div class="clr-row">
    <clr-select-container class="clr-col-12">
      <label i18n>Country</label>
      <select clrSelect formControlName="country">
        <option *ngFor="let country of countryList" value="{{ country }}">{{ country }}</option>
      </select>
    </clr-select-container>
  </div>
</div>
