export class Cockpit {
  id: string;
  provisionedOn: Date;
  supersededOn: Date;
  mac: string;
  iccId: string;
  bikePhoneNumber: string;

  topCard: Card;
  bottomCard: Card;
  versionApp: string;
  versionImg: string;
  versionBle: string;
  color: string;
  latestTestResult: string;

  bikeId: number;
  bikeProvisionId: number;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.provisionedOn = data.provisionedOn;
      this.supersededOn = data.supersededOn;
      this.mac = data.mac;
      this.iccId = data.iccId;
      this.bikePhoneNumber = data.bikePhoneNumber;
      this.topCard = data.topCard;
      this.bottomCard = data.bottomCard;
      this.versionApp = data.versionApp;
      this.versionImg = data.versionImg;
      this.versionBle = data.versionBle;
      this.color = data.color;
      this.latestTestResult = data.latestTestResult;
      this.bikeId = data.bikeId;
      this.bikeProvisionId = data.bikeProvisionId;
    }
  }
}

export class Card {
  id: string;
  serialNumber: string;
  provisionedOn: Date;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.serialNumber = data.serialNumber;
      this.provisionedOn = data.provisionedOn;
    }
  }
}
