import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ClrDatagridStateInterface } from '@clr/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Retailer } from '../../models/retailer.model';
import { RetailerService } from '../../services/retailer.service';
import { Shop } from '../../models/shop.model';
import { ShopService } from '../../services/shop.service';
@UntilDestroy()
@Component({
  selector: 'list-pro-users',
  templateUrl: './list-pro-users.component.html',
  styleUrls: ['./list-pro-users.component.scss'],
})
export class ListProUsersComponent implements OnInit, OnDestroy {
  public retailers: Retailer[];
  public total: number;
  public loading = true;

  public searchForm: UntypedFormGroup;
  public filtersOpened: boolean = true;

  @Input()
  public initialShop: Shop;

  public shop: Shop;

  constructor(
    private retailerService: RetailerService,
    private shopService: ShopService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.initialShop) {
      this.shop = this.initialShop;
    }
    this.searchForm = this.formBuilder.group({
      searchTerm: '',
      deleted: '',
    });
  }

  ngOnDestroy(): void {}

  public refresh(state: ClrDatagridStateInterface): void {
    console.log('table state:', state);

    this.loading = true;

    let params = {
      page: state.page.current - 1,
      size: state.page.size,
    };

    const searchTerm = this.searchForm.get('searchTerm').value;
    if (searchTerm) {
      params['searchTerm'] = searchTerm;
    }
    const deleted = this.searchForm.get('deleted').value;
    if (deleted) {
      params['deleted'] = deleted;
    }
    if (this.shop) {
      params['shopId'] = this.shop.id;
    }
    this.retailerService
      .getAll(params)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        console.log('Result', result);

        this.retailers = result.values();
        this.total = result.page.totalElements || this.retailers?.length;
        this.loading = false;
      });
  }

  onCreate() {
    this.router.navigate([`/pro/retailers/form`], { queryParams: { shopId: this.initialShop?.id } });
  }
}
