import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tenant',
  templateUrl: './tenant.component.html',
  styleUrls: ['./tenant.component.scss'],
})
export class TenantComponent implements OnInit {
  @Input()
  name: string;

  constructor() {}

  ngOnInit(): void {}
}
