import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserStorageService } from '../../auth/services/user-storage.service';
import { ACLS } from '../../../acls-definition';

/**
 * Structural directive used to add/remove elements from the view
 * based on the user's permissions.
 */
@Directive({
  selector: '[aclCheck]',
})
export class AclCheckDirective {
  private permissionToCheck: string;

  @Input()
  public set aclCheck(value: string) {
    this.permissionToCheck = value;
    this.evaluate();
  }

  private created = false;

  constructor(
    private template: TemplateRef<any>,
    private view: ViewContainerRef,
    private userStorageService: UserStorageService
  ) {}

  private evaluate(): void {
    const userRole = this.userStorageService.getUser()?.role;
    const isAllowed = AclCheckDirective.resolve(this.permissionToCheck, ACLS[userRole]);
    if (isAllowed) {
      this.allow();
    } else {
      this.disallow();
    }
  }

  private allow(): void {
    if (this.created) {
      return;
    }
    this.view.clear();
    this.view.createEmbeddedView(this.template);
    this.created = true;
  }

  private disallow(): void {
    if (!this.created) {
      return;
    }
    this.view.clear();
    this.created = false;
  }

  public static resolve(path, obj) {
    return path.split('.').reduce(function (prev, curr) {
      return prev ? prev[curr] : null;
    }, obj || self);
  }
}
