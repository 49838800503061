import { AfterViewInit, ChangeDetectorRef, Component, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Bike } from '../../../models/bike.model';
import { ClrTabLink } from '@clr/angular';

const BIKE_V1_TABS: any[] = [
  { label: 'Details', path: 'details' },
  { label: 'Timeline', path: 'timeline' },
  { label: 'Provisioning', path: 'provisioning' },
  { label: 'Maintenance', path: 'maintenance' },
  { label: 'Autodiag', path: 'errors' },
  { label: 'Rides', path: 'rides' },
  { label: 'Services', path: 'services' },
  { label: 'Battery', path: 'battery' },
];

const BIKE_V2_TABS: any[] = [
  { label: 'Autonomy', path: 'autonomy', hiddenIfV2: true },
  { label: 'Connectivity', path: 'connectivity', hiddenIfV2: true },
  { label: 'GSM', path: 'gsm', hiddenIfV2: true },
];

@UntilDestroy()
@Component({
  selector: 'app-view-bike',
  templateUrl: './view-bike.component.html',
  styleUrls: ['./view-bike.component.scss'],
})
export class ViewBike2Component implements AfterViewInit {
  bike?: Bike;

  tabsData: any[] = BIKE_V1_TABS;

  constructor(
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.bike = data.bike;
      if (this.bike.isV2()) {
        this.tabsData = BIKE_V1_TABS.concat(BIKE_V2_TABS);
      }
    });
  }

  @ViewChildren(ClrTabLink)
  tabs: QueryList<ClrTabLink>;

  ngAfterViewInit() {
    const url = this.router.url.substring(this.router.url.lastIndexOf('/') + 1);
    const currentTabIndex = this.tabsData.findIndex((comp) => comp.path === url);
    if (currentTabIndex > -1) {
      this.tabs.filter((element, index) => index === currentTabIndex)[0].activate();
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy(): void {}
}
