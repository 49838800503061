<div *ngIf="bike">
  <div class="clr-row">
    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
      <h2 i18n>Status</h2>
      <table class="table table-vertical">
        <tbody>
          <tr>
            <th i18n>Connected</th>
            <td>
              <span *ngIf="bike.isConnected == false" class="label label-danger" i18n>No</span>
              <span *ngIf="bike.isConnected == true" class="label label-success" i18n>Yes</span>
            </td>
          </tr>

          <tr>
            <th i18n>Last connected date</th>
            <td>
              {{ bike.lastConnectedDate | date: 'dd/MM/yy HH:mm' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div *aclCheck="'show_api_logs'" class="clr-col-lg-6 clr-col-md-8 clr-col-12">
      <h2 i18n>MQTT Logs</h2>
      <table class="table table-vertical">
        <tbody>
          <!--tr>
                                          <th i18n>Filters :</th>
                                          <td>
                                            <div>
                                              <clr-checkbox-container clrInline class="no-margin-top">
                                                <label>Url path contains : </label>
                                                <clr-checkbox-wrapper>
                                                  <input type="checkbox" clrCheckbox name="logsFilterBike" value="option1"
                                                         [(ngModel)]="logsFilters.bike"/>
                                                  <label>Bike Id or Mac</label>
                                                </clr-checkbox-wrapper>
                                                <clr-checkbox-wrapper>
                                                  <input type="checkbox" clrCheckbox name="logsFilterCustomer" value="option1"
                                                         [(ngModel)]="logsFilters.customers"/>
                                                  <label>One of Customer Id</label>
                                                </clr-checkbox-wrapper>
                                              </clr-checkbox-container>
                                              <clr-radio-container clrInline>
                                                <label>Source</label>
                                                <clr-radio-wrapper>
                                                  <input type="radio" clrRadio name="options" value="all" [(ngModel)]="logsFilters.os"/>
                                                  <label>All</label>
                                                </clr-radio-wrapper>
                                                <clr-radio-wrapper>
                                                  <input type="radio" clrRadio name="options" value="bike" [(ngModel)]="logsFilters.os"/>
                                                  <label>Only bike</label>
                                                </clr-radio-wrapper>
                                                <clr-radio-wrapper>
                                                  <input type="radio" clrRadio name="options" value="android"
                                                         [(ngModel)]="logsFilters.os"/>
                                                  <label>Only android</label>
                                                </clr-radio-wrapper>
                                                <clr-radio-wrapper>
                                                  <input type="radio" clrRadio name="options" value="ios" [(ngModel)]="logsFilters.os"/>
                                                  <label>Only ios</label>
                                                </clr-radio-wrapper>
                                              </clr-radio-container>
                                            </div>
                                          </td>
                                        </tr-->
          <tr>
            <th i18n>link:</th>
            <td>
              <a href="{{ getElasticLinkForConsumers() }}" target="_blank">Show logs in Elastic</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- .clr-row -->

  <div class="clr-row">
    <div class="clr-col-lg-4 clr-col-md-12 clr-col-12">
      <h2 i18n>Connectivity History</h2>

      <div *ngIf="!connectivityHistoryLoaded">
        <button (click)="loadConnectivityHistory()" class="btn btn-outline" i18n type="button">
          <cds-icon shape="sync"></cds-icon>
          Load
        </button>
      </div>
      <table *ngIf="connectivityHistoryLoaded" class="table">
        <thead>
          <tr>
            <th i18n>Date</th>
            <th i18n>Action</th>
            <th i18n>Client Initiated Disconnect</th>
            <th i18n>Disconnect Reason</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of connectivityHistoryItems">
            <td>{{ item.eventDate | date: 'dd/MM/yy HH:mm:ss' }}</td>
            <td>{{ item.eventType }}</td>
            <td>{{ item.clientInitiatedDisconnect }}</td>
            <td>{{ item.disconnectReason }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- .clr-row -->
</div>
