import { Injectable } from '@angular/core';
import { Bike, CustomerBike } from 'src/app/pages/bike/models/bike.model';
import { BikeHistory } from '../models/bike-history.model';
import { BikeEvent } from '../models/bike-event.model';
import { ACLS } from '../../../acls-definition';
import { User } from '../../../modules/auth/models/user';
import { AclCheckDirective } from '../../../modules/shared/directives/acl-check.directive';

enum Status {
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  INFO = 'info',
  NONE = '',
}

export enum Types {
  LIFECYCLE = 'lifecycle',
  EVENT = 'event',
  ERROR = 'error',
  DETAILS = 'details',
}

@Injectable({
  providedIn: 'root',
})
export class BikeTimelineMapper {
  constructor() {}

  mapProvisionToBikeEvent(bike: Bike): BikeEvent {
    return {
      id: 'provisioned',
      status: Status.SUCCESS,
      date: new Date(bike.updateTrace.createdOn),
      title: `Provisioned : ${bike.bikeProvision.model.name} ${bike.bikeProvision.color}`,
      description: `MAC : <a href="/bikes/view/${bike.id}">${bike.bikeProvision.mac}</a>`,
      type: Types.LIFECYCLE,
      shape: 'upload-cloud',
      source: {
        type: 'BIKE_ASSEMBLY_BENCH',
      },
    };
  }

  mapLastUpdate(bike: Bike, currentUser: User): BikeEvent {
    const currentUserCanSeeLocation = AclCheckDirective.resolve('show_location', ACLS[currentUser.role]);
    return {
      id: 'status-update',
      status: Status.NONE,
      date: new Date(bike.updateTrace.updatedOn),
      title: 'Status Update (last)',
      description: currentUserCanSeeLocation && `Last known location : ${getMapsLinkFromLocation(bike.location)}`,
      type: Types.LIFECYCLE,
      shape: 'cloud-traffic',
      source: {
        type:
          bike.updateTrace.updatedBy == 'anonymous_user' || bike.updateTrace.updatedBy.endsWith('consumer')
            ? 'BIKE'
            : 'APP_ANGELL',
        userId: bike.updateTrace.updatedBy,
      },
    };
  }

  mapUserAdded(customerBike: CustomerBike): BikeEvent {
    if (customerBike.role === 'OWNER') {
      const evt = {
        id: 'owner.added',
        date: new Date(customerBike.updateTrace.createdOn),
        title: `Bike installed : ${customerBike.bikeName ? customerBike.bikeName : ''}`,
        description: `Owner is ${
          customerBike.customer?.id
            ? `<a href="/users/view/${customerBike.customer.id}">${customerBike.email}</a>`
            : customerBike.email
        }`,
        type: customerBike.deleteTrace.deletedOn ? `customer.history.added.owner` : `customer.added.owner`,
        shape: 'user',
        status: Status.SUCCESS,
        source: {
          type: 'APP_ANGELL',
          userId: customerBike.updateTrace.createdBy,
        },
      };

      return evt;
    }
    return {
      id: 'customer.user.added',
      date: new Date(customerBike.updateTrace.createdOn),
      title: `${customerBike.role} added : ${
        customerBike.customer?.id
          ? `<a href="/users/view/${customerBike.customer.id}">${customerBike.email}</a>`
          : customerBike.email
      }`,
      description: '',
      type: customerBike.deleteTrace.deletedOn
        ? `customer.history.added.${customerBike.role}`
        : `customer.added.${customerBike.role}`,
      shape: 'users',
      status: Status.SUCCESS,
      source: {
        type: customerBike.role != 'AFTER_SALES' ? 'APP_ANGELL' : 'APP_PRO',
        userId: customerBike.updateTrace.createdBy,
      },
    };
  }

  mapUserDeleted(customerBike: CustomerBike): BikeEvent {
    if (customerBike.role === 'OWNER') {
      return {
        id: customerBike.updateTrace.createdOn + 'OWNERSdeleted',
        date: new Date(customerBike.deleteTrace.deletedOn),
        title: `Bike uninstalled : ${customerBike.bikeName ? customerBike.bikeName : ''}`,
        description: `Owner ${
          customerBike.customer?.id
            ? `<a href="/users/view/${customerBike.customer.id}">${customerBike.email}</a>`
            : customerBike.email
        } has been removed`,
        type: `customer.history.deleted.owner`,
        shape: 'user',
        status: Status.DANGER,
        source: {
          type: 'APP_ANGELL',
          userId: customerBike.deleteTrace.deletedBy,
        },
      };
    }
    return {
      id: customerBike.updateTrace.createdOn + 'deleted',
      date: new Date(customerBike.deleteTrace.deletedOn),
      title: `${customerBike.role} deleted : ${
        customerBike.customer?.id
          ? `<a href="/users/view/${customerBike.customer.id}">${customerBike.email}</a>`
          : customerBike.email
      }`,
      description: '',
      type: `customer.history.deleted.${customerBike.role}`,
      shape: 'users',
      status: Status.WARNING,
      source: {
        type: customerBike.role != 'AFTER_SALES' ? 'APP_ANGELL' : 'APP_PRO',
        userId: customerBike.deleteTrace.deletedBy,
      },
    };
  }

  mapFromHistoryEvent(history: BikeHistory): BikeEvent {
    const evt = {
      id: history.id,
      date: new Date(history.timestamp),
      title: `Status updated : ${history.eventName}`,
      description: '',
      status: Status.NONE,
      type: history.eventName,
      shape: null,
      solid: null,
      flip: null,
      source: {
        type: history.source,
        userId: null,
      },
    };
    if (history.eventName === 'motion.on-going') {
      evt.title = `Motion : ON GOING`;
      evt.shape = 'map';
      evt.status = Status.SUCCESS;
    }
    if (history.eventName === 'motion.ended') {
      evt.title = `Motion : ENDED`;
      evt.shape = 'map';
      evt.status = Status.DANGER;
    }
    if (history.eventName === 'lock') {
      evt.title = `Bike ${history.event['lock'] ? 'LOCKED' : 'UNLOCKED'}`;
      evt.shape = history.event['lock'] ? 'lock' : 'unlock';
      evt.description = `Metadata : ${JSON.stringify(history.event)}`;
      evt.description = `
      (Alarm : ${history.event['isAlarmOn'] ? 'Enabled' : 'Disabled'},
      Batteries (
        internal : ${history.event['internalBatteryLevel']},
        external : ${history.event['externalBatteryLevel']}
      ),
      Location (lat,lon) : ${history.event['location'] && history.event['location']['lat']},${history.event['location'] && history.event['location']['lon']},
      App Version (${history.event['appVersion']}),
      Bike Version (${JSON.stringify(history.event['bikeVersion'])})
      )`;
      evt.source.userId = history.event['customerId'];
    }
    if (history.eventName === 'carmode') {
      evt.title = `Alarm ${history.event['carMode'] === true ? 'DISABLED' : 'ENABLED'}`;
      evt.shape = history.event['carMode'] === true ? 'shield-x' : 'shield-check';
    }
    if (history.eventName === 'theft.on-going') {
      evt.title = `THEFT alert : STARTED`;
      evt.shape = 'announcement';
      evt.status = Status.DANGER;
    }

    if (history.eventName === 'theft.cancel') {
      evt.title = `THEFT alert : CANCELED`;
      evt.shape = 'announcement';
      evt.status = Status.SUCCESS;
    }

    if (history.eventName === 'fall.declared') {
      evt.title = `FALL alert : DECLARED`;
      evt.shape = 'bell';
      evt.status = Status.WARNING;
    }
    if (history.eventName === 'fall.on-going') {
      evt.title = `FALL alert : STARTED`;
      evt.shape = 'bell';
      evt.status = Status.DANGER;
    }

    if (history.eventName === 'fall.cancel') {
      evt.title = `FALL alert : CANCELED`;
      evt.shape = 'bell';
      evt.status = Status.SUCCESS;
    }

    if (history.eventName === 'ride.created') {
      evt.title = `New ride (${history.event['distance'] / 1000}km in ${history.event['duration'] / 60}mn)`;
      evt.description = `rided by <a href="/users/view/${history.event['customerId']}">${history.event['customerId']}</a>`;
      evt.shape = 'bicycle';
    }

    if (history.eventName === 'low_battery') {
      evt.title = `Low battery`;
      evt.description = `Internal battery is critically low : ${history.event['batteryLevel']}%`;
      evt.shape = 'battery';
    }

    if (history.eventName === 'update') {
      const bikeVersions = toBikeVersions(history.event['bikeVersionChange']);
      evt.title = `Bike UPDATED`;
      evt.shape = 'update';
      evt.description = `APP ${bikeVersions.previous.app}->${bikeVersions.next.app} <br /> BLE ${bikeVersions.previous.ble}->${bikeVersions.next.ble} <br /> IMG ${bikeVersions.previous.img}->${bikeVersions.next.img} <br /> BOOTLOADER ${bikeVersions.previous.bootloader}->${bikeVersions.next.bootloader}`;
    }

    if (history.eventName === 'bike.geoloc.updated') {
      evt.title = `Bike location consent has been updated, it's now : ${
        history.event['current_value'] == true ? 'ACCEPTED' : 'DECLINED'
      }`;
      evt.shape = 'map-marker';
      evt.status = history.event['current_value'] == true ? Status.SUCCESS : Status.DANGER;
      evt.description = `updated by <a href="/users/view/${history.event['customerId']}">${history.event['customerMail']}</a>`;
    }
    return evt;
  }
}

function toVersionS(version: any) {
  if (version == null) {
    return '-';
  }
  if (version['majorVersion']) {
    return `${version['majorVersion']}.${version['minorVersion']}.${version['incrementalVersion']}`;
  } else {
    return `${version['major']}.${version['minor']}.${version['patch']}`;
  }
}

function toBikeVersions(bikeVersionChange: any) {
  return {
    next: {
      app: toVersionS(bikeVersionChange['nextAppVersion']),
      ble: toVersionS(bikeVersionChange['nextBluetoothVersion']),
      img: toVersionS(bikeVersionChange['nextImageVersion']),
      bootloader: toVersionS(bikeVersionChange['nextBootloaderVersion']),
    },
    previous: {
      app: toVersionS(bikeVersionChange['previousAppVersion']),
      ble: toVersionS(bikeVersionChange['previousBluetoothVersion']),
      img: toVersionS(bikeVersionChange['previousImageVersion']),
      bootloader: toVersionS(bikeVersionChange['previousBootloaderVersion']),
    },
  };
}

function getMapsLinkFromLocation(location): string {
  return `<a href="https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lon}" target="_blank" rel="noopener noreferrer">${location.lat},${location.lon}</a>`;
}
