import { Component, OnInit, OnDestroy } from '@angular/core';
import { HistoryService } from '../../services/history.service';
import { ClrDatagridStateInterface } from '@clr/angular';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SmsLog } from '../../models/sms-log.model';
import { MailLog } from '../../models/mail-log.model';
import { PushLog } from '../../models/push-log.model';
import { DomSanitizer } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-list-notification-history',
  templateUrl: './list-notification-history.component.html',
  styleUrls: ['./list-notification-history.component.scss'],
})
export class ListNotificationHistoryComponent implements OnInit, OnDestroy {
  public notifications: (SmsLog | MailLog | PushLog)[];
  public total: number;
  public loading = true;
  public type: string = null;
  public queries: { [key: string]: string };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private historyService: HistoryService,
    private sanitizer: DomSanitizer
  ) {}

  public ngOnInit(): void {
    console.log('ngOnInit');

    this.route.params.pipe(untilDestroyed(this)).subscribe((params) => (this.type = params.type));

    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((queries) => {
      console.log('query params:', queries);
      this.queries = queries;
    });

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event) => event instanceof NavigationStart)
      )
      .subscribe((event) => {
        console.log('event', event);
        this.loading = true;
        this.notifications = null;
        this.total = null;
      });
  }

  public ngOnDestroy(): void {}

  public refresh(state: ClrDatagridStateInterface): void {
    console.log('table state:', state);

    this.loading = true;

    // We convert the filters from an array to a map,
    // because that's what our backend-calling service is expecting
    const filters: { [prop: string]: any } = {};

    if (state.filters) {
      for (const stateFilter of state.filters) {
        const { property, value } = stateFilter as { property: string; value: string };
        filters[property] = value;
      }
    }

    console.log('table filters:', filters);

    const params = { ...filters, ...this.queries };

    params.page = state.page.current - 1;
    params.size = state.page.size;

    this.historyService
      .getAllHistory(this.type, params)
      .pipe(untilDestroyed(this))
      .subscribe((resp) => {
        const notifications = resp.values();

        notifications.map((item) => {
          const mail = item as MailLog;

          if (mail.raw) {
            mail.safeContent = this.sanitizer.bypassSecurityTrustResourceUrl(
              `data:text/html;base64,${mail.getContentToBase64()}`
            );
          }

          return mail;
        });

        console.log('notifications', notifications);

        this.notifications = notifications;
        this.total = resp.page.totalElements || this.notifications.length;

        this.loading = false;
      });
  }
}
