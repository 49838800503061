import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { getCodes } from 'country-list';

const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });

@Component({
  selector: 'address-form',
  templateUrl: './address-form.component.html',
})
export class AddressFormComponent implements OnInit {
  @Input()
  addressFormGroup: FormGroup;

  constructor() {}

  ngOnInit(): void {}

  countryList = getCodes().map((code) => regionNamesInEnglish.of(code));
}
