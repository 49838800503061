export class BikeStatusHistory {
  id: string;
  source: string;
  hasExternalBattery: boolean;
  externalBatteryLevel: number;
  externalBatteryVoltage: number;
  externalBatteryRIntern: number;
  externalBatteryStateOfHealth: number;
  internalBatteryLevel: number;
  internalBatteryVoltage: number;
  internalBatteryRIntern: number;
  internalBatteryStateOfHealth: number;
  updatedAt: Date;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.source = data.source;
      this.hasExternalBattery = data.hasExternalBattery;
      this.externalBatteryLevel = data.externalBatteryLevel;
      this.internalBatteryLevel = data.internalBatteryLevel;
      this.externalBatteryVoltage = data.externalBatteryVoltage;
      this.internalBatteryVoltage = data.internalBatteryVoltage;
      this.internalBatteryRIntern = data.internalBatteryRIntern;
      this.updatedAt = new Date(data.updatedAt);
    }
  }

  public toBatteryPoint(type: string): { x: Date; y: number } {
    return {
      x: this.updatedAt,
      y: type == 'external' ? this.externalBatteryLevel : this.internalBatteryLevel,
    };
  }

  public toBatteryVol(type: string): { x: Date; y: number } {
    return {
      x: this.updatedAt,
      y: type == 'external' ? this.externalBatteryVoltage : this.internalBatteryVoltage,
    };
  }

  public toBatteryRIntern(type: string): { x: Date; y: number } {
    return {
      x: this.updatedAt,
      y: type == 'external' ? this.externalBatteryRIntern : this.internalBatteryRIntern,
    };
  }
}
