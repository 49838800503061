<table class="table table-vertical">
  <tbody>
    <tr>
      <th i18n>ID</th>
      <td>{{ shop.id }}</td>
    </tr>
    <tr>
      <th i18n>Name</th>
      <td>{{ shop.name }}</td>
    </tr>
    <tr>
      <th i18n>Email</th>
      <td>
        <a [href]="'mailto: ' + shop.email">{{ shop.email }}</a>
      </td>
    </tr>
    <tr>
      <th i18n>Phone number</th>
      <td>
        <a [href]="'tel: ' + shop.phoneNumber">{{ shop.phoneNumber }}</a>
      </td>
    </tr>
    <tr>
      <th i18n>Address</th>
      <td>
        <location [mapsQuery]="getFullAddress()"><address-view [address]="shop.address"></address-view></location>
      </td>
    </tr>
    <tr>
      <th i18n>Classification</th>
      <td>
        <span class="label">
          <cds-icon style="margin-right: 5px" shape="star" role="img"></cds-icon>
          <span>{{ shop.classification }}&nbsp;</span>
        </span>
      </td>
    </tr>

    <tr>
      <th i18n>Manager</th>
      <td>{{ shop.managerFullName ? shop.managerFullName : '-' }}</td>
    </tr>
  </tbody>
</table>
