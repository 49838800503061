import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BikeModelService } from '../../../services/bike-model.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { flatMap } from 'rxjs/operators';
import { BikeModel } from '../../../models/bike-model.model';

@UntilDestroy()
@Component({
  selector: 'app-view-model',
  templateUrl: './view-model.component.html',
  styleUrls: ['./view-model.component.scss'],
})
export class ViewModelComponent implements OnInit, OnDestroy {
  public model: BikeModel;

  constructor(private router: Router, private route: ActivatedRoute, private bikeModelService: BikeModelService) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        untilDestroyed(this),
        flatMap((params) => this.bikeModelService.getById(params.id))
      )
      .subscribe((model) => {
        console.log('model:', model);
        this.model = model;
      });
  }

  ngOnDestroy(): void {}
}
