import { AbstractControl, ValidatorFn } from '@angular/forms';

export const segmentPattern = '^[a-z0-9-]+$';

export function segmentsValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    for (let i = 0; i < control.value.length; i++) {
      const segment = control.value[i];
      if (!segment.name.match(segmentPattern)) {
        return { wrongPattern: control.value };
      }
    }
    return null;
  };
}
