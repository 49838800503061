export class NextVersion {
  nextVersion: string;
  filename: string;
  softwarePackageType: string;
  isUpToDate: boolean;

  constructor(data?: any) {
    if (data) {
      this.nextVersion = data.nextVersion;
      this.softwarePackageType = data.softwarePackageType;
      this.filename = data.filename;
      this.isUpToDate = data.isUpToDate;
    }
  }
}
