<h1 i18n>Dashboard</h1>

<div class="clr-row">
  <div class="clr-col-lg-3 clr-col-md-4 clr-col-12">
    <div class="card">
      <div class="card-header" i18n>Version stats (top 5)</div>
      <div class="card-block">
        <div class="card-table">
          <table class="table">
            <thead>
              <tr>
                <th i18n>Version</th>
                <th i18n>Persent</th>
                <th i18n>Number of bike</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let stat of versionStats">
                <td>{{ stat.version }}</td>
                <td>{{ stat.percent }}%</td>
                <td>{{ stat.count }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-sm btn-link" (click)="showAllVersions()">See All</button>
      </div>
    </div>
  </div>

  <div class="clr-col-lg-3 clr-col-md-4 clr-col-12">
    <div class="card">
      <div class="card-header" i18n>Bike stats</div>
      <div class="card-block">
        <div class="card-table">
          <table class="table table-vertical">
            <tbody>
              <tr>
                <th i18n>Installed</th>
                <td>{{ bikeStats?.installed }}</td>
              </tr>
              <tr>
                <th i18n>Not installed</th>
                <td>{{ bikeStats?.provisioned }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="clr-row">
  <div class="clr-col-lg-8 clr-col-md-4 clr-col-12">
    <div class="card">
      <div class="card-header" i18n>Ride stats (last 5 weeks)</div>
      <div class="card-block">
        <div class="card-table">
          <clr-datagrid (clrDgRefresh)="refresh()">
            <clr-dg-column i18n>Date</clr-dg-column>
            <clr-dg-column i18n>Duration</clr-dg-column>
            <clr-dg-column i18n>Distance</clr-dg-column>
            <clr-dg-column i18n>Speed</clr-dg-column>
            <clr-dg-column i18n>Users</clr-dg-column>
            <clr-dg-column i18n>Rides</clr-dg-column>
            <clr-dg-column i18n>Rides/u</clr-dg-column>
            <clr-dg-column i18n>Distance/r</clr-dg-column>
            <clr-dg-column i18n>Distance/u</clr-dg-column>
            <clr-dg-column i18n>Duration/r</clr-dg-column>
            <clr-dg-column i18n>Duration/u</clr-dg-column>

            <clr-dg-row *ngFor="let stat of rideStats">
              <clr-dg-cell>{{ stat.date | date: 'shortDate' }}</clr-dg-cell>
              <clr-dg-cell>{{ stat.duration | duration }}</clr-dg-cell>
              <clr-dg-cell>{{ stat.distance | meter }}</clr-dg-cell>
              <clr-dg-cell>{{ stat.speed | number: '1.0-1' }} km/h</clr-dg-cell>
              <clr-dg-cell>{{ stat.users }}</clr-dg-cell>
              <clr-dg-cell>{{ stat.rides }}</clr-dg-cell>
              <clr-dg-cell>{{ stat.avgRideByUser | number: '1.2' }}</clr-dg-cell>
              <clr-dg-cell>{{ stat.avgDistanceByRide | meter }}</clr-dg-cell>
              <clr-dg-cell>{{ stat.avgDistanceByUser | meter }}</clr-dg-cell>
              <clr-dg-cell>{{ stat.avgDurationByRide | duration }}</clr-dg-cell>
              <clr-dg-cell>{{ stat.avgDurationByUser | duration }}</clr-dg-cell>
            </clr-dg-row>
          </clr-datagrid>
        </div>
      </div>
    </div>
  </div>
</div>
