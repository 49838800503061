import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { User } from '../../models/user.model';
import { Bike } from '../../../bike/models/bike.model';

@UntilDestroy()
@Component({
  selector: 'user-bikes-details',
  templateUrl: './user-bikes-details.component.html',
  styleUrls: ['./user-bikes-details.component.scss'],
})
export class UserBikesDetailsComponent implements OnInit, OnDestroy {
  @Input()
  public bikes: Bike[];

  @Input()
  public userId: string;

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  public getRoleOfBike(bike: Bike): string {
    return bike.customerBikes
      .filter((customerBike) => {
        if (!customerBike.customer) {
          return false;
        }

        return customerBike.customer.id === this.userId;
      })
      .map((customerBike) => customerBike.role)
      .pop();
  }

  public getNameOfBike(bike: Bike): string {
    return bike.customerBikes
      .filter((customerBike) => {
        if (!customerBike.customer) {
          return false;
        }

        return customerBike.customer.id === this.userId;
      })
      .map((customerBike) => customerBike.bikeName)
      .pop();
  }
}
