import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../../models/user.model';
import { CustomerService } from '../../../services/customer.service';
import { RideService } from '../../../services/ride.service';
import { CustomerTimelineService } from '../../../services/customer-timeline.service';
import { FiltersUserEvent, TimelineEvent } from '../../../models/timeline-event.model';
import { CguValidation } from '../../../models/cgu-validation.model';

@UntilDestroy()
@Component({
  selector: 'app-view-user',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit, OnDestroy {
  public user: User;
  public events: TimelineEvent[] = [];
  public filteredEvents: TimelineEvent[] = [];

  public filters: FiltersUserEvent = {
    lastRides: false,
    freeSearch: '',
    lastConnexions: false,
    bikeHistory: false,
    cgu: false,
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private rideService: RideService,
    private customerTimelineService: CustomerTimelineService,
  ) {}

  ngOnInit(): void {
    this.route.parent.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.user = data.user;
      const events = this.customerTimelineService.getEventsFromUser(this.user);
      this.updateFilteredEvents(events);
      this.fetchBikeCustomers(this.user);
      this.fetchRidesOfUser(this.user);
      this.fetchUserSettings(this.user);
      this.fetchUserKnownPhones(this.user);
      this.fetchBikesOfUser(this.user);
      this.fetchCguValidation(this.user);
    });
  }

  ngOnDestroy(): void {}

  public updateFilteredEvents(events: TimelineEvent[]): void {
    this.events = this.customerTimelineService.updateAndSortEvents(this.events, events);
    this.filtersChanged();
  }

  public filtersChanged(): void {
    this.filteredEvents = this.events.filter((userEvent) => {
      if (userEvent.title.indexOf(this.filters.freeSearch) < 0) {
        return false;
      }
      if (this.filters.lastRides === false && userEvent.type === 'ride') {
        return false;
      }
      if (this.filters.lastConnexions === false && userEvent.type === 'connection') {
        return false;
      }
      if (this.filters.bikeHistory === false && userEvent.type === 'bikeHistory') {
        return false;
      }

      if (this.filters.cgu === false && userEvent.type === 'cgu') {
        return false;
      }
      return true;
    });
  }

  public fetchBikeCustomers(user: User): void {
    this.customerService
      .getAllCustomerBikes(user.id)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        const events = this.customerTimelineService.getEventsFromCustomerBikes(result.values());
        this.updateFilteredEvents(events);
      });
  }

  private fetchRidesOfUser(user: User): void {
    this.rideService
      .getLast10ByUserId(user.id)
      .pipe(untilDestroyed(this))
      .subscribe((resp) => {
        const rides = resp.values();
        const rideEvents = this.customerTimelineService.getEventsFromRides(rides);
        this.updateFilteredEvents(rideEvents);
      });
  }

  private fetchUserSettings(user: User): void {
    this.customerService
      .getSettings(user.id)
      .pipe(untilDestroyed(this))
      .subscribe((resp) => {
        const settingsEvents = this.customerTimelineService.getEventsFromSettings(resp);
        this.updateFilteredEvents(settingsEvents);
      });
  }

  private fetchUserKnownPhones(user: User): void {
    this.customerService
      .getKnownPhones(user.id)
      .pipe(untilDestroyed(this))
      .subscribe((resp) => {
        const userKnownPhonesEvents = this.customerTimelineService.getEventsFromKnownDevices(resp);
        this.updateFilteredEvents(userKnownPhonesEvents);
      });
  }

  private fetchBikesOfUser(user: User): void {
    this.customerService
      .getUserBikes(user.id)
      .pipe(untilDestroyed(this))
      .subscribe((resp) => {
        const bikes = resp.values();
        const events = this.customerTimelineService.getEventsFromBikes(bikes, user.id);
        this.updateFilteredEvents(events);
      });
  }
  private fetchCguValidation(user: User): void {
    this.customerService
      .getCguHistory(user.id)
      .pipe(untilDestroyed(this))
      .subscribe((resp) => {
        const cgus: CguValidation[] = resp;
        const events = this.customerTimelineService.getEventsFromCgus(cgus, user.id);
        this.updateFilteredEvents(events);
      });
  }
}
