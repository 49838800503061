import { Component, Input, OnInit } from '@angular/core';
import { Shop } from '../../models/shop.model';

@Component({
  selector: 'shop-link',
  templateUrl: './shop-link.component.html',
})
export class ShopLinkComponent implements OnInit {
  @Input()
  shop: Shop;

  constructor() {}

  ngOnInit(): void {}
}
