<div>
  <h3 i18n>Log Files</h3>

  <div *ngIf="logfiles.length == 0">No data</div>
  <table class="table" *ngIf="logfiles.length > 0">
    <thead>
      <tr>
        <th i18n>Type</th>
        <th i18n>Name</th>
        <th i18n>Date</th>
        <th i18n>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let log of logfiles">
        <td>{{ log.type }}</td>
        <td>
          <a href="{{ log.url }}">{{ log.name }}</a>
        </td>
        <td>{{ log.createdAt | date : 'dd/MM/yy HH:mm:ss' }}</td>
        <td>
          <span *ngIf="log.status === 'KO'" class="label label-danger" i18n>KO</span>
          <span *ngIf="log.status === 'OK'" class="label label-success" i18n>OK</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
