import { LinkResource } from './link-resource.model';

export class ResponseEntities<T> extends LinkResource {
  // tslint:disable-next-line:variable-name
  _embedded: Embedded<T>;
  page?: Page;

  constructor(TargetType: new (data?: any) => T, responseEntitiesData?: any) {
    super(responseEntitiesData);
    if (responseEntitiesData) {
      this._embedded = new Embedded<T>(TargetType, responseEntitiesData._embedded);
      this.page = new Page(responseEntitiesData.page);
    }
  }

  public values(): T[] {
    return this._embedded[Object.keys(this._embedded)[0]];
  }
}

export class ResponseCursorEntities<T> {
  // tslint:disable-next-line:variable-name
  results: T[];
  paging?: CursorPage;

  constructor(TargetType: new (data?: any) => T, responseEntitiesData?: any) {
    if (responseEntitiesData) {
      this.results = responseEntitiesData.results.map((item) => new TargetType(item));
      this.paging = new CursorPage(responseEntitiesData.paging);
    }
  }
}

class Embedded<T> {
  [key: string]: T[];

  constructor(TargetType: new (data?: any) => T, embeddedData?: any) {
    if (embeddedData) {
      for (const key of Object.keys(embeddedData)) {
        this[key] = [];
        for (const item of embeddedData[key]) {
          this[key].push(new TargetType(item));
        }
      }
    }
  }
}

class Page {
  size: number;
  totalElements: number;
  totalPages: number;
  number: number;

  constructor(pageData?: any) {
    if (pageData) {
      this.size = pageData.size;
      this.totalElements = pageData.totalElements;
      this.totalPages = pageData.totalPages;
      this.number = pageData.number;
    }
  }
}

class CursorPage {
  size: number;
  totalElements: number;
  actualPageCursor: string;
  nextPageCursor: string;

  constructor(pageData?: any) {
    if (pageData) {
      this.size = pageData.size;
      this.totalElements = pageData.totalElements;
      this.actualPageCursor = pageData.actualPageCursor;
      this.nextPageCursor = pageData.nextPageCursor;
    }
  }
}
