import { DeleteTrace, LinkResource, UpdateTrace } from 'src/app/modules/api/models';
import { User } from 'src/app/pages/user/models/user.model';
import { AngellCare } from '../../services/models/angell-care.model';
import { BikeModel } from './bike-model.model';
import { BikeOption } from './bike-option.model';
import { Segment } from './segment.model';

export class Bike extends LinkResource {
  id: string;
  mode: string;
  hasBattery: boolean;
  carMode: boolean;
  installed: boolean;
  isStolen: boolean;
  theftDate: Date;
  batteryLevel: number;
  internalBatteryLevel: number;
  externalBatteryLevel: number;
  assistanceLevel: number;
  bikePhoneNumber: string;
  appVersion: Version;
  bootloaderVersion?: Version;
  bluetoothVersion: Version;
  imageVersion: Version;
  bmsVersion: Version;
  bleSdVersion: Version;
  controllerVersion: Version;
  rearlightVersion: Version;
  bikeProvision: BikeProvision;
  customerBikes: CustomerBike[];
  owner: CustomerBike;
  location?: Location;

  segments: Segment[];

  isTheftOnGoing: boolean;
  isFallOnGoing: boolean;
  lastFallOnGoingDate: Date;
  lastTheftOnGoingDate: Date;
  lastFallCanceledDate: Date;
  lastTheftCanceledDate: Date;

  serviceAngellCare: AngellCare;

  bikeOrder: BikeOrder;
  marketZoneName: string;

  locked: boolean;
  siren: boolean;

  isConnected: boolean;
  lastConnectedDate: Date;

  isAfterSalesMode: boolean;
  devModeEnabled: boolean;

  updateTrace?: UpdateTrace;

  constructor(data?: any) {
    super(data?._links);

    if (data) {
      this.id = data.id;
      this.mode = data.mode;
      this.hasBattery = data.hasBattery;
      this.carMode = data.carMode;
      this.internalBatteryLevel = data.internalBatteryLevel;
      this.externalBatteryLevel = data.externalBatteryLevel;
      this.installed = data.installed;
      this.isStolen = data.isStolen;
      this.theftDate = data.theftDate != null ? new Date(data.theftDate) : null;
      this.batteryLevel = data.batteryLevel;
      this.bikePhoneNumber = data.bikePhoneNumber;
      this.assistanceLevel = data.assistanceLevel;
      this.bikeProvision = new BikeProvision(data.bikeProvision);
      this.location = new Location(data.location);
      this.appVersion = new Version(data.appVersion);
      this.bluetoothVersion = new Version(data.bluetoothVersion);
      this.imageVersion = new Version(data.imageVersion);

      this.bmsVersion = new Version(data.bmsVersion);
      this.bleSdVersion = new Version(data.bleSdVersion);
      this.controllerVersion = new Version(data.controllerVersion);
      this.rearlightVersion = new Version(data.rearlightVersion);

      this.segments = data.segments ? data.segments.map((segment) => new Segment(segment)) : [];

      this.isTheftOnGoing = data.isTheftOnGoing;
      this.isFallOnGoing = data.isFallOnGoing;
      this.lastFallOnGoingDate = data.lastFallOnGoingDate;
      this.lastTheftOnGoingDate = data.lastTheftOnGoingDate;
      this.lastFallCanceledDate = data.lastFallCanceledDate;
      this.lastTheftCanceledDate = data.lastTheftCanceledDate;

      this.locked = data.locked;
      this.siren = data.siren;

      this.isConnected = data.isConnected;
      this.lastConnectedDate = data.lastConnectedDate;

      this.isAfterSalesMode = data.isAfterSalesMode;

      this.updateTrace = new UpdateTrace(data.updateTrace);
      this.owner = data.owner;
      if (data.customerBikes) {
        this.customerBikes = data.customerBikes.map((customerBike) => new CustomerBike(customerBike));
      }

      if (data.bootloaderVersion) {
        this.bootloaderVersion = new Version(data.bootloaderVersion);
      }

      if (data.serviceAngellCare) {
        this.serviceAngellCare = new AngellCare(data.serviceAngellCare);
      }

      if (data.bikeOrder) {
        this.bikeOrder = new BikeOrder(data.bikeOrder);
      }
      this.marketZoneName = data.marketZoneName;
      this.devModeEnabled = data.devModeEnabled;
    }
  }

  public isV2(): boolean {
    return this.appVersion.majorVersion === 2;
  }
}

export class BikeOrder {
  id: string;
  orderRef: string;
  deliveryAt: Date;
  orderAt: Date;
  shipmentAt: Date;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.orderRef = data.orderRef;
      this.deliveryAt = data.deliveryAt;
      this.orderAt = data.orderAt;
      this.shipmentAt = data.shipmentAt;
    }
  }
}

export class CustomerBike {
  id: string;
  bikeName: string;
  email: string;
  role: string;
  customer: User;
  disableAt: Date;
  isDisabled: boolean;
  updateTrace?: UpdateTrace;
  deleteTrace?: DeleteTrace;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.bikeName = data.bikeName;
      this.email = data.email;
      this.role = data.role;
      this.isDisabled = data.isDisabled;
      this.disableAt = data.disableAt;

      if (data.customer) {
        this.customer = new User(data.customer);
      }
      this.updateTrace = new UpdateTrace(data.updateTrace);
      this.deleteTrace = new DeleteTrace(data.deleteTrace);
    }
  }
}

export class CustomerBikeAutonomy {
  id: string;
  autonomyEco: number;
  autonomyDry: number;
  autonomyFast: number;
  updateTrace: UpdateTrace;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.autonomyEco = data.autonomyEco;
      this.autonomyDry = data.autonomyDry;
      this.autonomyFast = data.autonomyFast;
      this.updateTrace = new UpdateTrace(data.updateTrace);
    }
  }
}

class BikeProvision extends LinkResource {
  id: string;
  mac: string;
  color: string;
  iccid: string;
  provisioned: boolean;
  testResults: string;
  motorControllerVersion: string;
  motorControllerSerialNumber: string;
  batterySerialNumber: string;
  bikeFrameSerialNumber: string;
  topSerialNumber: string;
  bottomSerialNumber: string;
  updateTrace: UpdateTrace;
  model: BikeModel;
  options: BikeOption[];
  antitheftCode: string;
  motorModel: string;

  constructor(data?: any) {
    super(data?._links);

    if (data) {
      this.id = data.id;
      this.iccid = data.iccid;
      this.mac = data.mac;
      this.color = data.color;
      this.provisioned = data.provisioned;
      this.testResults = data.testResults;

      this.motorControllerVersion = data.motorControllerVersion;
      this.motorControllerSerialNumber = data.motorControllerSerialNumber;
      this.batterySerialNumber = data.batterySerialNumber;
      this.bikeFrameSerialNumber = data.bikeFrameSerialNumber;
      this.topSerialNumber = data.topSerialNumber;
      this.bottomSerialNumber = data.bottomSerialNumber;
      this.model = new BikeModel(data.model);
      this.antitheftCode = data.antitheftCode;
      this.motorModel = data.motorModel;
      if (data.options) {
        this.options = data.options.map((option) => new BikeOption(option));
      }
      this.updateTrace = new UpdateTrace(data.updateTrace);
    }
  }
}

export class Location {
  lat: number;
  lon: number;
  hdop: number;

  constructor(data?: any) {
    if (data) {
      this.lat = data.lat;
      this.lon = data.lon;
      this.hdop = data.hdop;
    }
  }
}

export class Version {
  majorVersion: number;
  minorVersion: number;
  incrementalVersion: number;

  public toString(): string {
    return `${this.majorVersion || 0}.${this.minorVersion || 0}.${this.incrementalVersion || 0}`;
  }

  constructor(data?: any) {
    if (data) {
      this.majorVersion = data.majorVersion;
      this.minorVersion = data.minorVersion;
      this.incrementalVersion = data.incrementalVersion;
    }
  }
}

export interface BikeStats {
  installed: number;
  provisioned: number;
}

export class MaintenanceOperation {
  id: string;
  startedOn: Date;
  startedBy: string;
  endedOn: Date;
  endedBy: string;
  type: string;
  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.startedOn = new Date(data.startedOn);
      this.startedBy = data.startedBy;
      this.endedOn = data.endedOn != null ? new Date(data.endedOn) : null;
      this.endedBy = data.endedBy;
      this.type = data.type;
    }
  }
}
