<h1>
  <cds-icon style="margin-right: 5px" shape="store" role="img" size="md"></cds-icon>
  Shops
</h1>
<clr-accordion class="filters-accordion">
  <clr-accordion-panel [(clrAccordionPanelOpen)]="filtersOpened" class="filters-accordion-panel">
    <clr-accordion-title>Filters</clr-accordion-title>
    <clr-accordion-content *clrIfExpanded>
      <form
        clrForm
        [formGroup]="searchForm"
        (ngSubmit)="refresh(pagination)"
        [clrLayout]="'horizontal'"
        class="search-form clr-form-compact"
      >
        <div class="clr-row search-form-row">
          <div class="clr-col-xl-6 clr-col-sm-12">
            <clr-input-container>
              <label i18n>Search</label>
              <input
                size="40"
                class="free-search"
                clrInput
                type="text"
                placeholder="id, name, phone, mail, address, ..."
                formControlName="searchTerm"
                autofocus
              />
            </clr-input-container>
          </div>
          <div class="clr-col-xl-6 clr-col-12">
            <clr-select-container>
              <label>Classification</label>
              <select clrSelect formControlName="classification">
                <option [value]="'any'"></option>
                <option [value]="'GOLD'">GOLD</option>
                <option [value]="'SILVER'">SILVER</option>
                <option [value]="'BRONZE'">BRONZE</option>
              </select>
            </clr-select-container>
          </div>
          <!--
          <div class="clr-col-xl-3 clr-col-sm-12">
            <clr-select-container>
              <label class="clr-col-xl-3 clr-col-md-2">Deleted?</label>
              <select class="clr-col-xl-9 clr-col-md-10" clrSelect formControlName="deleted">
                <option [value]="''"></option>
                <option [value]="true">Yes</option>
                <option [value]="false">No</option>
              </select>
            </clr-select-container>
          </div>-->
        </div>
        <div class="clr-row">
          <div class="clr-col-12">
            <button class="btn btn-primary search-btn" type="submit" i18n>
              <cds-icon shape="search"></cds-icon>
            </button>
          </div>
        </div>
      </form>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>

<clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
  <clr-dg-action-bar *aclCheck="'actions_pro'">
    <button type="button" class="btn btn-sm btn-secondary" i18n (click)="onCreate()">
      <cds-icon shape="plus"></cds-icon>
      Add
    </button>
  </clr-dg-action-bar>

  <clr-dg-column i18n>Name</clr-dg-column>
  <clr-dg-column i18n>Phone</clr-dg-column>
  <clr-dg-column i18n>Email</clr-dg-column>
  <clr-dg-column i18n>Manager</clr-dg-column>
  <clr-dg-column i18n>Address</clr-dg-column>
  <clr-dg-column i18n>Classification</clr-dg-column>

  <clr-dg-row *ngFor="let shop of shops">
    <clr-dg-cell>
      <shop-link [shop]="shop"></shop-link>
    </clr-dg-cell>
    <clr-dg-cell>{{ shop.phoneNumber }}</clr-dg-cell>
    <clr-dg-cell>{{ shop.email }}</clr-dg-cell>
    <clr-dg-cell>{{ shop.managerFullName }}</clr-dg-cell>
    <clr-dg-cell>
      <location [lat]="shop.lat" [lon]="shop.lon" [displayName]="shop.fullAddress"></location>
    </clr-dg-cell>
    <clr-dg-cell>
      <span class="label">
        <cds-icon style="margin-right: 5px" shape="star" role="img"></cds-icon>
        <span>{{ shop.classification }}&nbsp;</span>
      </span>
    </clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer i18n>
    {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} shops
    <clr-dg-pagination #pagination [clrDgTotalItems]="total" [clrDgPageSize]="15"></clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
