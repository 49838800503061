import { LinkResource } from 'src/app/modules/api/models';

export class BikeOption extends LinkResource {
  id: string;
  name: string;
  technicalName: string;
  description: string;

  constructor(data?: any) {
    super(data?._links);

    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.technicalName = data.technicalName;
      this.description = data.description;
    }
  }
}
