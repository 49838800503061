<table class="table table-vertical">
  <tbody>
    <tr>
      <th i18n>ID</th>
      <td>{{ user.id }}</td>
    </tr>
    <tr>
      <th i18n>Email</th>
      <td>
        <a [href]="'mailto: ' + user.email">{{ user.email }}</a>
      </td>
    </tr>
    <tr>
      <th i18n>Role</th>
      <td>{{ user.role }}</td>
    </tr>
    <tr>
      <th i18n>Locale</th>
      <td>{{ user.locale }}</td>
    </tr>
    <tr>
      <th i18n>Phone number</th>
      <td>
        <a [href]="'tel: ' + user.phoneNumber">{{ user.phoneNumber }}</a>
      </td>
    </tr>
    <tr>
      <th i18n>First name</th>
      <td>{{ user.firstName }}</td>
    </tr>
    <tr>
      <th i18n>Last name</th>
      <td>{{ user.lastName }}</td>
    </tr>
    <tr>
      <th i18n>Activation key</th>
      <td>{{ user.activationKey }}</td>
    </tr>
  </tbody>
</table>
