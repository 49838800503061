<h1 i18n>
  <cds-icon style="margin-right: 5px" shape="store" role="img" size="md"></cds-icon>{{ shop.name }}
  <button *aclCheck="'actions_pro'" class="btn btn-outline btn-sm" [routerLink]="['/pro', 'shops', 'form', shop?.id]">
    <cds-icon shape="edit"></cds-icon>
    Edit
  </button>
  <button *aclCheck="'actions_pro'" class="btn btn-outline-danger btn-sm" (appConfirm)="onDelete(shop.id)">
    <cds-icon shape="trash"></cds-icon>
    Delete
  </button>
</h1>

<ng-container *ngIf="shop">
  <div class="clr-row">
    <div class="clr-col-lg-4 clr-col-12">
      <h3>Details</h3>
      <shop-details [shop]="shop"></shop-details>
    </div>
    <div class="clr-col-lg-8 clr-col-12">
      <h3 class="list-pro-users">Shop retailers</h3>
      <list-pro-users [initialShop]="shop"></list-pro-users>
    </div>
  </div>
</ng-container>
