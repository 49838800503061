import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BikeService } from '../../../../services/bike.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Bike } from '../../../../models/bike.model';
import { Chart, ChartConfiguration, ChartOptions } from 'chart.js';
import { BikeStatusHistory } from '../../../../models/bike-status-history.model';
import { BaseChartDirective } from 'ng2-charts';
import 'chartjs-adapter-moment';
import { ActivatedRoute, Router } from '@angular/router';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import { debounce } from 'lodash';
import { BikeHistory } from '../../../../models/bike-history.model';
import { BatteryConnection } from '../../../../models/battery.model';
import { BatteryService } from '../../../../services/battery.service';

Chart.register(zoomPlugin, annotationPlugin);

let timer;

@UntilDestroy()
@Component({
  selector: 'app-view-bike',
  templateUrl: './battery.component.html',
  styleUrls: ['./battery.component.scss'],
})
export class BatteryComponent implements OnInit, OnDestroy {
  bike?: Bike;

  initialLoading: boolean = false;
  now: Date = new Date();

  startDate: String;
  endDate: String;
  startTime: String;
  endTime: String;

  constructor(
    private bikeService: BikeService,
    private route: ActivatedRoute,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ref: ChangeDetectorRef,
    private batteryService: BatteryService,
  ) {
    this.getZoomMinMax = debounce(this.getZoomMinMax, 300);
  }

  public events: BikeHistory[] = [];

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const from = params.from
        ? new Date(parseInt(params.from, 10))
        : new Date(this.now.getTime() - 7 * 24 * 60 * 60 * 1000);
      const to = params.to ? new Date(parseInt(params.to, 10)) : this.now;

      this.initDatesInputs(from, to);

      if (!this.initialLoading) {
        this.route.parent.data.pipe(untilDestroyed(this)).subscribe((data) => {
          this.initialLoading = true;
          this.bike = data.bike;
          this.fetchBatteryHistoryById(this.bike.id, from, to);
          this.fetchBatteryConnections(this.bike.id);
        });

        this.bikeService
          .getEvents(this.bike.id, to, 0, null, {
            eventType: ['ride.created', 'update'],
          })
          .pipe(untilDestroyed(this))
          .subscribe((response) => {
            this.events = response.values() || [];
            this.displayRidesAndUpdates(this.events, from, to);
          });
      }
    });
  }

  private displayRidesAndUpdates(events: BikeHistory[], from: Date, to: Date) {
    this.displayRides(events, from, to);
    this.displayUpdates(events, from, to);
    this.chart.render();
  }

  private displayUpdates(events: BikeHistory[], from: Date, to: Date) {
    const updates = events.filter((e) => e.eventName == 'update' && e.timestamp > from && e.timestamp < to);
    updates.forEach((u) => {
      this.chart.options.plugins.annotation.annotations[`update${u.timestamp}`] = {
        type: 'line',
        xMin: new Date(u.timestamp),
        xMax: new Date(u.timestamp),
        borderWidth: 1,
        label: {
          content: 'Update',
          display: true,
          rotation: 270,
        },
      };
    });
  }

  private displayRides(events: BikeHistory[], from: Date, to: Date) {
    let rides = events.filter((e) => e.eventName == 'ride.created');
    if (rides.length == 0) {
      return;
    }

    rides = rides.filter(
      (e) =>
        (new Date(e.event['startTime']) > from && new Date(e.event['startTime']) < to) ||
        (new Date(e.event['endTime']) > from && new Date(e.event['endTime']) < to),
    );
    rides.forEach((r) => {
      this.chart.options.plugins.annotation.annotations[`ride${r.event['rideId']}`] = {
        type: 'box',
        xMin: new Date(r.event['startTime']),
        xMax: new Date(r.event['endTime']),
        backgroundColor: 'rgba(252,160,76,0.10)',
        borderColor: 'rgba(252,160,76,0.4)',
        label: {
          content: 'riding...',
          display: false,
          position: {
            y: 'end',
          },
        },
      };
    });
  }

  private initDatesInputs(from: Date, to: Date) {
    this.startTime = this.extractTimeFromString(from);
    this.startDate = this.extractDateFromString(from);

    this.endTime = this.extractTimeFromString(to);
    this.endDate = this.extractDateFromString(to);
  }

  formatQueryParams() {
    return {
      from: this.getDateFromInputs(this.startDate, this.startTime).getTime(),
      to: this.getDateFromInputs(this.endDate, this.endTime).getTime(),
    };
  }

  setStartTime(evt) {
    this.startTime = evt.target.value;
    this.fetchBatteryHistory();
  }

  setEndTime(evt) {
    this.endTime = evt.target.value;
    this.fetchBatteryHistory();
  }

  setStartDate(evt) {
    this.startDate = evt.target.value;
    this.fetchBatteryHistory();
  }

  setEndDate(evt) {
    this.endDate = evt.target.value;
    this.fetchBatteryHistory();
  }

  extractTimeFromString(now: Date) {
    return `${now.getHours() < 10 ? '0' : ''}${now.getHours()}:${now.getMinutes() < 10 ? '0' : ''}${now.getMinutes()}`;
  }

  extractDateFromString(now: Date) {
    return `${now.getFullYear()}-${now.getMonth() < 10 ? '0' : ''}${now.getMonth() + 1}-${
      now.getDate() < 10 ? '0' : ''
    }${now.getDate()}`;
  }

  ngOnDestroy(): void {}

  public bikeStatusHistory: BikeStatusHistory[];

  public fetchBatteryHistory() {
    const from = this.getDateFromInputs(this.startDate, this.startTime);
    const to = this.getDateFromInputs(this.endDate, this.endTime);
    this.displayRidesAndUpdates(this.events, from, to);
    this.fetchBatteryHistoryById(this.bike.id, from, to);
  }

  public fetchBatteryHistoryById(bikeId: string, from: Date, to: Date) {
    this.bikeService
      .getBikeStatusHistory(bikeId, from, to)
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.bikeStatusHistory = response.filter((bsh) => bsh.updatedAt != null);
        this.updateQueryParams();
        this.updateBatteryChart(from, to);
      });
  }

  private updateQueryParams() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.formatQueryParams(),
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  public getDateFromInputs(inputDateValue, inputTimeValue): Date {
    const fromDateParts = inputDateValue.split('-');
    const fromTimeParts = inputTimeValue.split(':');
    return new Date(+fromDateParts[0], +fromDateParts[1] - 1, +fromDateParts[2], +fromTimeParts[0], +fromTimeParts[1]);
  }

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  internalBatteryHidden: boolean = false;
  voltageInternalBatteryHidden: boolean = true;
  rInternBatteryHidden: boolean = true;
  externalBatteryHidden: boolean = true;
  voltageExternalBatteryHidden: boolean = true;

  public updateBatteryChart(from: Date, to: Date) {
    const chartData = this.bikeStatusHistory.map((item) => item.toBatteryPoint('internal'));
    const chartDataExternal = this.bikeStatusHistory.map((item) => item.toBatteryPoint('external'));
    const chartDataVol = this.bikeStatusHistory.map((item) => item.toBatteryVol('internal'));
    const chartDataVolExternal = this.bikeStatusHistory.map((item) => item.toBatteryVol('external'));
    const chartDataRIntern = this.bikeStatusHistory.map((item) => item.toBatteryRIntern('internal'));

    // If data has already been loaded, we get hidden params values from chart
    if (this.lineChartData.datasets.length > 0) {
      if (this.chart.chart.getDatasetMeta(0).hidden !== null) {
        this.internalBatteryHidden = !!this.chart.chart.getDatasetMeta(0).hidden;
      }
      if (this.chart.chart.getDatasetMeta(1).hidden !== null) {
        this.voltageInternalBatteryHidden = !!this.chart.chart.getDatasetMeta(1).hidden;
      }
      if (this.chart.chart.getDatasetMeta(2).hidden !== null) {
        this.rInternBatteryHidden = !!this.chart.chart.getDatasetMeta(2).hidden;
      }
      if (this.chart.chart.getDatasetMeta(3).hidden !== null) {
        this.externalBatteryHidden = !!this.chart.chart.getDatasetMeta(3).hidden;
      }
      if (this.chart.chart.getDatasetMeta(4).hidden !== null) {
        this.voltageExternalBatteryHidden = !!this.chart.chart.getDatasetMeta(4).hidden;
      }
    }

    this.chart.chart.options.scales['x'].min = from.getTime();
    this.chart.chart.options.scales['x'].max = to.getTime();

    this.lineChartData.datasets = [
      {
        data: chartData,
        label: 'Internal Battery Level',
        tension: 0,
        hidden: this.internalBatteryHidden,
        yAxisID: 'yBatteryPercent',
      },
      {
        data: chartDataVol,
        label: 'Voltage Internal Battery',
        tension: 0,
        hidden: this.voltageInternalBatteryHidden,
        yAxisID: 'yIntBatteryVolt',
      },
      {
        data: chartDataRIntern,
        label: 'R Intern Internal Battery',
        tension: 0,
        hidden: this.rInternBatteryHidden,
        yAxisID: 'yRIntern',
      },
      {
        data: chartDataExternal,
        label: 'External Battery Level',
        tension: 0,
        hidden: this.externalBatteryHidden,
        yAxisID: 'yBatteryPercent',
        borderColor: 'hsl(282, 43%, 54%)',
        backgroundColor: 'hsl(282, 45%, 70%)',
        pointBackgroundColor: 'hsl(282, 45%, 70%)',
      },
      {
        data: chartDataVolExternal,
        label: 'Voltage External Battery',
        tension: 0,
        hidden: this.voltageExternalBatteryHidden,
        yAxisID: 'yExtBatteryVolt',
      },
    ];
    this.chart?.update();
  }

  public lineChartData: ChartConfiguration<'line', { x: Date; y: number }[]>['data'] = {
    datasets: [],
  };

  public getZoomMinMax({ chart }) {
    const { min, max } = chart.scales.x;
    clearTimeout(timer);
    timer = setTimeout(() => {
      this.initDatesInputs(new Date(min), new Date(max));
      this.ref.detectChanges();
      this.updateQueryParams();
    }, 300);
  }

  public lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    elements: {
      point: {
        borderWidth: 0,
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },

    scales: {
      x: {
        type: 'time',
        time: {
          minUnit: 'minute',
          displayFormats: {
            minute: 'HH:mm',
            hour: 'HH:mm',
            day: 'DD/MM',
          },
        },
      },
      // y scale => internal and external battery level
      yBatteryPercent: {
        type: 'linear',
        position: 'left',
        display: true,
        grid: {
          drawOnChartArea: true,
        },
        min: 0,
        max: 105,
        title: {
          display: true,
          text: '% battery level',
          color: 'black',
        },
        ticks: {
          color: 'black',
          callback: (val, index) => {
            return `${val} %`;
          },
        },
        border: {
          color: 'black',
        },
      },
      // y scale => R intern battery
      yRIntern: {
        type: 'linear',
        position: 'right',
        display: false,
        grid: {
          drawOnChartArea: false,
        },
        min: 50,
        max: 200,

        border: {
          color: '#F1C40F',
        },
        ticks: {
          color: '#F1C40F',

          callback: (val, index) => {
            return `${val} Ω  `;
          },
        },
        title: {
          display: false,
          text: 'R internal batt.',
          color: '#F1C40F',
        },
      },
      // y scale => external battery voltage
      yExtBatteryVolt: {
        type: 'linear',
        position: 'right',
        display: false,
        grid: {
          drawOnChartArea: false,
        },
        min: 24,
        max: 44,
        ticks: {
          color: 'rgb(83,191,191)',
          callback: (val, index) => {
            return `${val} V  `;
          },
        },
        border: {
          color: 'rgb(83,191,191)',
        },
        title: {
          display: false,
          text: 'V external batt.',
          color: 'rgb(83,191,191)',
        },
      },

      // y scale => internal battery voltage
      yIntBatteryVolt: {
        type: 'linear',
        position: 'right',
        display: false,
        grid: {
          drawOnChartArea: false,
        },
        min: 2.4,
        max: 4.4,

        title: {
          display: false,
          text: 'V internal batt.',
        },
        border: {
          color: 'rgb(65, 163,232)',
        },
        ticks: {
          color: 'rgb(65, 163,232)',

          callback: (val, index) => {
            return `${val} V `;
          },
        },
      },
    },
    plugins: {
      annotation: {
        annotations: {},
      },
      legend: {
        position: 'right',
        onClick: (e, legendItem, legend) => {
          const index = legendItem.datasetIndex;
          const ci = legend.chart;
          const newHiddenValue = ci.getDatasetMeta(index).hidden === null ? !ci.data.datasets[index].hidden : null;
          ci.getDatasetMeta(index).hidden = newHiddenValue;
          if (ci.getDatasetMeta(index).yScale.id !== 'yBatteryPercent')
            ci.options.scales[ci.getDatasetMeta(index).yScale.id].display = newHiddenValue != null;
          ci.update();
        },
      },
      zoom: {
        pan: {
          enabled: false,
        },
        zoom: {
          drag: {
            enabled: true,
            backgroundColor: 'rgba(225,225,225,0.4)',
            borderWidth: 1,
          },
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: false,
          },
          mode: 'x',
          onZoomComplete: this.getZoomMinMax.bind(this),
        },
        limits: {
          x: { min: 'original', max: 'original' },
        },
      },
    },
  };

  public lineChartLegend = true;

  public batteriesConnections: BatteryConnection[];
  public fetchBatteryConnections(bikeId: string): void {
    this.batteryService
      .loadConnectionsByBike(bikeId, {})
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.batteriesConnections = response;
      });
  }
}
