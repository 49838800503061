import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthenticationService } from 'src/app/modules/auth/services/authentication.service';
import { ClrForm } from '@clr/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild(ClrForm, { static: true })
  public clrForm;

  public loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
  });

  public errors: { [key: string]: string };

  public redirect?: string = null;

  constructor(
    private fb: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.errors = {};
  }

  ngOnInit() {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      this.redirect = params.redirect;
    });
  }

  ngOnDestroy() {}

  onSubmit() {
    if (this.loginForm.invalid) {
      this.clrForm.markAsTouched();
      return;
    }

    // TODO: Use EventEmitter with form value
    console.warn(this.loginForm.value);

    this.authenticationService
      .login(this.loginForm.value.email, this.loginForm.value.password)
      .pipe(
        catchError((errors) => {
          this.errors.general = 'Wrong login or password.';

          return of('Wrong login or password.');
        })
      )
      .subscribe((user) => {
        console.log('User: ', user);

        if (typeof user === 'object') {
          if (this.redirect) {
            this.router.navigateByUrl(this.redirect);
          } else {
            this.router.navigate(['']);
          }
        }
      });
  }
}
