export class CguValidation {
  id: string;
  version: number;
  link: string;
  validatedOn: Date;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.version = data.version;
      this.link = data.link;
      this.validatedOn = data.validatedOn != null ? new Date(data.validatedOn) : null;
    }
  }
}
