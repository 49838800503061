import { LinkResource, UpdateTrace, DeleteTrace } from 'src/app/modules/api/models';
import { RefreshToken } from './refresh-token.model';

export class User extends LinkResource {
  id: string;
  email: string;
  role: string;
  locale: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  avatar: string;
  activationKey: string;
  activatedOn: string;
  deleted: boolean;
  activationKeyExpiration: Date;
  tenant: string;
  emailStatus: string;

  updateTrace: UpdateTrace;
  deleteTrace: DeleteTrace;

  constructor(data?: any) {
    super(data?._links);

    if (data) {
      // super(data._links);
      this.id = data.id;
      this.email = data.email;
      this.role = data.role;
      this.locale = data.locale;
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.phoneNumber = data.phoneNumber;
      this.avatar = data.avatar;
      this.activationKey = data.activationKey;
      this.activatedOn = data.activatedOn;
      this.deleted = data.deleted;
      this.activationKeyExpiration = data.activationKeyExpiration;
      this.tenant = data.tenant;

      this.updateTrace = new UpdateTrace(data.updateTrace);
      this.deleteTrace = new DeleteTrace(data.deleteTrace);
    }
  }
}
