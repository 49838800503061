export class MatoomaStats {
  iccid: string;
  state: string;
  smsIn: StatMatooma[];
  smsOut: StatMatooma[];
  data: StatMatooma[];

  constructor(data?: any) {
    if (data) {
      this.iccid = data.iccid;
      this.state = data.state;
      this.smsIn = data.smsIn.map((stat) => new StatMatooma(stat));
      this.smsOut = data.smsOut.map((stat) => new StatMatooma(stat));
      this.data = data.data.map((stat) => new StatMatooma(stat));
    }
  }
}

class StatMatooma {
  startDate: string;
  offer: number;
  counter: number;
  capacity: number;

  constructor(data?: any) {
    if (data) {
      this.startDate = data.startDate;
      this.offer = data.offer;
      this.counter = data.counter;
      this.capacity = data.capacity;
    }
  }
}
