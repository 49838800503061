import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/modules/api/services/api.service';
import { Observable, of } from 'rxjs';
import { ResponseEntities } from 'src/app/modules/api/models';
import { catchError, map } from 'rxjs/operators';
import { definitiveCache } from 'src/app/modules/api/definitive-cache/definitive-cache';
import { Firmware } from '../models/firmware.model';
import { HttpHeaders } from '@angular/common/http';
import { Descriptor } from '../models/software-packages.model';
import { Bike } from '../models/bike.model';

@Injectable({
  providedIn: 'root',
})
export class FirmwareService {
  constructor(private api: ApiService) {}

  private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message} with error : ${error}`);

      return of(result as T);
    };
  }

  public getAll(params: { [param: string]: string }): Observable<ResponseEntities<Firmware>> {
    return this.api
      .get<ResponseEntities<Firmware>>('/firmwares', {
        params,
      })
      .pipe(
        map((a) => new ResponseEntities<Firmware>(Firmware, a)),
        catchError(this.handleError<ResponseEntities<Firmware>>('getAll')),
        definitiveCache()
      );
  }

  public search(params: any): Observable<ResponseEntities<Firmware>> {
    return this.api
      .get<ResponseEntities<Firmware>>('/firmwares', {
        params,
      })
      .pipe(
        map((a) => new ResponseEntities<Firmware>(Firmware, a)),
        catchError(this.handleError<ResponseEntities<Firmware>>('all')),
        definitiveCache()
      );
  }

  public get(id: string): Observable<Firmware> {
    return this.api
      .get<Firmware>(`/firmwares/${id}`, {
        params: {},
      })
      .pipe(
        map((a) => new Firmware(a)),
        catchError(this.handleError<Firmware>('get')),
        definitiveCache()
      );
  }

  public getAutodiagDescriptor(id: string): Observable<Descriptor> {
    return this.api
      .get<Descriptor>(`/firmwares/${id}/descriptors/autodiag`, {
        params: {},
      })
      .pipe(
        map((a) => new Descriptor(a)),
        catchError(this.handleError<Descriptor>('get')),
        definitiveCache()
      );
  }

  public updateAutodiagDescriptor(id: string, content: string): Observable<any> {
    return this.api.patch<Descriptor>(`/firmwaredescriptor/${id}`, content);
  }

  public createFirmware(formData: FormData): Observable<any> {
    return this.api
      .post<Firmware>('/firmwares', formData, {
        headers: new HttpHeaders({
          'Content-Type': '',
        }),
      })
      .pipe(definitiveCache());
  }

  public update(firmware): Observable<any> {
    return this.api.put<Firmware>('/firmwares/' + firmware.id, firmware, {}).pipe(definitiveCache());
  }

  public enable(firmware: Firmware, enabled: boolean) {
    return this.api.put<Firmware>(`/firmwares/${firmware.id}`, { ...firmware, enabled }).pipe(
      map((a) => new Firmware(a)),
      catchError(this.handleError<Firmware>('put')),
      definitiveCache()
    );
  }

  public delete(id: string): Observable<any> {
    return this.api.delete<ResponseEntities<Firmware>>(`/firmwares/${id}`);
  }

  public download(id: string, type: string): void {
    this.api
      .get(`/firmwares/${id}/files/${type}`, {
        headers: new HttpHeaders({
          Accept: 'application/octet-stream',
        }),
        responseType: 'arraybuffer' as 'json',
      })
      .subscribe((response) => this.doDownloadFile(response, 'application/octet-stream', `${id}.${type}`));
  }

  //@see: https://stackoverflow.com/a/19328891/9722369
  private doDownloadFile(data: any, type: string, name: string) {
    var a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');

    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);

    a.remove();
  }
}
