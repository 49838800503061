<div *ngIf="bike" class="clr-row timeline-container">
  <div class="clr-col-lg-4 clr-col-md-6 clr-col-12 filters">
    <clr-accordion class="filters-accordion">
      <clr-accordion-panel [(clrAccordionPanelOpen)]="filtersAccordionOpened" class="filters-accordion-panel">
        <clr-accordion-title>Filters</clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
          <div class="clr-row timeline-filters">
            <div class="clr-col-lg-12 clr-col-md-8 clr-col-12">
              <clr-checkbox-container>
                <label>Display events :</label>

                <clr-checkbox-wrapper>
                  <input
                    (change)="allFilters()"
                    [(ngModel)]="filtersAllEvents"
                    clrCheckbox
                    name="options-2"
                    type="checkbox"
                  />
                  <label>All</label>
                </clr-checkbox-wrapper>

                <clr-checkbox-wrapper>
                  <input
                    (change)="changeFilters()"
                    [(ngModel)]="filters.eventTypes.alarm"
                    clrCheckbox
                    name="options-2"
                    type="checkbox"
                    value="details"
                  />
                  <label>Alarm enable/disable</label>
                </clr-checkbox-wrapper>

                <clr-checkbox-wrapper>
                  <input
                    (change)="changeFilters()"
                    [(ngModel)]="filters.eventTypes.fall"
                    clrCheckbox
                    name="options-5"
                    type="checkbox"
                    value="details"
                  />
                  <label>Fall alerts</label>
                </clr-checkbox-wrapper>

                <clr-checkbox-wrapper>
                  <input
                    (change)="changeFilters()"
                    [(ngModel)]="filters.eventTypes.motion"
                    clrCheckbox
                    name="options-5"
                    type="checkbox"
                    value="details"
                  />
                  <label>Motion alerts</label>
                </clr-checkbox-wrapper>

                <clr-checkbox-wrapper>
                  <input
                    (change)="changeFilters()"
                    [(ngModel)]="filters.eventTypes.lock"
                    clrCheckbox
                    name="options-1"
                    type="checkbox"
                    value="details"
                  />
                  <label>Lock / Unlock</label>
                </clr-checkbox-wrapper>

                <clr-checkbox-wrapper>
                  <input
                    (change)="changeFilters()"
                    [(ngModel)]="filters.eventTypes.battery"
                    clrCheckbox
                    name="options-1"
                    type="checkbox"
                    value="details"
                  />
                  <label>Low battery</label>
                </clr-checkbox-wrapper>

                <clr-checkbox-wrapper>
                  <input
                    (change)="changeFilters()"
                    [(ngModel)]="filters.eventTypes.rides"
                    clrCheckbox
                    name="options-7"
                    type="checkbox"
                    value="details"
                  />
                  <label>Rides</label>
                </clr-checkbox-wrapper>

                <clr-checkbox-wrapper>
                  <input
                    (change)="changeFilters()"
                    [(ngModel)]="filters.eventTypes.theft"
                    clrCheckbox
                    name="options-6"
                    type="checkbox"
                    value="details"
                  />
                  <label>Theft alerts</label>
                </clr-checkbox-wrapper>

                <clr-checkbox-wrapper>
                  <input
                    (change)="changeFilters()"
                    [(ngModel)]="filters.eventTypes.update"
                    clrCheckbox
                    name="options-3"
                    type="checkbox"
                    value="details"
                  />
                  <label>Firmware Update</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                  <input
                    (change)="changeFilters()"
                    [(ngModel)]="filters.eventTypes.customer"
                    clrCheckbox
                    name="options-4"
                    type="checkbox"
                    value="details"
                  />
                  <label>Users history</label>
                </clr-checkbox-wrapper>

                <clr-checkbox-wrapper>
                  <input
                    (change)="changeFilters()"
                    [(ngModel)]="filters.eventTypes.customerServiceMode"
                    clrCheckbox
                    name="options-4"
                    type="checkbox"
                    value="details"
                  />
                  <label>Customer Service Mode</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                  <input
                    (change)="changeFilters()"
                    [(ngModel)]="filters.eventTypes.geolocConsent"
                    clrCheckbox
                    name="options-4"
                    type="checkbox"
                    value="geolocConsent"
                  />
                  <label>Geoloc consent</label>
                </clr-checkbox-wrapper>
              </clr-checkbox-container>
            </div>
            <div class="clr-col-lg-12 clr-col-md-8 clr-col-12">
              <clr-checkbox-container>
                <label>Source :</label>

                <clr-checkbox-wrapper>
                  <input
                    (change)="changeFilters()"
                    [(ngModel)]="filters.trigger.app_angell"
                    clrCheckbox
                    name="options-5"
                    type="checkbox"
                    value="details"
                  />
                  <label>App Angell</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                  <input
                    (change)="changeFilters()"
                    [(ngModel)]="filters.trigger.app_pro"
                    clrCheckbox
                    name="options-6"
                    type="checkbox"
                    value="details"
                  />
                  <label>App Pro</label>
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper>
                  <input
                    (change)="changeFilters()"
                    [(ngModel)]="filters.trigger.bike"
                    clrCheckbox
                    name="options-2"
                    type="checkbox"
                    value="details"
                  />
                  <label>Bike</label>
                </clr-checkbox-wrapper>

                <clr-checkbox-wrapper>
                  <input
                    (change)="changeFilters()"
                    [(ngModel)]="filters.trigger.bench"
                    clrCheckbox
                    name="options-5"
                    type="checkbox"
                    value="details"
                  />
                  <label>Bench</label>
                </clr-checkbox-wrapper>
              </clr-checkbox-container>
            </div>
            <div class="clr-col-lg-12 clr-col-md-8 clr-col-12">
              <clr-input-container>
                <label i18n>Search</label>
                <input
                  (input)="applyFilters()"
                  [(ngModel)]="filters.freeSearch"
                  class="free-search"
                  clrInput
                  placeholder="ex : update, ..."
                  type="text"
                />
              </clr-input-container>
            </div>
            <div class="clr-col-lg-12 clr-col-md-8 clr-col-12">
              <clr-date-container>
                <label>Date</label>
                <input type="date" (clrDateChange)="initEvents($event)" [(clrDate)]="filters.date" />
              </clr-date-container>
            </div>
          </div>
        </clr-accordion-content>
      </clr-accordion-panel>
    </clr-accordion>
  </div>
  <div class="clr-col-lg-8 clr-col-md-6 clr-col-12 clr-order-2">
    <ul *ngIf="filteredEvents.length > 0" class="clr-timeline clr-timeline-vertical">
      <li *ngFor="let event of filteredEvents; let index = index" class="clr-timeline-step disabled">
        <div [title]="event.date | date: 'dd/MM/YY HH:mm:ss ZZZZZ'" class="clr-timeline-step-header">
          {{ event.date | date: 'dd/MM/YY HH:mm:ss' }}
        </div>
        <div class="timeline-icons">
          <cds-icon shape="circle" class="timeline-circle {{ event.type }}"></cds-icon>
          <cds-icon
            *ngIf="event.shape"
            solid="{{ event.solid }}"
            shape="{{ event.shape }}"
            badge="{{ event.status }}"
            class="timeline-icon {{ event.type }}"
          ></cds-icon>
        </div>
        <div class="clr-timeline-step-body">
          <span [innerHTML]="event.title" class="clr-timeline-step-title"></span>
          <span *ngIf="event.description" [innerHTML]="event.description" class="clr-timeline-step-description"></span>
          <div class="clr-timeline-step-description triggered-by">
            triggered by
            <span *ngIf="['APP_ANGELL', 'APP_PRO'].includes(event.source.type) && event.source.userId">
              <a href="/users/view/{{ event.source.userId }}">{{ event.source.userId }}</a>
            </span>
            <span *ngIf="!['APP_ANGELL', 'APP_PRO'].includes(event.source.type) || !event.source.userId">
              {{ event.source.type }}
            </span>
          </div>
        </div>
      </li>
      <li *ngIf="hasMorePage" class="clr-timeline-step disabled">
        <div class="clr-timeline-step-header"></div>
        <cds-icon aria-label="Not started" shape="circle"></cds-icon>
        <div class="clr-timeline-step-body">
          <span class="clr-timeline-step-title">
            <button
              (click)="loadBikeHistory(bike.id, null)"
              aria-label="show angell back"
              class="btn btn-icon btn-sm"
              i18n
              style="margin-top: 0; margin-bottom: 0"
              title="Show contract"
              type="button"
            >
              <cds-icon shape="eye"></cds-icon>&nbsp;Load more details
            </button>
          </span>
        </div>
      </li>
    </ul>
    <div *ngIf="filteredEvents.length === 0">No event found.</div>
  </div>
</div>
