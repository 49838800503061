import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClrDatagridStateInterface } from '@clr/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Segment } from '../../../models/segment.model';
import { SegmentService } from '../../../services/segment.service';

@UntilDestroy()
@Component({
  selector: 'app-list-segment',
  templateUrl: './list-segment.component.html',
  styleUrls: ['./list-segment.component.scss'],
})
export class ListSegmentComponent implements OnInit, OnDestroy {
  public segments: Segment[];
  public total: number;
  public loading = true;

  constructor(private segmentService: SegmentService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  public refresh(state: ClrDatagridStateInterface): void {
    console.log('table state:', state);

    this.loading = true;

    // We convert the filters from an array to a map,
    // because that's what our backend-calling service is expecting
    const filters: { [prop: string]: any } = {};

    if (state.filters) {
      for (const filter of state.filters) {
        const { property, value } = filter as { property: string; value: string };
        filters[property] = value;
      }
    }

    console.log('table filters:', filters);

    const params = filters;

    params.page = state.page.current - 1;
    params.size = state.page.size;

    this.segmentService
      .getAll(params)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.segments = result.values();
        this.total = result.page.totalElements || this.segments.length;
        this.loading = false;
      });
  }
}
