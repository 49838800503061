export class BikeProvisionHistory {
  id: string;
  action: string;
  mac: string;
  color: string;
  testResults: string;
  iccid: string;
  motorControllerSerialNumber: string;
  bikeFrameSerialNumber: string;
  batterySerialNumber: string;
  topSerialNumber: string;
  bottomSerialNumber: string;
  appVersion: string;
  bluetoothVersion: string;
  imageVersion: string;
  motorControllerVersion: string;
  antitheftCode: string;
  aesEncryptionKey: string;
  modelId: string;
  provisionedOn: string;
  createdOn: string;
  agent: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.action = data.action;
      this.mac = data.mac;
      this.color = data.color;
      this.testResults = data.testResults;
      this.iccid = data.iccid;
      this.motorControllerSerialNumber = data.motorControllerSerialNumber;
      this.bikeFrameSerialNumber = data.bikeFrameSerialNumber;
      this.batterySerialNumber = data.batterySerialNumber;
      this.topSerialNumber = data.topSerialNumber;
      this.bottomSerialNumber = data.bottomSerialNumber;
      this.appVersion = data.appVersion;
      this.bluetoothVersion = data.bluetoothVersion;
      this.imageVersion = data.imageVersion;
      this.motorControllerVersion = data.motorControllerVersion;
      this.antitheftCode = data.antitheftCode;
      this.aesEncryptionKey = data.aesEncryptionKey;
      this.modelId = data.modelId;
      this.provisionedOn = data.provisionedOn;
      this.createdOn = data.createdOn;
      this.agent = data.agent;
    }
  }
}

/**
 id: string;
 action: string;
 mac: string;
 color: string;
 testResults: string;
 iccid: string;
 motorControllerSerialNumber: string;
 bikeFrameSerialNumber: string;
 batterySerialNumber: string;
 topSerialNumber: string;
 bottomSerialNumber: string;
 appVersion: string;
 bluetoothVersion: string;
 imageVersion: string;
 motorControllerVersion: string;
 antitheftCode: string;
 aesEncryptionKey: string;
 modelId: string;
 provisionedOn: string;
 createdOn: string;
 "agent": null
 */
