import { UpdateTrace } from '../../../modules/api/models';

export class BikeHardwareConfig {
  id: string;
  model: string;
  color: string;
  motorPower: string;
  motorModel: string;
  wheelDiameter: number;
  controllerHwVersion: string;
  shutdownCableHwVersion: number;
  bikeframeSN: string;
  updateTrace: UpdateTrace;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.model = data.model;
      this.color = data.color;
      this.motorPower = data.motorPower;
      this.motorModel = data.motorModel;
      this.wheelDiameter = data.wheelDiameter;
      this.controllerHwVersion = data.controllerHwVersion;
      this.shutdownCableHwVersion = data.shutdownCableHwVersion;
      this.bikeframeSN = data.bikeframeSN;
      this.updateTrace = new UpdateTrace(data.updateTrace);
    }
  }
}
