import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { flatMap } from 'rxjs/operators';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SegmentService } from '../../../../services/segment.service';
import { Firmware, FirmwareFile } from '../../../../models/firmware.model';
import { FirmwareService } from '../../../../services/firmware.service';
import { BikeModel } from '../../../../models/bike-model.model';
import { BikeModelService } from '../../../../services/bike-model.service';
import { Segment } from '../../../../models/segment.model';
import { segmentsValidator } from '../../../../../../modules/shared/validators/segments.validator';

@UntilDestroy()
@Component({
  selector: 'app-update-firmware',
  templateUrl: './update-firmware.component.html',
  styleUrls: ['./update-firmware.component.scss'],
})
export class UpdateFirmwareComponent implements OnInit, OnDestroy {
  firmwareLoading = true;
  updateFirmwareForm: UntypedFormGroup;
  addConstraintForm: UntypedFormGroup;
  submitted = false;
  error = '';

  constraintTypes: string[] = ['FIRMWARE', 'HARDWARE', 'OPTION'];
  moduleTypes = {
    FIRMWARE: ['', 'APP', 'BLE', 'BLE_SD', 'BMS', 'BOOTLOADER', 'CONTROLLER', 'IMAGE', 'REARLIGHT'],
    HARDWARE: ['', 'TOP', 'BOTTOM', 'CONTROLLER'],
    OPTION: ['SHUTDOWN_CABLE'],
  };
  firmwareTypes: string[] = ['APP', 'BLE', 'BLE_SD', 'BMS', 'BOOTLOADER', 'CONTROLLER', 'IMAGE', 'REARLIGHT'];

  versionPattern = '^(\\d+).(\\d+).(\\d+)$';

  public firmware: Firmware;
  public datFile: FirmwareFile;
  public binFile: FirmwareFile;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private firmwareService: FirmwareService,
    private segmentService: SegmentService,
    private bikeModelService: BikeModelService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.fetchModels();
    this.route.params
      .pipe(
        untilDestroyed(this),
        flatMap((params) => this.firmwareService.get(params.id))
      )
      .subscribe((firmware) => {
        console.log('firmware:', firmware);
        this.firmwareLoading = false;
        this.firmware = firmware;
        this.binFile = firmware.files.find((file) => file.type == 'BIN');
        this.datFile = firmware.files.find((file) => file.type == 'DAT');
        this.addConstraintForm = this.formBuilder.group({
          constraintType: ['FIRMWARE', Validators.required],
          moduleType: ['', Validators.required],
          version: ['', Validators.pattern(this.versionPattern)],
        });
        this.updateFirmwareForm = this.formBuilder.group({
          type: [this.firmware.type, Validators.required],
          version: [this.firmware.version, Validators.pattern(this.versionPattern)],
          segments: new UntypedFormControl(firmware.segments, segmentsValidator()),
          distributionMediaLTE: this.firmware.distributionMediaLTE,
          distributionMediaBLE: this.firmware.distributionMediaBLE,
          models: new UntypedFormControl(firmware.models),
          firmwareConstraints: this.formBuilder.array(
            firmware.constraints
              .filter((c) => c.constraintType == 'FIRMWARE')
              .map((c) =>
                this.formBuilder.group({
                  id: c.id,
                  constraintType: c.constraintType,
                  moduleType: c.moduleType,
                  version: c.version,
                })
              )
          ),
          hardwareConstraints: this.formBuilder.array(
            firmware.constraints
              .filter((c) => c.constraintType == 'HARDWARE')
              .map((c) =>
                this.formBuilder.group({
                  id: c.id,
                  constraintType: c.constraintType,
                  moduleType: c.moduleType,
                  version: c.version,
                })
              )
          ),
          optionConstraints: this.formBuilder.array(
            firmware.constraints
              .filter((c) => c.constraintType == 'OPTION')
              .map((c) =>
                this.formBuilder.group({
                  id: c.id,
                  constraintType: c.constraintType,
                  moduleType: c.moduleType,
                  version: c.version,
                })
              )
          ),
        });
      });
  }

  firmwareConstraints(): UntypedFormArray {
    return this.updateFirmwareForm.get('firmwareConstraints') as UntypedFormArray;
  }
  hardwareConstraints(): UntypedFormArray {
    return this.updateFirmwareForm.get('hardwareConstraints') as UntypedFormArray;
  }
  optionConstraints(): UntypedFormArray {
    return this.updateFirmwareForm.get('optionConstraints') as UntypedFormArray;
  }

  addConstraint() {
    if (this.addConstraintForm.valid) {
      const formGroup = this.formBuilder.group({
        constraintType: this.addConstraintForm.controls.constraintType.value,
        moduleType: this.addConstraintForm.controls.moduleType.value,
        version: this.addConstraintForm.controls.version.value,
      });
      switch (this.addConstraintForm.controls.constraintType.value) {
        case 'FIRMWARE':
          this.firmwareConstraints().push(formGroup);
          break;
        case 'HARDWARE':
          this.hardwareConstraints().push(formGroup);
          break;
        case 'OPTION':
          this.optionConstraints().push(formGroup);
          break;
      }
      this.addConstraintForm.get('constraintType').setValue('');
      this.addConstraintForm.get('moduleType').setValue('');
      this.addConstraintForm.get('version').setValue('');
    }
  }

  removeConstraint(constraintType, i) {
    switch (constraintType) {
      case 'FIRMWARE':
        this.firmwareConstraints().removeAt(i);
        break;
      case 'HARDWARE':
        this.hardwareConstraints().removeAt(i);
        break;
      case 'OPTION':
        this.optionConstraints().removeAt(i);
        break;
    }
  }

  ngOnDestroy(): void {}

  public segments: Segment[] = [];
  public segmentsLoading: boolean = false;

  public fetchSegments(term) {
    this.segmentsLoading = true;
    this.segmentService.search(term, true).subscribe((segments) => {
      this.segmentsLoading = false;
      this.segments = [];
      if (segments) {
        this.segments.push(...segments);
      }
      if (!this.segments.find((c) => c.name == term)) {
        this.segments.unshift(new Segment({ name: term }));
      }
    });
  }

  public models: BikeModel[] = [];
  public modelsLoading: boolean = false;

  public fetchModels() {
    this.modelsLoading = true;
    this.bikeModelService.getAll(null).subscribe((result) => {
      this.modelsLoading = false;
      this.models = result.values();
    });
  }

  // convenience getter for easy access to form fields
  get f(): { [key: string]: AbstractControl } {
    return this.updateFirmwareForm.controls;
  }

  getDataFromForm(): { [key: string]: any } {
    const firmwareData = {
      version: this.f.version.value,
      type: this.f.type.value,
      distributionMediaLTE: this.f.distributionMediaLTE.value,
      distributionMediaBLE: this.f.distributionMediaBLE.value,
      models: this.updateFirmwareForm.get('models').value,
      segments: this.updateFirmwareForm.get('segments').value,
      constraints: undefined,
    };
    const firmwareConstraints = this.firmwareConstraints().controls.map((control) => {
      return {
        id: control.get('id')?.value,
        constraintType: control.get('constraintType').value,
        moduleType: control.get('moduleType').value,
        version: control.get('version').value,
      };
    });
    const hardwareConstraints = this.hardwareConstraints().controls.map((control) => {
      return {
        id: control.get('id')?.value,
        constraintType: control.get('constraintType').value,
        moduleType: control.get('moduleType').value,
        version: control.get('version').value,
      };
    });
    const optionConstraints = this.optionConstraints().controls.map((control) => {
      return {
        id: control.get('id')?.value,
        constraintType: control.get('constraintType').value,
        moduleType: control.get('moduleType').value,
        version: control.get('version').value,
      };
    });

    firmwareData.constraints = [...firmwareConstraints, ...hardwareConstraints, ...optionConstraints];

    return firmwareData;
  }

  public onSubmit(): void {
    this.submitted = true;

    // stop here if form is invalid
    if (this.updateFirmwareForm.invalid) {
      return;
    }

    const firmwareData = this.getDataFromForm();
    firmwareData.id = this.firmware.id;

    this.error = null;

    this.firmwareService
      .update(firmwareData)
      .pipe(untilDestroyed(this))
      .subscribe(
        (res) => this.router.navigate(['/bikes', 'firmwares', 'view', this.firmware.id]),
        (error) => {
          console.log(error);
          this.error = error;
        }
      );
  }

  public onDelete(firmware: Firmware): void {
    this.firmwareService
      .delete(firmware.id)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.router.navigate(['/bikes', 'firmwares']);
      });
  }

  public onCancel() {
    this.router.navigate(['/bikes', 'firmwares', 'view', this.firmware.id]);
  }
}
