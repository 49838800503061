<h1 i18n>Create bike version</h1>

<form clrForm [formGroup]="createVersionForm" (ngSubmit)="onSubmit()">
  <clr-alert [clrAlertType]="'danger'" [clrAlertClosed]="!error">
    <div clr-alert-item class="alert-item">
      <span class="alert-text">
        {{ error }}
      </span>
    </div>
  </clr-alert>

  <h2 i18n>Version Package</h2>

  <clr-input-container>
    <label i18n>Zip</label>
    <input clrInput type="file" placeholder="Upload file" accept="*" (change)="onFileSelect($event)" />
  </clr-input-container>

  <clr-select-container>
    <label i18n>Package type</label>
    <select clrSelect formControlName="softwarePackageType">
      <option value="">Choose package type</option>
      <option value="BIKE_IMAGE">Image</option>
      <option value="BIKE_APP_AND_BLE">App and Bluetooth</option>
    </select>
  </clr-select-container>

  <ng-container *ngIf="isSelected('BIKE_APP_AND_BLE')">
    <clr-input-container>
      <label i18n>App version</label>
      <input clrInput type="text" formControlName="appVersion" placeholder="1.2.3" required />
    </clr-input-container>

    <clr-input-container>
      <label i18n>Bluetooth version</label>
      <input clrInput type="text" formControlName="bleVersion" placeholder="1.2.3" required />
    </clr-input-container>
  </ng-container>

  <ng-container *ngIf="isSelected('BIKE_IMAGE')">
    <clr-input-container>
      <label i18n>Image version</label>
      <input clrInput type="text" formControlName="imageVersion" placeholder="1.2.3" required />
    </clr-input-container>
  </ng-container>

  <h2 i18n>Requirements</h2>

  <clr-input-container>
    <label i18n>Min. app version</label>
    <input clrInput type="text" formControlName="minAppVersion" placeholder="1.2.3" />
  </clr-input-container>

  <clr-input-container>
    <label i18n>Min. bluetooth version</label>
    <input clrInput type="text" formControlName="minBluetoothVersion" placeholder="1.2.3" />
  </clr-input-container>

  <clr-input-container>
    <label i18n>Min. image version</label>
    <input clrInput type="text" formControlName="minImageVersion" placeholder="1.2.3" />
  </clr-input-container>

  <h2 i18n>Target</h2>

  <clr-combobox-container>
    <label>Segments</label>
    <clr-combobox
      formControlName="segments"
      clrMulti="true"
      placeholder="Starts typing..."
      [clrLoading]="segmentsLoading"
      (clrInputChange)="fetchSegments($event)"
      (clrOpenChange)="$event ? fetchSegments('') : null"
    >
      <ng-container *clrOptionSelected="let selected">
        <cds-icon shape="tags" role="img"></cds-icon>
        {{ selected?.name }}
      </ng-container>
      <clr-options>
        <clr-option *clrOptionItems="let state of segments; field: 'name'" [clrValue]="state">
          <cds-icon shape="tags" role="img"></cds-icon>
          {{ state.name }}
        </clr-option>
      </clr-options>
    </clr-combobox>
  </clr-combobox-container>

  <button class="btn btn-primary" type="submit" i18n>Create version</button>

</form>
