import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, flatMap, switchMap } from 'rxjs/operators';
import { Descriptor } from '../../../../models/software-packages.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Segment } from '../../../../models/segment.model';
import { SegmentService } from '../../../../services/segment.service';
import { Firmware, FirmwareFile } from '../../../../models/firmware.model';
import { FirmwareService } from '../../../../services/firmware.service';
import { segmentPattern } from '../../../../../../modules/shared/validators/segments.validator';

const DESCRIPTOR_TYPE_AUTODIAG = 'autodiag';
const SOFTWARE_TYPE_APP = 'APP';

@UntilDestroy()
@Component({
  selector: 'app-view-version',
  templateUrl: './view-firmware.component.html',
  styleUrls: ['./view-firmware.component.scss'],
})
export class ViewFirmwareComponent implements OnInit, OnDestroy {
  addSegmentForm: UntypedFormGroup;

  public firmware: Firmware;
  public datFile: FirmwareFile;
  public binFile: FirmwareFile;

  public segments: Segment[] = [];

  public autodiagDescriptor: Descriptor;

  public readonly SOFTWARE_TYPE_APP: string = SOFTWARE_TYPE_APP;

  openAutodiagModal: boolean = false;
  editAutodiag: boolean = false;
  updateAutodiagForm: UntypedFormGroup;
  updateAutodiagFormError: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private firmwareService: FirmwareService,
    private segmentService: SegmentService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        untilDestroyed(this),
        flatMap((params) => this.firmwareService.get(params.id))
      )
      .subscribe((firmware) => {
        console.log('firmware:', firmware);
        this.firmware = firmware;
        this.binFile = firmware.files.find((file) => file.type == 'BIN');
        this.datFile = firmware.files.find((file) => file.type == 'DAT');
        if (this.firmware.type == 'APP') {
          this.firmwareService
            .getAutodiagDescriptor(firmware.id)
            .pipe(untilDestroyed(this))
            .subscribe((descriptor) => {
              this.autodiagDescriptor = descriptor;
              this.updateAutodiagForm.get('content').setValue(descriptor.content);
            });
        }
      });

    this.updateAutodiagForm = this.formBuilder.group({ content: [{ value: '', disabled: true }, Validators.required] });

    this.addSegmentForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(segmentPattern)]],
    });

    this.addSegmentForm.controls.name.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(150),
        switchMap((term) => this.segmentService.search(term))
      )
      .subscribe((segments) => {
        console.log(segments);
        this.segments = segments;
      });
  }

  ngOnDestroy(): void {}

  public onDelete(firmware: Firmware): void {
    this.firmwareService
      .delete(firmware.id)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.router.navigate(['/bikes', 'firmwares']);
      });
  }

  public onUpdate(firmware: Firmware): void {
    this.router.navigate(['/bikes', 'firmwares', 'update', firmware.id]);
  }

  public onDisable(version: Firmware): void {
    this.changeEnableState(version, false);
  }

  public onEnable(version: Firmware): void {
    this.changeEnableState(version, true);
  }

  public getRequirementsByType(type: String) {
    return this.firmware.constraints.filter((c) => c.constraintType == type);
  }

  public showAutodiagModal() {
    this.openAutodiagModal = true;
  }

  public enterAutodiagEditMode() {
    this.editAutodiag = true;
    this.updateAutodiagForm.get('content').enable();
  }

  public cancelAutodiagEditMode() {
    this.updateAutodiagForm.get('content').setValue(this.autodiagDescriptor.content);
    this.exitAutodiagEditMode();
  }

  private exitAutodiagEditMode() {
    this.updateAutodiagFormError = '';
    this.editAutodiag = false;
    this.updateAutodiagForm.get('content').disable();
  }

  public updateAutodiagDescriptor() {
    this.updateAutodiagFormError = '';

    // stop here if form is invalid
    if (this.updateAutodiagForm.invalid) {
      return;
    }

    const content = this.updateAutodiagForm.get('content').value;
    this.firmwareService
      .updateAutodiagDescriptor(this.autodiagDescriptor.id, content)
      .pipe(untilDestroyed(this))
      .subscribe(
        (descriptor) => {
          this.autodiagDescriptor.content = descriptor.content;
          this.exitAutodiagEditMode();
        },
        () => {
          this.updateAutodiagFormError = 'Oops, something went wrong... Please try again.';
        }
      );
  }

  private changeEnableState(firmware: Firmware, newState: boolean): void {
    this.firmwareService
      .enable(firmware, newState)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.firmware.enabled = newState;
      });
  }

  public download(id: string, type: string): void {
    this.firmwareService.download(id, type);
  }
}
