<h1 i18n>Cockpit {{ mac }}</h1>
<div *ngIf="mac" class="clr-row">
  <div class="clr-col-lg-4 clr-col-12">
    <h3 i18n>Provisioning</h3>
    <table class="table table-vertical" *ngIf="currentCockpit">
      <tbody>
        <tr>
          <th i18n>Id</th>
          <td>
            {{ currentCockpit.id }}
          </td>
        </tr>
        <tr>
          <th i18n>Mac</th>
          <td>
            {{ currentCockpit.mac }}
          </td>
        </tr>
        <tr>
          <th i18n>Color</th>
          <td>
            {{ currentCockpit.color }}
          </td>
        </tr>
        <tr>
          <th i18n>Versions</th>
          <td>
            {{ currentCockpit.versionApp || '-' }} / {{ currentCockpit.versionImg || '-' }} /
            {{ currentCockpit.versionBle || '-' }}
          </td>
        </tr>
        <tr>
          <th i18n>Provisioned on</th>
          <td>
            {{ currentCockpit.provisionedOn | date : 'dd/MM:YY HH:mm' }}
          </td>
        </tr>
        <tr>
          <th>Tests results</th>
          <td>
            <span
              class="label label-danger"
              *ngIf="!['SUCCESS', 'PASS'].includes(currentCockpit.latestTestResult)"
              i18n
              >{{ currentCockpit.latestTestResult }}</span
            >
            <span
              class="label label-success"
              *ngIf="['SUCCESS', 'PASS'].includes(currentCockpit.latestTestResult)"
              i18n
              >{{ currentCockpit.latestTestResult }}</span
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="clr-col-lg-3 clr-col-12">
    <div class="clr-row">
      <div class="clr-col-12">
        <h3 i18n>Bike</h3>
        <table class="table table-vertical" *ngIf="currentCockpit">
          <tbody>
            <tr>
              <th i18n>Id</th>
              <td>
                <a *ngIf="currentCockpit.bikeId" [routerLink]="['/bikes', 'view', currentCockpit.bikeId]" href>
                  {{ currentCockpit.bikeId }}
                </a>
                <span *ngIf="!currentCockpit.bikeId">-</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="clr-col-12">
        <h3 i18n>Sim Card</h3>
        <sim-card [iccid]="currentCockpit?.iccId" [phoneNumber]="currentCockpit?.bikePhoneNumber"></sim-card>
      </div>
    </div>
  </div>
  <div class="clr-col-lg-4 clr-col-12">
    <div class="clr-row">
      <div class="clr-col-12">
        <h3 i18n>Top Card</h3>
        <table class="table table-vertical" *ngIf="currentCockpit">
          <tbody>
            <tr>
              <th i18n>Id</th>
              <td>
                {{ currentCockpit.topCard.id }}
              </td>
            </tr>
            <tr>
              <th i18n>S/N</th>
              <td>
                {{ currentCockpit.topCard.serialNumber }}
              </td>
            </tr>
            <tr>
              <th i18n>Provisioned on</th>
              <td>
                {{ currentCockpit.topCard.provisionedOn }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="clr-col-12">
        <h3 i18n>Bottom Card</h3>
        <table class="table table-vertical" *ngIf="currentCockpit">
          <tbody>
            <tr>
              <th i18n>Id</th>
              <td>
                {{ currentCockpit.topCard.id }}
              </td>
            </tr>
            <tr>
              <th i18n>S/N</th>
              <td>
                {{ currentCockpit.bottomCard.serialNumber }}
              </td>
            </tr>
            <tr>
              <th i18n>Provisioned on</th>
              <td>
                {{ currentCockpit.bottomCard.provisionedOn }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="clr-col-lg-12 clr-col-12">
    <h3 i18n>History</h3>
    <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
      <clr-dg-column i18n>Provisioned on</clr-dg-column>
      <clr-dg-column i18n>Superseded on</clr-dg-column>
      <clr-dg-column i18n>Color</clr-dg-column>
      <clr-dg-column i18n>Top S/N</clr-dg-column>
      <clr-dg-column i18n>Top provisioned on</clr-dg-column>
      <clr-dg-column i18n>Bottom S/N</clr-dg-column>
      <clr-dg-column i18n>Bottom provisioned on</clr-dg-column>
      <clr-dg-column i18n>Versions (App/Img/BLE)</clr-dg-column>
      <clr-dg-column i18n>Tests results</clr-dg-column>
      <clr-dg-column i18n>ICCID</clr-dg-column>

      <clr-dg-row *ngFor="let cockpit of cockpitHistory">
        <clr-dg-cell>{{ cockpit.provisionedOn | date : 'dd/MM/yy HH:mm' }}</clr-dg-cell>
        <clr-dg-cell>{{ cockpit.supersededOn ? (cockpit.supersededOn | date : 'dd/MM/yy HH:mm') : '-' }}</clr-dg-cell>
        <clr-dg-cell>{{ cockpit.color }}</clr-dg-cell>
        <clr-dg-cell>{{ cockpit.topCard.serialNumber }}</clr-dg-cell>
        <clr-dg-cell>{{ cockpit.topCard.provisionedOn | date : 'dd/MM/yy HH:mm' }}</clr-dg-cell>
        <clr-dg-cell>{{ cockpit.bottomCard.serialNumber }}</clr-dg-cell>
        <clr-dg-cell>{{ cockpit.bottomCard.provisionedOn | date : 'dd/MM/yy HH:mm' }}</clr-dg-cell>
        <clr-dg-cell>
          {{ cockpit.versionApp || '-' }} / {{ cockpit.versionImg || '-' }} / {{ cockpit.versionBle || '-' }}
        </clr-dg-cell>
        <clr-dg-cell>
          <span class="label label-danger" *ngIf="!['SUCCESS', 'PASS'].includes(cockpit.latestTestResult)" i18n>{{
            cockpit.latestTestResult
          }}</span>
          <span class="label label-success" *ngIf="['SUCCESS', 'PASS'].includes(cockpit.latestTestResult)" i18n>{{
            cockpit.latestTestResult
          }}</span>
        </clr-dg-cell>
        <clr-dg-cell>
          <a [routerLink]="['/sims', 'view', cockpit.iccId]" href>
            {{ cockpit.iccId }}
          </a>
        </clr-dg-cell>
      </clr-dg-row>

      <clr-dg-footer i18n>
        {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} bikes
        <clr-dg-pagination #pagination [clrDgTotalItems]="total" [clrDgPageSize]="15"></clr-dg-pagination>
      </clr-dg-footer>
    </clr-datagrid>
  </div>

  <provisioning-logs
    *ngIf="currentCockpit"
    class="clr-col-lg-6 clr-col-md-8 clr-col-12"
    [mac]="currentCockpit.mac"
    filterType="BancCockpit"
  ></provisioning-logs>
</div>
