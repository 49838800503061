import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { User } from '../../../../models/user.model';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RetailerService } from '../../../../services/retailer.service';
import { Retailer } from '../../../../models/retailer.model';
import { Location } from '@angular/common';
import { Shop } from '../../../../models/shop.model';
import { ShopService } from '../../../../services/shop.service';

@UntilDestroy()
@Component({
  selector: 'form-retailer',
  templateUrl: './form-retailer.component.html',
})
export class FormRetailerComponent implements OnInit, OnDestroy {
  public user: User;
  addProUserForm: UntypedFormGroup;
  submitted = false;
  error = '';
  shop: Shop;
  public mode: string = 'CREATE';
  public loading: boolean = false;
  public initialRetailer: Retailer;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private retailerService: RetailerService,
    private shopService: ShopService,
    private location: Location
  ) {}

  public ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');

    if (id) {
      this.mode = 'UPDATE';
      this.loading = true;
      this.retailerService
        .get(id)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (value) => {
            this.initialRetailer = value;

            this.addProUserForm = this.formBuilder.group({
              email: [this.initialRetailer.customer.email, Validators.required],
              locale: [this.initialRetailer.customer.locale, Validators.pattern('^([a-z]{2})_([A-Z]{2})$')],
              firstName: [this.initialRetailer.customer.firstName, Validators.required],
              lastName: [this.initialRetailer.customer.lastName, Validators.required],
              phoneNumber: [this.initialRetailer.customer.phoneNumber, Validators.pattern('[- +()0-9]*')],
              role: ['ROLE_RETAILER'],
            });
            this.shop = this.initialRetailer.shop;
          },
          complete: () => {
            this.loading = false;
          },
        });
    } else {
      this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
        if (params.shopId) {
          this.shopService
            .get(params.shopId)
            .pipe(untilDestroyed(this))
            .subscribe({
              next: (shop) => {
                this.shop = shop;
              },
            });
        }
      });
      this.addProUserForm = this.formBuilder.group({
        email: [null, Validators.required],
        locale: ['fr_FR', Validators.pattern('^([a-z]{2})_([A-Z]{2})$')],
        firstName: [null, Validators.required],
        lastName: [null, Validators.required],
        phoneNumber: [null, Validators.pattern('[- +()0-9]*')],
        role: ['ROLE_RETAILER'],
      });
    }
  }

  public ngOnDestroy(): void {}

  // convenience getter for easy access to form fields
  get f(): { [key: string]: AbstractControl } {
    return this.addProUserForm.controls;
  }

  private formBuilderToUser(): User {
    return new User({
      id: this.initialRetailer?.customer?.id,
      email: this.f.email.value,
      locale: this.f.locale.value,
      firstName: this.f.firstName.value,
      lastName: this.f.lastName.value,
      phoneNumber: this.f.phoneNumber.value,
      role: this.f.role.value,
    });
  }

  public onSubmit(): void {
    this.submitted = true;

    // stop here if form is invalid
    if (this.addProUserForm.invalid) {
      return;
    }

    this.error = null;

    const retailer: Retailer = new Retailer({
      id: this.initialRetailer?.id,
      customer: this.formBuilderToUser(),
      shop: { id: this.shop.id },
    });

    if (this.mode === 'CREATE') {
      this.retailerService
        .create(retailer)
        .pipe(untilDestroyed(this))
        .subscribe(
          (res) => this.router.navigate(['/pro/retailers/view', res.id]),
          (error) => {
            console.log(error);
            this.error = error;
          }
        );
    } else if (this.mode === 'UPDATE') {
      this.retailerService
        .update(retailer)
        .pipe(untilDestroyed(this))
        .subscribe(
          (res) => this.router.navigate(['/pro/retailers/view', res.id]),
          (error) => {
            console.log(error);
            this.error = error;
          }
        );
    }
  }

  back() {
    this.location.back();
  }
}
