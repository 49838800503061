import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NextVersion } from '../../models/next-version.model';
import { NextVersionOTAV2 } from '../../models/bike-next-version.model';
import { Bike, CustomerBike, MaintenanceOperation } from '../../models/bike.model';
import { BikeService } from '../../services/bike.service';
import { VersionService } from '../../services/version.service';
import { RideService } from '../../../user/services/ride.service';
import { AngellBackService } from '../../../services/services/angell-back.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RideStats } from '../../../user/models/ride-stats.model';
import { AngellBack } from '../../../services/models/angell-back.model';
import { SimCard } from '../../models/simcard.model';
import { SimCardService } from '../../services/simcard.service';

@UntilDestroy()
@Component({
  selector: 'bike-summary',
  templateUrl: './bike-summary.component.html',
  styleUrls: ['./bike-summary.component.scss'],
})
export class BikeSummaryComponent implements OnInit, OnDestroy {
  @Input()
  bike: Bike;
  public lastMaintenanceOperation: MaintenanceOperation;
  public nextVersion?: NextVersion;
  public nextVersionOTAV2: NextVersionOTAV2;
  public owner: CustomerBike;

  constructor(
    private bikeService: BikeService,
    private versionService: VersionService,
    private rideService: RideService,
    private angellBackService: AngellBackService,
    private simService: SimCardService,
  ) {}

  ngOnInit(): void {
    this.owner = this.bike.customerBikes.find((cb) => cb.role == 'OWNER');
    this.fetchLastMaintenanceOperation();
    this.fetchNextVersion(this.bike);
    this.fetchNextVersionOTAV2(this.bike.id);
    this.fetchLastRideDate(this.bike.id);
    this.fetchLastDiagDate(this.bike.id);
    this.fetchRideStats(this.bike.id);
    this.fetchAngellBack(this.bike.id);
    this.fetchSim(this.bike.bikeProvision.iccid);
  }

  ngOnDestroy(): void {}

  public sim: SimCard;
  private fetchSim(iccid: string): void {
    this.simService
      .getByIccid(iccid, null)
      .pipe(untilDestroyed(this))
      .subscribe((sim) => {
        this.sim = sim;
      });
  }
  private fetchLastMaintenanceOperation(): void {
    this.bikeService
      .getMaintenanceOperations(this.bike.id, null)
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.lastMaintenanceOperation = response.values().find((operation) => operation.endedOn != null);
      });
  }

  public nextVersionV1Loading: boolean = false;
  public nextVersionV2Loading: boolean = false;
  private fetchNextVersion(bike: Bike): void {
    this.nextVersionV1Loading = true;
    this.versionService
      .checkNextVersion(
        bike.bikeProvision.mac,
        bike.appVersion.toString(),
        bike.bluetoothVersion.toString(),
        bike.imageVersion.toString(),
      )
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (nextVersion) => {
          console.log('nextVersion:', nextVersion);

          this.nextVersion = nextVersion;
        },
        error: (error) => console.log(error),
        complete: () => {
          this.nextVersionV1Loading = false;
        },
      });
  }

  private fetchNextVersionOTAV2(bikeId: string): void {
    this.nextVersionV2Loading = true;
    this.bikeService
      .getNextVersion(bikeId)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result) => {
          this.nextVersionOTAV2 = result;
        },
        error: (error) => console.log(error),
        complete: () => {
          this.nextVersionV2Loading = false;
        },
      });
  }

  public lastRideDate: Date;
  private fetchLastRideDate(id: string) {
    const params: { [key: string]: string } = {
      page: '0',
      size: '1',
      bikeId: id,
    };
    this.rideService
      .getAll(params)
      .pipe(untilDestroyed(this))
      .subscribe(
        (result) => {
          this.lastRideDate = result.values() && result.values().length > 0 && result.values()[0].startTime;
        },
        (error) => console.log(error),
      );
  }
  public lastDiagDate: Date;
  private fetchLastDiagDate(id: string) {
    const params: { [key: string]: string } = {
      page: '0',
      size: '1',
    };
    this.bikeService
      .getAutodiagHistory(this.bike.id, params)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.lastDiagDate = result.values() && result.values().length > 0 && result.values()[0].createdOn;
      });
  }

  totalRidesStats: RideStats;
  private fetchRideStats(id: string): void {
    let params: any = {};
    params.bikeId = id;
    this.rideService
      .getStats(params)
      .pipe(untilDestroyed(this))
      .subscribe((resp) => {
        const stats = resp.values();
        if (!stats) {
          return;
        }
        this.totalRidesStats = stats.reduce((previousValue, currentValue, currentIndex, array) => {
          return new RideStats({
            distance: currentValue.distance + (previousValue ? previousValue.distance : 0),
            duration: currentValue.duration + (previousValue ? previousValue.duration : 0),
            count: currentValue.count + (previousValue ? previousValue.count : 0),
          });
        });
      });
  }

  totalRidesStatsSince: RideStats;
  private fetchRideStatsSince(id: string, since: Date): void {
    let params: any = {
      bikeId: id,
      since: since ? since.getTime() : 0,
    };
    this.rideService
      .getStats(params)
      .pipe(untilDestroyed(this))
      .subscribe((resp) => {
        const stats = resp.values();
        if (!stats) {
          return;
        }
        this.totalRidesStatsSince = stats.reduce((previousValue, currentValue, currentIndex, array) => {
          return new RideStats({
            distance: currentValue.distance + (previousValue ? previousValue.distance : 0),
            duration: currentValue.duration + (previousValue ? previousValue.duration : 0),
            count: currentValue.count + (previousValue ? previousValue.count : 0),
          });
        });
      });
  }

  public angellBack: AngellBack;
  private fetchAngellBack(id: string) {
    this.angellBackService
      .getCurrent(id)
      .pipe(untilDestroyed(this))
      .subscribe((resp) => {
        this.angellBack = resp;
      });
  }
}
