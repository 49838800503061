import { LinkResource, UpdateTrace } from 'src/app/modules/api/models';
import { Version } from 'src/app/pages/bike/models/bike.model';
import { BikeAutodiagError } from './bike-autodiag-history.model';

export class BikeWarning {
  id: string;
  bikeId: number;
  date: Date;
  criticity: number;
  impacts: string[];
  type: string;
  description: string;
  updateTrace: UpdateTrace;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.bikeId = data.bikeId;
      this.date = new Date(data.date);
      this.criticity = data.criticity;
      this.type = data.type;
      this.description = data.description;
      this.impacts = data.impacts;
      this.updateTrace = new UpdateTrace(data.updateTrace);
    }
  }
}
