<clr-accordion class="filters-accordion">
  <clr-accordion-panel [(clrAccordionPanelOpen)]="filtersOpened" class="filters-accordion-panel">
    <clr-accordion-title>Filters</clr-accordion-title>
    <clr-accordion-content *clrIfExpanded>
      <form
        clrForm
        [formGroup]="searchForm"
        (ngSubmit)="onSubmit(pagination)"
        [clrLayout]="'horizontal'"
        class="search-form clr-form-compact"
      >
        <div class="clr-row search-form-row">
          <div class="clr-col-xl-6 clr-col-sm-12">
            <clr-input-container>
              <label i18n>Version</label>
              <input
                size="40"
                class="free-search"
                clrInput
                type="text"
                placeholder="ex : 0.0.1"
                formControlName="searchTerm"
              />
            </clr-input-container>
          </div>

          <div class="clr-col-xl-6 clr-col-sm-12">
            <clr-combobox-container>
              <label>Types</label>
              <clr-combobox
                formControlName="types"
                name="multiSelect"
                clrMulti="true"
                placeholder="ALL if no selection"
              >
                <ng-container *clrOptionSelected="let selected">
                  {{ selected }}
                </ng-container>
                <clr-options>
                  <clr-option *clrOptionItems="let state of firmwareTypes" [clrValue]="state">
                    {{ state }}
                  </clr-option>
                </clr-options>
              </clr-combobox>
            </clr-combobox-container>
          </div>
          <div class="clr-col-xl-6 clr-col-sm-12">
            <clr-checkbox-container clrInline>
              <label>Enabled</label>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="enabledTrue" value="true" formControlName="enabledTrue" />
                <label>Yes</label>
              </clr-checkbox-wrapper>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="enabledFalse" value="false" formControlName="enabledFalse" />
                <label>No</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>
          </div>
          <div class="clr-col-xl-6 clr-col-sm-12">
            <clr-checkbox-container clrInline>
              <label>Distribution</label>
              <clr-checkbox-wrapper>
                <input
                  type="checkbox"
                  clrCheckbox
                  name="distributionMediaBLE"
                  value="BLUETOOTH"
                  formControlName="distributionMediaBLE"
                />
                <label>BLE</label>
              </clr-checkbox-wrapper>
              <clr-checkbox-wrapper>
                <input
                  type="checkbox"
                  clrCheckbox
                  name="distributionMediaLTE"
                  value="LTE"
                  formControlName="distributionMediaLTE"
                />
                <label>LTE</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>
          </div>
          <div class="clr-col-xl-6 clr-col-sm-12">
            <clr-combobox-container>
              <label>Models</label>
              <clr-combobox
                formControlName="models"
                name="multiSelect"
                clrMulti="true"
                placeholder="ALL if no selection"
                [clrLoading]="modelsLoading"
              >
                <ng-container *clrOptionSelected="let selected">
                  <cds-icon shape="bicycle" role="img"></cds-icon>
                  {{ selected?.name }}
                </ng-container>
                <clr-options>
                  <clr-option *clrOptionItems="let state of models; field: 'name'" [clrValue]="state">
                    <cds-icon shape="bicycle" role="img"></cds-icon>
                    {{ state.name }}
                  </clr-option>
                </clr-options>
              </clr-combobox>
            </clr-combobox-container>
          </div>
          <div class="clr-col-xl-6 clr-col-sm-12">
            <clr-combobox-container>
              <label>Segments</label>
              <clr-combobox
                formControlName="segments"
                name="multiSelect"
                clrMulti="true"
                placeholder="ALL if no selection"
                [clrLoading]="segmentsLoading"
                (clrInputChange)="fetchSegments($event)"
                (clrOpenChange)="$event ? fetchSegments('') : null"
              >
                <ng-container *clrOptionSelected="let selected">
                  <cds-icon shape="tags" role="img"></cds-icon>
                  {{ selected?.name }}
                </ng-container>
                <clr-options>
                  <clr-option *clrOptionItems="let state of segments; field: 'name'" [clrValue]="state">
                    <cds-icon shape="tags" role="img"></cds-icon>
                    {{ state.name }}
                  </clr-option>
                </clr-options>
              </clr-combobox>
            </clr-combobox-container>
          </div>
        </div>
        <div class="clr-row">
          <div class="clr-col-12">
            <button class="btn btn-primary search-btn" type="submit" i18n>
              <cds-icon shape="search"></cds-icon>
            </button>
          </div>
        </div>
      </form>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>

<clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
  <clr-dg-action-bar *aclCheck="'actions_firmwares'">
    <button
      *aclCheck="'actions_firmwares'"
      type="button"
      class="btn btn-sm btn-secondary"
      [routerLink]="['/bikes', 'firmwares', 'create']"
      i18n
    >
      <cds-icon shape="plus"></cds-icon>
      Upload a new firmware
    </button>
  </clr-dg-action-bar>

  <clr-dg-column i18n>Type - Version</clr-dg-column>
  <clr-dg-column i18n>Segments</clr-dg-column>
  <clr-dg-column i18n>Brands</clr-dg-column>
  <clr-dg-column i18n>Models</clr-dg-column>
  <clr-dg-column i18n>Media</clr-dg-column>
  <clr-dg-column i18n>Enabled</clr-dg-column>
  <clr-dg-column i18n>Firmware req.</clr-dg-column>
  <clr-dg-column i18n>Hardware req.</clr-dg-column>
  <clr-dg-column i18n>Option req.</clr-dg-column>

  <clr-dg-row *ngFor="let firmware of firmwares" [clrDgItem]="firmware">
    <clr-dg-action-overflow *aclCheck="'actions_firmwares'">
      <button class="action-item" appConfirm (appConfirm)="onDelete(firmware)" i18n>Delete</button>
      <button class="action-item" (click)="onDisable(firmware)" *ngIf="firmware.enabled == true" i18n>Disable</button>
      <button class="action-item" (click)="onEnable(firmware)" *ngIf="firmware.enabled == false" i18n>Enable</button>
    </clr-dg-action-overflow>
    <clr-dg-cell
      ><a [routerLink]="['/bikes', 'firmwares', 'view', firmware.id]" href
        >{{ firmware.type }} {{ firmware.version }}</a
      >
    </clr-dg-cell>
    <clr-dg-cell>
      <segment [segment]="segment" *ngFor="let segment of firmware.segments"> </segment>
      <span *ngIf="firmware.segments.length == 0" i18n>-</span>
    </clr-dg-cell>
    <clr-dg-cell>
      <tenant [name]="tenant" *ngFor="let tenant of firmware.tenants"></tenant>
      <span *ngIf="firmware.tenants.length == 0" i18n>-</span>
    </clr-dg-cell>
    <clr-dg-cell>
      <bike-model-link [model]="model" *ngFor="let model of firmware.models"></bike-model-link>
      <span *ngIf="firmware.models.length == 0" i18n>-</span>
    </clr-dg-cell>
    <clr-dg-cell>
      {{ firmware.getDistribution() }}
    </clr-dg-cell>
    <clr-dg-cell>
      <span class="label label-danger" *ngIf="firmware.enabled == false" i18n>No</span>
      <span class="label label-success" *ngIf="firmware.enabled == true" i18n>Yes</span>
    </clr-dg-cell>
    <clr-dg-cell>
      <span
        class="label label-info"
        style="font-weight: bold"
        *ngFor="let constraint of firmware.getConstraintsByType('FIRMWARE')"
      >
        {{ constraint.moduleType }}&nbsp;> {{ constraint.version }}
      </span>
      <span *ngIf="firmware.getConstraintsByType('FIRMWARE').length == 0" i18n>-</span>
    </clr-dg-cell>
    <clr-dg-cell>
      <span
        class="label label-info"
        style="font-weight: bold"
        *ngFor="let constraint of firmware.getConstraintsByType('HARDWARE')"
      >
        {{ constraint.moduleType }}&nbsp;> {{ constraint.version }}
      </span>
      <span *ngIf="firmware.getConstraintsByType('HARDWARE').length == 0" i18n>-</span>
    </clr-dg-cell>
    <clr-dg-cell>
      <span
        class="label label-info"
        style="font-weight: bold"
        *ngFor="let constraint of firmware.getConstraintsByType('OPTION')"
      >
        {{ constraint.moduleType }}&nbsp;> {{ constraint.version }}
      </span>
      <span *ngIf="firmware.getConstraintsByType('OPTION').length == 0" i18n>-</span>
    </clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer i18n>
    {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} versions
    <clr-dg-pagination #pagination [clrDgTotalItems]="total"></clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
