<h5 i18n>Bike summary</h5>
<div class="clr-row summary">
  <div class="clr-col-lg-6 clr-col-sm-12">
    <div class="summary-card">
      <div class="summary-content">
        <div class="summary-title" i18n>Cockpit</div>
        <div class="summary-value">
          {{ bike.bikeProvision.mac }}
        </div>
      </div>

      <div class="stats-action">
        <a [routerLink]="['/cockpits/view', bike.bikeProvision.mac]" href i18n>
          <cds-icon shape="detail-expand" />
        </a>
      </div>
    </div>
  </div>

  <div class="clr-col-lg-6 clr-col-sm-12">
    <div class="summary-card">
      <div class="summary-content">
        <div class="summary-title" i18n>Model</div>
        <div class="summary-value">
          <bike-model-link [model]="bike.bikeProvision.model"></bike-model-link>
        </div>
      </div>
    </div>
  </div>
  <div class="clr-col-lg-6 clr-col-sm-12">
    <div class="summary-card">
      <div class="summary-content">
        <div class="summary-title" i18n>Owner</div>
        <div class="summary-value">
          <span *ngIf="owner">{{ owner.customer.firstName }} {{ owner.customer.lastName | uppercase }}</span>
          <span *ngIf="!owner" class="label label-danger">Bike not installed</span>
        </div>
      </div>

      <div class="stats-action" *ngIf="owner">
        <a [routerLink]="['/users/view', this.owner.customer.id, 'details']" href i18n>
          <cds-icon shape="detail-expand" />
        </a>
      </div>
    </div>
  </div>
  <div class="clr-col-lg-6 clr-col-sm-12">
    <div class="summary-card">
      <div class="summary-content">
        <div class="summary-title" i18n>Color</div>
        <div class="summary-value">
          <span class="label">
            <cds-icon style="margin-right: 5px" shape="paint-roller" role="img"></cds-icon>
            {{ bike.bikeProvision.color }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="clr-col-lg-6 clr-col-sm-12">
    <div class="summary-card">
      <div class="summary-content">
        <div class="summary-title" i18n>Provisioning</div>
        <div class="summary-value">
          <span *ngIf="bike.bikeProvision.provisioned == false" class="label label-danger" i18n
            >Not fully provisioned</span
          >
          <span *ngIf="bike.bikeProvision.provisioned == true" class="label label-success" i18n>Ok</span>
        </div>
      </div>
      <div class="stats-action">
        <a [routerLink]="['/bikes/view', this.bike.id, 'provisioning']" href i18n>
          <cds-icon shape="detail-expand" />
        </a>
      </div>
    </div>
  </div>

  <div class="clr-col-lg-6 clr-col-sm-12">
    <div class="summary-card">
      <div class="summary-content">
        <div class="summary-title" i18n>Covered by</div>
        <div class="summary-value">
          <span class="label" *ngIf="bike.serviceAngellCare">
            <cds-icon shape="certificate" style="margin-right: 3px"></cds-icon>
            <span>Angell care</span>
          </span>
          <span class="label" *ngIf="angellBack">
            <cds-icon shape="certificate" style="margin-right: 3px"></cds-icon>
            <span>Angell back</span>
          </span>
          <span *ngIf="!bike.serviceAngellCare && !angellBack">-&nbsp;</span>
        </div>
      </div>

      <div class="stats-action">
        <a [routerLink]="['/bikes/view', this.bike.id, 'services']" href i18n>
          <cds-icon shape="detail-expand" />
        </a>
      </div>
    </div>
  </div>

  <div class="clr-col-lg-6 clr-col-sm-12">
    <div class="summary-card">
      <div class="summary-content">
        <div class="summary-title" i18n>Is up to date</div>
        <div class="summary-value">
          <span *ngIf="nextVersionV1Loading || nextVersionV2Loading" class="spinner spinner-inline"></span>
          <span *ngIf="!nextVersionV1Loading && !nextVersionV2Loading">
            <span
              *ngIf="nextVersion?.isUpToDate == false || nextVersionOTAV2?.remainingUpgrades > 0"
              class="label label-danger"
              i18n
              >No</span
            >
            <span
              *ngIf="nextVersion?.isUpToDate == true && nextVersionOTAV2?.remainingUpgrades == 0"
              class="label label-success"
              i18n
              >Yes</span
            >
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="clr-col-lg-6 clr-col-sm-12">
    <div class="summary-card">
      <div class="summary-content">
        <div class="summary-title" i18n>Current version</div>
        <div class="summary-value">v{{ bike.appVersion.toString() }}</div>
      </div>
    </div>
  </div>

  <div class="clr-col-lg-6 clr-col-sm-12">
    <div class="summary-card">
      <div class="summary-content">
        <div class="summary-title" i18n>Last connex.</div>
        <div class="summary-value">
          <span *ngIf="!sim.isActivated()" class="label label-danger" i18n>Sim not activated</span>
          <span
            *ngIf="sim.isActivated() && bike.lastConnectedDate"
            title="{{ bike.lastConnectedDate | date: 'dd/MM/yy HH:mm' }}"
          >
            {{ bike.lastConnectedDate | date: 'dd/MM/yy' }}
          </span>
          <span *ngIf="sim.isActivated() && !bike.lastConnectedDate" class="label label-danger">
            No connexion logged
          </span>
        </div>
      </div>
      <div class="stats-action">
        <a [routerLink]="['/bikes/view', this.bike.id, 'connectivity']" href i18n>
          <cds-icon shape="detail-expand" />
        </a>
      </div>
    </div>
  </div>
  <div class="clr-col-lg-6 clr-col-sm-12">
    <div class="summary-card">
      <div class="summary-content">
        <div class="summary-title" i18n>Last maintenance</div>
        <div class="summary-value">
          <span *ngIf="lastMaintenanceOperation != null">
            {{ lastMaintenanceOperation.endedOn | date: 'dd/MM/yy HH:mm' }} by
            {{ lastMaintenanceOperation.startedBy }}
          </span>
          <span *ngIf="lastMaintenanceOperation == null">&nbsp;-</span>
        </div>
      </div>
    </div>
  </div>
  <div class="clr-col-lg-6 clr-col-sm-12">
    <div class="summary-card">
      <div class="summary-content">
        <div class="summary-title" i18n>Last ride</div>
        <div class="summary-value">
          <span *ngIf="lastRideDate" title="{{ lastRideDate | date: 'dd/MM/YY HH:mm:ss' }}">{{
            lastRideDate | date: 'dd/MM/YY'
          }}</span>
          <span *ngIf="!lastRideDate" class="label label-danger">No ride</span>
        </div>
      </div>
      <div class="stats-action">
        <a [routerLink]="['/bikes/view', this.bike.id, 'rides']" href i18n>
          <cds-icon shape="detail-expand" />
        </a>
      </div>
    </div>
  </div>

  <div class="clr-col-lg-6 clr-col-sm-12">
    <div class="summary-card">
      <div class="summary-content">
        <div class="summary-title" i18n>Last autodiag</div>
        <div class="summary-value">
          <span *ngIf="lastDiagDate" title="{{ lastDiagDate | date: 'dd/MM/YY HH:mm:ss' }}">
            {{ lastDiagDate | date: 'dd/MM/YY' }}
          </span>
          <span *ngIf="!lastDiagDate">-&nbsp;</span>
        </div>
      </div>
      <div class="stats-action">
        <a [routerLink]="['/bikes/view', this.bike.id, 'errors']" href i18n>
          <cds-icon shape="detail-expand" />
        </a>
      </div>
    </div>
  </div>

  <div class="clr-col-lg-6 clr-col-sm-12">
    <div class="summary-card">
      <div class="summary-content">
        <div class="summary-title" i18n>Km (total)</div>
        <div class="summary-value">
          <span title="{{ totalRidesStats?.count }} rides">{{ totalRidesStats?.distance | meter }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="clr-col-lg-6 clr-col-sm-12">
    <div class="summary-card">
      <div class="summary-content">
        <div class="summary-title" i18n>Km (since last maint.)</div>
        <div class="summary-value">
          <span title="{{ totalRidesStatsSince?.count }} rides">{{ totalRidesStatsSince?.distance | meter }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
