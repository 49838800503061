import { LinkResource } from 'src/app/modules/api/models';
import { Bike } from '../../bike/models/bike.model';

export class AngellCare {
  id: string;
  startAt: Date;
  endAt: Date;
  contractNumber: string;
  valid: boolean;
  bikeStatus: Bike;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.startAt = data.startAt;
      this.endAt = data.endAt;
      this.valid = data.valid;
      this.contractNumber = data.contractNumber;

      if (data.bikeStatus) {
        this.bikeStatus = new Bike(data.bikeStatus);
      }
    }
  }
}
