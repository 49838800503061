<h1 i18n>Firmware Information</h1>

<ng-container *ngIf="firmware">
  <div class="clr-row">
    <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
      <h2 i18n>Info</h2>
      <table class="table table-vertical version-table">
        <tbody>
          <tr>
            <th i18n>Type - Version</th>
            <td>{{ firmware.type }} - {{ firmware.version }}</td>
          </tr>
          <tr>
            <th i18n>Enabled</th>
            <td>
              <span class="label label-danger" *ngIf="firmware.enabled == false" i18n>No</span>
              <span class="label label-success" *ngIf="firmware.enabled == true" i18n>Yes</span>
            </td>
          </tr>
          <tr>
            <th i18n>Distribution</th>
            <td>
              {{ firmware.getDistribution() }}
            </td>
          </tr>
          <tr>
            <th i18n>Files</th>
            <td>
              <div>
                <a href [title]="binFile.name" (click)="!!download(firmware.id, 'bin')">
                  <cds-icon *ngIf="!binFile.compression" shape="file"></cds-icon>
                  <cds-icon *ngIf="binFile.compression == 'LZMA'" shape="file-zip"></cds-icon>
                  .bin
                </a>
              </div>
              <div>
                <a href [title]="datFile.name" (click)="!!download(firmware.id, 'dat')">
                  <cds-icon shape="file"></cds-icon>
                  .dat</a
                >
              </div>
            </td>
          </tr>
          <tr *ngIf="firmware.type == 'APP'">
            <th i18n>Descriptor</th>
            <td>
              <span *ngIf="!autodiagDescriptor"> - </span>
              <span *ngIf="autodiagDescriptor">
                <a
                  href
                  aria-label="autodiag"
                  i18n
                  (click)="!!showAutodiagModal()"
                  [title]="autodiagDescriptor ? 'Show autodiag' : 'No autodiag for this version'"
                >
                  descriptor.yaml
                </a></span
              >
            </td>
          </tr>
        </tbody>
      </table>

      <form
        clrForm
        [formGroup]="updateAutodiagForm"
        class="clr-form no-padding autodiag-form"
        clrLayout="vertical"
        (ngSubmit)="updateAutodiagDescriptor()"
      >
        <clr-modal [(clrModalOpen)]="openAutodiagModal" [clrModalSize]="'lg'">
          <h3 class="modal-title">
            <span>Autodiag Descriptor</span>
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              [class.hidden]="editAutodiag"
              (click)="enterAutodiagEditMode()"
            >
              <cds-icon shape="edit" *aclCheck="'actions_firmwares'"></cds-icon>
              Edit
            </button>
          </h3>
          <div class="modal-body">
            <clr-alert [clrAlertType]="'danger'" [clrAlertClosed]="!updateAutodiagFormError">
              <div clr-alert-item class="alert-item">
                <span class="alert-text">
                  {{ updateAutodiagFormError }}
                </span>
              </div>
            </clr-alert>
            <clr-textarea-container class="clr-form-control">
              <textarea
                class="textarea-editable"
                clrTextarea
                name="description"
                formControlName="content"
                required
                [disabled]="!editAutodiag"
                [rows]="24"
                [cols]="100"
              ></textarea>
              <clr-control-error *clrIfError="'required'" i18n>This is a required field</clr-control-error>
            </clr-textarea-container>
          </div>
          <div class="modal-footer">
            <button *ngIf="!editAutodiag" type="button" class="btn btn-secondary" (click)="openAutodiagModal = false">
              Close
            </button>
            <button *ngIf="editAutodiag" type="button" class="btn btn-secondary" (click)="cancelAutodiagEditMode()">
              Cancel
            </button>
            <button *ngIf="editAutodiag" type="submit" class="btn btn-primary">Update descriptor</button>
          </div>
        </clr-modal>
      </form>

      <div *aclCheck="'actions_firmwares'">
        <button class="btn btn-danger-outline" appConfirm (appConfirm)="onDelete(firmware)">Delete</button>
        <button
          class="btn btn-danger clr-float-lg-right"
          (click)="onDisable(firmware)"
          *ngIf="firmware.enabled == true"
        >
          Disable
        </button>
        <button
          class="btn btn-success clr-float-lg-right"
          (click)="onEnable(firmware)"
          *ngIf="firmware.enabled == false"
          [disabled]="firmware.models.length == 0 || firmware.segments.length == 0"
        >
          Enable
        </button>
        <button class="btn btn-primary-outline clr-float-lg-right" (click)="onUpdate(firmware)">Update</button>
      </div>
    </div>

    <div class="clr-col-lg-3 clr-col-md-8 clr-col-12">
      <h2 i18n>Targets</h2>

      <table class="table table-vertical">
        <tbody>
          <tr>
            <th i18n>Models</th>
            <td>
              <div class="models"></div>
              <bike-model-link [model]="model" *ngFor="let model of firmware.models"></bike-model-link>
              <span *ngIf="firmware.models.length == 0" i18n>No model</span>
            </td>
          </tr>
          <tr>
            <th i18n>Segments</th>
            <td>
              <div class="segments"></div>
              <segment [segment]="segment" *ngFor="let segment of firmware.segments"></segment>
              <span *ngIf="firmware.segments.length == 0" i18n>No segment</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="clr-col-lg-3 clr-col-md-8 clr-col-12">
      <h2 i18n>Requirements</h2>

      <table class="table table-vertical">
        <tbody>
          <tr>
            <th>Firmwares</th>
            <td>
              <span
                class="label label-info"
                style="font-weight: bold"
                *ngFor="let constraint of firmware.getConstraintsByType('FIRMWARE')"
              >
                {{ constraint.moduleType }}&nbsp;> {{ constraint.version }}
              </span>
              <span *ngIf="firmware.getConstraintsByType('FIRMWARE').length == 0" i18n>-</span>
            </td>
          </tr>
          <tr>
            <th>Hardware</th>
            <td>
              <span
                class="label label-info"
                style="font-weight: bold"
                *ngFor="let constraint of firmware.getConstraintsByType('HARDWARE')"
              >
                {{ constraint.moduleType }}&nbsp;> {{ constraint.version }}
              </span>
              <span *ngIf="firmware.getConstraintsByType('HARDWARE').length == 0" i18n>-</span>
            </td>
          </tr>
          <tr>
            <th>Option</th>
            <td>
              <span
                class="label label-info"
                style="font-weight: bold"
                *ngFor="let constraint of firmware.getConstraintsByType('OPTION')"
              >
                {{ constraint.moduleType }}&nbsp;> {{ constraint.version }}
              </span>
              <span *ngIf="firmware.getConstraintsByType('OPTION').length == 0" i18n>-</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- .clr-row -->
</ng-container>
