<h1 i18n>Battery {{ sn }}</h1>
<div *ngIf="sn" class="clr-row">
  <div class="clr-col-lg-4 clr-col-12">
    <h3 i18n>Battery</h3>
    <table class="table table-vertical" *ngIf="currentBattery">
      <tbody>
        <tr>
          <th i18n>Serial Number</th>
          <td>
            {{ currentBattery.serialNumber }}
          </td>
        </tr>
        <tr>
          <th i18n>Color</th>
          <td>
            {{ currentBattery.color }}
          </td>
        </tr>
        <tr>
          <th i18n>LockCode</th>
          <td>
            {{ currentBattery.lockCode }}
          </td>
        </tr>
        <tr>
          <th i18n>Provisioned on</th>
          <td>
            {{ currentBattery.provisionedOn | date: 'dd/MM/yy HH:mm' }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="clr-col-lg-4 clr-col-12">
    <h3 i18n>Core pack</h3>
    <table class="table table-vertical" *ngIf="currentBattery && currentBattery.corepack">
      <tbody>
        <tr>
          <th i18n>Serial Number</th>
          <td>
            {{ currentBattery.corepack?.serialNumber }}
          </td>
        </tr>
        <tr>
          <th i18n>Manufacturer</th>
          <td>
            <span class="label">
              {{ currentBattery.corepack?.manufacturer }}
            </span>
          </td>
        </tr>
        <tr>
          <th i18n>Software version</th>
          <td>
            {{ currentBattery.corepack?.softwareVersion }}
          </td>
        </tr>
        <tr>
          <th i18n>Hardware version</th>
          <td>
            {{ currentBattery.corepack?.hardwareVersion }}
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="!currentBattery || !currentBattery.corepack">-</div>
  </div>

  <div class="clr-col-lg-4 clr-col-12">
    <h3 i18n>Bikes pairing</h3>
    <table class="table" *ngIf="batteriesConnections && batteriesConnections.length > 0">
      <thead>
        <tr>
          <th i18n>Bike id</th>
          <th i18n>Nb of conn.</th>
          <th i18n>1st connection</th>
          <th i18n>Last connection</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let batteryConnection of batteriesConnections">
          <td>
            <a [routerLink]="['/bikes/view/', batteryConnection.bikeId, 'battery']">
              {{ batteryConnection.bikeId }}
            </a>
          </td>
          <td>{{ batteryConnection.count }}</td>
          <td>{{ batteryConnection.firstConnection | date: 'dd/MM/yy HH:mm' }}</td>
          <td>{{ batteryConnection.lastConnection | date: 'dd/MM/yy HH:mm' }}</td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="!batteriesConnections || batteriesConnections.length <= 0">-</div>
  </div>
  <div class="clr-col-lg-12 clr-col-12">
    <h3 i18n>Provisioning History</h3>
    <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
      <clr-dg-column i18n>Provisioned on</clr-dg-column>
      <clr-dg-column i18n>Superseded on</clr-dg-column>
      <clr-dg-column i18n>Serial Number</clr-dg-column>
      <clr-dg-column i18n>Core Pack SN</clr-dg-column>
      <clr-dg-column i18n>Software version</clr-dg-column>
      <clr-dg-column i18n>Hardware version</clr-dg-column>
      <clr-dg-column i18n>Color</clr-dg-column>
      <clr-dg-column i18n>Lock code</clr-dg-column>
      <clr-dg-column i18n>Test report</clr-dg-column>

      <clr-dg-row *ngFor="let cockpit of batteryHistory">
        <clr-dg-cell>{{ cockpit.provisionedOn | date: 'dd/MM/yy HH:mm' }}</clr-dg-cell>
        <clr-dg-cell>{{ cockpit.supersededOn ? (cockpit.supersededOn | date: 'dd/MM/yy HH:mm') : '-' }}</clr-dg-cell>
        <clr-dg-cell>{{ cockpit.serialNumber }}</clr-dg-cell>
        <clr-dg-cell>{{ cockpit.corepack?.serialNumber }}</clr-dg-cell>
        <clr-dg-cell>{{ cockpit.corepack?.softwareVersion }}</clr-dg-cell>
        <clr-dg-cell>{{ cockpit.corepack?.hardwareVersion }}</clr-dg-cell>
        <clr-dg-cell>{{ cockpit.color }}</clr-dg-cell>
        <clr-dg-cell>{{ cockpit.lockCode }}</clr-dg-cell>
        <clr-dg-cell>
          <button
            type="button"
            class="btn btn-icon btn-outline btn-sm show-more"
            (click)="testReportShowMore = true; selectedTestReport = cockpit.testReport"
          >
            <cds-icon shape="eye"></cds-icon>
          </button>
        </clr-dg-cell>
      </clr-dg-row>

      <clr-dg-footer i18n>
        {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} batteries
        <clr-dg-pagination #pagination [clrDgTotalItems]="total" [clrDgPageSize]="15"></clr-dg-pagination>
      </clr-dg-footer>
    </clr-datagrid>
  </div>
  <clr-modal [(clrModalOpen)]="testReportShowMore" [clrModalSize]="'lg'">
    <h3 class="modal-title">
      <span>Test Report</span>
    </h3>
    <div class="modal-body">
      <div class="clr-row">
        <div class="clr-col-12">{{ selectedTestReport | json }}</div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="testReportShowMore = false">Close</button>
    </div>
  </clr-modal>
</div>
