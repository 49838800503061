<clr-main-container>
  <clr-header>
    <div class="branding">
      <a class="nav-link" routerLink="/">
        <span class="title"> Angell BO </span>
      </a>
    </div>
    <div class="header-actions">
      <a *ngIf="!isAuthenticated" class="nav-link nav-text" routerLink="/account/login" routerLinkActive="active" i18n
        >Login</a
      >
      <clr-dropdown *ngIf="isAuthenticated">
        <button class="dropdown-toggle btn btn-primary" clrDropdownTrigger>
          <cds-icon shape="user" size="md"></cds-icon>
          <cds-icon shape="caret" direction="down"></cds-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
          <a href="#" (click)="onLogout($event)" clrDropdownItem i18n>Log out</a>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
  </clr-header>

  <div class="content-container">
    <div class="content-area">
      <router-outlet></router-outlet>
    </div>

    <clr-vertical-nav *ngIf="isAuthenticated" [clr-nav-level]="2">
      <a clrVerticalNavLink [routerLink]="['/dashboard']" routerLinkActive="active">
        <cds-icon clrVerticalNavIcon shape="dashboard"></cds-icon>
        <span class="nav-text" i18n>Dashboard</span>
      </a>

      <label class="nav-header" i18n>Bikes</label>

      <a
        *aclCheck="'menu_bikesList'"
        clrVerticalNavLink
        [routerLink]="['/bikes', 'lists']"
        [appRouterLinkMatch]="{ active: '^/bikes/(lists|imports|view/\\d+)?$' }"
      >
        <cds-icon clrVerticalNavIcon shape="view-list"></cds-icon>
        <span class="nav-text" i18n>Bikes</span>
      </a>

      <a
        *aclCheck="'menu_cockpitsList'"
        clrVerticalNavLink
        [routerLink]="['/cockpits', 'lists']"
        [appRouterLinkMatch]="{ active: '^/cockpits/(lists|view/\\d+)?$' }"
      >
        <cds-icon clrVerticalNavIcon shape="tablet"></cds-icon>
        <span class="nav-text" i18n>Cockpits</span>
      </a>

      <a *aclCheck="'menu_bikesModel'" clrVerticalNavLink [routerLink]="['/sims']" routerLinkActive="active">
        <cds-icon clrVerticalNavIcon shape="view-cards"></cds-icon>
        <span class="nav-text" i18n>Sim cards</span>
      </a>

      <a *aclCheck="'menu_bikesModel'" clrVerticalNavLink [routerLink]="['/batteries']" routerLinkActive="active">
        <cds-icon clrVerticalNavIcon shape="battery"></cds-icon>
        <span class="nav-text" i18n>Batteries</span>
      </a>

      <a *aclCheck="'menu_bikesModel'" clrVerticalNavLink [routerLink]="['/bikes', 'models']" routerLinkActive="active">
        <cds-icon clrVerticalNavIcon shape="bicycle"></cds-icon>
        <span class="nav-text" i18n>Models</span>
      </a>

      <label class="nav-header" i18n>OTA</label>
      <a *aclCheck="'menu_bikesVersions'" clrVerticalNavLink [routerLink]="['versions']" routerLinkActive="active">
        <cds-icon clrVerticalNavIcon shape="download"></cds-icon>
        <span class="nav-text" i18n>Versions</span>
      </a>

      <a
        *aclCheck="'menu_bikesSegments'"
        clrVerticalNavLink
        [routerLink]="['/bikes', 'segments']"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <cds-icon clrVerticalNavIcon shape="tags"></cds-icon>
        <span class="nav-text" i18n>Segments</span>
      </a>

      <label class="nav-header" i18n>Users</label>

      <a
        *aclCheck="'menu_usersList'"
        clrVerticalNavLink
        [routerLink]="['/users', 'lists']"
        [appRouterLinkMatch]="{ active: '^/users/(lists|view/[a-f0-9\\-]+|edit/[a-f0-9\\-]+)?$' }"
      >
        <cds-icon clrVerticalNavIcon shape="users"></cds-icon>
        <span class="nav-text" i18n>Users</span>
      </a>

      <a
        *aclCheck="'menu_adminsList'"
        clrVerticalNavLink
        [routerLink]="['/users', 'admins', 'lists']"
        [appRouterLinkMatch]="{ active: '^/users/admins/(lists|view/[a-f0-9\\-]+|edit/[a-f0-9\\-]+)?$' }"
      >
        <cds-icon clrVerticalNavIcon shape="users"></cds-icon>
        <span class="nav-text" i18n>Admins</span>
      </a>

      <label class="nav-header" i18n>Pro</label>

      <a
        *aclCheck="'menu_proList'"
        clrVerticalNavLink
        [routerLink]="['/pro/shops']"
        [appRouterLinkMatch]="{ active: '^/pro/(lists|view/[a-f0-9\\-]+|edit/[a-f0-9\\-]+)?$' }"
      >
        <cds-icon clrVerticalNavIcon shape="store"></cds-icon>
        <span class="nav-text" i18n>Shops</span>
      </a>
      <a
        *aclCheck="'menu_proList'"
        clrVerticalNavLink
        [routerLink]="['/pro/retailers']"
        [appRouterLinkMatch]="{ active: '^/pro/(lists|view/[a-f0-9\\-]+|edit/[a-f0-9\\-]+)?$' }"
      >
        <cds-icon clrVerticalNavIcon shape="users"></cds-icon>
        <span class="nav-text" i18n>Retailers</span>
      </a>
      <label class="nav-header" i18n>Notifications</label>

      <a
        *aclCheck="'menu_notificationsList'"
        clrVerticalNavLink
        [routerLink]="['/users', 'notifications', 'lists']"
        [appRouterLinkMatch]="{ active: '^/users/notifications/(lists|view/[a-f0-9\\-]+|edit/[a-f0-9\\-]+)?$' }"
      >
        <cds-icon clrVerticalNavIcon shape="bell"></cds-icon>
        <span class="nav-text" i18n>Previews</span>
      </a>

      <label class="nav-header" i18n>Services</label>

      <a
        *aclCheck="'menu_services'"
        clrVerticalNavLink
        [routerLink]="['/services', 'angell-back', 'list']"
        [appRouterLinkMatch]="{ active: '^/services/angell-back/.*$' }"
      >
        <cds-icon clrVerticalNavIcon shape="certificate"></cds-icon>
        <span class="nav-text" i18n>Angell Back</span>
      </a>

      <a
        *aclCheck="'menu_services'"
        clrVerticalNavLink
        [routerLink]="['/services', 'angell-care', 'list']"
        [appRouterLinkMatch]="{ active: '^/services/angell-care/.*$' }"
      >
        <cds-icon clrVerticalNavIcon shape="certificate"></cds-icon>
        <span class="nav-text" i18n>Angell Care</span>
      </a>

      <label *aclCheck="'menu_newsCenter'" class="nav-header" i18n>News</label>

      <a
        *aclCheck="'menu_newsCenter'"
        clrVerticalNavLink
        [routerLink]="['/news', 'templates', 'list']"
        [appRouterLinkMatch]="{ active: '^/news/templates/.*$' }"
      >
        <cds-icon clrVerticalNavIcon shape="picture"></cds-icon>
        <span class="nav-text" i18n>Templates</span>
      </a>
      <a
        *aclCheck="'menu_newsCenter'"
        clrVerticalNavLink
        [routerLink]="['/news', 'campaigns', 'list']"
        [appRouterLinkMatch]="{ active: '^/news/campaigns/.*$' }"
      >
        <cds-icon clrVerticalNavIcon shape="export"></cds-icon>
        <span class="nav-text" i18n>Campaigns</span>
      </a>
    </clr-vertical-nav>
  </div>
</clr-main-container>
