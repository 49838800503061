import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../../models/user.model';
import { UserSettings } from '../../../models/user-settings.model';
import { Bike } from '../../../../bike/models/bike.model';
import { UserKnownPhone } from '../../../models/user-known-phone.model';
import { CustomerService } from '../../../services/customer.service';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-view-user',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit, OnDestroy {
  public user: User;
  public settings: UserSettings;
  public bikes: Bike[];
  public knownPhones: UserKnownPhone[];

  constructor(private router: Router, private route: ActivatedRoute, private customerService: CustomerService) {}

  ngOnInit(): void {
    this.route.parent.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.user = data.user;
      this.fetchBikesOfUser(this.user);
      this.fetchUserSettings(this.user);
      this.fetchUserKnownPhones(this.user);
    });
  }

  ngOnDestroy(): void {}

  private fetchUser(id: string): Observable<User> {
    return this.customerService.getById(id);
  }
  private fetchUserSettings(user: User): void {
    this.customerService
      .getSettings(user.id)
      .pipe(untilDestroyed(this))
      .subscribe((resp) => {
        this.settings = resp;
      });
  }

  private fetchUserKnownPhones(user: User): void {
    this.customerService
      .getKnownPhones(user.id)
      .pipe(untilDestroyed(this))
      .subscribe((resp) => {
        this.knownPhones = resp;
      });
  }

  private fetchBikesOfUser(user: User): void {
    this.customerService
      .getUserBikes(user.id)
      .pipe(untilDestroyed(this))
      .subscribe((resp) => {
        const bikes = resp.values();
        this.bikes = bikes;
      });
  }

  private remove(user: User): void {
    this.customerService
      .remove(user.id)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.fetchUser(user.id)
          .pipe(untilDestroyed(this))
          .subscribe((u) => (this.user = u));
      });
  }

  private restore(user: User): void {
    this.customerService
      .restore(user.id)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.fetchUser(user.id)
          .pipe(untilDestroyed(this))
          .subscribe((u) => (this.user = u));
      });
  }

  public onRestore(user: User): void {
    console.log('restore', user);

    this.restore(user);
  }

  public onDelete(user: User): void {
    if (window.confirm('Are you sure you want to delete this user?')) {
      console.log('delete', user);
      this.remove(user);
    } else {
      console.log('not delete', user);
    }
  }

  public revokeDevice(device: UserKnownPhone): void {
    this.customerService
      .revokeDevice(this.user.id, device.latestRefreshToken)
      .pipe(untilDestroyed(this))
      .subscribe(() => (device.latestRefreshToken = null));
  }

  public revokeAccessToken(device: UserKnownPhone): void {
    this.customerService
      .revokeAccessToken(this.user.id, device.latestAccessToken)
      .pipe(untilDestroyed(this))
      .subscribe(() => (device.latestAccessToken = null));
  }
}
