import { UpdateTrace } from '../../../modules/api/models';

export class SimCard {
  iccid: string;
  phoneNumber: string;
  provider: string;
  activationRequestedBy: string;
  activationRequestedOn: Date;
  activatedBy: string;
  activatedOn: Date;
  terminatedBy: string;
  terminatedOn: Date;
  suspendedBy: string;
  suspendedOn: Date;
  updateTrace: UpdateTrace;

  constructor(data?: any) {
    if (data) {
      this.iccid = data.iccid;
      this.phoneNumber = data.phoneNumber;
      this.provider = data.provider;
      this.activationRequestedBy = data.activationRequestedBy;
      this.activationRequestedOn = data.activationRequestedOn;
      this.activatedBy = data.activatedBy;
      this.activatedOn = data.activatedOn != null ? new Date(data.activatedOn) : null;
      this.suspendedBy = data.suspendedBy;
      this.suspendedOn = data.suspendedOn != null ? new Date(data.suspendedOn) : null;
      this.terminatedBy = data.terminatedBy;
      this.terminatedOn = data.terminatedOn != null ? new Date(data.terminatedOn) : null;
      this.updateTrace = new UpdateTrace(data.updateTrace);
    }
  }
  getState() {
    if (this.terminatedOn) {
      return 'Terminated';
    }
    if (this.suspendedOn) {
      return 'Suspended';
    }
    if (this.activatedOn) {
      return 'Active';
    }

    if (this.activationRequestedOn) {
      return 'Activation requested';
    }
    return 'In stock';
  }

  isActivated() {
    // If sim card has activation date but no activation requested date, that means it's the old fashion way : to know if simcard is activated, we use the presence of phone number.
    if (!this.activationRequestedOn && this.activatedOn) {
      return !!this.phoneNumber;
    }
    // else, we check if activated on is filled or not.
    return !!this.activatedOn;
  }
}
