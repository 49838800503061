import {LinkResource} from 'src/app/modules/api/models';

export class Ride extends LinkResource {
  id: string;
  year: number;
  month: number;
  duration: number;
  endTime: Date;
  startTime: Date;
  profile: number;
  geometry: string;
  distance: number;
  source: string;
  locationPermissionStatus: string;
  bikeId: string;
  bikeName: string;

  constructor(data?: any) {
    super(data._links);
    this.id = data.id;
    this.year = data.year;
    this.month = data.month;
    this.duration = data.duration;
    this.endTime = data.endTime;
    this.startTime = data.startTime;
    this.profile = data.profile;
    this.geometry = data.geometry;
    this.distance = data.distance;
    this.source = data.source;
    this.locationPermissionStatus = data.locationPermissionStatus;
    this.bikeId = data.bikeId;
    this.bikeName = data.bikeName;
  }
}
