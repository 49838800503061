import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {}

  getWeekNb(date: Date) {
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
  }

  // Returns the four-digit year corresponding to the ISO week of the date.
  getWeekYear(date: Date) {
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    return date.getFullYear();
  }

  addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  formatDateToCdsDatePickerFormat(now: Date) {
    return `${now.getFullYear()}-${now.getMonth() < 10 ? '0' : ''}${now.getMonth() + 1}-${
      now.getDate() < 10 ? '0' : ''
    }${now.getDate()}`;
  }

  getDateFromCdsDatePickerFormat(date: string, hours: number, minutes: number, seconds: number) {
    const fromDateParts = date.split('-');
    return new Date(+fromDateParts[0], +fromDateParts[1] - 1, +fromDateParts[2], hours, minutes, seconds);
  }
}
