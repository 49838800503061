<clr-combobox-container>
  <label>Shop</label>
  <clr-combobox
    [(ngModel)]="shop"
    (ngModelChange)="onModelChange()"
    [clrLoading]="loading"
    (clrInputChange)="fetchShops($event)"
    (clrOpenChange)="$event ? fetchShops('') : null"
  >
    <clr-options>
      <clr-option *clrOptionItems="let shop of shops; field: 'name'" [clrValue]="shop">
        <span class="label label-info">
          <cds-icon style="margin-right: 5px" shape="store" role="img"></cds-icon>
          {{ shop.name }} ({{ shop.email }})
        </span>
      </clr-option>
    </clr-options>
  </clr-combobox>
</clr-combobox-container>
