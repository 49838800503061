<table class="table" *ngIf="bikes">
  <thead>
    <tr>
      <th i18n>Name</th>
      <th i18n>MAC</th>
      <th i18n>Model</th>
      <th i18n>Version (app/ble/image)</th>
      <th i18n>Role</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let bike of bikes">
      <td>
        <a [routerLink]="['/bikes', 'view', bike.id]" href>{{ getNameOfBike(bike) }}</a>
      </td>
      <td>
        <a [routerLink]="['/bikes', 'view', bike.id]" href>{{ bike.bikeProvision.mac }}</a>
      </td>
      <td>
        <a [routerLink]="['/bikes', 'models', 'view', bike.bikeProvision.model.id]" href>{{
          bike.bikeProvision.model.name
        }}</a>
      </td>
      <td>
        {{ bike.appVersion.toString() }}/{{ bike.bluetoothVersion.toString() }} /{{ bike.imageVersion.toString() }}
      </td>
      <td>{{ getRoleOfBike(bike) }}</td>
    </tr>
  </tbody>
</table>
<clr-alert *ngIf="!bikes" [clrAlertClosable]="false">
  <clr-alert-item>
    <span class="alert-text" i18n>No bikes for now</span>
  </clr-alert-item>
</clr-alert>
