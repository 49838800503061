<div *ngIf="bike">
  <div class="clr-row">
    <div class="clr-col-lg-8 clr-col-md-12 clr-col-12">
      <h2 i18n>GSM History</h2>

      <div *ngIf="!GSMHistoryLoaded">
        <button (click)="loadGSMHistory()" class="btn btn-outline" i18n type="button">
          <cds-icon shape="sync"></cds-icon>
          Load
        </button>
      </div>
      <table *ngIf="GSMHistoryLoaded" class="table">
        <thead>
          <tr>
            <th i18n>Date</th>
            <th i18n>Operator</th>
            <th i18n>Signal</th>
            <th i18n>ICCID</th>
            <th i18n>IMSI</th>
            <th i18n>IMEI</th>
            <th i18n>Technology</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of GSMHistoryItems">
            <td>{{ item.createdDate | date: 'dd/MM/yy HH:mm:ss' }}</td>
            <td>{{ item.operatorName }} ({{ item.operatorCode }})</td>
            <td>
              <span *ngIf="item.techno == 'GSM'">{{ item.rssi }}</span>
              <span *ngIf="item.techno == 'CATM'">{{ item.rsrq }}</span>
            </td>
            <td>
              <a [routerLink]="['/sims', 'view', item.iccid]" href>
                {{ item.iccid }}
              </a>
            </td>
            <td>{{ item.imsi }}</td>
            <td>{{ item.imei }}</td>
            <td>
              <span *ngIf="item.techno == 'GSM'">GPRS (2G)</span>
              <span *ngIf="item.techno == 'CATM'">LTE-M (4G)</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- .clr-row -->
</div>
