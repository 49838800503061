import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SimCardService } from '../../../services/simcard.service';
import { SimCard } from '../../../models/simcard.model';
import { Bike } from '../../../models/bike.model';
import { MatoomaStats } from '../../../models/matooma-stats.model';
import { CockpitService } from '../../../services/cockpit.service';
import { Cockpit } from '../../../models/cockpit.model';
import { ClrDatagridStateInterface } from '@clr/angular';

interface SIMStat {
  date?: string;
  dataOffer?: number;
  dataCapacity?: number;
  smsReceived?: number;
}

@UntilDestroy()
@Component({
  selector: 'app-view-model',
  templateUrl: './view-sim.component.html',
  styleUrls: ['./view-sim.component.scss'],
})
export class ViewSimComponent implements OnInit, OnDestroy {
  public simCard: SimCard;

  public loading = true;

  constructor(
    private route: ActivatedRoute,
    private simCardService: SimCardService,
    private cockpitService: CockpitService,
  ) {}

  ngOnInit(): void {
    this.route.params.pipe(untilDestroyed(this)).subscribe((params) => {
      this.fetchSimCard(params.iccid);
      this.fetchAssociatedBikes(params.iccid, null);
    });
  }

  ngOnDestroy(): void {}

  public fetchSimCard(iccid: string): void {
    this.loading = true;

    this.simCardService
      .getByIccid(iccid, null)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result) => {
          this.simCard = result;
        },
        complete: () => {
          this.loading = false;
        },
      });
  }

  public cockpits: Cockpit[];
  public totalCockpits: number;
  public loadingCockpits = true;

  public fetchAssociatedBikes(iccid, state: ClrDatagridStateInterface) {
    this.loadingCockpits = true;
    const params = {
      page: state.page.current - 1,
      size: state.page.size,
      iccid,
    };

    this.cockpitService
      .getAll(params)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result) => {
          this.cockpits = result.values();
          this.totalCockpits = result.page.totalElements || this.cockpits.length;
        },
        complete: () => {
          this.loadingCockpits = false;
        },
      });
  }
}
