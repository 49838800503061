import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Shop } from '../../models/shop.model';

@UntilDestroy()
@Component({
  selector: 'shop-details',
  templateUrl: './shop-details.component.html',
  styleUrls: ['./shop-details.component.scss'],
})
export class ShopDetailsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  @Input()
  public shop: Shop;

  ngOnDestroy(): void {}

  getFullAddress() {
    return `${this.shop.address.line1}${this.shop.address.line2 ? ` ${this.shop.address.line2}` : ''},${
      this.shop.address.zipCode
    } ${this.shop.address.city}, ${this.shop.address.country}`;
  }
}
