<div class="clr-row timeline-filters">
  <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
    <clr-input-container>
      <label i18n>Search</label>
      <input
        size="40"
        class="free-search"
        clrInput
        type="text"
        placeholder="You can search a term in title"
        [(ngModel)]="filters.freeSearch"
        (input)="filtersChanged()"
      />
    </clr-input-container>
  </div>
  <div class="clr-col-lg-4 clr-col-md-8 clr-col-12">
    <clr-checkbox-container>
      <label>Show ?</label>
      <clr-checkbox-wrapper>
        <input
          type="checkbox"
          (change)="filtersChanged()"
          clrCheckbox
          name="options-1"
          value="errors"
          [(ngModel)]="filters.lastRides"
        />
        <label>Last 10 rides</label>
      </clr-checkbox-wrapper>
      <clr-checkbox-wrapper>
        <input
          type="checkbox"
          (change)="filtersChanged()"
          clrCheckbox
          name="options-1"
          value="connection"
          [(ngModel)]="filters.lastConnexions"
        />
        <label>Last connections</label>
      </clr-checkbox-wrapper>
      <clr-checkbox-wrapper>
        <input
          type="checkbox"
          (change)="filtersChanged()"
          clrCheckbox
          name="options-3"
          value="bikeHistory"
          [(ngModel)]="filters.bikeHistory"
        />
        <label>Bikes history</label>
      </clr-checkbox-wrapper>
      <clr-checkbox-wrapper>
        <input
          type="checkbox"
          (change)="filtersChanged()"
          clrCheckbox
          name="options-3"
          value="cgu"
          [(ngModel)]="filters.cgu"
        />
        <label>Cgu validations</label>
      </clr-checkbox-wrapper>
    </clr-checkbox-container>
  </div>
</div>
<ul class="clr-timeline clr-timeline-vertical" *ngIf="filteredEvents.length > 0">
  <li class="clr-timeline-step disabled" *ngFor="let event of filteredEvents">
    <div class="clr-timeline-step-header" [title]="event.date | date: 'dd/MM/YY HH:mm:ss ZZZZZ'">
      {{ event.date | date: 'dd/MM/YY HH:mm:ss' }}
    </div>
    <cds-icon shape="{{ event.status }}" aria-label="Not started"></cds-icon>
    <div class="clr-timeline-step-body">
      <span class="clr-timeline-step-title" [innerHTML]="event.title"></span>
      <span class="clr-timeline-step-description" *ngIf="event.description" [innerHTML]="event.description"></span>
    </div>
  </li>
</ul>
<div *ngIf="filteredEvents.length === 0">No event found.</div>
