import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomerService } from '../../../../services/customer.service';
import { ClrDatagridStateInterface } from '@clr/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopService } from '../../../../services/shop.service';
import { Shop } from '../../../../models/shop.model';

@UntilDestroy()
@Component({
  selector: 'app-list-pro-list-shops',
  templateUrl: './list-shops.component.html',
  styleUrls: ['./list-shops.component.scss'],
})
export class ListShopsComponent implements OnInit, OnDestroy {
  public shops: Shop[];
  public total: number;
  public loading = true;

  public searchForm: UntypedFormGroup;
  public filtersOpened: boolean = true;

  public afterSalesMode: boolean = false;

  constructor(
    private customerService: CustomerService,
    private shopService: ShopService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.afterSalesMode = data.mode == 'after-sales';
    });
    this.searchForm = this.formBuilder.group({
      searchTerm: '',
      classification: '',
      deleted: '',
    });
  }

  ngOnDestroy(): void {}

  public refresh(state: ClrDatagridStateInterface): void {
    console.log('table state:', state);

    this.loading = true;

    let params = {
      page: state.page.current - 1,
      size: state.page.size,
    };

    const searchTerm = this.searchForm.get('searchTerm').value;
    if (searchTerm) {
      params['searchTerm'] = searchTerm;
    }
    const classification = this.searchForm.get('classification').value;
    if (classification && classification !== 'any') {
      params['classification'] = classification;
    }

    this.shopService
      .getAll(params)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        console.log('Result', result);

        this.shops = result.values();
        this.total = result.page.totalElements || this.shops?.length;
        this.loading = false;
      });
  }

  onCreate() {
    this.router.navigate([`/pro/shops/form`]);
  }
}
