import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CustomerService } from '../../services/customer.service';
import { User } from '../../models/user.model';

@Injectable({ providedIn: 'root' })
export class ViewCustomerResolver  {
  constructor(private customerService: CustomerService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
    return this.customerService.getById(route.params.id);
  }
}
