import { LinkResource } from 'src/app/modules/api/models';

export class RideStats extends LinkResource {
  month: number;
  year: number;
  distance: number; // meter
  duration: number; // second
  count: number;

  constructor(data?: any) {
    super(data._links);
    this.month = data.month < 10 ? `0${data.month}` : data.month;
    this.year = data.year <= 99 ? `20${data.year}` : data.year;
    this.distance = data.distance;
    this.duration = data.duration;
    this.count = data.count;
  }
}

export interface RideGlobalStats {
  date: string;
  distance: number; // meter
  duration: number; // second
  speed: number;
  users: number;
  rides: number;
  avgDurationByRide: number;
  avgDistanceByRide: number;
  avgDurationByUser: number;
  avgDistanceByUser: number;
  avgRideByUser: number;
}
