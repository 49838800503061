import { Component, OnInit, OnDestroy } from '@angular/core';
import { BikeModel } from '../../../models/bike-model.model';
import { BikeModelService } from '../../../services/bike-model.service';
import { ClrDatagridStateInterface } from '@clr/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-list-model',
  templateUrl: './list-model.component.html',
  styleUrls: ['./list-model.component.scss'],
})
export class ListModelComponent implements OnInit, OnDestroy {
  public models: BikeModel[];
  public total: number;
  public loading = true;

  constructor(private bikeModelService: BikeModelService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  public refresh(state: ClrDatagridStateInterface): void {
    console.log('table state:', state);

    this.loading = true;

    // We convert the filters from an array to a map,
    // because that's what our backend-calling service is expecting
    const filters: { [prop: string]: any } = {};

    if (state.filters) {
      for (const filter of state.filters) {
        const { property, value } = filter as { property: string; value: string };
        filters[property] = value;
      }
    }

    console.log('table filters:', filters);

    const params = filters;

    params.page = state.page.current - 1;
    params.size = state.page.size;

    this.bikeModelService
      .getAll(params)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.models = result.values();
        this.total = result.page.totalElements || this.models.length;
        this.loading = false;
      });
  }
}
