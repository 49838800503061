import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/modules/api/services/api.service';
import { Observable, of } from 'rxjs';
import { ResponseEntities } from 'src/app/modules/api/models';
import { catchError, map, tap } from 'rxjs/operators';
import { definitiveCache } from 'src/app/modules/api/definitive-cache/definitive-cache';
import { AngellBack } from '../models/angell-back.model';
import { AngellBackImportResult } from '../models/import-result.model';

@Injectable({
  providedIn: 'root',
})
export class AngellBackService {
  constructor(private api: ApiService) {}

  private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error?.message} with error : ${error}`);

      return of(result as T);
    };
  }

  public getAll(params: { [param: string]: string }): Observable<ResponseEntities<AngellBack>> {
    return this.api
      .get<ResponseEntities<AngellBack>>(`/services/angell-back`, {
        params,
      })
      .pipe(
        tap((a) => console.log('ServiceAngellBack:', a)),
        map((a) => new ResponseEntities<AngellBack>(AngellBack, a)),
        catchError(this.handleError<ResponseEntities<AngellBack>>('all')),
        definitiveCache(),
      );
  }

  public create(body): Observable<AngellBack> {
    return this.api.post<AngellBack>('/services/angell-back', body).pipe(
      map((a) => new AngellBack(a)),
      catchError(this.handleError<AngellBack>('create')),
      definitiveCache(),
    );
  }

  public importDeliveryDate(body): Observable<AngellBackImportResult[]> {
    return this.api.post<AngellBackImportResult[]>(`/orders/deliveryDate/import`, body).pipe(
      map((a) => a.map((a) => new AngellBackImportResult(a))),
      definitiveCache(),
    );
  }

  public import(body): Observable<AngellBackImportResult[]> {
    return this.api.post<AngellBackImportResult[]>(`/services/angell-back/import`, body).pipe(
      map((a) => a.map((a) => new AngellBackImportResult(a))),
      catchError(this.handleError<AngellBackImportResult[]>('all')),
      definitiveCache(),
    );
  }

  public update(id: string, body): Observable<AngellBack> {
    return this.api.put<AngellBack>(`/services/angell-back/${id}`, body).pipe(
      map((a) => new AngellBack(a)),
      catchError(this.handleError<AngellBack>('update')),
      definitiveCache(),
    );
  }

  public get(id: string): Observable<AngellBack> {
    return this.api.get<AngellBack>(`/services/angell-back/${id}`).pipe(
      map((a) => new AngellBack(a)),
      catchError(this.handleError<AngellBack>('get')),
      definitiveCache(),
    );
  }

  public getByBikeId(id: string): Observable<ResponseEntities<AngellBack>> {
    return this.api.get<AngellBack>(`services/angell-back?bikeId=${id}`).pipe(
      tap((a) => console.log('ServiceAngellBack:', a)),
      map((a) => new ResponseEntities<AngellBack>(AngellBack, a)),
      catchError(this.handleError<ResponseEntities<AngellBack>>('all')),
      definitiveCache(),
    );
  }

  public delete(id: string): Observable<any> {
    return this.api.delete<AngellBack>(`/services/angell-back/${id}`);
  }
  public getCurrent(bikeId: string): Observable<any> {
    return this.api.get<AngellBack>(`/bikes/${bikeId}/angell-back`);
  }
}
