<h1>Versions</h1>

<div>
  <clr-tabs>
    <clr-tab *ngFor="let subComponent of tabsData">
      <button clrTabLink [routerLink]="subComponent.path">{{ subComponent.label }}</button>
    </clr-tab>
  </clr-tabs>
  <div>
    <router-outlet></router-outlet>
  </div>
</div>
