<h1 i18n>Batteries</h1>

<clr-accordion class="filters-accordion">
  <clr-accordion-panel [(clrAccordionPanelOpen)]="filtersOpened" class="filters-accordion-panel">
    <clr-accordion-title>Filters</clr-accordion-title>
    <clr-accordion-content *clrIfExpanded>
      <form
        clrForm
        [formGroup]="searchForm"
        (ngSubmit)="onSubmit(pagination)"
        [clrLayout]="'horizontal'"
        class="search-form clr-form-compact"
      >
        <div class="clr-row search-form-row">
          <div class="clr-col-xl-5 clr-col-sm-12">
            <clr-input-container>
              <label i18n>Search</label>
              <input
                size="40"
                class="free-search"
                clrInput
                type="text"
                placeholder="S/N, core pack S/N, color, versions..."
                formControlName="searchTerm"
              />
            </clr-input-container>
          </div>
        </div>
        <div class="clr-row">
          <div class="clr-col-12">
            <button class="btn btn-primary search-btn" type="submit" i18n>
              <cds-icon shape="search"></cds-icon>
            </button>
          </div>
        </div>
      </form>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>

<!--

  lockCode: string;
  testReport: any;
  updateTrace: UpdateTrace;
-->
<clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
  <clr-dg-column i18n>Serial number</clr-dg-column>
  <clr-dg-column i18n>Corepack SN</clr-dg-column>
  <clr-dg-column i18n>Software version</clr-dg-column>
  <clr-dg-column i18n>Hardware version</clr-dg-column>
  <clr-dg-column i18n>Color</clr-dg-column>
  <clr-dg-column i18n>Provisioned on </clr-dg-column>

  <clr-dg-row *ngFor="let battery of batteries">
    <clr-dg-cell>
      <a [routerLink]="['/batteries', battery.serialNumber]" href> {{ battery.serialNumber }} </a>&nbsp;
    </clr-dg-cell>
    <clr-dg-cell>{{ battery.corepack?.serialNumber }}</clr-dg-cell>
    <clr-dg-cell>{{ battery.corepack?.softwareVersion }}</clr-dg-cell>
    <clr-dg-cell>{{ battery.corepack?.hardwareVersion }}</clr-dg-cell>
    <clr-dg-cell>{{ battery.color }}</clr-dg-cell>
    <clr-dg-cell>{{ battery.provisionedOn | date: 'dd/MM/yy HH:mm' }}</clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer i18n>
    {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} batteries
    <clr-dg-pagination #pagination [clrDgTotalItems]="total" [clrDgPageSize]="15"></clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
