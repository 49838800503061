import {
  Directive,
  OnDestroy,
  OnChanges,
  AfterContentInit,
  ContentChildren,
  QueryList,
  Input,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { RouterLink, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface MatchExp {
  [classes: string]: string;
}

/**
 * This directive will give you ability to add a class to the element
 * when router url match a regular expression.
 * The syntax is same with ngClass but replace the true/false expression
 * with your string based regexp (like the string you pass to new RegExp(''))
 *
 * @example
 * Example: active-class will be added to a tag when router URL
 * contains this segment: products/12345
 *
 * ```html
 * <a routerLink="/products"
 *  [appRouterLinkMatch]="{
 *    "active-class": "products/\\d+"
 *  }"></a>
 * ```
 */
@UntilDestroy()
@Directive({
  selector: '[appRouterLinkMatch]',
})
export class RouterLinkMatchDirective implements OnDestroy, OnChanges, AfterContentInit {
  private curRoute: string;
  private matchExp: MatchExp;

  @ContentChildren(RouterLink, { descendants: true })
  links: QueryList<RouterLink>;

  @ContentChildren(RouterLink, { descendants: true })
  linksWithHrefs: QueryList<RouterLink>;

  @Input('appRouterLinkMatch')
  set routerLinkMatch(matchExp: MatchExp) {
    console.log('appRouterLinkMatch:', matchExp);

    if (matchExp && typeof matchExp === 'object') {
      this.matchExp = matchExp;
    } else {
      throw new TypeError(
        `Unexpected type '${typeof matchExp}' of value for ` + `input of routerLinkMatch directive, expected 'object'`
      );
    }
  }

  constructor(private router: Router, private renderer: Renderer2, private ngEl: ElementRef) {
    router.events.pipe(untilDestroyed(this)).subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.curRoute = (e as NavigationEnd).urlAfterRedirects;
        this._update();
      }
    });
  }

  ngOnChanges(): void {
    this._update();
  }

  ngAfterContentInit(): void {
    this.links.changes.pipe(untilDestroyed(this)).subscribe(() => this._update());
    this.linksWithHrefs.changes.pipe(untilDestroyed(this)).subscribe(() => this._update());
    this._update();
  }

  private _update(): void {
    if (!this.links || !this.linksWithHrefs || !this.router.navigated) {
      return;
    }

    /**
     * This a way of causing something to happen in the next micro-task / during a new round
     * of change detection.
     */
    Promise.resolve().then(() => {
      const matchExp = this.matchExp;

      for (const classes of Object.keys(matchExp)) {
        if (matchExp[classes] && typeof matchExp[classes] === 'string') {
          const regexp = new RegExp(matchExp[classes]);
          if (this.curRoute.match(regexp)) {
            this._toggleClass(classes, true);
          } else {
            this._toggleClass(classes, false);
          }
        }
      }
    });
  }

  private _toggleClass(classes: string, enabled: boolean): void {
    classes
      .split(/\s+/g)
      .filter((cls) => !!cls)
      .forEach((cls) => {
        if (enabled) {
          this.renderer.addClass(this.ngEl.nativeElement, cls);
        } else {
          this.renderer.removeClass(this.ngEl.nativeElement, cls);
        }
      });
  }

  ngOnDestroy(): void {}
}
