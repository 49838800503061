import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'trends',
  templateUrl: './trends.component.html',
})
export class TrendsComponent implements OnInit {
  @Input()
  percentage: number;

  @Input()
  bound: number = 1;

  constructor() {}

  ngOnInit(): void {}
}
