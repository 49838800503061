import { Component, OnInit, Input } from '@angular/core';
import { DeleteTrace, UpdateTrace } from 'src/app/modules/api/models';

@Component({
  selector: 'app-update-trace',
  templateUrl: './update-trace.component.html',
  styleUrls: ['./update-trace.component.scss'],
})
export class UpdateTraceComponent implements OnInit {
  @Input()
  updateTrace: UpdateTrace;

  @Input()
  deleteTrace: DeleteTrace;

  constructor() {}

  ngOnInit(): void {}
}
