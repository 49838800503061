import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { User } from '../../../../models/user.model';
import { CustomerService } from '../../../../services/customer.service';
import { ClrDatagridStateInterface } from '@clr/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RetailerService } from '../../../../services/retailer.service';
import { Retailer } from '../../../../models/retailer.model';

@UntilDestroy()
@Component({
  selector: 'app-list-retailers',
  templateUrl: './list-retailers.component.html',
  styleUrls: ['./list-retailers.component.scss'],
})
export class ListRetailersComponent implements OnInit, OnDestroy {
  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
