import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Shop } from '../../models/shop.model';
import { ShopService } from '../../services/shop.service';

@UntilDestroy()
@Component({
  selector: 'shop-combobox',
  templateUrl: './shop-combobox.component.html',
  styleUrls: ['./shop-combobox.component.scss'],
})
export class ShopComboboxComponent implements OnInit {
  constructor(private formBuilder: FormBuilder, private shopService: ShopService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  @Input()
  public shop;
  @Output() shopChange = new EventEmitter<Shop>();

  public shops: Shop[] = [];

  public loading: boolean = false;

  public fetchShops(term) {
    this.loading = true;
    const params = {
      page: 0,
      size: 10,
      searchTerm: term,
    };

    this.shopService
      .getAll(params)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.loading = false;
        this.shops = [];
        if (result) {
          this.shops = result.values();
        }
      });
  }

  onModelChange() {
    this.shopChange.emit(this.shop);
  }
}
