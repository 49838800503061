<h1>
  <cds-icon style="margin-right: 5px" shape="users" role="img" size="md"></cds-icon>Retailer :
  {{ retailer.customer.email }}
</h1>
<div class="clr-row">
  <div class="clr-col-lg-6 clr-col-md-8 clr-col-12">
    <h2 i18n>Customer Info</h2>
    <user-details [user]="retailer.customer"></user-details>

    <span *aclCheck="'actions_pro'">
      <button class="btn btn-outline" type="button" i18n [routerLink]="['/pro', 'retailers', 'form', retailer?.id]">
        <cds-icon shape="pencil"></cds-icon>
        Edit
      </button>
      <span>
        <button
          class="btn btn-danger-outline"
          type="button"
          i18n
          appConfirm
          (appConfirm)="onDelete(retailer)"
          *ngIf="retailer.deleteTrace.deletedOn == null"
        >
          <cds-icon shape="trash"></cds-icon> Delete
        </button>
      </span>
      <button
        class="btn btn-danger-outline"
        type="button"
        i18n
        (click)="onRestore(retailer)"
        *ngIf="retailer.deleteTrace.deletedOn != null"
      >
        <cds-icon shape="check"></cds-icon>
        Restore
      </button>
    </span>
    <button
      class="btn btn-outline"
      type="button"
      i18n
      [routerLink]="['/users', 'notifications', 'history', 'mail']"
      [queryParams]="{ customer_id: retailer.customer.id }"
    >
      <cds-icon shape="bell"></cds-icon>
      View notifications
    </button>
  </div>

  <div class="clr-col-lg-6 clr-col-md-8 clr-col-12">
    <div class="clr-row">
      <div class="clr-col-12">
        <h2 i18n>Works at</h2>
        <table class="table table-vertical">
          <tbody>
            <tr>
              <th i18n>Shop</th>
              <td><shop-link [shop]="retailer.shop"></shop-link></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="clr-col-12">
        <h2 i18n>Bikes</h2>
        <user-bikes-details [bikes]="bikes" [userId]="retailer.customer.id"></user-bikes-details>
      </div>
    </div>
  </div>
</div>
<!-- .clr-row -->
