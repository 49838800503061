import { LinkResource } from 'src/app/modules/api/models';

export class Admin extends LinkResource {
  id: string;
  email: string;
  role: string;

  constructor(data?: any) {
    if (data) {
      super(data._links);
      this.id = data.id;
      this.email = data.email;
      this.role = data.role;
    }
  }
}
