import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/modules/api/services/api.service';
import { Observable, of } from 'rxjs';
import { ResponseEntities } from 'src/app/modules/api/models';
import { Admin } from '../models/admin.model';
import { map, catchError } from 'rxjs/operators';
import { definitiveCache } from 'src/app/modules/api/definitive-cache/definitive-cache';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private api: ApiService) {}

  private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message} with error : ${error}`);

      return of(result as T);
    };
  }

  public getAll(params: { [param: string]: string }): Observable<ResponseEntities<Admin>> {
    return this.api
      .get<ResponseEntities<Admin>>(`/admins`, {
        params,
      })
      .pipe(
        map((a) => new ResponseEntities<Admin>(Admin, a)),
        catchError(this.handleError<ResponseEntities<Admin>>('all')),
        definitiveCache()
      );
  }

  public resetPassword(id: string): Observable<any> {
    return this.api.delete(`/admins/${id}/password`);
  }

  public delete(id: string): Observable<any> {
    return this.api.delete(`/admins/${id}`);
  }

  public create(email: string, role: string): Observable<Admin> {
    return this.api.post<Admin>('/admins', { email, role }).pipe(
      map((a) => new Admin(a)),
      catchError(this.handleError<Admin>('create')),
      definitiveCache()
    );
  }
}
