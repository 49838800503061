import { Component, OnInit, OnDestroy } from '@angular/core';
import { CustomerService } from '../../services/customer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { flatMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { User } from '../../models/user.model';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { UserProfile } from '../../models/user-profile.model';

@UntilDestroy()
@Component({
  selector: 'app-edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.scss'],
})
export class EditCustomerComponent implements OnInit, OnDestroy {
  public user: User;
  updateUserForm: UntypedFormGroup;
  submitted = false;
  error = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private customerService: CustomerService
  ) {}

  public ngOnInit(): void {
    this.route.params
      .pipe(
        untilDestroyed(this),
        flatMap((params) => this.fetchUser(params.id))
      )
      .subscribe((user) => {
        console.log('user:', user);
        this.user = user;

        this.updateUserForm = this.formBuilder.group({
          email: [user.email, Validators.required],
          locale: [user.locale, Validators.pattern('^([a-z]{2})_([A-Z]{2})$')],
          firstName: [user.firstName],
          lastName: [user.lastName],
          phoneNumber: [user.phoneNumber],
        });
      });
  }

  public ngOnDestroy(): void {}

  private fetchUser(id: string): Observable<User> {
    return this.customerService.getById(id);
  }

  // convenience getter for easy access to form fields
  get f(): { [key: string]: AbstractControl } {
    return this.updateUserForm.controls;
  }

  private formBuilderToUser(): UserProfile {
    return new UserProfile({
      email: this.f.email.value,
      locale: this.f.locale.value,
      firstName: this.f.firstName.value,
      lastName: this.f.lastName.value,
      phoneNumber: this.f.phoneNumber.value,
    });
  }

  public onSubmit(): void {
    this.submitted = true;

    // stop here if form is invalid
    if (this.updateUserForm.invalid) {
      return;
    }

    this.error = null;

    this.customerService
      .updateProfileById(this.user.id, this.formBuilderToUser())
      .pipe(untilDestroyed(this))
      .subscribe(
        (res) => this.router.navigate(['/users', 'view', this.user.id]),
        (error) => {
          console.log(error);
          this.error = error;
        }
      );
  }
}
