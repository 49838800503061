import { Component, OnDestroy, OnInit } from '@angular/core';
import { Bike } from 'src/app/pages/bike/models/bike.model';
import { BikeService } from 'src/app/pages/bike/services/bike.service';
import { ClrDatagridStateInterface } from '@clr/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Segment } from '../../../models/segment.model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SegmentService } from '../../../services/segment.service';
import { MarketZone } from '../../../models/market-zone.model';
import { MarketZonesService } from '../../../services/market-zones.service';

@UntilDestroy()
@Component({
  selector: 'app-list-bike',
  templateUrl: './list-bike.component.html',
  styleUrls: ['./list-bike.component.scss'],
})
export class ListBikeComponent implements OnInit, OnDestroy {
  public segments: Segment[];
  public searchForm: UntypedFormGroup;
  public filtersOpened: boolean = false;
  public bikes: Bike[];
  public total: number;
  public loading = true;
  public segmentsLoading: boolean = false;

  marketZones: MarketZone[] = [];
  selectedZone: MarketZone;

  constructor(
    private bikeService: BikeService,
    private segmentService: SegmentService,
    private marketZoneService: MarketZonesService,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      searchTerm: '',
      installed: '',
      testResults: '',
      appVersion: '',
      segments: [],
      tenant: '',
      marketZoneId: '',
    });
    this.loadMarketZones();
  }

  ngOnDestroy(): void {}

  private constructParamsFromState(state) {
    const params = {
      searchTerm: this.searchForm.get('searchTerm').value,
      installed: this.searchForm.get('installed').value,
      segments: this.searchForm
        .get('segments')
        .value?.map((s) => s.name)
        .join(),
      testResults: this.searchForm.get('testResults').value,
      appVersion: this.searchForm.get('appVersion').value,
      tenant: this.searchForm.get('tenant').value,
      marketZoneId: this.searchForm.get('marketZoneId').value,
      page: state.page.current - 1,
      size: state.page.size,
    };
    if (params.installed === 'any') {
      delete params.installed;
    }
    if (params.testResults === 'any') {
      delete params.testResults;
    }
    if (params.tenant === 'any') {
      delete params.tenant;
    }
    if (!params.segments) {
      delete params.segments;
    }
    if (!params.marketZoneId) {
      delete params.marketZoneId;
    }
    return params;
  }

  private loadMarketZones(): void {
    this.marketZoneService
      .getAll()
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.marketZones = result;
      });
  }

  public refresh(state: ClrDatagridStateInterface): void {
    this.loading = true;

    const params = this.constructParamsFromState(state);

    this.bikeService
      .search(params)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.bikes = result.values();
        this.total = result.page.totalElements || this.bikes.length;
        this.loading = false;
        if (this.filtersOpened === false) {
          this.filtersOpened = true;
        }
      });
  }

  public segmentsToList(segments: Segment[]): string {
    return segments.map((segment) => segment.name).join(', ');
  }

  public fetchSegments(term) {
    this.segmentsLoading = true;
    this.segmentService.search(term, true).subscribe((segments) => {
      this.segmentsLoading = false;
      this.segments = segments;
    });
  }

  public onSubmit(state: ClrDatagridStateInterface): void {
    if (this.searchForm.invalid) {
      return;
    }

    this.loading = true;

    const params = this.constructParamsFromState(state);

    this.bikeService
      .search(params)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.loading = false;
        this.bikes = result.values();
        this.total = result.page.totalElements || this.bikes.length;
      });
  }
}
