import { LinkResource, UpdateTrace } from 'src/app/modules/api/models';

export class SmsLog extends LinkResource {
  id: string;
  text: string;
  phoneNumber: string;
  notificationStatus: string;
  userId: string;
  tenant: string;

  updateTrace: UpdateTrace;

  constructor(data?: any) {
    if (data) {
      super(data._links);
      this.id = data.id;
      this.text = data.text;
      this.phoneNumber = data.phoneNumber;
      this.notificationStatus = data.notificationStatus;
      this.userId = data.userId;
      this.tenant = data.tenant;

      this.updateTrace = new UpdateTrace(data.updateTrace);
    }
  }
}
