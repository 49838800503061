<cds-icon
  *ngIf="percentage > bound"
  shape="arrow"
  size="lg"
  status="danger"
  title="Last week err/km trend : rising ({{ percentage }}%)"
  style="transform: rotate(45deg)"
></cds-icon>
<cds-icon
  *ngIf="percentage < -bound"
  shape="arrow"
  size="lg"
  status="success"
  title="Last week err/km trend : downward ({{ percentage }}%)"
  style="transform: rotate(135deg)"
></cds-icon>
<cds-icon
  *ngIf="percentage >= -bound && percentage <= bound"
  shape="arrow"
  size="lg"
  title="Last week err/km trend : stable ({{ percentage }}%)"
  style="transform: rotate(90deg)"
></cds-icon>
