<h1 i18n>Cockpits</h1>

<clr-accordion class="filters-accordion">
  <clr-accordion-panel [(clrAccordionPanelOpen)]="filtersOpened" class="filters-accordion-panel">
    <clr-accordion-title>Filters</clr-accordion-title>
    <clr-accordion-content *clrIfExpanded>
      <form
        clrForm
        [formGroup]="searchForm"
        (ngSubmit)="onSubmit(pagination)"
        [clrLayout]="'horizontal'"
        class="search-form clr-form-compact"
      >
        <div class="clr-row search-form-row">
          <div class="clr-col-xl-5 clr-col-sm-12">
            <clr-input-container>
              <label i18n>Search</label>
              <input
                size="40"
                class="free-search"
                clrInput
                type="text"
                placeholder="MAC, top/bottom S/N, ..."
                formControlName="searchTerm"
              />
            </clr-input-container>
          </div>
          <div class="clr-col-xl-5 clr-col-sm-12">
            <clr-input-container>
              <label i18n>Version</label>
              <input clrInput type="text" placeholder="  ex: 1.2.25" formControlName="appVersion" />
            </clr-input-container>
          </div>

          <div class="clr-col-xl-5 clr-col-sm-12">
            <clr-select-container>
              <label class="clr-col-xl-3 clr-col-md-2">Is assembled ?</label>
              <select class="clr-col-xl-9 clr-col-md-10" clrSelect formControlName="isAssembledOnBike">
                <option value=""></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </clr-select-container>
          </div>
          <div class="clr-col-xl-5 clr-col-sm-12">
            <clr-select-container>
              <label class="clr-col-xl-3 clr-col-md-2">Tests status ?</label>
              <select class="clr-col-xl-9 clr-col-md-10" clrSelect formControlName="testResults">
                <option [value]="'any'"></option>
                <option value="SUCCESS">SUCCESS</option>
                <option value="FAILED">FAILED</option>
              </select>
            </clr-select-container>
          </div>
        </div>
        <div class="clr-row">
          <div class="clr-col-12">
            <button class="btn btn-primary search-btn" type="submit" i18n>
              <cds-icon shape="search"></cds-icon>
            </button>
          </div>
        </div>
      </form>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>

<clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
  <clr-dg-column i18n>Mac</clr-dg-column>
  <clr-dg-column i18n>Color</clr-dg-column>
  <clr-dg-column i18n>Bike</clr-dg-column>
  <clr-dg-column i18n>Top S/N</clr-dg-column>
  <clr-dg-column i18n>Bottom S/N</clr-dg-column>
  <clr-dg-column i18n>Versions (APP/IMG/BLE)</clr-dg-column>
  <clr-dg-column i18n>Provisioned on</clr-dg-column>
  <clr-dg-column i18n>Tests results</clr-dg-column>
  <clr-dg-column i18n>ICCID</clr-dg-column>

  <clr-dg-row *ngFor="let cockpit of cockpits">
    <clr-dg-cell>
      <a [routerLink]="['/cockpits', 'view', cockpit.mac]" href>{{ cockpit.mac }}</a
      >&nbsp;
    </clr-dg-cell>
    <clr-dg-cell>{{ cockpit.color }}</clr-dg-cell>
    <clr-dg-cell>
      <a *ngIf="cockpit.bikeId" [routerLink]="['/bikes', 'view', cockpit.bikeId]" href>{{ cockpit.bikeId }}</a>
      <span *ngIf="!cockpit.bikeId">-</span>
    </clr-dg-cell>
    <clr-dg-cell>{{ cockpit.topCard.serialNumber }}</clr-dg-cell>
    <clr-dg-cell>{{ cockpit.bottomCard.serialNumber }}</clr-dg-cell>
    <clr-dg-cell>
      {{ cockpit.versionApp || '-' }} / {{ cockpit.versionImg || '-' }} / {{ cockpit.versionBle || '-' }}
    </clr-dg-cell>
    <clr-dg-cell>{{ cockpit.provisionedOn | date : 'dd/MM/yy HH:mm' }}</clr-dg-cell>
    <clr-dg-cell>
      <span class="label label-danger" *ngIf="!['SUCCESS', 'PASS'].includes(cockpit.latestTestResult)" i18n>{{
        cockpit.latestTestResult
      }}</span>
      <span class="label label-success" *ngIf="['SUCCESS', 'PASS'].includes(cockpit.latestTestResult)" i18n>{{
        cockpit.latestTestResult
      }}</span>
    </clr-dg-cell>
    <clr-dg-cell>
      <a [routerLink]="['/sims', 'view', cockpit.iccId]" href>
        {{ cockpit.iccId }}
      </a>
    </clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer i18n>
    {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} bikes
    <clr-dg-pagination #pagination [clrDgTotalItems]="total" [clrDgPageSize]="15"></clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
