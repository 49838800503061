export class NextVersionOTAV2 {
  authorizedMedia: number;
  firmwares: NextVersionFirmware[];
  remainingUpgrades: number;
  constructor(data?: any) {
    if (data) {
      this.authorizedMedia = data.authorizedMedia;
      this.remainingUpgrades = data.remainingUpgrades;
      this.firmwares = data.firmwares.map((f) => new NextVersionFirmware(f));
    }
  }
}

class NextVersionFirmware {
  version: number;
  type: number;
  dat: NextVersionFirmwareFile;
  bin: NextVersionFirmwareFile;

  constructor(data?: any) {
    if (data) {
      this.version = data.version;
      this.type = data.type;
      this.dat = new NextVersionFirmwareFile(data.dat);
      this.bin = new NextVersionFirmwareFile(data.bin);
    }
  }
}

class NextVersionFirmwareFile {
  fileId: number;
  size: number;

  constructor(data?: any) {
    if (data) {
      this.fileId = data.fileId;
      this.size = data.size;
    }
  }
}
