<h1 i18n>Sim Cards</h1>

<clr-accordion class="filters-accordion">
  <clr-accordion-panel [(clrAccordionPanelOpen)]="filtersOpened" class="filters-accordion-panel">
    <clr-accordion-title>Filters</clr-accordion-title>
    <clr-accordion-content *clrIfExpanded>
      <form
        clrForm
        [formGroup]="searchForm"
        (ngSubmit)="onSubmit(pagination)"
        [clrLayout]="'horizontal'"
        class="search-form clr-form-compact"
      >
        <div class="clr-row search-form-row">
          <div class="clr-col-xl-5 clr-col-sm-12">
            <clr-input-container>
              <label i18n>Search</label>
              <input
                size="40"
                class="free-search"
                clrInput
                type="text"
                placeholder="Iccid, phone number, cockpit mac, ..."
                formControlName="searchTerm"
              />
            </clr-input-container>
          </div>
          <div class="clr-col-xl-3 clr-col-sm-12">
            <clr-select-container>
              <label class="clr-col-xl-3 clr-col-md-2">Provider</label>
              <select class="clr-col-xl-9 clr-col-md-10" clrSelect formControlName="provider">
                <option value="">All</option>
                <option value="MATOOMA">Matooma</option>
                <option value="WIRELESS_LOGIC">Wireless Logic</option>
              </select>
            </clr-select-container>
          </div>
          <div class="clr-col-xl-3 clr-col-sm-12">
            <clr-select-container>
              <label class="clr-col-xl-3 clr-col-md-2">State</label>
              <select class="clr-col-xl-9 clr-col-md-10" clrSelect formControlName="state">
                <option value="">All</option>
                <option value="STOCK">In stock</option>
                <option value="ACTIVATED">Activated</option>
                <option value="SUSPENDED">Suspended</option>
                <option value="TERMINATED">Terminated</option>
              </select>
            </clr-select-container>
          </div>
        </div>
        <div class="clr-row">
          <div class="clr-col-12">
            <button class="btn btn-primary search-btn" type="submit" i18n>
              <cds-icon shape="search"></cds-icon>
            </button>
          </div>
        </div>
      </form>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>

<clr-datagrid (clrDgRefresh)="onSubmit($event)" [clrDgLoading]="loading">
  <clr-dg-column i18n>ICCID</clr-dg-column>
  <clr-dg-column i18n>Provider</clr-dg-column>
  <clr-dg-column i18n>Phone Number</clr-dg-column>
  <clr-dg-column i18n>State</clr-dg-column>

  <clr-dg-row *ngFor="let simCard of simCards">
    <clr-dg-cell>
      <a [routerLink]="['/sims', 'view', simCard.iccid]" href>
        {{ simCard.iccid }}
      </a>
    </clr-dg-cell>
    <clr-dg-cell>
      <simcard-provider [provider]="simCard.provider"></simcard-provider>
    </clr-dg-cell>
    <clr-dg-cell>{{ simCard.phoneNumber ? simCard.phoneNumber : '-' }}</clr-dg-cell>
    <clr-dg-cell>
      <simcard-status [simCard]="simCard"></simcard-status>
    </clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer i18n>
    {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} SIM
    <clr-dg-pagination #pagination [clrDgTotalItems]="total" [clrDgPageSize]="15"></clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
