import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from 'src/app/modules/api/services/api.service';
import { ResponseEntities } from 'src/app/modules/api/models/response-entities.model';
import { map, catchError, filter, switchMap, defaultIfEmpty } from 'rxjs/operators';
import { definitiveCache } from 'src/app/modules/api/definitive-cache/definitive-cache';
import { Segment } from '../models/segment.model';

@Injectable({
  providedIn: 'root',
})
export class SegmentService {
  constructor(private api: ApiService) {}

  private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message} with error : ${error}`);

      return of(result as T);
    };
  }

  public getAll(params: { [param: string]: string }): Observable<ResponseEntities<Segment>> {
    return this.api
      .get<ResponseEntities<Segment>>('/segments', {
        params,
      })
      .pipe(
        map((a) => new ResponseEntities<Segment>(Segment, a)),
        catchError(this.handleError<ResponseEntities<Segment>>('all')),
        definitiveCache()
      );
  }

  public search(term: string, allowEmpty?: boolean): Observable<Segment[]> {
    return of(term).pipe(
      filter((t) => (allowEmpty ? true : t !== null && t.length > 0)),
      switchMap((term) =>
        this.getAll({
          q: term + '%',
        })
      ),
      map((resp) => resp.values()),
      defaultIfEmpty([])
    );
  }
}
