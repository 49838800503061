<h1 i18n>Bike Version Information</h1>

<ng-container *ngIf="version">
  <div class="clr-row">
    <div class="clr-col-lg-6 clr-col-md-8 clr-col-12">
      <h2 i18n>Info</h2>
      <table class="table table-vertical version-table">
        <tbody>
          <tr>
            <th i18n>ID</th>
            <td>{{ version.id }}</td>
          </tr>
          <tr>
            <th i18n>Type</th>
            <td>{{ version.softwarePackageType }}</td>
          </tr>
          <tr>
            <th i18n>Enabled</th>
            <td>
              <span class="label label-danger" *ngIf="version.enable == false" i18n>No</span>
              <span class="label label-success" *ngIf="version.enable == true" i18n>Yes</span>
            </td>
          </tr>
          <tr>
            <th i18n>Filename</th>
            <td>
              <span title="{{ version.filename }}">{{ version.filename }}</span>
            </td>
          </tr>
          <tr>
            <th i18n>Min app version</th>
            <td>{{ version.softwareVersionRequirements.compactMinAppVersion }}</td>
          </tr>
          <tr>
            <th i18n>Min bluetooth version</th>
            <td>{{ version.softwareVersionRequirements.compactMinBluetoothVersion }}</td>
          </tr>
          <tr>
            <th i18n>Min image version</th>
            <td>{{ version.softwareVersionRequirements.compactMinImageVersion }}</td>
          </tr>
        </tbody>
      </table>

      <div *aclCheck="'actions_versions'">
        <button class="btn btn-danger-outline" (click)="onDelete(version)">Delete</button>
        <button class="btn btn-outline" (click)="onDisable(version)" *ngIf="version.enable == true">Disable</button>
        <button class="btn btn-success-outline" (click)="onEnable(version)" *ngIf="version.enable == false">
          Enable
        </button>
      </div>
    </div>

    <div class="clr-col-lg-3 clr-col-md-8 clr-col-12">
      <h2 i18n>Softwares</h2>

      <form
        clrForm
        [formGroup]="updateAutodiagForm"
        class="clr-form no-padding autodiag-form"
        clrLayout="vertical"
        (ngSubmit)="updateAutodiagDescriptor()"
      >
        <clr-modal [(clrModalOpen)]="openAutodiagModal" [clrModalSize]="'lg'">
          <h3 class="modal-title">
            <span>Autodiag Descriptor</span>
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              [class.hidden]="editAutodiag"
              (click)="enterAutodiagEditMode()"
            >
              <cds-icon shape="edit"></cds-icon>
              Edit
            </button>
          </h3>
          <div class="modal-body">
            <clr-alert [clrAlertType]="'danger'" [clrAlertClosed]="!updateAutodiagFormError">
              <div clr-alert-item class="alert-item">
                <span class="alert-text">
                  {{ updateAutodiagFormError }}
                </span>
              </div>
            </clr-alert>
            <clr-textarea-container class="clr-form-control">
              <textarea
                class="textarea-editable"
                clrTextarea
                name="description"
                formControlName="content"
                required
                [disabled]="!editAutodiag"
                [rows]="24"
                [cols]="100"
              ></textarea>
              <clr-control-error *clrIfError="'required'" i18n>This is a required field</clr-control-error>
            </clr-textarea-container>
          </div>
          <div class="modal-footer">
            <button *ngIf="!editAutodiag" type="button" class="btn btn-secondary" (click)="openAutodiagModal = false">
              Close
            </button>
            <button *ngIf="editAutodiag" type="button" class="btn btn-secondary" (click)="cancelAutodiagEditMode()">
              Cancel
            </button>
            <button *ngIf="editAutodiag" type="submit" class="btn btn-primary">Update descriptor</button>
          </div>
        </clr-modal>
      </form>
      <table class="table software-table">
        <thead>
          <tr>
            <th class="left" i18n>Type</th>
            <th class="left" i18n>Version</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let software of version.softwares">
            <td class="left">
              <span>{{ software.softwareType }}</span>
              <button
                *ngIf="software.softwareType === SOFTWARE_TYPE_APP"
                [disabled]="!autodiagDescriptor"
                type="button"
                class="btn btn-icon btn-link btn-sm"
                aria-label="autodiag"
                i18n
                (click)="openAutodiagModal = true"
                [title]="autodiagDescriptor ? 'Show autodiag' : 'No autodiag for this version'"
              >
                <cds-icon shape="briefcase"></cds-icon>
              </button>
            </td>
            <td class="left">{{ software.version.toString() }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="clr-col-lg-3 clr-col-md-8 clr-col-12">
      <h2 i18n>Targets</h2>

      <table class="table table-vertical">
        <tbody>
          <tr>
            <th i18n>Segments</th>
            <td>
              <div class="segments"></div>
              <span class="label" *ngFor="let segment of version.segments">
                {{ segment.name }}&nbsp;
                <a *aclCheck="'actions_versions'" href (click)="!!onDeleteSegment(segment)">
                  <cds-icon shape="times"></cds-icon>
                </a>
              </span>
              <span *ngIf="version.segments.length == 0" i18n>No segment</span>
              <div *aclCheck="'actions_versions'">
                <form clrForm [formGroup]="addSegmentForm" (ngSubmit)="onSubmitSegment()">
                  <clr-datalist-container>
                    <input
                      clrDatalistInput
                      formControlName="name"
                      placeholder="Segment name"
                      name="name"
                      autocomplete="off"
                    />
                    <datalist>
                      <option>{{ addSegmentForm.get('name').value }}</option>
                      <option *ngFor="let segment of segments" [value]="segment.name"></option>
                    </datalist>
                  </clr-datalist-container>

                  <!-- input class="clr-input" type="text" formControlName="name" /-->
                  <button class="btn btn-sm" type="submit">Add</button>
                </form>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- .clr-row -->
</ng-container>
