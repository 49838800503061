import { DeleteTrace, UpdateTrace } from '../../../modules/api/models';
import { User } from './user.model';

export class NotificationChannel {
  id: string;
  channel: string;
  option: string;
  isEnabled: boolean;
  constructor(data?: any) {
    this.id = data.id;
    this.channel = data.channel;
    this.option = data.option;
    this.isEnabled = data.isEnabled;
  }
}

export class PushToken {
  id: string;
  deviceType: string;
  agent: AngellAgent;
  customer: User;
  updateTrace: UpdateTrace;
  deleteTrace: DeleteTrace;
  constructor(data?: any) {
    this.id = data.id;
    this.deviceType = data.deviceType;
    this.agent = new AngellAgent(JSON.parse(data.agent));
    this.customer = new User(data.customer);
    this.updateTrace = new UpdateTrace(data.updateTrace);
    this.deleteTrace = new DeleteTrace(data.deleteTrace);
  }
}

export class AngellAgent {
  appBuild: string;
  appName: string;
  appVersion: string;
  brand: string;
  distribution: string;
  id: string;
  manufacturer: string;
  model: string;
  osName: string;
  osVersion: string;
  type: string;
  timezone: string;

  constructor(data?: any) {
    this.appBuild = data.appBuild;
    this.appName = data.appName;
    this.appVersion = data.appVersion;
    this.brand = data.brand;
    this.distribution = data.distribution;
    this.id = data.id;
    this.manufacturer = data.manufacturer;
    this.model = data.model;
    this.osName = data.osName;
    this.osVersion = data.osVersion;
    this.type = data.type;
    this.timezone = data.timezone;
  }
}
