import { LinkResource } from 'src/app/modules/api/models';
import { Version } from 'src/app/pages/bike/models/bike.model';

export class BikeAutodiagHistory extends LinkResource {
  id: string;
  diag: number;
  batteryLevels: string;
  appVersion: Version;
  errors: BikeAutodiagError[];
  createdOn: Date;
  createdBy: string;
  rideId: string;

  constructor(data?: any) {
    super(data?._links);

    if (data) {
      this.id = data.id;
      this.diag = data.diag;
      this.appVersion = new Version(data.appVersion);
      this.batteryLevels = data.batteryLevels;
      this.rideId = data.rideId;
      this.createdOn = data.createdOn;
      this.createdBy = data.createdBy;

      if (data.errors && data.errors.length > 0) {
        this.errors = data.errors.map((err) => new BikeAutodiagError(err));
      }
    }
  }
}

export class BikeAutodiagError {
  name: string;
  description: string;
  position: number;
  detail: string;

  constructor(data?: any) {
    if (data) {
      this.name = data.name;
      this.description = data.description;
      this.position = data.position;
      this.detail = data.detail;
    }
  }
}
