<clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
  <clr-dg-action-bar *aclCheck="'actions_versions'">
    <button
      *aclCheck="'actions_versions'"
      type="button"
      class="btn btn-sm btn-secondary"
      [routerLink]="['/bikes', 'versions', 'create']"
      i18n
    >
      <cds-icon shape="plus"></cds-icon> Upload a new version
    </button>
    <button
      type="button"
      class="btn btn-sm btn-secondary"
      [routerLink]="['/bikes', 'versions', 'test-next-version']"
      i18n
    >
      <cds-icon shape="search"></cds-icon> Test next version processing
    </button>
  </clr-dg-action-bar>

  <clr-dg-column i18n>ID</clr-dg-column>
  <clr-dg-column i18n>Version type</clr-dg-column>
  <clr-dg-column i18n>App Version</clr-dg-column>
  <clr-dg-column i18n>Ble Version</clr-dg-column>
  <clr-dg-column i18n>Image Version</clr-dg-column>
  <clr-dg-column i18n>Segments</clr-dg-column>
  <clr-dg-column i18n>Enable</clr-dg-column>

  <clr-dg-row *ngFor="let version of versions" [clrDgItem]="version">
    <clr-dg-action-overflow *aclCheck="'actions_versions'">
      <button class="action-item" (click)="onDelete(version)" i18n>Delete</button>
      <button class="action-item" (click)="onDisable(version)" *ngIf="version.enable == true" i18n>Disable</button>
      <button class="action-item" (click)="onEnable(version)" *ngIf="version.enable == false" i18n>Enable</button>
    </clr-dg-action-overflow>
    <clr-dg-cell
      ><a [routerLink]="['/bikes', 'versions', 'view', version.id]" href>{{ version.id }}</a></clr-dg-cell
    >
    <clr-dg-cell>{{ version.softwarePackageType }}</clr-dg-cell>
    <clr-dg-cell>{{ getSoftwareByType(version, 'APP') }}</clr-dg-cell>
    <clr-dg-cell>{{ getSoftwareByType(version, 'BLE') }}</clr-dg-cell>
    <clr-dg-cell>{{ getSoftwareByType(version, 'IMAGE') }}</clr-dg-cell>
    <clr-dg-cell>{{ segmentsToList(version.segments) }}</clr-dg-cell>
    <clr-dg-cell>
      <span class="label label-danger" *ngIf="version.enable == false" i18n>No</span>
      <span class="label label-success" *ngIf="version.enable == true" i18n>Yes</span>
    </clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer i18n>
    {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} versions
    <clr-dg-pagination #pagination [clrDgTotalItems]="total"></clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
