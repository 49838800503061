import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListBikeComponent } from './pages/bikes/list-bike/list-bike.component';
import { AuthenticationGuard } from 'src/app/modules/auth/guards/authentication.guard';
import { ListModelComponent } from './pages/models/list-model/list-model.component';
import { ViewModelComponent } from './pages/models/view-model/view-model.component';
import { ListVersionComponent } from './pages/versions/softwares/list-version/list-version.component';
import { ViewVersionComponent } from './pages/versions/softwares/view-version/view-version.component';
import { CreateVersionComponent } from './pages/versions/softwares/create-version/create-version.component';
import { TestNextVersionComponent } from './pages/versions/softwares/test-next-version/test-next-version.component';
import { ImportBikeComponent } from './pages/bikes/import-bike/import-bike.component';
import { ListSegmentComponent } from './pages/segments/list-segment/list-segment.component';
import { ListFirmwareComponent } from './pages/versions/firmwares/list-firmware/list-firmware.component';
import { ViewFirmwareComponent } from './pages/versions/firmwares/view-firmware/view-firmware.component';
import { CreateFirmwareComponent } from './pages/versions/firmwares/create-firmware/create-firmware.component';
import { UpdateFirmwareComponent } from './pages/versions/firmwares/update-firmware/update-firmware.component';
import { DetailsComponent } from './pages/bikes/view-bike/details/details.component';
import { ViewBike2Component } from './pages/bikes/view-bike/view-bike.component';
import { ProvisioningComponent } from './pages/bikes/view-bike/provisioning/provisioning.component';
import { AutodiagComponent } from './pages/bikes/view-bike/autodiag/autodiag.component';
import { RidesComponent } from './pages/bikes/view-bike/rides/rides.component';
import { ServicesComponent } from './pages/bikes/view-bike/services/services.component';
import { BatteryComponent } from './pages/bikes/view-bike/battery/battery.component';
import { AutonomyComponent } from './pages/bikes/view-bike/autonomy/autonomy.component';
import { TimelineComponent } from './pages/bikes/view-bike/timeline/timeline.component';
import { ConnectivityComponent } from './pages/bikes/view-bike/connectivity/connectivity.component';
import { ViewBikeResolver } from './pages/bikes/view-bike/view-bike.resolver';
import { ListBatteryComponent } from './pages/batteries/list-batteries/list-battery.component';
import { ViewBatteryComponent } from './pages/batteries/view-battery/view-battery.component';
import { VersionsComponent } from './pages/versions/versions.component';
import { ListCockpitComponent } from './pages/cockpits/list-cockpits/list-cockpit.component';
import { ViewCockpitComponent } from './pages/cockpits/view-cockpit/view-cockpit.component';
import { ListSimComponent } from './pages/sims/list-sims/list-sim.component';
import { ViewSimComponent } from './pages/sims/view-sim/view-sim.component';
import { GSMComponent } from './pages/bikes/view-bike/gsm/gsm.component';
import { SavComponent } from './pages/bikes/view-bike/sav/sav.component';
import { BikesSupervisionComponent } from './pages/bikes/bikes-supervision/bikes-supervision.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'lists',
  },
  {
    path: 'lists',
    pathMatch: 'full',
    canActivate: [AuthenticationGuard],
    component: ListBikeComponent,
  },
  {
    path: 'bikes/supervision',
    pathMatch: 'full',
    canActivate: [AuthenticationGuard],
    component: BikesSupervisionComponent,
  },
  {
    path: 'cockpits/lists',
    pathMatch: 'full',
    canActivate: [AuthenticationGuard],
    component: ListCockpitComponent,
  },
  {
    path: 'cockpits/view/:mac',
    pathMatch: 'full',
    canActivate: [AuthenticationGuard],
    component: ViewCockpitComponent,
  },
  {
    path: 'view/:id',
    canActivate: [AuthenticationGuard],
    component: ViewBike2Component,
    resolve: {
      bike: ViewBikeResolver,
    },
    data: {
      now: new Date(),
    },
    children: [
      { path: '', redirectTo: 'details', pathMatch: 'full' },
      { path: 'details', component: DetailsComponent },
      { path: 'provisioning', component: ProvisioningComponent },
      { path: 'errors', component: AutodiagComponent },
      { path: 'rides', component: RidesComponent },
      { path: 'services', component: ServicesComponent },
      { path: 'battery', component: BatteryComponent },
      { path: 'autonomy', component: AutonomyComponent },
      { path: 'timeline', component: TimelineComponent },
      { path: 'connectivity', component: ConnectivityComponent },
      { path: 'gsm', component: GSMComponent },
      { path: 'maintenance', component: SavComponent },
    ],
  },
  {
    path: 'imports',
    canActivate: [AuthenticationGuard],
    component: ImportBikeComponent,
  },
  {
    path: 'models',
    canActivate: [AuthenticationGuard],
    component: ListModelComponent,
  },
  {
    path: 'models/view/:id',
    canActivate: [AuthenticationGuard],
    component: ViewModelComponent,
  },

  {
    path: 'batteries',
    canActivate: [AuthenticationGuard],
    component: ListBatteryComponent,
  },
  {
    path: 'batteries/:sn',
    canActivate: [AuthenticationGuard],
    component: ViewBatteryComponent,
  },
  {
    path: 'sims',
    canActivate: [AuthenticationGuard],
    component: ListSimComponent,
  },
  {
    path: 'sims/view/:iccid',
    canActivate: [AuthenticationGuard],
    component: ViewSimComponent,
  },
  {
    path: 'versions',
    canActivate: [AuthenticationGuard],
    component: VersionsComponent,
    children: [
      { path: '', redirectTo: 'firmwares', pathMatch: 'full' },
      { path: 'softwares', component: ListVersionComponent },
      { path: 'firmwares', component: ListFirmwareComponent },
    ],
  },
  {
    path: 'firmwares',
    canActivate: [AuthenticationGuard],
    component: ListFirmwareComponent,
  },
  {
    path: 'firmwares/view/:id',
    canActivate: [AuthenticationGuard],
    component: ViewFirmwareComponent,
  },
  {
    path: 'firmwares/create',
    canActivate: [AuthenticationGuard],
    component: CreateFirmwareComponent,
  },
  {
    path: 'firmwares/update/:id',
    canActivate: [AuthenticationGuard],
    component: UpdateFirmwareComponent,
  },
  {
    path: 'versions',
    canActivate: [AuthenticationGuard],
    component: ListVersionComponent,
  },
  {
    path: 'versions/view/:id',
    canActivate: [AuthenticationGuard],
    component: ViewVersionComponent,
  },
  {
    path: 'versions/create',
    canActivate: [AuthenticationGuard],
    component: CreateVersionComponent,
  },
  {
    path: 'versions/test-next-version',
    canActivate: [AuthenticationGuard],
    component: TestNextVersionComponent,
  },
  {
    path: 'segments',
    canActivate: [AuthenticationGuard],
    component: ListSegmentComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BikeRoutingModule {}
