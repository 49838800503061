import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { ClrDatagridStateInterface } from '@clr/angular';
import { UserStorageService } from 'src/app/modules/auth/services/user-storage.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

interface NotificationType {
  name: string;
  format?: string;
  lang: string;
  preview: SafeResourceUrl;
}

@UntilDestroy()
@Component({
  selector: 'app-list-notification',
  templateUrl: './list-notification.component.html',
  styleUrls: ['./list-notification.component.scss'],
})
export class ListNotificationComponent implements OnInit, OnDestroy {
  public notifications: NotificationType[] = [];
  public total: number;
  public loading = true;
  public type: string = null;
  public languages = ['fr', 'en'];
  filtersOpened: boolean = true;
  public searchForm: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private sanitizer: DomSanitizer,
    private userStorageService: UserStorageService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    console.log('ngOnInit');
    this.searchForm = this.formBuilder.group({
      tenant: 'ANGELL',
    });
    this.route.params.pipe(untilDestroyed(this)).subscribe((params) => (this.type = params.type));
  }

  ngOnDestroy(): void {}

  public refresh(state: ClrDatagridStateInterface): void {
    console.log('table state:', state);

    this.loading = true;

    let type = this.type;

    if (type === 'mail') {
      type = 'email';
    }

    this.notificationService
      .getNotificationsTypes(type, this.searchForm.get('tenant').value)
      .pipe(untilDestroyed(this))
      .subscribe((names) => {
        this.notifications = [];

        if (type === 'email') {
          names.forEach((name) => {
            this.languages.forEach((lang) => {
              this.notifications.push({
                name,
                lang,
                format: 'HTML',
                preview: this.sanitizer.bypassSecurityTrustResourceUrl(
                  `${environment.api.url}/bo/_debug/notification/view/${type}/${name}.html?lang=${lang}&tenant=${
                    this.searchForm.get('tenant').value
                  }`
                ),
              });
              this.notifications.push({
                name,
                lang,
                format: 'Text',
                preview: this.sanitizer.bypassSecurityTrustResourceUrl(
                  `${environment.api.url}/_debug/notification/view/${type}/${name}.txt?lang=${lang}&tenant=${
                    this.searchForm.get('tenant').value
                  }`
                ),
              });
            });
          });
        } else {
          names.forEach((name) => {
            this.languages.forEach((lang) => {
              this.notifications.push({
                name,
                lang,
                preview: this.sanitizer.bypassSecurityTrustResourceUrl(
                  `${environment.api.url}/_debug/notification/view/${type}/${name}.html?lang=${lang}&tenant=${
                    this.searchForm.get('tenant').value
                  }`
                ),
              });
            });
          });
        }

        this.total = this.notifications.length;

        this.loading = false;
      });
  }

  reloadNotifs() {
    this.refresh(null);
  }
}
