<h1 i18n>Bike Models</h1>

<clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
  <clr-dg-column i18n>Brand</clr-dg-column>
  <clr-dg-column i18n>ID</clr-dg-column>
  <clr-dg-column i18n>Name</clr-dg-column>
  <clr-dg-column i18n>Technical name</clr-dg-column>

  <clr-dg-row *ngFor="let model of models">
    <clr-dg-cell><tenant [name]="model.tenant"></tenant></clr-dg-cell>
    <clr-dg-cell
      ><a [routerLink]="['/bikes', 'models', 'view', model.id]" href>{{ model.id }}</a></clr-dg-cell
    >
    <clr-dg-cell
      ><a [routerLink]="['/bikes', 'models', 'view', model.id]" href>{{ model.name }}</a></clr-dg-cell
    >
    <clr-dg-cell
      ><a [routerLink]="['/bikes', 'models', 'view', model.id]" href>{{ model.technicalName }}</a></clr-dg-cell
    >
  </clr-dg-row>

  <clr-dg-footer i18n>
    {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} models
    <clr-dg-pagination #pagination [clrDgTotalItems]="total" [clrDgPageSize]="15"></clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
