import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/modules/api/services/api.service';
import { Observable, of } from 'rxjs';
import { ResponseEntities } from 'src/app/modules/api/models';
import { catchError, map } from 'rxjs/operators';
import { definitiveCache } from 'src/app/modules/api/definitive-cache/definitive-cache';
import { Retailer } from '../models/retailer.model';

@Injectable({
  providedIn: 'root',
})
export class RetailerService {
  constructor(private api: ApiService) {}

  private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message} with error : ${error}`);

      return of(result as T);
    };
  }

  public getAll(params: { [prop: string]: any }): Observable<ResponseEntities<Retailer>> {
    return this.api
      .get<ResponseEntities<Retailer>>('/retailers', {
        params: {
          ...params,
        },
      })
      .pipe(
        map((a) => new ResponseEntities<Retailer>(Retailer, a)),
        catchError(this.handleError<ResponseEntities<Retailer>>('all_filtered')),
        definitiveCache()
      );
  }

  public get(id: string): Observable<Retailer> {
    return this.api.get<Retailer>(`/retailers/${id}`, {
      params: {},
    });
  }

  public create(retailer: Retailer): Observable<Retailer> {
    return this.api.post<Retailer>(`/retailers/`, retailer);
  }

  public update(retailer: Retailer): Observable<Retailer> {
    return this.api.put<Retailer>(`/retailers/${retailer.id}`, retailer);
  }

  public remove(id: string): Observable<object> {
    return this.api.delete<any>(`/retailers/${id}`);
  }

  public restore(id: string): Observable<Retailer> {
    return this.api.patch<Retailer>(`/retailers/${id}/restore`, {});
  }
}
