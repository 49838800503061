import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): unknown {
    let diff = value;

    const day = Math.floor(diff / 86400);
    diff = diff - day * 86400;

    const hrs = Math.floor(diff / 3600);
    diff = diff - hrs * 3600;

    const min = Math.floor(diff / 60);
    diff = diff - min * 60;

    const sec = Math.round((diff + Number.EPSILON) * 100) / 100;

    const parts = [];

    if (day > 0) {
      parts.push(`${day}d`);
    }

    if (hrs > 0) {
      parts.push(`${hrs}h`);
    }

    if (min > 0) {
      parts.push(`${min}m`);
    }

    if (sec > 0) {
      parts.push(`${sec}s`);
    }

    return parts.join(' ');
  }
}

/*
function ago($when) {
        $diff = date("U") - $when;

        // Days
        $day = floor($diff / 86400);
        $diff = $diff - ($day * 86400);

        // Hours
        $hrs = floor($diff / 3600);
        $diff = $diff - ($hrs * 3600);

        // Mins
        $min = floor($diff / 60);
        $diff = $diff - ($min * 60);

        // Secs
        $sec = $diff;

        // Return how long ago this was. eg: 3d 17h 4m 18s ago
        // Skips left fields if they aren't necessary, eg. 16h 0m 27s ago / 10m 7s ago
        $str = sprintf("%s%s%s%s",
                $day != 0 ? $day."d " : "",
                ($day != 0 || $hrs != 0) ? $hrs."h " : "",
                ($day != 0 || $hrs != 0 || $min != 0) ? $min."m " : "",
                $sec."s ago"
        );

        return $str;
}
*/
