import { Component, OnDestroy, OnInit } from '@angular/core';
import { BikeService } from '../../../../services/bike.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Bike } from '../../../../models/bike.model';
import { BikeProvisionHistory } from '../../../../models/bike-provision-history.model';
import { ActivatedRoute } from '@angular/router';
import { BikeHardwareConfig } from '../../../../models/bike-hardware.config';
import { BikeModel } from '../../../../models/bike-model.model';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BikeModelService } from '../../../../services/bike-model.service';

@UntilDestroy()
@Component({
  selector: 'app-view-bike-provisioning',
  templateUrl: './provisioning.component.html',
  styleUrls: [],
})
export class ProvisioningComponent implements OnInit, OnDestroy {
  now?: Date;
  bike?: Bike;

  provisionHistoryLoaded: boolean = false;
  loadMoreProvisionHistoryToken: string;
  provisionHistoryItems: BikeProvisionHistory[] = [];

  constructor(
    private bikeService: BikeService,
    private bikeModelService: BikeModelService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.route.parent.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.bike = data.bike;
      this.now = data.now;
      this.fetchHwConfig(this.bike);
      this.fetchSyncProvisioningFlag(this.bike);
    });
    this.fetchModels();
  }

  ngOnDestroy(): void {}

  public hardwareConfigHistoryLoading: boolean = false;
  public hardwareConfigHistory: BikeHardwareConfig[] = [];
  public fetchHwConfig(bike: Bike): void {
    this.hardwareConfigHistoryLoading = true;
    this.bikeService
      .getHardwareConfigHistory(bike.id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result) => {
          console.log('result : ', result);
          this.hardwareConfigHistory = result.values() || [];
        },
        complete: () => {
          this.hardwareConfigHistoryLoading = false;
        },
      });
  }

  public syncProvisioningFlagLoading: boolean = false;
  public syncProvisioningFlag: boolean = null;
  public fetchSyncProvisioningFlag(bike: Bike): void {
    this.syncProvisioningFlagLoading = true;
    this.bikeService
      .getSyncProvisioningFlag(bike.id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result) => {
          this.syncProvisioningFlag = result;
        },
        complete: () => {
          this.syncProvisioningFlagLoading = false;
        },
      });
  }

  onSyncProvisioningFlagChanged() {
    this.updateSyncProvisioningFlag(this.bike);
  }

  public updateSyncProvisioningFlag(bike: Bike): void {
    this.syncProvisioningFlagLoading = true;
    this.bikeService
      .updateSyncProvisioningFlag(bike.id, this.syncProvisioningFlag)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result) => {
          this.syncProvisioningFlag = result;
        },
        complete: () => {
          this.syncProvisioningFlagLoading = false;
        },
      });
  }

  public loadProvisionHistory() {
    this.bikeService
      .getProvisionHistory(this.bike.bikeProvision.mac, this.now, '')
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.provisionHistoryItems = response.results;
        this.loadMoreProvisionHistoryToken = response.paging.nextPageCursor;
        this.provisionHistoryLoaded = true;
      });
  }

  public models: BikeModel[] = [];
  public modelsLoading: boolean = false;
  public fetchModels() {
    this.modelsLoading = true;
    this.bikeModelService.getAll(null).subscribe((result) => {
      this.modelsLoading = false;
      this.models = result.values();
    });
  }

  public editModalDisplayed: boolean = false;
  public selectedModel: BikeModel;
  public selectedColor: string;
  public editError: string;

  public onOpenEditModal() {
    this.editError = '';
    this.editModalDisplayed = true;
    this.selectedModel = this.models.find(
      (model) => model.technicalName === this.bike.bikeProvision.model.technicalName,
    );
    this.selectedColor = this.bike.bikeProvision.color;
  }

  public update() {
    this.editError = '';
    if (!this.selectedModel || !this.selectedColor) {
      this.editError = 'All inputs are required';
    }
    this.bikeService
      .updateProvisioning(this.bike.bikeProvision.mac, {
        technicalName: this.selectedModel.technicalName,
        color: this.selectedColor,
        timestamp: Date.now(),
      })
      .subscribe({
        next: () => {
          this.editModalDisplayed = false;
          this.bike.bikeProvision.model = this.selectedModel;
          this.bike.bikeProvision.color = this.selectedColor;
        },
        error: (err) => {
          this.editError = err;
          console.log(`Something went wrong : ${err}`);
        },
      });
  }
}
