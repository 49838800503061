import { Component, OnDestroy, OnInit } from '@angular/core';
import { BikeService } from '../../../../services/bike.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Bike, CustomerBike } from '../../../../models/bike.model';
import { BikeEvent, FiltersBikeEvent } from '../../../../models/bike-event.model';
import { BikeTimelineService } from '../../../../services/bike-timeline.service';
import { ActivatedRoute } from '@angular/router';
import { UserStorageService } from '../../../../../../modules/auth/services/user-storage.service';
import { ACLS } from '../../../../../../acls-definition';
import { User } from '../../../../../../modules/auth/models/user';

@UntilDestroy()
@Component({
  selector: 'app-view-bike',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit, OnDestroy {
  public now: Date;
  public bike?: Bike;
  public allCustomerBikes: CustomerBike[] = [];
  private filtersAccordionOpened: boolean = true;
  private hasMorePage: boolean = false;
  private currentPage: number = 0;
  private eventsLoading: boolean = false;
  private allEvents: BikeEvent[] = [];
  private filteredEvents: BikeEvent[] = [];
  public filtersAllEvents = false;
  private eventTypes: string[] = [];

  private sources: string[] = [];

  public filters: FiltersBikeEvent = {
    freeSearch: '',
    date: new Date(),
    trigger: {
      app_angell: true,
      app_pro: true,
      bike: true,
      bench: true,
    },
    eventTypes: {
      lock: false,
      update: false,
      alarm: false,
      customer: false,
      fall: false,
      theft: false,
      rides: false,
      battery: false,
      customerServiceMode: false,
      motion: false,
      geolocConsent: false,
    },
  };

  constructor(
    private route: ActivatedRoute,
    private bikeService: BikeService,
    private bikeTimelineService: BikeTimelineService,
    private userStorageService: UserStorageService,
  ) {}

  private currentUser: User;

  ngOnInit(): void {
    this.currentUser = this.userStorageService.getUser();

    this.route.parent.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.now = data.now;
      this.bike = data.bike;

      this.bikeService
        .getAllCustomerBikes(this.bike.id)
        .pipe(untilDestroyed(this))
        .subscribe((result) => {
          this.allCustomerBikes = result.values();
          this.initEvents(null);
        });
    });
  }

  ngOnDestroy(): void {}

  public allFilters() {
    this.filters.eventTypes = {
      lock: this.filtersAllEvents,
      update: this.filtersAllEvents,
      alarm: this.filtersAllEvents,
      customer: this.filtersAllEvents,
      fall: this.filtersAllEvents,
      theft: this.filtersAllEvents,
      rides: this.filtersAllEvents,
      battery: this.filtersAllEvents,
      customerServiceMode: this.filtersAllEvents,
      motion: this.filtersAllEvents,
      geolocConsent: this.filtersAllEvents,
    };
    this.changeFilters();
  }

  public initEvents(date: Date): void {
    this.eventTypes = this.getListOfEventTypes();
    this.sources = this.getListOfSource();

    this.currentPage = 0;
    this.allEvents = [];
    this.allEvents = this.addNewEvents(this.bikeTimelineService.extractEventsFromBike(this.bike, this.currentUser));
    if (this.allCustomerBikes) {
      this.allEvents = this.addNewEvents(
        this.bikeTimelineService.extractEventsFromCustomerBikes(this.allCustomerBikes),
      );
    }
    this.loadBikeHistory(this.bike.id, date);
  }

  private getListOfEventTypes(): string[] {
    let eventTypes: string[] = [];

    if (this.filters.eventTypes.alarm) {
      eventTypes.push('carmode');
    }

    if (this.filters.eventTypes.theft) {
      eventTypes.push('theft.on-going');
      eventTypes.push('theft.cancel');
    }

    if (this.filters.eventTypes.fall) {
      eventTypes.push('fall.declared');
      eventTypes.push('fall.on-going');
      eventTypes.push('fall.cancel');
    }

    if (this.filters.eventTypes.customer) {
      eventTypes.push('customer.deleted');
      eventTypes.push('customer.invited');
    }

    if (this.filters.eventTypes.lock) {
      eventTypes.push('lock');
    }

    if (this.filters.eventTypes.update) {
      eventTypes.push('update');
    }

    if (this.filters.eventTypes.rides) {
      eventTypes.push('ride.created');
    }

    if (this.filters.eventTypes.battery) {
      eventTypes.push('low_battery');
    }

    if (this.filters.eventTypes.customerServiceMode) {
      eventTypes.push('bike.customer-service-mode.enabled');
      eventTypes.push('bike.customer-service-mode.disabled');
    }

    if (this.filters.eventTypes.motion) {
      eventTypes.push('motion.on-going');
      eventTypes.push('motion.ended');
    }

    if (this.filters.eventTypes.geolocConsent) {
      eventTypes.push('bike.geoloc.updated');
    }

    return eventTypes;
  }

  private getListOfSource(): string[] {
    let sources: string[] = [];

    if (this.filters.trigger.app_angell) {
      sources.push('APP_ANGELL');
    }
    if (this.filters.trigger.app_pro) {
      sources.push('APP_PRO');
    }

    if (this.filters.trigger.bike) {
      sources.push('BIKE');
    }

    if (this.filters.trigger.bench) {
      sources.push('BIKE_ASSEMBLY_BENCH');
      sources.push('STOUEN_BENCH');
      sources.push('TESTBENCH');
    }

    return sources;
  }

  public changeFilters(): void {
    this.initEvents(null);
  }

  private applyFilters(): void {
    this.filteredEvents = this.allEvents.filter((bikeEvent) => {
      if (this.filters.eventTypes.customer === false && bikeEvent.type.startsWith('customer.history')) {
        return false;
      }

      if (this.filters.trigger.app_angell === false && bikeEvent.source.type == 'APP_ANGELL') {
        return false;
      }

      if (this.filters.trigger.app_pro === false && bikeEvent.source.type == 'APP_PRO') {
        return false;
      }

      if (this.filters.trigger.bike === false && bikeEvent.source.type == 'BIKE') {
        return false;
      }

      if (
        this.filters.trigger.bench === false &&
        (bikeEvent.source.type == 'BIKE_ASSEMBLY_BENCH' ||
          bikeEvent.source.type == 'STOUEN_BENCH' ||
          bikeEvent.source.type == 'TESTBENCH')
      ) {
        return false;
      }

      let endOfDay = this.filters.date;
      endOfDay.setHours(23, 59, 59, 999);

      if (this.filters.date && bikeEvent.date > endOfDay) {
        return false;
      }

      if (this.filters.freeSearch?.length > 0) {
        if (bikeEvent.title.toLowerCase().indexOf(this.filters.freeSearch.toLowerCase()) >= 0) {
          return true;
        }

        if (bikeEvent.description.toLowerCase().indexOf(this.filters.freeSearch.toLowerCase()) >= 0) {
          return true;
        }

        return false;
      }

      return true;
    });
  }

  public loadBikeHistory(id, date) {
    let dataQueryParam = date ? date : this.now;
    dataQueryParam.setHours(23, 59, 59, 999);

    if (this.eventTypes.length > 0) {
      this.eventsLoading = true;
      this.bikeService
        .getEvents(id, dataQueryParam, this.currentPage, null, {
          eventType: this.eventTypes,
          source: this.sources,
        })
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (response) => {
            if (response.page.totalPages > 0) {
              this.hasMorePage = this.currentPage < response.page.totalPages - 1;
            } else {
              this.hasMorePage = false;
            }

            this.currentPage++;
            this.allEvents = this.addNewEvents(
              this.bikeTimelineService.extractEventsFromBikeHistory(response.values()),
            );
            this.applyFilters();
          },
          complete: () => (this.eventsLoading = true),
        });
    } else {
      this.hasMorePage = false;
      this.applyFilters();
    }
  }

  private addNewEvents(newBikeEvents: BikeEvent[]): BikeEvent[] {
    let events: BikeEvent[] = [...this.allEvents];
    // Add new events if not exists.
    newBikeEvents
      ?.filter((newBikeEvent) => !events.some((bikeEvent) => bikeEvent.id === newBikeEvent.id))
      .forEach((bikeEvent) => {
        events.push(bikeEvent);
      });

    return this.sortEvents(events);
  }

  private sortEvents(events: BikeEvent[]) {
    return events.sort((a, b) => {
      if (b.date instanceof Date && !isNaN(b.date.getTime()) && a.date instanceof Date && !isNaN(a.date.getTime())) {
        return b.date.getTime() - a.date.getTime();
      }
      return 0;
    });
  }
}
