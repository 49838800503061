<h1 i18n>Sim Card : {{ simCard.iccid }}</h1>
<div class="clr-row">
  <div class="clr-col-6">
    <h3>Details</h3>
    <table class="table table-vertical">
      <tbody>
        <tr>
          <th i18n>Provider</th>
          <td>
            <simcard-provider [provider]="simCard.provider"></simcard-provider>
          </td>
        </tr>
        <tr>
          <th i18n>Iccid</th>
          <td>
            {{ simCard.iccid }}
          </td>
        </tr>

        <tr>
          <th i18n>State</th>
          <td>
            <simcard-status [simCard]="simCard"></simcard-status>
          </td>
        </tr>
        <tr>
          <th i18n>Phone number</th>
          <td>{{ simCard.phoneNumber ? simCard.phoneNumber : '-' }}</td>
        </tr>

        <tr>
          <th i18n>Activation requested</th>
          <td>
            <span *ngIf="simCard.activationRequestedOn">
              on {{ simCard.activationRequestedOn | date: 'dd/MM/yy HH:mm' }} by {{ simCard.activationRequestedBy }}
            </span>
            <span *ngIf="!simCard.activationRequestedOn">-</span>
          </td>
        </tr>
        <tr>
          <th i18n>Activated</th>
          <td>
            <span *ngIf="simCard.activatedOn">
              on {{ simCard.activatedOn | date: 'dd/MM/yy HH:mm' }} by {{ simCard.activatedBy }}
            </span>
            <span *ngIf="!simCard.activatedOn">-</span>
          </td>
        </tr>
        <tr>
          <th i18n>Terminated</th>
          <td>
            <span *ngIf="simCard.terminatedOn">
              on {{ simCard.terminatedOn | date: 'dd/MM/yy HH:mm' }} by {{ simCard.terminatedBy }}
            </span>
            <span *ngIf="!simCard.terminatedOn">-</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="clr-col-6">
    <h3>Present on</h3>
    <clr-datagrid (clrDgRefresh)="fetchAssociatedBikes(simCard.iccid, $event)" [clrDgLoading]="loading">
      <clr-dg-column i18n>Bike</clr-dg-column>
      <clr-dg-column i18n>Cockpit</clr-dg-column>

      <clr-dg-row *ngFor="let cockpit of cockpits">
        <clr-dg-cell>
          <a *ngIf="cockpit.bikeId" [routerLink]="['/bikes', 'view', cockpit.bikeId]" href>{{ cockpit.bikeId }}</a>
          <span *ngIf="!cockpit.bikeId">-</span>
        </clr-dg-cell>
        <clr-dg-cell>
          <a [routerLink]="['/cockpits', 'view', cockpit.mac]" href>{{ cockpit.mac }}</a
          >&nbsp;
        </clr-dg-cell>
      </clr-dg-row>

      <clr-dg-footer i18n>
        {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ totalCockpits }} bikes
        <clr-dg-pagination #pagination [clrDgTotalItems]="totalCockpits" [clrDgPageSize]="15"></clr-dg-pagination>
      </clr-dg-footer>
    </clr-datagrid>
  </div>
  <div class="clr-col-6" *ngIf="simCard.provider == 'MATOOMA'">
    <h3>Matooma</h3>

    <a
      class="btn btn-link"
      href="https://m2mmanager.matooma.com/fr/home/parc-management/devices?simcardIccid={{ simCard.iccid }}"
      rel="noopener noreferrer"
      target="_blank"
    >
      <cds-icon shape="pop-out"></cds-icon>
      Open Matooma Manager
    </a>
  </div>
</div>
