import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from 'src/app/modules/api/services/api.service';
import { ResponseEntities } from 'src/app/modules/api/models/response-entities.model';
import { catchError, map } from 'rxjs/operators';
import { definitiveCache } from 'src/app/modules/api/definitive-cache/definitive-cache';
import { Cockpit } from '../models/cockpit.model';
import { MarketZone } from '../models/market-zone.model';

@Injectable({
  providedIn: 'root',
})
export class MarketZonesService {
  constructor(private api: ApiService) {}

  private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message} with error : ${error}`);

      return of(result as T);
    };
  }

  public getAll(): Observable<MarketZone[]> {
    return this.api.get<MarketZone[]>(`/marketZones`);
  }
  public updateForBike(bikeId: string, marketZone: MarketZone): Observable<MarketZone[]> {
    return this.api.patch<MarketZone[]>(`/bikes/${bikeId}/marketZone`, marketZone);
  }
}
