import { LinkResource, UpdateTrace } from 'src/app/modules/api/models';
import { SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { encode } from 'js-base64';

export class MailLog extends LinkResource {
  id: string;
  to: string;
  subject: string;
  raw: string;
  safeContent: SafeResourceUrl;
  isHtml: boolean;
  notificationStatus: string;
  userId: string;
  tenant: string;

  updateTrace: UpdateTrace;

  constructor(data?: any) {
    if (data) {
      super(data._links);
      this.id = data.id;
      this.to = data.to;
      this.subject = data.subject;
      this.raw = data.raw;
      this.isHtml = data.html;
      this.notificationStatus = data.notificationStatus;
      this.userId = data.userId;
      this.tenant = data.tenant;

      this.updateTrace = new UpdateTrace(data.updateTrace);
    }
  }

  public getContentToBase64(): string {
    return encode(this.raw);
  }
}
