import { Component, OnDestroy, OnInit } from '@angular/core';
import { BikeService } from '../../../../services/bike.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Bike, CustomerBike } from '../../../../models/bike.model';
import { ClrDatagridStateInterface } from '@clr/angular';
import { ActivatedRoute } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-view-bike',
  templateUrl: './autonomy.component.html',
  styleUrls: [],
})
export class AutonomyComponent implements OnInit, OnDestroy {
  bike?: Bike;
  customersBikes: CustomerBike[] = [];
  constructor(private bikeService: BikeService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.parent.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.bike = data.bike;
      this.customersBikes = data.bike.customerBikes;
      this.fetchAllCustomerBikesAutonomy(this.getUndeletedCustomerBikes());
    });
  }

  ngOnDestroy(): void {}

  public getUndeletedCustomerBikes() {
    return this.customersBikes.filter((cb) => cb.deleteTrace.deletedOn == null);
  }

  autonomies = {};

  public fetchAllCustomerBikesAutonomy(customersBikes: CustomerBike[]) {
    customersBikes.forEach(
      (cb) =>
        (this.autonomies[cb.id] = {
          values: [],
          loading: false,
          total: 0,
        })
    );
    customersBikes.forEach((cb) => this.fetchAutonomy(cb.id));
  }

  public fetchAutonomy(customerBikeId: string) {
    this.autonomies[customerBikeId].loading = true;
    this.bikeService
      .getCustomerBikeAutonomy(customerBikeId, null)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.autonomies[customerBikeId].values = result.values();
        this.autonomies[customerBikeId].total = result.page.totalElements || result.values()?.length || 0;
        this.autonomies[customerBikeId].loading = false;
      });
  }

  public refreshAutonomy(state: ClrDatagridStateInterface, customerBikeId: string): void {
    this.autonomies[customerBikeId].loading = true;

    const params = {
      page: state.page.current - 1,
      size: state.page.size,
    };

    params.page = state.page.current - 1;
    params.size = state.page.size;

    this.bikeService
      .getCustomerBikeAutonomy(customerBikeId, params)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.autonomies[customerBikeId].values = result.values();
        this.autonomies[customerBikeId].total = result.page.totalElements || result.values()?.length || 0;
        this.autonomies[customerBikeId].loading = false;
      });
  }
}
