import { Bike } from './bike.model';

export class ImportResult {
  source: ImportSource;
  bikeStatus: Bike;
  state: string;

  constructor(data?: any) {
    if (data) {
      this.source = new ImportSource(data.source);
      this.bikeStatus = new Bike(data.bikeStatus);
      this.state = data.state;
    }
  }
}

export class ImportSource {
  id: number;
  mac: string;
  timestamp: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.mac = data.mac;
      this.timestamp = data.timestamp;
    }
  }
}
