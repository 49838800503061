import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BikeService } from '../../../../../../services/bike.service';

@UntilDestroy()
@Component({
  selector: 'provisioning-logs',
  templateUrl: './provisioning-logs.component.html',
  styleUrls: ['./provisioning-logs.component.scss'],
})
export class ProvisioningLogsComponent implements OnInit, OnDestroy {
  @Input()
  mac: string;

  @Input()
  filterType: string;

  logfiles: any[] = [];
  constructor(private bikeService: BikeService) {}

  ngOnInit(): void {
    this.fetchBikeLogs(this.mac);
  }

  ngOnDestroy(): void {}

  public fetchBikeLogs(mac: string): void {
    this.bikeService
      .getLogfile(mac)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.logfiles = result.filter((r) => {
          if (!this.filterType) {
            return true;
          }
          if (r.type.match(new RegExp(this.filterType))?.length > 0) {
            return true;
          }
          return false;
        });
      });
  }
}
