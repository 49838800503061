import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BikeService } from '../../../services/bike.service';
import { ImportResult } from '../../../models/import-result.model';
import { BikeModel } from '../../../models/bike-model.model';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BikeModelService } from '../../../services/bike-model.service';

@UntilDestroy()
@Component({
  selector: 'app-import-bike',
  templateUrl: './import-bike.component.html',
  styleUrls: ['./import-bike.component.scss'],
})
export class ImportBikeComponent implements OnInit, OnDestroy {
  importForm: UntypedFormGroup;
  submitted = false;
  error = '';
  results: ImportResult[] = [];
  resultsStats = {
    noChange: 0,
    create: 0,
    created: 0,
    update: 0,
    updated: 0,
    failed: 0,
  };

  models: BikeModel[] = [];

  mode = 'upload';

  loading = false;
  imported = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private bikeService: BikeService,
    private bikeModelService: BikeModelService
  ) {}

  ngOnInit(): void {
    this.bikeModelService
      .getAll({})
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.models = result.values();
      });

    this.importForm = this.formBuilder.group({
      csv: ['', Validators.required],
      model: ['', Validators.required],
    });
  }

  ngOnDestroy(): void {}

  // convenience getter for easy access to form fields
  get f(): { [key: string]: AbstractControl } {
    return this.importForm.controls;
  }

  private sendCSV(dryRun: boolean): Observable<ImportResult[]> {
    this.loading = true;

    return this.bikeService.importBikes(this.f.model.value, this.f.csv.value, dryRun).pipe(
      untilDestroyed(this),
      tap((response) => {
        console.log(`import bikes (dry run: ${dryRun}):`, response);

        this.results = response;
        this.loading = false;

        response.forEach((item) => {
          switch (item.state) {
            case 'NO_CHANGE':
              if (dryRun) {
                this.resultsStats.noChange++;
              }
              break;
            case 'CREATE':
              this.resultsStats.create++;
              break;
            case 'CREATED':
              this.resultsStats.created++;
              break;
            case 'UPDATE':
              this.resultsStats.update++;
              break;
            case 'UPDATED':
              this.resultsStats.updated++;
              break;
            case 'FAILED':
              this.resultsStats.failed++;
              break;
          }
        });
      })
    );
  }

  public onSubmit(): void {
    this.submitted = true;
    this.mode = 'preview';

    // stop here if form is invalid
    if (this.importForm.invalid) {
      this.importForm.markAsTouched();

      return;
    }

    this.error = null;

    this.sendCSV(true).subscribe(
      (response) => {},
      (error) => {
        console.log(error);
        this.error = error;

        this.f.csv.markAsTouched();
        this.f.csv.setErrors({ incorrect: true });
      }
    );
  }

  public onApply(): void {
    this.sendCSV(false).subscribe(
      (response) => {
        this.imported = true;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public onCancel(): void {
    this.resultsStats = {
      noChange: 0,
      create: 0,
      created: 0,
      update: 0,
      updated: 0,
      failed: 0,
    };

    this.mode = 'upload';
    this.results = [];
    this.imported = false;
  }
}
