<h1 i18n>
  Bike ({{ bike?.id }})&nbsp;
  <tenant [name]="bike.bikeProvision.model?.tenant"></tenant>
  <span *ngIf="bike?.isV2() && bike?.isConnected" class="connectivity online"></span>
</h1>

<div>
  <clr-tabs>
    <clr-tab *ngFor="let subComponent of tabsData">
      <button clrTabLink [routerLink]="subComponent.path">{{ subComponent.label }}</button>
    </clr-tab>
  </clr-tabs>
  <div *ngIf="bike">
    <router-outlet></router-outlet>
  </div>
</div>
