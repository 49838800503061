export class Shop {
  id: string;
  name: string;
  fullAddress: string;
  lat: number;
  lon: number;
  phoneNumber: string;
  email: string;
  managerFullName: string;
  classification: string;
  address: Address;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.fullAddress = data.fullAddress;
      this.address = new Address(data.address);
      this.lat = data.lat;
      this.lon = data.lon;
      this.phoneNumber = data.phoneNumber;
      this.email = data.email;
      this.managerFullName = data.managerFullName;
      this.classification = data.classification;
    }
  }
}

export class Address {
  line1: string;
  line2: string;
  zipCode: string;
  city: string;
  country: string;

  constructor(data?: any) {
    if (data) {
      this.line1 = data.line1;
      this.line2 = data.line2;
      this.zipCode = data.zipCode;
      this.city = data.city;
      this.country = data.country;
    }
  }
}
