import { publishLast, refCount } from 'rxjs/operators';
import { OperatorFunction } from 'rxjs';

/**
 * Combine publishLast() and refCount() pipes in one
 * DefinitiveCache keep updateTrace in cache and always return the same
 * Usage : observable.pipe(definitiveCache())
 */
export function definitiveCache<T>(): OperatorFunction<T, T> {
  const refCountPipe = refCount();
  const refPublishLast = publishLast();

  return (source: any): any => {
    return refCountPipe(refPublishLast(source));
  };
}
