import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { Ride } from '../models/ride.model';
import { Bike, CustomerBike } from '../../bike/models/bike.model';
import { UserSettings } from '../models/user-settings.model';
import { UserKnownPhone } from '../models/user-known-phone.model';
import { BikeEvent } from '../../bike/models/bike-event.model';
import { TimelineEvent } from '../models/timeline-event.model';
import { CguValidation } from '../models/cgu-validation.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerTimelineService {
  constructor() {}

  public updateAndSortEvents(bikeEvents: TimelineEvent[], newBikeEvents: TimelineEvent[]): TimelineEvent[] {
    let updatedBikeEvent: TimelineEvent[] = [];
    updatedBikeEvent = updatedBikeEvent.concat(bikeEvents);
    newBikeEvents
      .filter((newBikeEvent) => !bikeEvents.some((bikeEvent) => bikeEvent.id === newBikeEvent.id))
      .forEach((bikeEvent) => {
        updatedBikeEvent.push(bikeEvent);
      });
    return updatedBikeEvent.sort((a, b) => {
      if (b.date instanceof Date && !isNaN(b.date.getTime()) && a.date instanceof Date && !isNaN(a.date.getTime())) {
        return b.date.getTime() - a.date.getTime();
      }
      return 0;
    });
  }

  public getEventsFromUser(user: User): TimelineEvent[] {
    let events: TimelineEvent[] = [];
    events.push({
      id: 'created',
      status: 'success-standard',
      date: new Date(user.updateTrace.createdOn),
      title: `Created : ${user.firstName} ${user.lastName} (${user.email})`,
      description: `with locale ${user.locale} & phone ${user.phoneNumber}`,
      type: 'lifecycle',
    });
    return events;
  }

  public getEventsFromBikes(bikes: Bike[], customerId: String): TimelineEvent[] {
    return bikes.map((bike) => {
      const customerBike: CustomerBike = bike.customerBikes.find(
        (customerBike) => customerBike.customer?.id === customerId,
      );
      return {
        id: `bike-${bike.id}`,
        status: 'success-standard',
        date: new Date(customerBike.updateTrace?.createdOn),
        title:
          customerBike.role === 'OWNER'
            ? `Installed bike : ${customerBike.bikeName}`
            : `Added as ${customerBike.role} on bike : ${customerBike.bikeName}`,
        description: `${bike.bikeProvision.model.name} (<a href="/bikes/view/${bike.id}">${bike.bikeProvision.mac}</a>)`,
        type: 'bike',
      };
    });
  }

  public getEventsFromCustomerBikes(customerBikes: CustomerBike[]): TimelineEvent[] {
    const customerBikesEvents: BikeEvent[] = [];
    customerBikes.forEach((customerBike) => {
      if (customerBike.deleteTrace.deletedOn) {
        customerBikesEvents.push({
          id: customerBike.updateTrace.createdOn + 'ADDED',
          date: new Date(customerBike.updateTrace.createdOn),
          title:
            customerBike.role === 'OWNER'
              ? `Installed bike : ${customerBike.bikeName}`
              : `Added as ${customerBike.role} on bike : ${customerBike.bikeName}`,
          description: `by ${customerBike.updateTrace.createdBy}`,
          status: 'circle',
          type: `bikeHistory`,
        });
      }
      if (customerBike.deleteTrace.deletedOn) {
        customerBikesEvents.push({
          id: customerBike.updateTrace.createdOn + 'deleted',
          date: new Date(customerBike.deleteTrace.deletedOn),
          title:
            customerBike.role === 'OWNER'
              ? `Uninstalled bike : ${customerBike.bikeName}`
              : `Deleted as ${customerBike.role} on bike : ${customerBike.bikeName}`,
          description: `by ${customerBike.deleteTrace.deletedBy}`,
          status: 'circle',
          type: `bikeHistory`,
        });
      }
    });
    return customerBikesEvents;
  }

  public getEventsFromSettings(settings: UserSettings): TimelineEvent[] {
    const TimelineEvent: TimelineEvent = {
      id: 'last-update-settings',
      status: 'success-standard',
      date: new Date(settings.updateTrace?.updatedOn),
      title: `Last update of settings`,
      description: ``,
      type: 'settings',
    };
    return [TimelineEvent];
  }

  public getEventsFromRides(rides: Ride[]): TimelineEvent[] {
    return rides.map((ride) => {
      return {
        id: ride.id,
        status: 'dot-circle',
        date: new Date(ride.startTime),
        title: `New ride : ${ride.distance / 1000}km in ${(ride.duration / 60).toFixed(2)}mn`,
        description: `Ended at ${ride.endTime}`,
        type: 'ride',
      };
    });
  }

  public getEventsFromKnownDevices(userKnownPhones: UserKnownPhone[]): TimelineEvent[] {
    return userKnownPhones.map((userKnownPhone) => {
      return {
        id: userKnownPhone.id,
        status: 'dot-circle',
        date: new Date(userKnownPhone.updateTrace?.createdOn),
        title: `Connected with : ${userKnownPhone.manufacturer} ${userKnownPhone.model}`,
        description: `${userKnownPhone.osName} ${userKnownPhone.osVersion} - App ${userKnownPhone.appVersion} (${userKnownPhone.appDistribution}/${userKnownPhone.appBuild})`,
        type: 'connection',
      };
    });
  }

  getEventsFromCgus(cgus: CguValidation[], id: string) {
    return cgus.map((cgu: CguValidation) => {
      return {
        id: cgu.id,
        status: 'circle',
        date: cgu.validatedOn,
        title: `Cgu v${cgu.version} validated`,
        description: ``,
        type: 'cgu',
      };
    });
  }
}
