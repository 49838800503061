import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClrDatagridStateInterface } from '@clr/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Segment } from '../../../../models/segment.model';
import { FirmwareService } from '../../../../services/firmware.service';
import { Firmware } from '../../../../models/firmware.model';
import { BikeModel } from '../../../../models/bike-model.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SegmentService } from '../../../../services/segment.service';
import { BikeModelService } from '../../../../services/bike-model.service';
import { FirmwareTypes } from '../../../../models/firmware.types.model';

interface FirmwareFilter {
  [key: string]: any;

  version: string;
  segments: string[];
  models: string[];
  types: string[];
  distributionBLE: boolean;
  distributionLTE: boolean;
  enabled: boolean;
  page: number;
  size: number;
}

@UntilDestroy()
@Component({
  selector: 'list-firmwares',
  templateUrl: './list-firmware.component.html',
  styleUrls: ['./list-firmware.component.scss'],
})
export class ListFirmwareComponent implements OnInit, OnDestroy {
  public firmwares: Firmware[];
  public total: number;
  public loading = true;
  public searchForm: UntypedFormGroup;

  public filtersOpened: boolean = true;

  public firmwareTypes: String[] = Object.values(FirmwareTypes);

  constructor(
    private firmwareService: FirmwareService,
    private segmentService: SegmentService,
    private bikeModelService: BikeModelService,
    private formBuilder: UntypedFormBuilder
  ) {}

  versionPattern = '^([\\d\\*]+)(.[\\d\\*]+)*$';

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      searchTerm: ['', Validators.pattern(this.versionPattern)],
      enabledTrue: true,
      enabledFalse: true,
      distributionMediaBLE: false,
      distributionMediaLTE: false,
      types: [],
      models: [],
      segments: [],
    });

    this.fetchModels();
  }

  ngOnDestroy(): void {}

  public models: BikeModel[] = [];
  public modelsLoading: boolean = false;

  public fetchModels() {
    this.modelsLoading = true;
    this.bikeModelService.getAll(null).subscribe((result) => {
      this.modelsLoading = false;
      this.models = result.values();
    });
  }

  public segments: Segment[] = [];
  public segmentsLoading: boolean = false;

  public fetchSegments(term) {
    this.segmentsLoading = true;
    this.segmentService.search(term, true).subscribe((segments) => {
      this.segmentsLoading = false;
      this.segments = [];
      if (segments) {
        this.segments.push(...segments);
      }
    });
  }

  private constructParamsFromState(state: ClrDatagridStateInterface): FirmwareFilter {
    const enableYes = this.searchForm.get('enabledTrue').value;
    const enableNo = this.searchForm.get('enabledFalse').value;
    let enabled;
    if (enableYes != enableNo) {
      enabled = enableYes ? 'true' : 'false';
    }

    const params = {
      version: this.searchForm.get('searchTerm').value,
      segments: this.searchForm
        .get('segments')
        .value?.map((s) => s.name)
        .join(),
      models: this.searchForm
        .get('models')
        .value?.map((s) => s.technicalName)
        .join(),
      types: this.searchForm.get('types').value?.join(),
      distributionBLE: this.searchForm.get('distributionMediaBLE').value,
      distributionLTE: this.searchForm.get('distributionMediaLTE').value,
      enabled: enabled,
      page: state.page.current - 1,
      size: state.page.size,
    };

    if (!params.version) {
      delete params.version;
    }

    if (!params.segments) {
      delete params.segments;
    }

    if (!params.models) {
      delete params.models;
    }

    if (!params.types) {
      delete params.types;
    }

    if (!params.distributionBLE) {
      delete params.distributionBLE;
    }

    if (!params.distributionLTE) {
      delete params.distributionLTE;
    }

    if (!params.enabled) {
      delete params.enabled;
    }

    return params;
  }

  public onSubmit(state: ClrDatagridStateInterface): void {
    console.log(this.searchForm);
    if (this.searchForm.invalid) {
      return;
    }

    this.loading = true;

    const params = this.constructParamsFromState(state);

    this.firmwareService
      .search(params)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.loading = false;
        this.firmwares = result.values();
        this.total = result.page.totalElements || this.firmwares.length;
      });
  }

  public refresh(state: ClrDatagridStateInterface): void {
    console.log('table state:', state);

    this.loading = true;

    // We convert the filters from an array to a map,
    // because that's what our backend-calling service is expecting
    const filters: { [prop: string]: any } = {};

    if (state.filters) {
      for (const filter of state.filters) {
        const { property, value } = filter as { property: string; value: string };
        filters[property] = value;
      }
    }

    console.log('table filters:', filters);

    const params = this.constructParamsFromState(state);

    params.page = state.page.current - 1;
    params.size = state.page.size;

    this.firmwareService
      .getAll(params)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result) => {
          this.loading = false;
          this.firmwares = result.values();
          this.total = result.page.totalElements || this.firmwares.length;
        },
        error: null,
        complete: () => {
          this.loading = false;
        },
      });
  }

  public onDelete(firmware: Firmware) {
    this.firmwareService.delete(firmware.id).subscribe(() => {
      this.firmwares = this.firmwares.filter((f) => f.id != firmware.id);
    });
  }

  public onEnable(firmware: Firmware) {
    this.firmwareService
      .enable(firmware, true)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        firmware.enabled = true;
      });
  }

  public onDisable(firmware: Firmware) {
    this.firmwareService
      .enable(firmware, false)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        firmware.enabled = false;
      });
  }
}
