<h1 i18n>Update user</h1>

<form clrForm [formGroup]="updateUserForm" (ngSubmit)="onSubmit()" *ngIf="updateUserForm">
  <clr-alert [clrAlertType]="'danger'" [clrAlertClosed]="!error">
    <div clr-alert-item class="alert-item">
      <span class="alert-text">
        {{ error }}
      </span>
    </div>
  </clr-alert>

  <clr-input-container>
    <label i18n>Email</label>
    <input clrInput type="email" formControlName="email" required />
  </clr-input-container>

  <clr-input-container>
    <label i18n>Locale</label>
    <input clrInput type="text" formControlName="locale" required />
  </clr-input-container>

  <clr-input-container>
    <label i18n>First name</label>
    <input clrInput type="text" formControlName="firstName" />
  </clr-input-container>

  <clr-input-container>
    <label i18n>Last name</label>
    <input clrInput type="text" formControlName="lastName" />
  </clr-input-container>

  <clr-input-container>
    <label i18n>Phone number</label>
    <input clrInput type="text" formControlName="phoneNumber" />
  </clr-input-container>

  <button class="btn btn-primary" type="submit" i18n>Update user</button>
</form>
