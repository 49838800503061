export class UpdateTrace {
  createdOn: Date;
  createdBy: string;
  updatedOn: Date;
  updatedBy: string;

  constructor(data?: any) {
    if (data) {
      this.createdOn = data.createdOn;
      this.createdBy = data.createdBy;
      this.updatedOn = data.updatedOn;
      this.updatedBy = data.updatedBy;
    }
  }
}
