import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from '../../models/user.model';
import { CustomerService } from '../../services/customer.service';
import { ClrDatagridStateInterface } from '@clr/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-list-customer',
  templateUrl: './list-customer.component.html',
  styleUrls: ['./list-customer.component.scss'],
})
export class ListCustomerComponent implements OnInit, OnDestroy {
  public users: User[];
  public total: number;
  public loading = true;

  public searchForm: UntypedFormGroup;
  public filtersOpened: boolean = true;

  public afterSalesMode: boolean = false;

  constructor(
    private customerService: CustomerService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.data.pipe().subscribe((data) => {
      this.afterSalesMode = data.mode == 'after-sales';
    });
    this.searchForm = this.formBuilder.group({
      searchTerm: '',
      activated: '',
      deleted: '',
      tenant: '',
    });
  }

  ngOnDestroy(): void {}

  public refresh(state: ClrDatagridStateInterface): void {
    console.log('table state:', state);

    this.loading = true;

    let params = {
      page: state.page.current - 1,
      size: state.page.size,
      role: this.afterSalesMode ? 'ROLE_RETAILER' : 'ROLE_CUSTOMER',
    };

    const searchTerm = this.searchForm.get('searchTerm').value;
    if (searchTerm) {
      params['searchTerm'] = searchTerm;
    }
    const tenant = this.searchForm.get('tenant').value;
    if (tenant) {
      params['tenant'] = tenant;
    }

    const deleted = this.searchForm.get('deleted').value;
    if (deleted) {
      params['deleted'] = deleted;
    }
    const activated = this.searchForm.get('activated').value;
    if (activated) {
      params['activated'] = activated;
    }

    this.customerService
      .getAll(params)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        console.log('Result', result);

        this.users = result.values();
        this.total = result.page.totalElements || this.users?.length;
        this.loading = false;
      });
  }

  onCreate() {
    this.router.navigate([`/after-sales/create`]);
  }
}
