export class BikeGSMHistory {
  id: string;
  rssi: number;
  rsrq: number;
  iccid: string;
  imei: string;
  imsi: string;
  operatorCode: number;
  operatorName: string;
  techno: string;
	createdDate: Date;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.rssi = data.rssi;
      this.rsrq = data.rsrq;
      this.iccid = data.iccid;
      this.imei = data.imei;
      this.imsi = data.imsi;
      this.operatorCode = data.operatorCode;
      this.operatorName = data.operatorName;
      this.techno = data.techno;
      this.createdDate = new Date(data.createdOn);
    }
  }
}
