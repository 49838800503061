export enum FirmwareTypes {
  APP = 'APP',
  BLE = 'BLE',
  BLE_SD = 'BLE_SD',
  BMS = 'BMS',
  BOOTLOADER = 'BOOTLOADER',
  CONTROLLER = 'CONTROLLER',
  IMAGE = 'IMAGE',
  REARLIGHT = 'REARLIGHT',
}
