import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { TokenInjectorInterceptor } from './interceptors/token-injector.interceptor';
import { ApiModule } from '../api/api.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, ApiModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInjectorInterceptor, multi: true },
  ],
})
export class AuthModule {}
