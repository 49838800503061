import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { SimCardService } from '../../services/simcard.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SimCard } from '../../models/simcard.model';

@UntilDestroy()
@Component({
  selector: 'sim-card',
  templateUrl: './sim-card-details.component.html',
})
export class SimCardDetailsComponent {
  @Input()
  iccid: string;

  @Input()
  phoneNumber: string;

  public simCard: SimCard;
  public loading: boolean = true;

  constructor(private simcardService: SimCardService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.loading = true;
    this.simcardService
      .getByIccid(this.iccid, null)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result) => {
          this.simCard = result;
        },
        complete: () => {
          this.loading = false;
        },
      });
  }
}
