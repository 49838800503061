import { Component, OnDestroy, OnInit } from '@angular/core';
import { BikeService } from '../../../../services/bike.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Bike, CustomerBike, MaintenanceOperation } from '../../../../models/bike.model';
import { ActivatedRoute } from '@angular/router';
import { BikeWarning } from '../../../../models/bike-warning.model';
import { NextVersionOTAV2 } from '../../../../models/bike-next-version.model';
import { NextVersion } from '../../../../models/next-version.model';
import { VersionService } from '../../../../services/version.service';
import * as moment from 'moment';
import { RideService } from '../../../../../user/services/ride.service';
import { AngellBackService } from '../../../../../services/services/angell-back.service';

@UntilDestroy()
@Component({
  selector: 'app-view-bike',
  templateUrl: './sav.component.html',
  styleUrls: ['./sav.component.scss'],
})
export class SavComponent implements OnInit, OnDestroy {
  // Global
  public bike?: Bike;
  public now: Date;

  // Diagnosis
  public since: string = 'inspection';
  public allWarnings: BikeWarning[] = [];
  public filteredWarnings: BikeWarning[] = [];

  public warningsGroupedByTypes: any;
  public types: any = {
    Battery: {
      selected: false,
      icon: 'battery',
    },
    Cockpit: {
      selected: false,
      icon: 'tablet',
    },
    Commodo: {
      selected: false,
      icon: 'cursor-hand-click',
    },
    Controller: {
      selected: false,
      icon: 'internet-of-things',
    },
    OTA: {
      selected: false,
      icon: 'cloud',
    },
    'Rear light': {
      selected: false,
      icon: 'lightbulb',
    },
    'Turn light': {
      selected: false,
      icon: 'bolt',
    },
  };

  // Summary
  public maintenanceOperations: MaintenanceOperation[];
  public lastMaintenanceOperation: MaintenanceOperation;

  constructor(
    private route: ActivatedRoute,
    private bikeService: BikeService,
  ) {}

  ngOnInit(): void {
    this.route.parent.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.bike = data.bike;
      this.now = data.now;

      this.fetchWarnings();
      this.fetchMaintenanceOperations();
    });
  }

  ngOnDestroy(): void {}

  public onSinceChange(): void {
    this.fetchWarnings();
  }

  private getDateFromSince(since: string): number {
    switch (since) {
      case 'week':
        return moment(new Date()).subtract(1, 'w').valueOf();
      case 'month':
        return moment(new Date()).subtract(1, 'M').valueOf();
      case 'inspection':
        return this.lastMaintenanceOperation ? this.lastMaintenanceOperation.endedOn.getTime() : 0;
      default:
        return 0;
    }
  }

  private fetchWarnings(): void {
    this.bikeService
      .getWarnings(this.bike.id, this.getDateFromSince(this.since), {})
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        if (!result || !result.values()) {
          return;
        }
        this.allWarnings = result.values();
        this.warningsGroupedByTypes = this.allWarnings.reduce(function (warningByTypes, warning) {
          const type = (warningByTypes[warning['type']] = warningByTypes[warning['type']] || {});
          (type.warnings = type['warnings'] || []).push(warning);
          type.criticity = !type.criticity || type.criticity < warning.criticity ? warning.criticity : type.criticity;
          return warningByTypes;
        }, {});
        for (const key of Object.keys(this.warningsGroupedByTypes)) {
          this.types[key].selected = true;
        }
        this.filteredWarnings = this.allWarnings;
      });
  }

  public onWarningToggled(type): void {
    type.selected = !type.selected;
    this.updateFilters();
  }

  private updateFilters(): void {
    this.filteredWarnings = this.allWarnings.filter((w) => this.types[w.type].selected);
  }

  protected readonly Object = Object;

  private fetchMaintenanceOperations(): void {
    let dataQueryParam = new Date();
    dataQueryParam.setHours(23, 59, 59, 999);

    this.bikeService
      .getMaintenanceOperations(this.bike.id, null)
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.maintenanceOperations = response.values();
        this.lastMaintenanceOperation = this.maintenanceOperations.find((operation) => operation.endedOn != null);
      });
  }

  public showMaintenanceOpeModal = false;
  public dateFrom: Date = new Date();
  public dateTo: Date = new Date();

  public submitMaintenceOperation(): void {
    const ope = new MaintenanceOperation({
      startedOn: this.dateFrom,
      endedOn: this.dateTo,
      type: 'BO',
    });
    this.bikeService
      .saveMaintenanceOperation(this.bike.id, ope)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.showMaintenanceOpeModal = false;
          this.fetchMaintenanceOperations();
        },
        error: (error) => console.log(error),
        complete: () => {},
      });
  }
}
