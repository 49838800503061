export class DeleteTrace {
  deletedOn: Date;
  deletedBy: string;

  constructor(data?: any) {
    if (data) {
      this.deletedOn = data.deletedOn;
      this.deletedBy = data.deletedBy;
    }
  }
}
