<div *ngIf="bike">
  <div class="clr-row">
    <div class="clr-col-lg-6 clr-col-md-8 clr-col-12">
      <h2 i18n>
        Bench provisioning
        <span *ngIf="bike.devModeEnabled">
          <button
            type="button"
            class="btn btn-sm btn-icon btn-secondary btn-link"
            (click)="onOpenEditModal()"
            *aclCheck="'actions_provisioning'"
          >
            <cds-icon shape="edit"></cds-icon>
          </button>
        </span>
      </h2>
      <table class="table table-vertical">
        <tbody>
          <tr>
            <th i18n>Cockpit</th>
            <td>
              <a [routerLink]="['/cockpits', 'view', bike.bikeProvision.mac]" href>{{ bike.bikeProvision.mac }}</a>
            </td>
          </tr>
          <tr>
            <th i18n>Model</th>
            <td>
              <bike-model-link [model]="bike.bikeProvision.model"></bike-model-link>
              <span class="label">
                <cds-icon style="margin-right: 5px" shape="paint-roller" role="img"></cds-icon>
                {{ bike.bikeProvision.color }}
              </span>
            </td>
          </tr>
          <tr>
            <th i18n>Bike Frame S/N</th>
            <td [title]="bike.bikeProvision.bikeFrameSerialNumber || ''">
              {{ bike.bikeProvision.bikeFrameSerialNumber }}
            </td>
          </tr>
          <tr>
            <th i18n>Motor Model</th>
            <td [title]="bike.bikeProvision.motorModel || ''">{{ bike.bikeProvision.motorModel || '-' }}</td>
          </tr>
          <tr>
            <th i18n>Motor controller version</th>
            <td [title]="bike.bikeProvision.motorControllerVersion || ''">
              {{ bike.bikeProvision.motorControllerVersion }}
            </td>
          </tr>
          <tr>
            <th i18n>Motor controller S/N</th>
            <td [title]="bike.bikeProvision.motorControllerSerialNumber || ''">
              {{ bike.bikeProvision.motorControllerSerialNumber }}
            </td>
          </tr>
          <tr>
            <th i18n>Antitheft Code</th>
            <td [title]="bike.bikeProvision.antitheftCode || ''">
              {{ bike.bikeProvision.antitheftCode || '-' }}
            </td>
          </tr>

          <tr>
            <th i18n>Battery S/N</th>
            <td [title]="bike.bikeProvision.batterySerialNumber || ''">
              {{ bike.bikeProvision.batterySerialNumber }}
            </td>
          </tr>
          <tr>
            <th i18n>Tests Results</th>
            <td [title]="bike.bikeProvision.testResults || ''">
              <span
                *ngIf="!['SUCCESS', 'PASS'].includes(bike.bikeProvision.testResults)"
                class="label label-danger"
                i18n
                >{{ bike.bikeProvision.testResults }}</span
              >
              <span
                *ngIf="['SUCCESS', 'PASS'].includes(bike.bikeProvision.testResults)"
                class="label label-success"
                i18n
                >{{ bike.bikeProvision.testResults }}</span
              >
            </td>
          </tr>
          <tr>
            <th i18n>Last update</th>
            <td>
              {{
                (bike.bikeProvision.updateTrace.updatedOn | date: 'dd/MM/yy HH:mm:ss') ||
                  (bike.bikeProvision.updateTrace.createdOn | date: 'dd/MM/yy HH:mm:ss')
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="clr-col-lg-6 clr-col-md-8 clr-col-12">
      <h2 i18n>Hardware Config (from bike)</h2>
      <span *ngIf="hardwareConfigHistory.length == 0" i18n>No data</span>
      <table class="table table-vertical" *ngIf="hardwareConfigHistory.length > 0">
        <tbody>
          <tr>
            <th i18n>Bike Model</th>
            <td [title]="hardwareConfigHistory[0]?.model || ''">
              {{ hardwareConfigHistory[0]?.model }}
            </td>
          </tr>
          <tr>
            <th i18n>Motor Model</th>
            <td [title]="hardwareConfigHistory[0]?.motorModel || ''">
              {{ hardwareConfigHistory[0]?.motorModel || '-' }}
            </td>
          </tr>
          <tr>
            <th i18n>Motor controller version</th>
            <td [title]="hardwareConfigHistory[0]?.controllerHwVersion || ''">
              {{ hardwareConfigHistory[0]?.controllerHwVersion }}
            </td>
          </tr>
          <tr>
            <th i18n>Motor Power</th>
            <td [title]="hardwareConfigHistory[0]?.motorPower || ''">
              {{ hardwareConfigHistory[0]?.motorPower || '-' }}
            </td>
          </tr>
          <tr>
            <th i18n>Bike Color</th>
            <td [title]="hardwareConfigHistory[0]?.color || ''">
              {{ hardwareConfigHistory[0]?.color }}
            </td>
          </tr>
          <tr>
            <th i18n>Wheel diameter</th>
            <td [title]="hardwareConfigHistory[0]?.wheelDiameter || ''">
              {{ hardwareConfigHistory[0]?.wheelDiameter }}
            </td>
          </tr>

          <tr>
            <th i18n>Bike Frame S/N</th>
            <td [title]="hardwareConfigHistory[0]?.bikeframeSN || ''">
              {{ hardwareConfigHistory[0]?.bikeframeSN }}
            </td>
          </tr>
          <tr>
            <th i18n>Shutdown cable HW version</th>
            <td [title]="hardwareConfigHistory[0]?.shutdownCableHwVersion || ''">
              {{ hardwareConfigHistory[0]?.shutdownCableHwVersion }}
            </td>
          </tr>
          <tr>
            <th i18n>Last update</th>
            <td>
              {{
                (hardwareConfigHistory[0]?.updateTrace.updatedOn | date: 'dd/MM/yy HH:mm:ss') ||
                  (hardwareConfigHistory[0]?.updateTrace.createdOn | date: 'dd/MM/yy HH:mm:ss')
              }}
            </td>
          </tr>
        </tbody>
      </table>
      <clr-checkbox-container *aclCheck="'actions_sync_bike_provisioning'">
        <label>Sync provisioning Cloud -> Bike (on next MQTT connexion) :</label>

        <clr-checkbox-wrapper>
          <input
            (change)="onSyncProvisioningFlagChanged()"
            [(ngModel)]="syncProvisioningFlag"
            clrCheckbox
            name="options-2"
            type="checkbox"
          />
          <label>Yes</label>
        </clr-checkbox-wrapper>
      </clr-checkbox-container>
    </div>

    <div class="clr-col-lg-12 clr-col-md-12 clr-col-12">
      <h2 i18n>Bench Provisioning History</h2>

      <div *ngIf="!provisionHistoryLoaded">
        <button (click)="loadProvisionHistory()" class="btn btn-outline" i18n type="button">
          <cds-icon shape="sync"></cds-icon>
          Load
        </button>
      </div>
      <table *ngIf="provisionHistoryLoaded" class="table">
        <thead>
          <tr>
            <th i18n>id</th>
            <th i18n>action</th>
            <th i18n>mac</th>
            <th i18n>color</th>
            <th i18n>testResults</th>
            <th i18n>iccid</th>
            <th i18n>motorControllerSerialNumber</th>
            <th i18n>bikeFrameSerialNumber</th>
            <th i18n>batterySerialNumber</th>
            <th i18n>topSerialNumber</th>
            <th i18n>bottomSerialNumber</th>
            <th i18n>appVersion</th>
            <th i18n>bluetoothVersion</th>
            <th i18n>imageVersion</th>
            <th i18n>motorControllerVersion</th>
            <th i18n>antitheftCode</th>
            <th i18n>aesEncryptionKey</th>
            <th i18n>modelId</th>
            <th i18n>provisionedOn</th>
            <th i18n>createdOn</th>
            <th i18n>agent</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of provisionHistoryItems">
            <td>{{ item.id }}</td>
            <td>{{ item.action }}</td>
            <td>{{ item.mac }}</td>
            <td>{{ item.color }}</td>
            <td>{{ item.testResults }}</td>
            <td>
              <a [routerLink]="['/sims', 'view', item.iccid]" href>
                {{ item.iccid }}
              </a>
            </td>
            <td>{{ item.motorControllerSerialNumber }}</td>
            <td>{{ item.bikeFrameSerialNumber }}</td>
            <td>{{ item.batterySerialNumber }}</td>
            <td>{{ item.topSerialNumber }}</td>
            <td>{{ item.bottomSerialNumber }}</td>
            <td>{{ item.appVersion }}</td>
            <td>{{ item.bluetoothVersion }}</td>
            <td>{{ item.imageVersion }}</td>
            <td>{{ item.motorControllerVersion }}</td>
            <td>{{ item.antitheftCode }}</td>
            <td>{{ item.aesEncryptionKey }}</td>
            <td>{{ item.modelId }}</td>
            <td>{{ item.provisionedOn }}</td>
            <td>{{ item.createdOn }}</td>
            <td>{{ item.agent }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <provisioning-logs class="clr-col-lg-6 clr-col-md-8 clr-col-12" [mac]="bike.bikeProvision.mac"></provisioning-logs>
  </div>
  <!-- .clr-row -->
  <clr-modal [(clrModalOpen)]="editModalDisplayed">
    <h3 class="modal-title" i18n>Edit provisioning</h3>
    <div class="modal-body">
      <clr-select-container>
        <label i18n>Model</label>
        {{ selectedModel }}
        <select clrSelect [(ngModel)]="selectedModel" (ngModelChange)="selectedColor = selectedModel.colors[0]">
          <option></option>
          <option *ngFor="let model of models" [ngValue]="model">{{ model.name }}</option>
        </select>
      </clr-select-container>
      <clr-select-container>
        <label i18n>Color</label>
        <select clrSelect [(ngModel)]="selectedColor" *ngIf="selectedModel">
          <option *ngFor="let color of selectedModel?.colors">{{ color }}</option>
        </select>
      </clr-select-container>
      <clr-alert [clrAlertType]="'danger'" [clrAlertClosed]="!editError">
        <div class="alert-item">
          <span class="alert-text" i18n> ERROR : {{ editError }} </span>
        </div>
      </clr-alert>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="editModalDisplayed = false">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="update()">Update</button>
    </div>
  </clr-modal>
</div>
