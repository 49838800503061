import { Component, OnDestroy, OnInit } from '@angular/core';
import { BikeService } from '../../../../services/bike.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Bike } from '../../../../models/bike.model';
import { AngellBackService } from '../../../../../services/services/angell-back.service';
import { AngellBack } from '../../../../../services/models/angell-back.model';

@UntilDestroy()
@Component({
  selector: 'app-view-bike',
  templateUrl: './services.component.html',
  styleUrls: [],
})
export class ServicesComponent implements OnInit, OnDestroy {
  bike?: Bike;
  public angellBacks: AngellBack[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private bikeService: BikeService,
    private angellBackService: AngellBackService
  ) {}

  ngOnInit(): void {
    this.route.parent.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.bike = data.bike;
      this.fetchAngellBack(this.bike.id);
    });
  }

  ngOnDestroy(): void {}

  public fetchAngellBack(bikeId: string): void {
    this.angellBackService
      .getByBikeId(bikeId)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.angellBacks = result.values();
      });
  }

  public showAngellBack(id: string): void {
    this.router.navigate(['/services', 'angell-back', 'view', id]);
  }

  public showAngellCare(id: string): void {
    this.router.navigate(['/services', 'angell-care', 'view', id]);
  }
}
