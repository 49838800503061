import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Segment } from '../../../models/segment.model';
import { SegmentService } from '../../../services/segment.service';
import { SupervisionByBike, SupervisionData, Week } from '../../../models/bike-supervision.model';
import { ActivatedRoute, Router } from '@angular/router';
import { SupervisionService } from '../../../services/supervision.service';
import { ClrDatagridStateInterface } from '@clr/angular';

@UntilDestroy()
@Component({
  selector: 'bikes-supervision',
  templateUrl: './bikes-supervision.component.html',
  styleUrls: ['./bikes-supervision.component.scss'],
})
export class BikesSupervisionComponent implements OnInit, OnDestroy {
  now: Date = new Date();

  segmentName: string;
  from: Date;
  to: Date;

  public segmentSupervision: SupervisionData;
  public bikesSupervision: SupervisionByBike[] = [];

  constructor(
    private supervisionService: SupervisionService,
    private segmentService: SegmentService,
    private route: ActivatedRoute,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  weeks: Week[] = [];

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.from = params.from
        ? new Date(parseInt(params.from, 10))
        : new Date(this.now.getTime() - 7 * 24 * 60 * 60 * 1000);
      this.to = params.to ? new Date(parseInt(params.to, 10)) : this.now;
      this.segmentName = params.segment ? params.segment : 'prod';

      if (!params.from || !params.to || !params.segment) {
        this.onOpenUpdateTargetModal();
      } else {
        this.fetchSegmentSupervision();
      }
    });
  }

  ngOnDestroy(): void {}

  private updateQueryParams() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        segment: this.segmentName,
        from: this.from.getTime(),
        to: this.to.getTime(),
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  segmentSupervisionLoading: boolean;
  segmentSupervisionKmLoading: boolean;
  segmentSupervisionErrorLoading: boolean;
  public fetchSegmentSupervision() {
    this.segmentSupervisionLoading = true;
    const params = {
      segment: this.segmentName,
      from: this.from.getTime(),
      to: this.to.getTime(),
    };

    this.segmentSupervisionKmLoading = true;
    this.segmentSupervision = new SupervisionData();
    this.supervisionService
      .loadSegmentWeeklySupervisionKmData(params)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (value) => {
          this.segmentSupervision.weeklyKm = value;
        },
        complete: () => {
          this.segmentSupervisionKmLoading = false;
          this.weeks = this.segmentSupervision.getWeeks();
        },
      }); /*
    this.supervisionService
      .loadSegmentSupervisionKmData(params)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (value) => {
          this.segmentSupervision.km = value;
        },
        complete: () => {
          this.segmentSupervisionKmLoading = false;
          this.weeks = this.segmentSupervision.getWeeks();
        },
      });*/
    this.segmentSupervisionErrorLoading = true;
    this.supervisionService
      .loadSegmentWeeklySupervisionErrorsData(params)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (value) => {
          this.segmentSupervision.weeklyErrors = value;
        },

        complete: () => {
          this.segmentSupervisionErrorLoading = false;
          this.weeks = this.segmentSupervision.getWeeks();
        },
      });
    /*this.supervisionService
      .loadSegmentSupervisionErrorsData(params)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (value) => {
          this.segmentSupervision.errors = value;
        },

        complete: () => {
          this.segmentSupervisionErrorLoading = false;
          this.weeks = this.segmentSupervision.getWeeks();
        },
      });*/
  }

  showBikesSupervision: boolean = true;
  bikesSupervisionLoading: boolean;
  bikesWeeklySupervisionLoading: boolean;
  public total: number = 0;

  public refreshBikesSupervision(state: ClrDatagridStateInterface): void {
    const params = {
      segment: this.segmentName,
      from: this.from.getTime(),
      to: this.to.getTime(),
      page: state.page.current - 1,
      size: state.page.size,
      withWeeklyStats: true,
    };

    this.bikesSupervisionLoading = true;
    this.bikesWeeklySupervisionLoading = true;
    /*this.supervisionService
      .loadBikesSupervision(params)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result) => {
          if (this.bikesWeeklySupervisionLoading == true) {
            this.bikesSupervision = result.values();
            this.total = result.page.totalElements;
          }
        },
        complete: () => {
          this.bikesSupervisionLoading = false;
        },
      });*/

    this.supervisionService
      .loadWeeklyBikesSupervision(params)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result) => {
          this.bikesSupervision = result.values();
          this.total = result.page.totalElements;
        },
        complete: () => {
          this.bikesWeeklySupervisionLoading = false;
        },
      });
  }

  // TARGET FORM
  updateTargetModalDisplayed: boolean = false;

  startDate: string;
  endDate: string;

  public segment: Segment;
  public segments: Segment[];
  public segmentsLoading: boolean = false;

  onOpenUpdateTargetModal() {
    this.segment = new Segment({ name: this.segmentName });
    this.initDatesInputs(this.from, this.to);
    this.updateTargetModalDisplayed = true;
  }

  private initDatesInputs(from: Date, to: Date) {
    this.startDate = this.extractDateFromString(from);

    this.endDate = this.extractDateFromString(to);
  }

  extractDateFromString(now: Date) {
    return `${now.getFullYear()}-${now.getMonth() < 10 ? '0' : ''}${now.getMonth() + 1}-${
      now.getDate() < 10 ? '0' : ''
    }${now.getDate()}`;
  }

  public getDateFromInputs(inputDateValue): Date {
    const fromDateParts = inputDateValue.split('-');
    return new Date(+fromDateParts[0], +fromDateParts[1] - 1, +fromDateParts[2]);
  }

  setStartDate(evt) {
    this.startDate = evt.target.value;
    console.log('start date : ', this.startDate);
    console.log('end date : ', this.endDate);
    console.log('segment : ', this.segmentName);
  }

  setEndDate(evt) {
    this.endDate = evt.target.value;
  }

  public fetchSegments(term) {
    this.segmentsLoading = true;
    this.segmentService.search(term, true).subscribe((segments) => {
      this.segmentsLoading = false;
      this.segments = segments;
    });
  }
  formError: string;
  validateForm(from: Date, to: Date, segment: Segment) {
    const difference = Math.abs(to.getTime() - from.getTime());
    const differenceEnJours = difference / (1000 * 3600 * 24);

    // Vérifier si la différence en jours est inférieure à un an
    if (differenceEnJours > 365) {
      this.formError = 'Max range for period is a year';
      return false;
    }
    this.formError = undefined;
    return true;
  }

  onTargetUpdated() {
    const from: Date = this.getDateFromInputs(this.startDate);
    const to: Date = this.getDateFromInputs(this.endDate);
    if (!this.validateForm(from, to, this.segment)) {
      return;
    }

    this.segmentName = this.segment.name;
    this.from = from;
    this.to = to;
    this.updateTargetModalDisplayed = false;

    this.updateQueryParams();

    // this.fetchSegmentSupervision();
    this.bikesSupervision = [];
  }
}
