<h1 i18n>Notifications</h1>

<clr-accordion class="filters-accordion">
  <clr-accordion-panel [(clrAccordionPanelOpen)]="filtersOpened" class="filters-accordion-panel">
    <clr-accordion-title>Filters</clr-accordion-title>
    <clr-accordion-content *clrIfExpanded>
      <form clrForm [formGroup]="searchForm" [clrLayout]="'horizontal'" class="search-form clr-form-compact">
        <clr-select-container>
          <label>Client</label>
          <select clrSelect formControlName="tenant" (ngModelChange)="reloadNotifs()">
            <option [value]="'ANGELL'">Angell</option>
            <option [value]="'MINI'">Mini</option>
          </select>
        </clr-select-container>
      </form>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>

<ul class="nav" role="tablist">
  <li role="presentation" class="nav-item">
    <button
      class="btn btn-link nav-link"
      [routerLink]="['/users', 'notifications', 'lists', 'mail']"
      routerLinkActive="active"
      type="button"
    >
      Mail
    </button>
  </li>
  <li role="presentation" class="nav-item">
    <button
      class="btn btn-link nav-link"
      [routerLink]="['/users', 'notifications', 'lists', 'push']"
      routerLinkActive="active"
      type="button"
    >
      Push
    </button>
  </li>
  <li role="presentation" class="nav-item">
    <button
      class="btn btn-link nav-link"
      [routerLink]="['/users', 'notifications', 'lists', 'sms']"
      routerLinkActive="active"
      type="button"
    >
      SMS
    </button>
  </li>
</ul>
<section role="tabpanel" *ngIf="type == 'mail'">
  <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
    <clr-dg-column i18n>Name</clr-dg-column>
    <clr-dg-column i18n>Format</clr-dg-column>
    <clr-dg-column i18n>Language</clr-dg-column>

    <clr-dg-row *clrDgItems="let notification of notifications" [clrDgItem]="notification">
      <clr-dg-cell>{{ notification.name }}</clr-dg-cell>
      <clr-dg-cell>{{ notification.format }}</clr-dg-cell>
      <clr-dg-cell>{{ notification.lang }}</clr-dg-cell>
    </clr-dg-row>

    <clr-dg-detail *clrIfDetail="let info">
      <clr-dg-detail-header>Preview</clr-dg-detail-header>
      <clr-dg-detail-body>
        <iframe class="view-mail-content" [src]="info.preview"></iframe>
      </clr-dg-detail-body>
    </clr-dg-detail>

    <clr-dg-footer i18n>
      {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} notifications
      <clr-dg-pagination #pagination [clrDgTotalItems]="total" [clrDgPageSize]="15"></clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</section>

<section role="tabpanel" *ngIf="type == 'push'">
  <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
    <clr-dg-column i18n>Name</clr-dg-column>
    <clr-dg-column i18n>Language</clr-dg-column>

    <clr-dg-row *clrDgItems="let notification of notifications" [clrDgItem]="notification">
      <clr-dg-cell>{{ notification.name }}</clr-dg-cell>
      <clr-dg-cell>{{ notification.lang }}</clr-dg-cell>
    </clr-dg-row>

    <clr-dg-detail *clrIfDetail="let info">
      <clr-dg-detail-header>Preview</clr-dg-detail-header>
      <clr-dg-detail-body>
        <iframe class="view-mail-content" [src]="info.preview"></iframe>
      </clr-dg-detail-body>
    </clr-dg-detail>

    <clr-dg-footer i18n>
      {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} notifications
      <clr-dg-pagination #pagination [clrDgTotalItems]="total" [clrDgPageSize]="15"></clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</section>

<section role="tabpanel" *ngIf="type == 'sms'">
  <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
    <clr-dg-column i18n>Name</clr-dg-column>
    <clr-dg-column i18n>Language</clr-dg-column>

    <clr-dg-row *clrDgItems="let notification of notifications" [clrDgItem]="notification">
      <clr-dg-cell>{{ notification.name }}</clr-dg-cell>
      <clr-dg-cell>{{ notification.lang }}</clr-dg-cell>
    </clr-dg-row>

    <clr-dg-detail *clrIfDetail="let info">
      <clr-dg-detail-header>Preview</clr-dg-detail-header>
      <clr-dg-detail-body>
        <iframe class="view-mail-content" [src]="info.preview"></iframe>
      </clr-dg-detail-body>
    </clr-dg-detail>

    <clr-dg-footer i18n>
      {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ total }} notifications
      <clr-dg-pagination #pagination [clrDgTotalItems]="total" [clrDgPageSize]="15"></clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</section>
