import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'meter',
})
export class MeterPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): string {
    let sym = 'm';

    if (value > 1000) {
      value = value / 1000;
      sym = 'km';
    }

    value = Math.round((value + Number.EPSILON) * 100) / 100;

    return `${value} ${sym}`;
  }
}
