<div class="clr-row">
  <div class="clr-col-lg-6 clr-col-md-8 clr-col-12">
    <h2 i18n>Info</h2>
    <user-details [user]="user"></user-details>
    <span *aclCheck="'actions_users'">
      <button class="btn btn-outline" type="button" i18n [routerLink]="['/list-retailers', 'edit', user.id]">
        <cds-icon shape="pencil"></cds-icon>
        Edit
      </button>
      <span>
        <button
          class="btn btn-danger-outline"
          type="button"
          i18n
          appConfirm
          (appConfirm)="onDelete(user)"
          *ngIf="user.deleted == false"
        >
          <cds-icon shape="trash"></cds-icon> Delete
        </button>
      </span>
      <button class="btn btn-danger-outline" type="button" i18n (click)="onRestore(user)" *ngIf="user.deleted == true">
        <cds-icon shape="check"></cds-icon>
        Restore
      </button>
    </span>
    <button
      class="btn btn-outline"
      type="button"
      i18n
      [routerLink]="['/users', 'view', user.id, 'notifications', 'mail']"
      [queryParams]="{ customer_id: user.id }"
    >
      <cds-icon shape="bell"></cds-icon>
      View notifications
    </button>
  </div>

  <div class="clr-col-lg-6 clr-col-md-8 clr-col-12">
    <h2 i18n>Bikes</h2>
    <user-bikes-details [bikes]="bikes" [userId]="this.user.id"></user-bikes-details>
  </div>
</div>
<!-- .clr-row -->

<div class="clr-row">
  <div class="clr-col-lg-8 clr-col-md-8 clr-col-12">
    <h2 i18n>User settings</h2>

    <table class="table table-vertical" *ngIf="settings">
      <tbody>
        <tr>
          <th i18n>Gender</th>
          <td>{{ settings.gender }}</td>
        </tr>
        <tr>
          <th i18n>Age</th>
          <td>{{ settings.age }}</td>
        </tr>
        <tr>
          <th i18n>Weight</th>
          <td>{{ settings.weight }} kg</td>
        </tr>
        <tr>
          <th i18n>Height</th>
          <td>{{ settings.height }} cm</td>
        </tr>
        <tr>
          <th i18n>Bike Location consent</th>
          <td>
            <span *ngIf="settings.isBikeGeolocationAuthorized == null" i18n>Not answered</span>
            <span class="label label-success" *ngIf="settings.isBikeGeolocationAuthorized === true" i18n>Accepted</span>
            <span class="label label-danger" *ngIf="settings.isBikeGeolocationAuthorized === false" i18n>Declined</span>
          </td>
        </tr>
        <tr>
          <th i18n>Apple Music</th>
          <td>
            <span class="label label-success" *ngIf="settings.appleMusicIsEnabled" i18n>Enabled</span>
            <span class="label label-danger" *ngIf="!settings.appleMusicIsEnabled" i18n>Disabled</span>
          </td>
        </tr>
        <tr>
          <th i18n>Spotify</th>
          <td>
            <span class="label label-success" *ngIf="settings.spotifyIsEnabled" i18n>Enabled</span>
            <span class="label label-danger" *ngIf="!settings.spotifyIsEnabled" i18n>Disabled</span>
          </td>
        </tr>
        <tr>
          <th i18n>Strava</th>
          <td>
            <span class="label label-success" *ngIf="settings.stravaRefreshToken" i18n>Enabled</span>
            <span class="label label-danger" *ngIf="!settings.stravaRefreshToken" i18n>Disabled</span>
          </td>
        </tr>
        <tr>
          <th i18n>Distance unit</th>
          <td>{{ settings.distanceUnit }}</td>
        </tr>
        <tr>
          <th i18n>Assistance level</th>
          <td>{{ settings.assistanceLevel }}</td>
        </tr>
        <tr>
          <th i18n>Auto save</th>
          <td>
            <span class="label label-success" *ngIf="settings.autoSave" i18n>Yes</span>
            <span class="label label-danger" *ngIf="!settings.autoSave" i18n>No</span>
          </td>
        </tr>
        <tr>
          <th i18n>Appearance mode</th>
          <td>{{ settings.appearanceMode }}</td>
        </tr>
        <tr>
          <th i18n>Auto turn signal</th>
          <td>
            <span class="label label-success" *ngIf="settings.autoTurnSignal" i18n>Yes</span>
            <span class="label label-danger" *ngIf="!settings.autoTurnSignal" i18n>No</span>
          </td>
        </tr>
        <tr>
          <th i18n>Auto headlights</th>
          <td>
            <span class="label label-success" *ngIf="settings.autoHeadlights" i18n>Yes</span>
            <span class="label label-danger" *ngIf="!settings.autoHeadlights" i18n>No</span>
          </td>
        </tr>
        <tr>
          <th i18n>Fall detection</th>
          <td>
            <span class="label label-success" *ngIf="settings.fallDetection" i18n>Yes</span>
            <span class="label label-danger" *ngIf="!settings.fallDetection" i18n>No</span>
          </td>
        </tr>
        <tr>
          <th i18n>Battery sleep enable ?</th>
          <td>
            <span class="label label-success" *ngIf="settings.batterySleepEnabled === true" i18n>Yes</span>
            <span class="label label-danger" *ngIf="settings.batterySleepEnabled === false" i18n>No</span>
          </td>
        </tr>
        <tr>
          <th i18n>Battery sleep duration</th>
          <td>{{ settings.batterySleepDuration }}mn</td>
        </tr>
        <tr>
          <th i18n>Map in 3D</th>
          <td>
            <span class="label label-success" *ngIf="settings.mapIn3d" i18n>Yes</span>
            <span class="label label-danger" *ngIf="!settings.mapIn3d" i18n>No</span>
          </td>
        </tr>
        <tr>
          <th i18n>Fast ride enabled ?</th>
          <td>
            <span class="label label-success" *ngIf="settings.fastRide === true" i18n>Yes</span>
            <span class="label label-danger" *ngIf="settings.fastRide === false" i18n>No</span>
          </td>
        </tr>
        <tr>
          <th i18n>Commodos</th>
          <td>
            <span class="label">
              {{ settings.commodos.button1 }}
              <span class="badge">1</span>
            </span>
            <span class="label">
              {{ settings.commodos.button2 }}
              <span class="badge">2</span>
            </span>
            <span class="label">
              {{ settings.commodos.button3 }}
              <span class="badge">3</span>
            </span>
            <span class="label">
              {{ settings.commodos.button4 }}
              <span class="badge">4</span>
            </span>
          </td>
        </tr>
        <tr>
          <th i18n>Widgets</th>
          <td>
            <span class="label widget" *ngFor="let widget of settings.widgets">{{ widget.widgetType }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- .clr-row -->

<div class="clr-row">
  <div class="clr-col-lg-8 clr-col-md-8 clr-col-12" *ngIf="knownPhones">
    <h2 i18n>5 last known devices</h2>
    <clr-datagrid>
      <clr-dg-column i18n>Phone</clr-dg-column>
      <clr-dg-column i18n>Os</clr-dg-column>
      <clr-dg-column i18n>App</clr-dg-column>
      <clr-dg-column i18n>Last connection</clr-dg-column>
      <clr-dg-column i18n>Refresh Token</clr-dg-column>
      <clr-dg-column i18n>Access Token</clr-dg-column>

      <clr-dg-row *ngFor="let knownPhone of knownPhones">
        <clr-dg-cell [title]="knownPhone.phoneId"
          >{{ knownPhone.manufacturer }} - {{ knownPhone.brand }} {{ knownPhone.model }}</clr-dg-cell
        >
        <clr-dg-cell>{{ knownPhone.osName }} {{ knownPhone.osVersion }}</clr-dg-cell>
        <clr-dg-cell
          >{{ knownPhone.appVersion }} ({{ knownPhone.appBuild }}) - {{ knownPhone.appDistribution }}</clr-dg-cell
        >
        <clr-dg-cell>{{ knownPhone.lastConnection | date: 'dd/MM/yy HH:mm' }}</clr-dg-cell>
        <clr-dg-cell>
          <button
            class="btn btn-danger-outline btn-sm"
            appConfirm
            title="Revoke device"
            *ngIf="knownPhone.latestRefreshToken"
            appConfirmSpecificMessage="Are you sure you want to revoke this device ?"
            (appConfirm)="revokeDevice(knownPhone)"
            i18n
          >
            <cds-icon shape="remove"></cds-icon>
          </button>
        </clr-dg-cell>
        <clr-dg-cell>
          <button
            class="btn btn-danger-outline btn-sm"
            appConfirm
            title="Revoke access token"
            *ngIf="knownPhone.latestAccessToken"
            appConfirmSpecificMessage="Are you sure you want to revoke this access token ?"
            (appConfirm)="revokeAccessToken(knownPhone)"
            i18n
          >
            <cds-icon shape="remove"></cds-icon>
          </button>
        </clr-dg-cell>
      </clr-dg-row>
    </clr-datagrid>
  </div>
  <div class="clr-col-lg-4 clr-col-md-8 clr-col-12" *ngIf="user.updateTrace">
    <h2 i18n>Change history</h2>

    <app-update-trace [updateTrace]="user.updateTrace" [deleteTrace]="user.deleteTrace"></app-update-trace>
  </div>
</div>
