import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/modules/api/services/api.service';
import { ResponseEntities } from 'src/app/modules/api/models';
import { BikeModel } from '../models/bike-model.model';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { definitiveCache } from 'src/app/modules/api/definitive-cache/definitive-cache';

@Injectable({
  providedIn: 'root',
})
export class BikeModelService {
  constructor(private api: ApiService) {}

  private handleError<T>(operation = 'operation', result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message} with error : ${error}`);

      return of(result as T);
    };
  }

  getAll(params: { [param: string]: string }): Observable<ResponseEntities<BikeModel>> {
    return this.api
      .get<ResponseEntities<BikeModel>>('/models', {
        params,
      })
      .pipe(
        map((a) => new ResponseEntities<BikeModel>(BikeModel, a)),
        catchError(this.handleError<ResponseEntities<BikeModel>>('all')),
        definitiveCache()
      );
  }

  getById(id: string): Observable<BikeModel> {
    return this.api.get<BikeModel>(`/models/${id}`, {
      params: {
        projection: 'bikeModelFullAdmin',
      },
    });
  }
}
