export class BikeHistory {
  id: string;
  source: string;
  bikeId: number;
  eventName: string;
  timestamp: Date;
  event: Object;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.source = data.source;
      this.bikeId = data.bikeId;
      this.eventName = data.eventName;
      this.timestamp = new Date(data.timestamp);
      this.event = data.event;
    }
  }
}
/*

export abstract class AbstractBikeHistory {
  id: string;
  source: string;
  bikeId: number;
  eventName: string;
  timestamp: Date;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.source = data.source;
      this.bikeId = data.bikeId;
      this.eventName = data.eventName;
      this.timestamp = new Date(data.timestamp);
    }
  }

  abstract getTitle(): string;
  abstract getDescription(): string;
}

export class BikeHistoryLock extends AbstractBikeHistory {
  event: LockEventHistory;

  constructor(data: any) {
    if (data) {
      super(data);
      this.event = new LockEventHistory(data);
    }
  }
  getTitle(): string {
    return `Bike ${this.event.lock ? 'LOCKED' : 'UNLOCKED'}`;
  }

  getDescription(): string {
    return `by ${this.source}
    (
    Alarm : ${this.event.isAlarmOn ? 'Enabled' : 'Disabled'},
    Batteries (internal : ${this.event.internalBatteryLevel},external : ${this.event.externalBatteryLevel}),
    Location (${this.event.location.lon},${this.event.location.lat}),
    Bike Version
    (app=${this.event['bikeVersion']['appVersion']},
      ble=${this.event['bikeVersion']['bleVersion']},
      img=${this.event['bikeVersion']['imageVersion']},
      bootloader=${this.event['bikeVersion']['bootloaderVersion']})
  )`;
  }
}


export class LockEventHistory {
  bikeVersion: Object;
  lock: boolean;
  isAlarmOn: boolean;
  internalBatteryLevel: number;
  externalBatteryLevel: number;
  customerId: string;
  location: Location;

  constructor(data?: any) {
    if (data) {
      this.bikeVersion = data.bikeVersion;
      this.lock = data.lock;
      this.isAlarmOn = data.isAlarmOn;
      this.internalBatteryLevel = data.internalBatteryLevel;
      this.externalBatteryLevel = data.externalBatteryLevel;
      this.customerId = data.customerId;
      this.location = new Location(data.location);
    }
  }
}
*/
