import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiService } from '../../api/services/api.service';
import { UserStorageService } from './user-storage.service';
import { User } from 'src/app/modules/auth/models/user';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private authenticationSuccessSource = new Subject<User>();
  private logoutSource = new Subject<User>();

  public authenticationSuccess$ = this.authenticationSuccessSource.asObservable();

  public logout$ = this.logoutSource.asObservable();

  constructor(private api: ApiService, private userStorage: UserStorageService, private router: Router) {}

  login(email: string, password: string): any {
    return this.api
      .post<any>('/auth', {
        email,
        password,
        grantType: 'BACK_OFFICE',
      })
      .pipe(
        map((response) => {
          // login successful if there's a jwt token in the response
          if (response && response.accessToken) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            this.userStorage.setUser(response.user);
            this.userStorage.setToken(response.accessToken);

            this.authenticationSuccessSource.next(response.user as User);
          }

          return response.user;
        })
      );
  }

  public logout(): Observable<any> {
    const user = this.userStorage.getUser();

    this.userStorage.clear();

    this.logoutSource.next(user);

    return this.api.delete('/auth');
  }

  public isAuthenticated(): boolean {
    return !!this.userStorage.getToken();
  }

  public redirectToLogin(url: string): Promise<boolean> {
    this.logout();

    return this.router.navigate(['account', 'login'], {
      queryParams: {
        redirect: url,
      },
      replaceUrl: true,
    });
  }
}
