import { ChangeDetectorRef, Component, QueryList, ViewChildren } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { ClrTabLink } from '@clr/angular';

const TABS: any[] = [
  { label: 'V2 (Firmwares)', path: 'firmwares' },
  { label: 'V1 (Softwares)', path: 'softwares' },
];

@UntilDestroy()
@Component({
  selector: 'versions',
  templateUrl: './versions.component.html',
})
export class VersionsComponent {
  constructor(private cdr: ChangeDetectorRef, private route: ActivatedRoute, private router: Router) {}

  tabsData: any[] = TABS;

  ngOnInit(): void {}

  @ViewChildren(ClrTabLink)
  tabs: QueryList<ClrTabLink>;
  ngAfterViewInit() {
    const url = this.router.url.substring(this.router.url.lastIndexOf('/') + 1);
    const currentTabIndex = this.tabsData.findIndex((comp) => comp.path === url);
    if (currentTabIndex > -1) {
      this.tabs.filter((element, index) => index === currentTabIndex)[0].activate();
      this.cdr.detectChanges();
    }
  }
}
